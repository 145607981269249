import React from 'react';
import './StoreProducts.css';
import { UpdateQtyTree,SaveUpdateQtyTree } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { Button, Grid, Typography ,TextField} from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';




class UpdateQuantity extends React.Component{
    state = {
        fetchingData : false,
        productId:null,
        lotProd:false,
        qty_data:false,
        form_option : false,
        add_line:false,
        updated_data:{},
        lot_options: []
    }

    componentDidMount(){
        changeAppTitle('Console | Update Quantity');
        let productId = this.props.match.params.productId;
        this.setState({productId});
        this.fetchQuantityTree(productId);
        
    }

    fetchQuantityTree=async(id)=>{
        this.setState({fetchingData:true});
        let res  = await UpdateQtyTree({id});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else {
            console.log(res.result.form_options.lot)
            this.setState({qty_data: res.result.qty_data, form_option: res.result.form_options});
            if(res.result.form_options.lot != undefined){
                this.setState({lotProd:true});
            }
        }
        this.setState({fetchingData:false});
    }


    handleSubmit=async (e)=>{
        if (this.state.updated_data) {
                this.setState({ fetchingData: true });
                let res = {}
                res = await SaveUpdateQtyTree(this.state.updated_data);
                if (res.error || res.errorMessage) {
                    alert(res.error || res.errorMessage);
                }
                else{
                   await this.fetchQuantityTree(this.state.productId);
                   await this.setState({updated_data:{}});
                }
            }

            this.setState({fetchingData:false});
    }

    handleChange = (e,property=null,newValue=null) => {
        let key = property;
        let value = newValue;
        if(!newValue){
            key = e.target.name;
            if(e.target.type === 'checkbox')
                value = e.target.checked;
            else{
                value = e.target.value;    
                e.preventDefault();
            }
        }
        let updated_data = {...this.state.updated_data,...{[key]:value}};
        this.setState({updated_data});

        if(this.state.lotProd && key == 'product_id'){
            let lot_options = this.state.form_option.lot.filter(val => val.varaint_id == value);
            this.setState({lot_options})
        }


    }

    handelValdation = ()=>{
        let updated_data = this.state.updated_data;
        let flag =  true;
        let mandatry_fields = {'product_id':'Product','location_id':'Location','inventory_quantity':'quantity'};
        if(this.state.lotProd){
            mandatry_fields['lot_id']='Lot/Serial';
        }
        for (let element of Object.keys(mandatry_fields)){
            if(!(Object.keys(updated_data).includes(element))){
                alert(`${mandatry_fields[element]} is Mandatory Field`);
                flag = false;
            }
        }

        return flag;
    }
    

    saveLine = ()=>{
        if(this.handelValdation()){
        this.handleSubmit();
        this.setState({add_line:false});
        }
    }
    
    CreateQtyLine=()=>{
    const new_line = {id:'New',product_id:'New',location_id:'New',lot_id:'New',inventory_quantity:'New',value:'New'}
    const qty_data = [...this.state.qty_data,new_line];
    this.setState({qty_data,add_line:true});
    }

    render(){
        return(
            <>
            <div className="store-orders">
                <Grid container spacing={3}>
                    <Grid item >
                    <Typography variant='h6' sx={{ fontWeight: 'bold'}}>Update Quantity</Typography>
                    </Grid>
                    <Grid item >
                                {this.state.add_line? 
                                <Button 
                                style={{background:"#02A457",color:"beige",fontSize: '13px'}} 
                                onClick={()=>this.saveLine()}>
                                Save 
                                </Button>

                                :
                                <Button 
                                    style={{background:"#02A457",color:"beige",fontSize: '13px'}} 
                                    onClick={()=>this.CreateQtyLine()}
                                >
                                    Create 
                                </Button>

                                    }
                    </Grid>
                </Grid>
             
                
            {this.state.qty_data &&
                    <table className="order-table">
                        <thead>
                            <tr>
                                <th style={{padding:'6px'}}>Product</th>
                                <th style={{padding:'6px'}}>Location</th>
                                {this.state.lotProd &&
                                <th style={{padding:'6px'}}>Lot Id</th>
                                    }
                                <th style={{padding:'6px'}}>On Hand Quantity</th>
                                <th style={{padding:'6px'}}>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {}
                            {this.state.qty_data.map((row)=>(
                                // <tr onClick={()=>this.goTolot(row.id)}>
                                row.id=='New'?
                                (<tr>
                                    <td>
                                    <Select
                                                id="product_id"
                                                onChange={this.handleChange}
                                                value={this.state.updated_data.product_id}
                                                name='product_id'
                                            >
                                                {this.state.form_option.product_variants.map((option)=>(
                                                    <MenuItem value={option.product_id}>
                                                        {option.product_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                    </td>
                                    <td> <Select
                                                id="location_id"
                                                onChange={this.handleChange}
                                                value={this.state.updated_data.location_id}
                                                name='location_id'
                                            >
                                                {this.state.form_option.locations.map((option)=>(
                                                    <MenuItem value={option.location_id}>
                                                        {option.location_name}
                                                    </MenuItem>
                                                ))}
                                            </Select></td>

                                {this.state.lotProd &&
                                    
                                    <td><Select
                                    id="lot_id"
                                    onChange={this.handleChange}
                                    value={this.state.updated_data.lot_id}
                                    name='lot_id'
                                >
                                    {this.state.lot_options.map((option)=>(
                                        <MenuItem value={option.lot_id}>
                                            {option.lot_name}
                                        </MenuItem>
                                    ))}
                                </Select></td>
                                }
                                    <td> <input
                                            id="inventory_quantity"
                                            name="inventory_quantity"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={this.state.updated_data.inventory_quantity}
                                        /></td>
                                    <td></td>
                                </tr>) : <tr>
                                    <td>{row.product_id}</td>
                                    <td>{row['location']}</td>
                                {this.state.lotProd &&

                                    <td>{row['lot']}</td>
                                }
                                    <td>{row['quantity']}</td>
                                    <td>{row['value']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>

            </>
        )
    }

}

export default UpdateQuantity;