import React from 'react';
import './MyCoupons.css';
import urls from '../../urls';
import { changeAppTitle } from '../../Shared/utilities';

const CouponCard=(props)=>{

    return(
        <div className={`coupon__card ${props.coupon['state']}`}>
            <div className="comp-type">{props.coupon['company']['company_type']}</div>
            <div>
                <span className="comp-name">{props.coupon['company']['name']}</span>
            </div>
            <div>
                <span>
                    <span className="highlight">Code # : </span>
                    <span className="reference">{props.coupon['promo_code']}</span>
                </span>
                <span>{props.coupon['rule_date_to']}</span>
            </div>
            <div className="highlight">
                {props.coupon['maximum_use_number'] ?
                    <span>No Of Times # : {props.coupon['maximum_use_number']}</span>  
                    :
                    <></>  
                }
            </div>
            <div className="highlight">
                {props.coupon['rule_minimum_amount'] ?
                    <span>Minimum Order Value: &#8377; {props.coupon['rule_minimum_amount']}</span>
                    :
                    <></>
                }
            </div>
            {/* Ankit Start 16 may 2023 */}
            <div className="highlight">
                {props.coupon['discount_amount'] &&
                    <span>Discount Amount: &#8377; {props.coupon['discount_amount']}</span>
                }
                {props.coupon['discount_percent'] &&
                    <span>Discount Percentage: {props.coupon['discount_percent']}</span>
                }
            </div>
            {/* Ankit End 16 may 2023 */}
            <div className="cta-btn">
                <span>{props.coupon['name']}</span>
            </div>
        </div>
    );
}


class MyCoupons extends React.Component {

    state = {
        coupons:null
    }

    componentDidMount(){
        changeAppTitle('Shopaas | My Coupons');
        this.fetchUserCoupons();
    }

    fetchUserCoupons=async(e)=>{
        try {
            const axios = require("axios");
            const response = await axios(urls.getUserCoupons, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                let coupons = res.result.data;
                this.setState({coupons});
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }


    render() { 
        return (
            <div className="my-orders">
                {this.state.coupons && Object.keys(this.state.coupons).length>0 &&
                    <>
                        <div className="my-coupons__header">
                            <span>Coupons</span>
                        </div>
                        <div className="my-coupons__cards">
                            {Object.keys(this.state.coupons).map((couponId,idx)=>(
                                <>
                                    <CouponCard coupon={this.state.coupons[couponId]}/>
                                </>
                                ))
                            }
                        </div>
                    </>
                }
                {this.state.coupons && Object.keys(this.state.coupons).length===0 &&
                    <div className="no-coupons">
                        There are currently no coupons.
                    </div>
                } 
            </div>
        );
    }
}
 
export default MyCoupons;
