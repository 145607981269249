import React from 'react';
import Button from '@material-ui/core/Button';
import {GetLocationPopup} from '../Popup/Popup';
import { AddLocation } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { AuthContext } from '../../Shared/context';
import './UserLocation.css'


class UserLocation extends React.Component {

    static contextType = AuthContext;

    state = {open:false}

    handlePopupClose=()=>{
        this.setState({open:false});
    }

    render() { 
        return (
            <>
                {/* <Button variant="contained" className="locate-user" onClick={()=>this.setState({open:true})}>
                     Locate me
                </Button> */}
                <div className='locate-user' onClick={()=>this.setState({open:true})}>
                    <AddLocation/>
                    <Tooltip title={`${this.context.custom_session_info?.location || 'Add Location'}`} arrow>
                        <span className='location'>{`${this.context.custom_session_info?.location || 'Add Location'}`}</span>
                    </Tooltip>
                </div>
                <GetLocationPopup 
                    title='Get Location'
                    open={this.state.open}
                    handlePopupClose={this.handlePopupClose}
                />
            </>
        );
    }
}
 
export default UserLocation;
