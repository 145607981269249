const server_origin = process.env.NODE_ENV === 'production'?'https://shopaas.arkess.com':'http://be.localhost'
// const server_origin = "http://be.localhost";
//const server_origin = 'https://shopaas.arkess.com';

const domain = server_origin;

const urls = {
    domain: domain,
    extension: '/shopaas',
    login: domain + '/login',
    // Bishu 1st nov 22 changes related to the auth login options
    authlogin: domain + '/authlogin',
    // Bishu ends here
    getSessionInfo: domain + '/session_info',
    logout: domain + '/session_logout',
    getLocation: domain + '/find_live_location_data',
    confirmLocation: domain + '/set_location_session_data',
    getHomepageData: domain + '/homepage',
    getSliders: domain + '/fetch_sliders',
    getCompanies: domain + '/companies',
    getCompanyInfo: domain + '/get_company_info',
    getProducts: domain + '/products',
    getProductDetails: domain + '/product_details',
    addToCart: domain + '/add_to_cart',
    resetCart: domain + '/reset_cart',
    getCart: domain + '/get_cart',
    checkCart: domain + '/check_cart',
    redeemRewardPoints: domain + '/redeem_reward_points',
    processCheckout: domain + '/process_checkout',
    getAddressess: domain + '/get_address',
    putAdress: domain + '/add_address',
    selectShippingAddress: domain + '/select_shipping_address',
    getGeoData: domain + '/getGeoData',
    getPaymentDetails: domain + '/payment_details',
    validateDelivery: domain + '/validate_delivery',
    payNow: domain + '/pay_now',
    getPaymentConfirmationData: domain + '/payment_confirmation',
    getUserOrders: domain + '/endConsumer/orders',
    getUserCoupons: domain + '/endConsumer/coupons',
    applyPromo: domain + '/apply_coupon',
    sendFeedback: domain + '/order/review_rating',
    cancelOrder: domain + '/order/cancel',
    // Piyush Kumar 26/5/2023
    shortClose:domain + '/order/short_close',
    // Piyush Kumar
    reOrder: domain + '/order/re-order',
    orderDetails: domain + '/order/details',
    editFavourites: domain + '/my_favourite',
    relatedProducts: domain + '/related_products',
    searchCompany: domain + '/search_company',
    searchProduct: domain + '/search_product',
    compareProduct: domain + '/compare_product',
    getReturnCart: domain + '/get_return_cart',
    processReturnOrder: domain + '/return_order',
    registerCompany: domain + '/register_your_company',
    SignUp: domain + '/customer_signup',
    getOtp: domain + '/send_otp',
    resetPassword: domain + '/reset_password',
    redeemPoints: domain + '/redeemPoints',
    getFAQS: domain + '/product_faq',
    getLEGALS: domain + '/product_legal',
    companyCompare: domain + '/compare_companies',
    //Console urls below 
    allowedCompanies:domain + '/allowedCompanies',
    getStoreOrders: domain + '/store/orders',
    getStoreOrderData: domain + '/store/order_data',
    saveStoreOrderData: domain + '/store/save_order_data',
    getOrderDeliveryData: domain + '/store/order/delivery_data',
    saveOrderDeliveryData: domain + '/store/order/save_delivery_data',
    getOrderInvoiceData: domain + '/store/order/invoice_data',
    saveOrderInvoiceData: domain + '/store/order/save_invoice_data',
    companyDataTransfer: domain + '/company/products/data_transfer',
    saveCompanyDataTransfer: domain + '/company/products/save_data_transfer',
//  Ankit Start 07-11-2022 bugid-1602
    getToCompanyData: domain + '/company/products/get_to_field_data',
//  Ankit End 07-11-2022 bugid-1602
    getProductOptions: domain + '/company/products/options',
    productDataUpdate: domain + '/company/products/product_data_update',
    saveProductDataUpdate: domain + '/company/products/save_product_data',
    stockInventory: domain + '/company/stock_inventory',
    saveStockInventory: domain + '/company/save_stock_inventory',
    //Users
    getUsersTreeData:domain  +'/users_tree_data',
    getUserData: domain + '/users',
    saveUserData: domain + '/users',
    
    storeProducts: domain + '/store/products',
    saveStoreProducts: domain + '/store/save_product',


    //mohit categories added...
    storeCategories: domain + '/store/categories',
    saveStoreCategory: domain + '/store/save_category',

    
    storeBrands: domain + '/store/brands',
    saveStoreBrand: domain + '/store/save_brand',

        
    storeUomCategory: domain + '/store/uom_category',
    saveUomCategory: domain + '/store/save_uom_category',
    
    sendContactusData: domain + '/contactus_data',

    //product variant
    // Ankit Start 30-08-22
    // saveStoreProductsVarient: domain + '/store/save_product',
    saveStoreProductsVarient: domain + '/store/save_variant_product',
    // Ankit Start 30-08-22
    storeProductVarientTree: domain + '/store/productVarients',
    renewMembership: domain + '/store/renew_membership',
    pricelists: domain + '/store/pricelists',
    // Piyush Kumar bugid 1587
    changeActivePriceList1: domain + '/store/changeActivePriceList1',
    // Piyush Kumar End
    savePricelist: domain + '/store/save_pricelist',
    //Variant  
    getVariantData:domain + '/get_VariantData',
    //promotion
    savePromtionData:domain + '/save_promotion_program',
    getPromotionData:domain + '/promotion_program',
    getSpecificData:domain + '/get_specific_data',
    // pankaj loyalty 
    saveLoyaltyData:domain + '/save_loyalty',
    getLoyaltyData:domain + '/get_loyalty',
    //payment Data
    savePaymentData:domain +'/save_paymentData',
    // Dashboard urls below
    dashboardOrdersData: domain + '/dashboard/ordersData',
    // languages urls
    languages:domain + '/languages',
    //contact us 
    getContactUsData:domain +'/contactUsData',

    // Ankit Start bugid-1716 09-12-2022
    LotSerialTree: domain+'/lot_serial_tree',
    prodcutLot: domain+'/product_lot',
    saveProdcutLot: domain+'/save_product_lot',
    // Ankit End bugid-1716 09-12-2022
    // Ankit Start 10-01-2023
    UpdateQty : domain+'/UpdateQuantity',
    SaveUpdateQty:domain+'/SaveUpdateQuantity',
    // Ankit End 10-01-2023
    // Ankit Start 24 Jan 2023
    ProductAttributeTree:domain+'/ProductAttributeTreeData',
    ProductAttributeForm:domain+'/ProductAttributeFormData',
    SaveProductAttribute:domain+'/SaveProductAttributeData',
    // Ankit End 24 Jan 2023
    // Ankit Start 8 Feb 2023
    toggleCompanyUser:domain+'/internal_user/convert_user',
    // Ankit End 8 Feb 2023
    // Ankit Start 14 Feb 2023
    customerOrderDetails:domain+'/endConsumer/orderDetails',
    // Ankit End 14 Feb 2023
    // Ankit Start 1 May 2023
    razorpay_api : domain+'/payment/razorpay/capture',
    // Ankit End 1 May 2023
    addDelivery : domain+'/delivery_process',
    // Ankit Start 22 May 2023
    company_compare_buy_now: domain+'/company_compare_buy_now',
    get_company_options: domain+'/compare_company_options'
    // Ankit End 22 May 2023
}

export default urls;
