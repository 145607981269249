import React from 'react';
import './AddressForm.css';
import { AuthContext } from '../../Shared/context';
import { Select, MenuItem } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import urls from '../../urls';
import { formValidation } from '../../Shared/utilities';
import Loader from '../../Components/Shared/Loader';

class AddressForm extends React.Component {

    static contextType = AuthContext;

    state = {
        newAddress: {
            id: null,
            name: "",
            street: "",
            street2: "",
            zip: null,
            city: { id: null, name: "" },
            state: { id: null, name: "" },
            country: { id: null, name: "" },
            phone_code: '91',
            email: "",
            phone: "",
        },
        geoData: {
            country: {},
            state: {},
            city: {}
        },
        showOtp: false,
        showOtpVerfied: false,
        loadingData: true,
        errors: {}
    }

    componentDidMount() {
        let newAddress = this.context.newAddress;
        if (newAddress) {
            this.setEditAddress(newAddress);
            this.context.setNewAddress(null);
        }
        else {
            this.setState({ loadingData: false });
            this.fetchAddressDetails();
            this.fetchGeoData();
        }
    }

    setEditAddress = async (newAddress) => {
        let country_id = newAddress.country['id']
        let country = { [country_id]: newAddress.country }
        let state_id = newAddress.state['id']
        let state = { [state_id]: newAddress.state }
        let city_id = newAddress.city['id']
        let city = { [city_id]: newAddress.city }
        let geoData = { country, country_id, state, state_id, city, city_id }
        await this.setState({ geoData });
        this.setState({ newAddress });
        this.setState({ loadingData: false });
        await this.fetchGeoData();
        await this.fetchGeoData(country_id);
        await this.fetchGeoData(country_id, state_id);
    }


    fetchAddressDetails = async () => {
    }

    fetchGeoData = async (country_id = null, state_id = null) => {
        try {
            const axios = require("axios");
            const response = await axios(urls.getGeoData, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        country_id,
                        state_id
                    }
                })
            }, 7000);
            const res = await response.data;
            if (!res.result.errorMessage && !res.error) {
                let geoData = { ...this.state.geoData, ...res.result.data };
                await this.setState({ geoData });
                if (res.result.data.default) {
                    this.handleChange(null, 'country', res.result.data.default);
                }
            }
            else
                alert(res.result.errorMessage || res.error);
        }
        catch (err) {
            alert(err.message);
        }
    }

    handleValidation = async (key = null) => {
        let errors = formValidation(this.state.errors, this.state.newAddress, key);
        this.setState({ errors });
        return (errors);
    }


    handleChange = async (e = null, x = null, val = null) => {
        let newAddress = this.state.newAddress;
        let key = x;
        let value = val;
        if (!val) {
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let fetchGeoData = false;
        if (['country', 'state', 'city'].includes(key)) {
            let tempValue = value;
            value = this.state.geoData[key][value];
            fetchGeoData = true;
            if (key === 'country') {
                newAddress = { ...newAddress, ...{ 'phone_code': value['phone_code'] } };
                if (tempValue != newAddress?.country?.id)
                    // pankaj bugid 137 
                    newAddress = { ...newAddress, ...{ 'state': {}, 'city': {}, 'zip': '' } };
            }
            else if (key === 'state') {
                if (tempValue != newAddress?.state?.id)
                    newAddress = { ...newAddress, ...{ 'city': {}, 'zip': '' } };
            }
        }
        newAddress = { ...newAddress, ...{ [key]: value } };
        if (['mobile', 'phone'].includes(key))
            newAddress = { ...newAddress, ...{ 'mobile': value, 'phone': value } };
        await this.setState({ newAddress });
        this.handleValidation(key);
        if (fetchGeoData) {
            this.fetchGeoData(this.state.newAddress.country['id'], this.state.newAddress.state['id']);
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let errors = await this.handleValidation();
            if (Object.keys(errors).length > 0) {
                alert('Please fill all fields properly')
                return;
            }
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.putAdress, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        ...this.state.newAddress
                    }
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                if (this.props.history.location.pathname.includes('my/address')) {
                    this.props.goBack();
                }
                else {
                    this.props.history.push(urls.extension + '/shop/checkout');
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            return;
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
            return;
        }
    }

    goBackLastPage = () => {
        if (this.props.history.location.pathname.includes('my/address')) {
            this.props.goBack();
        }
        else {
            let pathname = urls.extension + '/shop/checkout';
            this.props.history.push({ pathname });
        }
    }
    // pankaj verify number
    VerfiyState = () => {
        console.log('savhdjhasd')

        if (this.state.newAddress.phone) {
            this.setState({ showOtp: true })
        }
        else {
            alert('Must have a number in the box')
        }
    }
    verifyOtp = () => {
        console.log('asdjhvsad')
        this.setState({ showOtpVerfied: true })


    }
    // end

    render() {
        return (
            <>
                <div className="address-form">
                    
                    <form onSubmit={this.handleSubmit}>
                        <h3 className="address-form__heading">Shipping Address</h3>
                        <div className="address-form__inputs" style={{ width: "100%" }}>
                            <label htmlFor="name">Name</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.newAddress['name']}
                            />
                            <span className="input-error">{this.state.errors["name"]}</span>
                        </div>
                        <div className="address-form__inputs" style={{ width: "100%" }}>
                            <label htmlFor="phone">Phone</label>
                            <span style={{ display: "flex", gap: "5px" }}>
                                <span className="prefix">{'+' + this.state.newAddress['phone_code']}</span>
                                <input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    onChange={this.handleChange}
                                    value={this.state.newAddress['phone']}
                                    style={{ minWidth:260 }}
                                />
                            </span>
                            <br />
                            {/* pankaj add otp system  */}
                            {this.state.showOtp == false ?
                                <span onClick={this.VerfiyState}>Verify</span> : null}
                            {this.state.showOtpVerfied == true ?
                                <span>Verifed</span> : null}

                            {this.state.showOtp ?
                                <div className="" >
                                    {this.state.showOtpVerfied == false ?
                                        <label htmlFor="otp">OTP
                                            <input
                                                id="otp"
                                                name="otp"
                                                type="otp"
                                                onChange={this.handleChange}
                                            // value={}
                                            />
                                        </label> : null}
                                    {this.state.showOtpVerfied == false ?
                                        <span onClick={this.verifyOtp} style={{ float: 'right' }}>Tick</span> : null}
                                    <span className="input-error">{this.state.errors["otp"]}</span>
                                </div>
                                : null
                            }
                            {/* pankaj  add otp system end */}
                            <span className="input-error">{this.state.errors["phone"]}</span>
                        </div>
                        <div className="address-form__inputs" style={{ width: "50%" }}>
                            <label htmlFor="email">Email</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={this.handleChange}
                                value={this.state.newAddress['email']}
                            />
                            <span className="input-error">{this.state.errors["email"]}</span>
                        </div>
                        <div className="address-form__inputs" style={{ width: "100%" }}>
                            <label htmlFor="street">Street and Number</label>
                            <input
                                id="street"
                                name="street"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.newAddress['street']}
                            />
                            <span className="input-error">{this.state.errors["street"]}</span>
                        </div>
                        <div className="address-form__inputs" style={{ width: "100%" }}>
                            <label htmlFor="steet2">Street 2</label>
                            <input
                                id="street2"
                                name="street2"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.newAddress['street2']}
                            />
                        </div>
                        {!this.state.loadingData &&
                            <div className="address-form__inputs" style={{ width: "100%" }}>
                                <label htmlFor="country">Country</label>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper-label"
                                    onChange={this.handleChange}
                                    value={this.state.newAddress['country']['id']}
                                    style={{ width: '250px' }}
                                    name='country'
                                >
                                    {Object.keys(this.state.geoData.country).map((country_id, idx1) => (
                                        <MenuItem value={country_id}>
                                            {this.state.geoData.country[country_id]['name']}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                                <span className="input-error">{this.state.errors["country"]}</span>
                            </div>
                        }
                        {this.state.newAddress.country['id'] && this.state.newAddress.country['id'] === this.state.geoData.country_id &&
                            <div className="address-form__inputs" style={{ width: "100%" }}>
                                <label htmlFor="email">State/Province</label>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper-label"
                                    onChange={this.handleChange}
                                    value={this.state.newAddress['state']['id']}
                                    style={{ width: '250px' }}
                                    name='state'
                                >
                                    {Object.keys(this.state.geoData.state).map((state_id, idx1) => (
                                        <MenuItem value={state_id}>
                                            {this.state.geoData.state[state_id]['name']}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                                <span className="input-error">{this.state.errors["state"]}</span>
                            </div>
                        }
                        {this.state.newAddress.state['id'] && this.state.newAddress.state['id'] === this.state.geoData.state_id &&
                            <div className="address-form__inputs" style={{ width: "100%" }}>
                                <label htmlFor="city">City</label>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper-label"
                                    onChange={this.handleChange}
                                    style={{ width: '250px' }}
                                    value={this.state.newAddress['city']['id']}
                                    name='city'
                                >
                                    {Object.keys(this.state.geoData.city).map((city_id, idx1) => (
                                        <MenuItem value={city_id}>
                                            {this.state.geoData.city[city_id]['name']}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                                <span className="input-error">{this.state.errors["city"]}</span>
                            </div>
                        }
                        <div className="address-form__inputs" style={{ width: "40%" }}>
                            <label htmlFor="zip">Zip Code</label>
                            <input
                                id="zip"
                                name="zip"
                                type="number"
                                onChange={this.handleChange}
                                value={this.state.newAddress['zip']}
                            />
                            <span className="input-error">{this.state.errors["zip"]}</span>
                        </div>
                    </form>
                </div>
                {/* mohit comment out it back feb 1*/}
                <div className="navigation-btns">
                    <button className="btn__back"
                        onClick={() => this.goBackLastPage()}
                    >
                        <ArrowBack />  Back
                    </button>
                    <button className="btn__next"
                        onClick={(e) => this.handleSubmit(e)}
                    >
                        Next  <ArrowForward />
                    </button>
                </div>
                {/* mohit comment out it back  feb 1*/}
                <Loader open={this.state.fetchingData} handleClose={() => { }} />
            </>
        );
    }
}

export default AddressForm;
