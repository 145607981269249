import React from 'react';
import './MyAddress.css';
import urls from '../../urls';
import { Edit,Add } from '@material-ui/icons';
import { AuthContext } from '../../Shared/context';
import AddressForm from '../../Components/AddressForm/AddressForm';
import Loader from '../../Components/Shared/Loader';

class MyAddress extends React.Component {

    static contextType = AuthContext;

    state = {
        addressData:{partners:null,billing_address:null,selected_shipping_address:null},
        editAddress:false,fetchingData:false
    }

    componentDidMount(){
        this.fetchAddressess();
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.search !== this.props.location.search){
            let currentSearch = this.props.location.search || '';
            if(!currentSearch.includes("id"))
                this.goBack();
        }
    }

    fetchAddressess = async () => {
        try {
            this.setState({fetchingData:true});
            const axios = require("axios");
            const response = await axios(urls.getAddressess, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                let addressData = this.state.addressData;
                addressData = {...addressData,...res.result.data}
                this.setState({addressData});
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            alert(err.message);
            this.setState({fetchingData:false});
        }
    }

    addOrEditAddress=(address=null)=>{
        if(address){
            this.context.setNewAddress(address);
        }
        this.setState({editAddress:true});
        this.changeUrlParams('id',address?.id || 'New');
    }

    changeUrlParams=(key,value)=>{
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        searchParams.delete(key)
        if(key && value)
            searchParams.append(key,value);
        searchParams = searchParams.toString();
        let newPageRoute = `?${searchParams}`;
        this.props.history.push(this.props.history.location.pathname +newPageRoute);
    }

    addressBox=(address,highlightAddressId,showFooterBtn=false)=>{
        return(
            <div className={`myaddress-box ${highlightAddressId === address['id']?"highlight":""}`}>
                <div style={{padding:"5px 10px"}}>
                    {address['name'] && <span>{address['name']} <br/></span> }
                    {address['street'] && <span>{address['street']} <br/></span> }
                    {address['street2'] && <span>{address['street2']} <br/></span> }
                    {`${address['city']['name']}`} &nbsp;&nbsp;
                    {`${address['zip']}`} <br/> 
                    {`${address['state']['name']}`} <br/>
                    {`${address['country']['name']}`}
                </div>
                {/* Ankit Start 13-1-2023 (Removing Edit Option From Address) */}
                {address['is_editable'] && 
                <Edit className="edit-address" onClick={()=>this.addOrEditAddress(address)}/>
                }
                {/* Ankit End 13-1-2023 */}
            </div>
        );
    }

    goBack=()=>{
        this.setState({editAddress:false,addressData:{partners:null,billing_address:null,selected_shipping_address:null}});
        this.fetchAddressess();
    }

    render() { 
        return (
            <>
            {this.state.editAddress===false ?
                <div className="addressess">
                    <div className="shipping-address">
                        <h3 className="shipping-address__heading">Manage Addresses</h3>
                        <button className="add-address" onClick={()=>this.addOrEditAddress()}>
                            <Add style={{width:"20px",height:"20px"}} /> Add an address
                        </button>
                        {this.state.addressData['partners'] && Object.keys(this.state.addressData['partners']).length>0 &&
                            <div className="myaddress-boxes">
                                {Object.keys(this.state.addressData['partners']).map((partner_id,idx)=>{
                                    return(
                                        this.addressBox(
                                            this.state.addressData['partners'][partner_id],
                                            this.state.addressData['selected_shipping_address'],
                                            true
                                            )
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
                :
                <AddressForm 
                    history={this.props.history}
                    goBack={this.goBack}
                />
            }
            <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </>
        );
    }
}
 
export default MyAddress;
