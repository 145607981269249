import React from 'react';
import urls from '../../urls';
import { changeAppTitle,formValidation } from '../../Shared/utilities';

class ResetPassword extends React.Component {

    state = {
        resetData:{
            id:null,
            email:"",
            otp:"",
            passwd:'',
            confirmPasswd:'',
            token:''
        },
        errors: {},
        otpSent:false,
        
    }

    componentDidMount(){
        changeAppTitle('Shopaas | Reset Password');
        // const { token } = this.props.match.params;
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        
        let urltoken = searchParams.get('token');
        if (urltoken !=null){

            let resetData = this.state.resetData;
            // resetData = {resetData:{token:urltoken}};        
            resetData = {...resetData,...{'token':urltoken}};
            this.setState({resetData});
                // resetData = {...resetData,...{'token':urltoken}};
            // await this.setState({resetData});
            }
        
    }

    handleValidation=async(key=null)=>{
        let errors = formValidation(this.state.errors,this.state.resetData,key);        
        this.setState({errors});
        return(errors);
    }

    handleChange=async(e)=>{
        let resetData = this.state.resetData;
        e.preventDefault();
        let key = e.target.name;
        let value = e.target.value;

        resetData = {...resetData,...{[key]:value}};
        if (this.state.resetData.token != ''){
            resetData['token'] = this.state.resetData.token;
        }
        await this.setState({resetData});
        this.handleValidation(key);
    }

    handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            const axios = require("axios");
            let url = urls.getOtp;
            let errors = {};
            let params = {email:this.state.resetData.email};
            if(this.state.otpSent || this.state.resetData.token !=''){
                errors = await this.handleValidation();
                url = urls.resetPassword;
                params = {...params,...this.state.resetData};
                // if (this.state.resetData.resetData.token != ''){
                //     params['token'] = this.state.resetData.resetData.token;
                // }
            }
            else{
                errors = await this.handleValidation('email');
            }
            if((Object.keys(errors).length>0 && this.state.resetData.token =='') || Object.keys(errors).length>1){
                alert('Please fill all fields properly')
                return;
            }
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(!this.state.otpSent && this.state.resetData.token =='')
                    this.setState({otpSent:true});
                else{
                    alert('success');
                    this.props.history.push(urls.extension + '/login');
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            return;
        } 
        catch (err) {
            alert(err.message);
            return;
        }
    }

    render() { 
        return (
            <>
                <div className="signup-form" style={{minHeight:"fit-content"}}>
                    <form onSubmit={this.handleSubmit}>
                        {(!this.state.otpSent && this.state.resetData.token =='') &&
                            <>
                                <div className="signup-form__inputs" >
                                    <label htmlFor="email">Your Email</label>
                                    <input
                                        // id="email"
                                        name="email"
                                        type="email"
                                        onChange={this.handleChange}
                                        value={this.state.resetData['email']}
                                    />
                                    <span className="input-error">{this.state.errors["email"]}</span>
                                </div>
                                <button className="sign-up-btn" 
                                    onClick={(e)=>this.handleSubmit(e)}
                                >
                                    Get OTP
                                </button>
                            </>
                        }
                        {(this.state.otpSent || this.state.resetData.token !='')  &&
                            <>
                                <div className="signup-form__inputs" >
                                    <label htmlFor="otp">OTP</label>
                                    <input
                                        id="otp"
                                        name="otp"
                                        type="otp"
                                        onChange={this.handleChange}
                                        value={this.state.resetData['otp']}
                                    />
                                    <span className="input-error">{this.state.errors["otp"]}</span>
                                </div>
                                <div className="signup-form__inputs" >
                                    <label htmlFor="passwd">Password</label>
                                    <input
                                        id="passwd"
                                        name="passwd"
                                        type="passwd"
                                        onChange={this.handleChange}
                                        value={this.state.resetData['passwd']}
                                    />
                                    <span className="input-error">{this.state.errors["passwd"]}</span>
                                </div>
                                <div className="signup-form__inputs" >
                                    <label htmlFor="confirmPasswd">Confirm Password</label>
                                    <input
                                        id="confirmPasswd"
                                        name="confirmPasswd"
                                        type="confirmPasswd"
                                        onChange={this.handleChange}
                                        value={this.state.resetData['confirmPasswd']}
                                    />
                                    <span className="input-error">{this.state.errors["confirmPasswd"]}</span>
                                </div>
                                <button className="sign-up-btn" 
                                    onClick={(e)=>this.handleSubmit(e)}
                                    value="reset"
                                >
                                    Reset
                                </button>
                                <button className="sign-up-btn" 
                                    onClick={(e)=>this.setState({otpSent:false})}
                                    value="back"
                                    style={{marginTop:"10px",background:"#875a7b"}}
                                >
                                    Back
                                </button>
                            </>
                        }
                    </form>
                </div>
            </>
        );
    }
}
 
export default ResetPassword;
