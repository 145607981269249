import React from 'react';
// import './StoreProduct.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { storeProducts,saveStoreProductsVarient,storeProductVariantTree } from '../../../Shared/consoleServices';
import { Select,MenuItem } from '@material-ui/core';
import Loader from '../../../Components/Shared/Loader';
import TaxesPopup from './TaxesPopup';
import Chip from '@material-ui/core/Chip';
import urls from "../../../urls";
import { Tab,Alert } from '@mui/material';
import { TabList,TabContext } from '@material-ui/lab';
// import VariantSelection from './VariantProduct';
import Stack from '@mui/material/Stack';
import { Button, CardActionArea, Input, TextField, Grid, FormControl, InputLabel, Checkbox, FormControlLabel, Typography } from '@mui/material';
// Ankit Start 27 April 2023
import ProductDataPopup from '../StoreProducts/productDataPopup';
// Ankit End 27 April 2023
class storeProductVariant extends React.Component {

    state = {
        viewMode:true,
        productId:null,
        productData:null,
        updatedProductData:{},
        errors:{},
        form_options:{},
        fetchingData:false,
        updatingProductData:false,
        productUpdated:false,
        popupData:{open:false},
        selectedTab:"General Info",
        Tabs:['General Info','Sales','eCommerce'],
        // Ankit  bugid-1564 Start 30-08-2022
        popupName:"",
        // Ankit  bugid-1564 End 30-08-2022
         // Ankit start bugid-1674
        ecommerce_api:false,
         // Ankit End bugid-1674
        server_msg: false,
        severity: false,
        // Ankit Start 27 April 2023
        productFormData:{open:false,form_data:null,origin:null,selected_value:null}
        // Ankit End 27 April 2023
    }
    labelStyleSelect = { fontSize: 15 }

    componentDidMount(){
        changeAppTitle('Console | Product');
        let productId = this.props.match.params.productId;
        this.setState({productId});
        if (productId=='New'){
        this.setState({viewMode:false});
        }
        this.fetchProductData(productId);

        
    }

    fetchProductData=async(id)=>{
        this.setState({fetchingData:true});
        let res  = await storeProductVariantTree({id});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({productData:res.result.data.product,form_options:res.result.data.form_options});
        }
        this.setState({fetchingData:false});
    }

    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        // Ankit Start 27 April 2023
        if(e && e.target.value === 'Search_More'){
            this.openProductDataPopup(e.target.name);
            return
        }
        // Ankit End 27 April 2023
        if(!newValue){
            key = e.target.name;
            if(e.target.type === 'checkbox')
                value = e.target.checked;
            else{
                value = e.target.value;    
                e.preventDefault();
            }
        }
        let updatedProductData = this.state.updatedProductData;
        updatedProductData = {...updatedProductData,...{[key]:value}};
        // Ankit Start 1 Feb 2023
        let productData = this.state.productData;
        productData = {...productData,...{[key]:value}};
        // this.setState({updatedProductData});
        this.setState({updatedProductData,productData});
        // Ankit Start 1 Feb 2023

    }


    changeImage=(e)=>{
        let newImage = e.target.files[0];
        if(newImage){
            this.handleChange(null,e.target.name,newImage);
        }
    }


    handleSubmit=async(e)=>{
        if(this.state.updatedProductData && Object.keys(this.state.updatedProductData).length>0){
            this.setState({fetchingData:true});
            let res = await saveStoreProductsVarient({id:this.state.productData['id'],updatedData:this.state.updatedProductData});
            if(res.error || res.errorMessage){
                alert(res.error || res.errorMessage);
                this.setState({ server_msg: 'Failed !! ' + res.errorMessage, severity: 'error',fetchingData:false});
            }
            else{
                // this.setState({productUpdated:true});
                // this.setState({productData:res.result.data.product,form_options:res.result.data.form_options});
                const productId = this.state.productId;
                if (productId == 'New'){
                    this.props.history.push(urls.extension + '/console/productvarient/' + (res.product_id || 'New'));
                    // Ankit Start 2023 14-04-2023
                    await this.setState({ecommerce_api:false,selectedTab: "General Info"})
                    // Ankit Start 2023 14-04-2023
                    await this.fetchProductData(res.product_id);
                     // Ankit Start 20 march 2023 (image is getting blank)
                     if (this.state.updatedProductData?.reference_image){
                        let productData = this.state.productData
                        productData = {...productData,image:this.state.updatedProductData?.reference_image}
                       await this.setState({productData})
                    }
                    // Ankit End 20 march 2023 (image is getting blank)

                    this.setState({productId:res.product_id,server_msg: 'Success', severity: 'success',updatedProductData:{}});
                }else{
                // Ankit Start 2023 14-04-2023
                    await this.setState({ecommerce_api:false,selectedTab: "General Info"})
                    // Ankit Start 2023 14-04-2023
                await this.fetchProductData(productId);
                // Ankit Start 20 March 2023
                if (this.state.updatedProductData?.reference_image){
                    let productData = this.state.productData
                    productData = {...productData,image:this.state.updatedProductData?.reference_image}
                    await this.setState({productData})
                }
                // Ankit End 20 March 2023
                this.setState({ server_msg: 'Success', severity: 'success',updatedProductData:{}});
                }
            }
            
            this.setState({fetchingData:false});
        }
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === "edit"){
            show = true;
        }
        return show;
    }
    // Ankit bugid-1564  End 30-08-22

    // openTaxesPopup=()=>{
    //     let popupData = this.state.popupData;
    //     let taxes_id = this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id || [];
    //     let options = this.state.form_options['taxes_id'];
    //     popupData = {...popupData,...{open:true,taxes_id,options}};
    //     this.setState({popupData});
    // }

    // handlePopupActions=async(selectedItems=null)=>{
    //     let popupData = this.state.popupData;
    //     popupData = {...popupData,...{open:false}};
    //     this.setState({popupData});
    //     if(selectedItems){
    //         let updatedProductData = this.state.updatedProductData;
    //         updatedProductData = {...updatedProductData,...{taxes_id:selectedItems}}
    //         await this.setState({updatedProductData});
    //     }
    // }

    // removeTax=(tax_id)=>{
    //     let taxes = this.state.updatedProductData?.taxes_id;
    //     taxes = taxes.filter(i=>i!==tax_id);
    //     this.handleChange(null,'taxes_id',taxes);
    // }

    openPopup = (type)=>{
        let popupData = this.state.popupData;
        let idss = this.state.updatedProductData?.[type] || this.state.productData?.[type] || [];
        let options = this.state.form_options[type];
        popupData = {...popupData,...{open:true,idss,options}}; 
        this.setState({popupData,popupName:type});
        
    }


    handlePopupActions=async(selectedItems=null, type=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if(selectedItems && type){
            let updatedProductData = this.state.updatedProductData;
            updatedProductData = {...updatedProductData,...{[type]:selectedItems}};
            await this.setState({updatedProductData});
        }
    }
    removeTax=(tax_id,type)=>{
        let remove = this.state.updatedProductData?.[type] || this.state.productData?.[type];
        remove = remove.filter(i=>i!==tax_id);
        this.handleChange(null,type,remove);
    }

    // Ankit bugid-1564  End 30-08-22


    getDisplayImage(imageToUpload){
        if(typeof(imageToUpload) !== typeof('string'))
            imageToUpload = URL.createObjectURL(imageToUpload);
        else
            imageToUpload = urls.domain + imageToUpload;
        return imageToUpload;
    }

    getFieldValue=(field)=>{
        let value = this.state.productData[field];
        if(this.state.updatedProductData && this.state.updatedProductData.hasOwnProperty(field))
            value = this.state.updatedProductData[field];
        return(value);
    }

    // Ankit Start 22/12/2022 bugid-1674
    // onTabChange=async(e,newValue)=>{
    //     let getSpecificData = false;
    //     if(newValue === 'Variants' && !this.state.form_options?.attrs){
    //         getSpecificData = true;
    //     }
    //     if(getSpecificData){
    //         this.setState({fetchingData:true});
    //         let res  = await storeProducts({id:this.state.productId,'getSpecificData':"getVariantData"});
    //         if(res.error || res.result.errorMessage){
    //             alert(res.error || res.result.errorMessage);
    //         }
    //         else{
    //             let form_options = this.state.form_options;
    //             form_options = {...form_options,...res.result.data.form_options}
    //             this.setState({form_options});
    //             this.setState({selectedTab:newValue});
    //         }
    //         this.setState({fetchingData:false});
    //     }
    //     else{
    //         this.setState({selectedTab:newValue});
    //     }
    // }
    // pankaj add variant value  

onTabChange=async(e,newValue)=>{
        let getSpecificData = false;
        console.log(!this.state.form_options?.attrs)
        if((newValue === 'eCommerce') && !this.state.form_options?.attrs){
            getSpecificData = true;
        }
        if(getSpecificData){
            this.setState({fetchingData:true});
            let res = false
        if(newValue=== 'eCommerce' && !this.state.ecommerce_api){
                res  = await storeProductVariantTree({id:this.state.productId,'getSpecificData':"Ecommerce"});
                if(res.error || res.result.errorMessage){
                    alert(res.error || res.result.errorMessage);
                }else{
                    let productData = {...this.state.productData, ...res.result.all_ecommerce_data.ecommerce_data};
                    let form_options = {...this.state.form_options, ...res.result.all_ecommerce_data.ecommerce_form_options};
                    this.setState({ productData,form_options});
                    this.setState({selectedTab:newValue, ecommerce_api:true});
                    // if ('extra_media' in res.result.all_ecommerce_data) {
                    // const ExtraMediaData = res.result.all_ecommerce_data.extra_media
                    // this.setState({ ExtraMediaData, ExtraMedia:true });
                    // }
            // Ankit End bugid-1613 28-09-2022
            }
            }else{
                this.setState({selectedTab:newValue});
            }
            }else{
                this.setState({selectedTab:newValue});
            }
            this.setState({fetchingData:false});
        }


    // Ankit End 22/12/2022 bugid-1674

    getVariantList1 = (e)=>{ 
    
        
        this.handleChange(null,'attribute_value',e)

    }

    // Ankit Start 29-11-2022
    discardPage = ()=>{    
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    // Ankit Start 27 April 2023
    handleProductDataPopupActions = (type=null,selected=null,option=null)=>{
        if (type){
            console.log(type,selected,option);
            let form_options = this.state.form_options;
            let updatedProductData = this.state.updatedProductData;
            updatedProductData = {...updatedProductData,[type]:selected};
            form_options = {...form_options,[type]:{...form_options[type],...option}};
            this.setState({updatedProductData,form_options});
        }
        let productFormData = {...this.state.productFormData,open:false}
          
        this.setState({productFormData})
    }

    openProductDataPopup = (val)=>{
        let productFormData = {open:true,
            form_data:this.state.form_options[val],
            origin:val,
            selected_value:(this.state.updatedProductData?.[val] || this.state.productData[val])};
        this.setState({productFormData});
    }

    // Ankit End 27 April 2023


    render() { 
        return (
            <div className="console-form">
                {this.state.productData &&  !this.state.productUpdated &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                
                                {this.showBtn('edit') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                                        {`${this.state.viewMode?"Edit":"Save"}`}
                                    </Button>
                                }
                                {/* Ankit Start 29-11-2022 */}
                                <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.discardPage()}>
                                    Discard       
                                </Button>
                                <h3 className="console-form__heading">{this.state.productData['name']}</h3>
                                {/* Ankit End 29-11-2022 */}
                            </span>
                            <span className="header__right">
                            </span>
                        </header>
                        
                        {/* mohit start */}
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            {this.state.server_msg ?
                                <Alert variant="filled" style={{ marginBottom: 10 }} onClose={() => { this.setState({ server_msg: false }) }} severity={this.state.severity}>{this.state.server_msg}</Alert>
                                : ''}
                        </Stack>
                        {/* mohit End */}

                        <TabContext value={this.state.selectedTab}>
                            <TabList 
                                onChange={this.onTabChange}                                 
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                {this.state.Tabs.map((tab)=>(
                                    <Tab 
                                        label={tab} 
                                        value={tab} 
                                    />
                                ))}
                            </TabList>
                        </TabContext>   
                        {this.state.selectedTab === 'General Info' && 
                            <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                                <span>
                                    {(this.state.updatedProductData?.reference_image || this.state.productData?.image) &&
                                        <div className="uploaded-image">
                                            <img className="preview" src={this.getDisplayImage(this.state.updatedProductData?.reference_image || this.state.productData?.image)} alt="upload" />
                                        </div>
                                    }
                                    <input 
                                        type="file"
                                        id="reference_image"
                                        name="reference_image"
                                        title="upload image"
                                        accept="image/*"
                                        onChange={this.changeImage}
                                    />
                                </span>
                                <span>
                                    <div className="console-form__inputs" >
                                        <label htmlFor="sale_ok">Can be Sold</label>
                                        <input
                                            id="sale_ok"
                                            name="sale_ok"
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('sale_ok')}
                                        />
                                    </div>
                                    <div className="console-form__inputs" >
                                        <label htmlFor="purchase_ok">Can be Purchased</label>
                                        <input
                                            id="purchase_ok"
                                            name="purchase_ok"
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('purchase_ok')}
                                        />
                                    </div>
                                    <div className="console-form__inputs" >
                                        <label htmlFor="is_published">Publish</label>
                                        <input
                                            id="is_published"
                                            name="is_published"
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('is_published')}
                                        />
                                    </div>
                                </span>
                                
                                    
                                    <Grid container style={{ marginBottom: 5 }}  >
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1} >
                                      
                                        <TextField
                                            label={<Typography variant="headline" style={this.labelStyle}> Product Name </Typography>}
                                            id="name"
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.name || this.state.productData['name']}
                                            size="small"
                                        />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1}>
                                            <span className="input-error">{this.state.errors["name"]}</span>
                                        </Grid>
                                    </Grid>
                                       
                                    
                                    <Grid container md={3} style={{ marginBottom: 3 }} >
                                    <Grid item xs={12} md={13} mr={1}>
                                        <TextField
                                            label={<Typography variant="headline" style={this.labelStyle}> Internal Reference </Typography>}
                                            id="default_code"
                                            name="default_code"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.default_code || this.state.productData['default_code']}
                                            size="small"
                                        />                                       
                                    
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1}>
                                            <span className="input-error">{this.state.errors["default_code"]}</span>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                
                                <Grid container style={{ marginBottom: 10 }}  >
                                <Grid container md={3} style={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={13} mr={1} >

                                        <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Sales Price </Typography>}
                                            id="list_price"
                                            name="list_price"
                                            type="number"
                                            min={0}
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.list_price || this.state.productData['list_price']}
                                            size="small"
                                        />
                                        <span className="input-error">{this.state.errors["list_price"]}</span>
                                        </Grid>
                                        </Grid>
                                        <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1}>
                                            
                                            <TextField

                                                label={<Typography variant="headline" style={this.labelStyle}> Cost </Typography>}
                                            id="standard_price"
                                            name="standard_price"
                                            type="number"
                                            min={0}
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.standard_price || this.state.productData['standard_price']}
                                            size="small"
                                        />
                                        <span className="input-error">{this.state.errors["standard_price"]}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: 20 }}  >
                                <Grid container md={3} style={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={6} mr={1} >
                                        <FormControl sx={{ mr: 3, minWidth: 220 }} variant="filled">


                                            <Typography htmlFor="type" variant="headline" style={this.labelStyleSelect}> Type </Typography>
                                            <Select
                                            
                                            id="type"
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.type || this.state.productData['type']}
                                            name='type'
                                            margin="normal"
                                            // Ankit Start 10 April 2023
                                            disabled={true}
                                            // Ankit End 10 April 2023
                                        >
                                            {Object.keys(this.state.form_options['type']).map((option)=>(
                                                <MenuItem value={option}>
                                                    {this.state.form_options['type'][option]}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                            </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={6} mr={1}>
                                            <FormControl xs={12} sx={{ minWidth: 220 }} >


                                                <Typography htmlFor="categ_id" variant="headline" style={this.labelStyleSelect}> Product Category </Typography>
                                                <Select
                                            labelId="categ_id"
                                            id="categ_id"
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.categ_id || this.state.productData['categ_id']}
                                            name='categ_id'
                                            margin="normal"
                                            
                                        >
                                            {Object.keys(this.state.form_options['categ_id']).map((option)=>(
                                                <MenuItem value={option}>
                                                    {this.state.form_options['categ_id'][option]}
                                                </MenuItem>
                                            ))}
                                            {/* /* Ankit start 27 April 2023 */}
                                        {Object.keys(this.state.form_options['categ_id']).length > 10?
                                             <MenuItem value='Search_More'> Search More  </MenuItem> : <MenuItem> </MenuItem>
                                            }
                                        {/* Ankit End 27 April 2023*/}  
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginBottom: 10 }}  >
                                        <Grid container md={3} style={{ marginBottom: 3 }}>
                                            <Grid item xs={12} md={13} mr={1} >
                                            <TextField
                                                    
                                            label={<Typography variant="headline" style={this.labelStyle}> HSN/SAC Code </Typography>}
                                            id="l10n_in_hsn_code"
                                            name="l10n_in_hsn_code"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.l10n_in_hsn_code || this.state.productData['l10n_in_hsn_code']}
                                            size="small"    
                                        />
                                        <span className="input-error">{this.state.errors["l10n_in_hsn_code"]}</span>
                                        </Grid>
                                        </Grid>
                                        <Grid container md={3} style={{ marginBottom: 3 }}>
                                            <Grid item xs={12} md={13} mr={1}>
                                                <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> HSN/SAC Description </Typography>}
                                            id="l10n_in_hsn_description"
                                            name="l10n_in_hsn_description"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.l10n_in_hsn_description || this.state.productData['l10n_in_hsn_description']}
                                            size="small"
                                        />
                                        <span className="input-error">{this.state.errors["l10n_in_hsn_description"]}</span>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                
                                <span style={{display:'flex',alignItems:'center',flex:'wrap'}}>
                                <Grid container md={3}>
                                            <Grid item xs={12} md={13}>
                                        <TextField                                                                             
                                        label={<Typography variant="headline" style={this.labelStyle}> Variant Price </Typography>}
                                            id="variant_price"
                                            name="variant_price"
                                            type="number"
                                            min={0}
                                            onChange={this.handleChange}
                                            value={this.state.updatedProductData?.variant_price || this.state.productData['variant_price']}
                                            size="small"
                                        />
                                        <span className="input-error">{this.state.errors["variant_price"]}</span>
                                        </Grid>
                                        </Grid>
                                {/* <div className="console-form__inputs" > */}
                                <Grid item xs={12} >
                                <Typography htmlFor="taxes_id" variant="headline" style={this.labelStyleSelect}>Customer Taxes  </Typography>

                                    {/* Ankit  bugid-1564 Start 30-08-2022 */}
                                    {/* <Button 
                                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                                        onClick={()=>this.openTaxesPopup()}
                                    >
                                        Select
                                    </Button> */}
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("taxes_id")}>
                                        Select Tax
                                    </Button>
                                    {/* {(this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id).map((tax_id)=>(
                                        <Chip
                                            label={this.state.form_options['taxes_id'][tax_id]}
                                            onDelete={()=>this.removeTax(tax_id)}
                                        />
                                    ))} */}
                                    {(this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id).map((tax_id)=>(
                                        <Chip
                                            label={this.state.form_options['taxes_id'][tax_id]}
                                            onDelete={()=>this.removeTax(tax_id,'taxes_id')}
                                        />
                                    ))}
                                     {/* Ankit  bugid-1564 End 24-08-2022 */}
                                    {/* </div> */}
                                    </Grid>
                                </span>
                            </fieldset>
                        }
                         {/* Piyush Kumar bugid-1564 sale in product varient */}
                        {this.state.selectedTab === 'Sales' && 
                            <>
                            <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                            <Grid container style={{ marginBottom: 15 }}>
                                    <Grid item xs={12} sm={2} md={2}>
                                   <FormControl sx={{ mb: 1, minWidth: 150 }} variant="filled">
                                   <Typography htmlFor="invoice_policy" variant="headline" style={this.labelStyleSelect}> Invoicing Policy</Typography>
                                    <Select
                                    labelId="invoice_policy"
                                    id="invoice_policy"
                                    onChange={this.handleChange}
                                    value={this.state.updatedProductData?.invoice_policy || this.state.productData['invoice_policy']}
                                    name='invoice_policy'
                                    >
                                    <MenuItem value="order">Ordered Quantities</MenuItem>
                                    <MenuItem value="delivery">Delivered Quantities</MenuItem>
                                    </Select>
                                    </FormControl>
                                    </Grid>
                            
                                    <Grid item xs={12} >
                                            {/* <label htmlFor="optional_product_ids">Optional Products</label> */}
                                            <Typography htmlFor="optional_product_ids" variant="headline" style={this.labelStyleSelect}> Optional Products </Typography>
                                    <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("optional_product_ids")}>
                                    Optional Products
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {(this.state.updatedProductData?.optional_product_ids || this.state.productData?.optional_product_ids).map((ids)=>(
                                        <Chip
                                            label={this.state.form_options['optional_product_ids'][ids]}
                                            onDelete={()=>this.removeTax(ids,'optional_product_ids')}
                                        />
                                    ))}
                                    </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginBottom: 10 }}>
                                        <Grid xs={12} >
                                            {/* <label htmlFor="description_sale">Sales Description</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Sales Description </Typography>}
                                    id="description_sale"
                                    name="description_sale"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.updatedProductData?.description_sale || this.state.productData['description_sale']}
                                    size="small"
                                            />
                                        </Grid>
                                    </Grid>
                            </fieldset>
                            </> }
                         
                         {/* Piyush Kumar bugid-1564 sale in product varient End */}
                         
                        {/* {this.state.selectedTab === 'E-commerce' && 
                            <>
                            <Button 
                                style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                                onClick={()=>this.openTaxesPopup()}
                            >
                                Category 
                            </Button>
                            <Button 
                                style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                                onClick={()=>this.openTaxesPopup()}
                            >
                                Product Alternative 
                            </Button>
                            </>
                        } */}
                        {/* Ankit  bugid-1564  start 30-08-22 */}
                        {
                            this.state.selectedTab === 'eCommerce' && 
                            <>
                            <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                            <h1>Shop</h1>
                            {/* <div className="console-form__inputs" > */}
                                {/* <label htmlFor="public_categ_ids">Categories</label> */}
                                <Grid container style={{ marginBottom: 10 }}>
                                        <Grid item xs={12} >
                                            {/* <label htmlFor="public_categ_ids">Categories</label> */}
                                            <Typography htmlFor="public_categ_ids" variant="headline" style={this.labelStyleSelect}> Categories</Typography>
                                    <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("public_categ_ids")}>
                                    Categories
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {(this.state.updatedProductData?.public_categ_ids || this.state.productData?.public_categ_ids).map((ids)=>(
                                        <Chip
                                            label={this.state.form_options['public_categ_ids'][ids]}
                                            onDelete={()=>this.removeTax(ids,'public_categ_ids')}
                                        />
                                    ))}
                                    </div>
                                </div>
                                </Grid>
                                </Grid>
                            {/* </div> */}
                        {/* <div className="console-form__inputs" > */}
                        <Grid container style={{ marginBottom: 10 }}>
                                <Grid item xs={12} sm={2} md={2}>
                            <FormControl sx={{ mb: 1, minWidth: 150 }} variant="filled">
                                {/* <label htmlFor="inventory_availability">Availability</label> */}
                                <Typography htmlFor="inventory_availability" variant="headline" style={this.labelStyleSelect}> Availability</Typography>
                                <Select
                                labelId="inventory_availability"
                                id="inventory_availability"
                                onChange={this.handleChange}
                                value={this.state.updatedProductData?.inventory_availability || this.state.productData['inventory_availability']}
                                name='inventory_availability'
                            >
    
                                    <MenuItem value="never">Sell regardless of invertory</MenuItem>
                                    <MenuItem value="always">Show invertory on website and prevent sales if not enough stock</MenuItem>
                                    <MenuItem value="threshold">Show invertory below a thresold and prevent sales if not enough stock</MenuItem>
                                    <MenuItem value="custom">Show product-specific notifications</MenuItem>



                                </Select>
                                </FormControl>

                        </Grid>
                            {/* </div> */}
                            {/* <div className="console-form__inputs" >
                                <label htmlFor="alternative_product_ids">Alternative Products</label> */}
                                        <Grid item xs={12} >
                                        <Typography htmlFor="alternative_product_ids" variant="headline" style={this.labelStyleSelect}> Alternative Products</Typography>
                                <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("alternative_product_ids")}>
                                    Alternative Products
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {(this.state.updatedProductData?.alternative_product_ids || this.state.productData?.alternative_product_ids).map((ids)=>(
                                        <Chip
                                            label={this.state.form_options['alternative_product_ids'][ids]}
                                            onDelete={()=>this.removeTax(ids,'alternative_product_ids')}
                                        />
                                    ))}
                                    </div>
                                </div>
                            {/* </div> */}
                            </Grid>

                            {/* <div className="console-form__inputs" >
                                <label htmlFor="accessory_product_ids">Accessory Products</label> */}
                                <Grid item xs={12} >
                                    <Typography htmlFor="accessory_product_ids" variant="headline" style={this.labelStyleSelect}> Accessory Products</Typography>

                                <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("accessory_product_ids")}>
                                    Accessory products
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {(this.state.updatedProductData?.accessory_product_ids || this.state.productData?.accessory_product_ids).map((ids)=>(
                                        <Chip
                                            label={this.state.form_options['accessory_product_ids'][ids]}
                                            onDelete={()=>this.removeTax(ids,'accessory_product_ids')}
                                        />
                                    ))}
                                    </div>
                                </div>
                        </Grid>
                            {/* </div> */}
                            {/* <div className="console-form__inputs" > */}
                                {/* <label htmlFor="website_style_ids">Style</label> */}
                                <Grid item xs={12} >
                                <Typography htmlFor="website_style_ids" variant="headline" style={this.labelStyleSelect}> Style</Typography>

                                <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("website_style_ids")}>
                                    Style
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {(this.state.updatedProductData?.website_style_ids || this.state.productData?.website_style_ids).map((ids)=>(
                                        <Chip
                                            label={this.state.form_options['website_style_ids'][ids]}
                                            onDelete={()=>this.removeTax(ids,'website_style_ids')}
                                        />
                                    ))}
                                    </div>
                                </div>
                        
                            {/* </div> */}
                           </ Grid>
                           </Grid>
                            </fieldset>
                            </>
                            }
                        {/* Ankit  bugid-1564  End 30-08-22 */}
                    </form>
                }
                {/* {this.state.productUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Products created successfully
                    </div>
                } */}
                {/* Ankit  bugid-1564  Start 30-08-22 */}
                {/* <TaxesPopup 
                    data={this.state.popupData}
                    handlePopupActions={this.handlePopupActions}
                /> */}
                 <TaxesPopup 
                    data={this.state.popupData}
                    handlePopupActions={this.handlePopupActions}
                    header={this.state.popupName}
                />
                {/* Ankit  bugid-1564  End 30-08-22 */}
                  {/* Ankit Start 27 April 2023 */}
                  <ProductDataPopup 
                    open={this.state.productFormData['open']} 
                    data={this.state.productFormData}
                    handleProductDataPopupActions={this.handleProductDataPopupActions}
                    type={this.state.productFormData['origin']}
                    selected_value={this.state.productFormData['selected_value']}
                    origin={'product_form_option_data'}
                />
                {/* Ankit End 27 April 2023 */}
                <Loader open={this.state.fetchingData || this.state.updatingProductData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default storeProductVariant;
