import React from 'react';
import './CartSummary.css';
import { fetchCart } from '../../Shared/services';
import ApplyPromo from '../ApplyPromo/ApplyPromo';
import urls from '../../urls';
import Chip from '@material-ui/core/Chip';


class CartSummary extends React.Component {

    state = {
        cart:null
    }

    componentDidMount(){
        this.fetchCartSummary();
    }

    fetchCartSummary = async()=>{
        let res = await fetchCart();
        if(res && res.result && res.result.data){
            this.setState({cart:res.result.data});
        }
    }


        // Piyush Kumar 29/03/2023 added fun to delete coupon 
        // deleteCoupon = async (line_id,val=null,key=null) => {
        deleteCoupon = async (val,line_id,key=null) => {
            try {
                const axios = require("axios");
                    let params = {
                        order_line: line_id,
                        product_id: this.state.cart['coupons'][line_id]['product_id'],
                        add_qty: 0,
                        set_qty: val,
                        return_updated_data: true
                    }
                
                const response = await axios(urls.addToCart, {
                    method: 'POST',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        jsonrpc: "2.0",
                        params: params
                    })
                }, 7000);
                const res = await response.data;
                if (!res.result.errorMessage && !res.error) {
                    if(res.result.statusCode === 200){
                        let cart = this.state.cart;
                        let coupons = this.state.cart['coupons']
                        if(key === "deleteCoupon"){
                            delete coupons[line_id]
                            this.setState({ cart });
                            this.componentDidMount()
                        }
                        
                    }
                }
                else
                    alert(res.result.errorMessage || res.error);
            }
            catch (err) {
                alert(err.message);
            }
        }

    render() { 
        return (
            <>
                {this.state.cart &&
                <div className="cart-summary">
                    <div className="heading order-line" style={{fontWeight:"bold"}}>
                        <span>Image</span>
                        <span>Name</span>
                        <span>Quantiy</span>
                        <span>Price</span>
                    </div>
                    <div className='cart-summary-products'>
                        {Object.keys(this.state.cart['sale_order_lines']).map((lineId)=>(
                            <div className="order-line">
                                <span>
                                    <img src={urls.domain + this.state.cart['sale_order_lines'][lineId]['product_image']} loading="lazy" alt="No Preview Available"/>
                                </span>
                                <span className="name">{this.state.cart['sale_order_lines'][lineId]['name']}</span>
                                <span>{this.state.cart['sale_order_lines'][lineId]['product_uom_qty']}</span>
                                <span>&#8377;{this.state.cart['sale_order_lines'][lineId]['product_price']}</span>
                            </div>
                        ))}
                    </div>
                    <div className="heading order-total">Order Total</div>
                    <div className="subtotal">
                         
                        <div className="details">
                            <span className="left1">Subtotal:</span>
                            {console.log("subtotal....\n",this.state.cart)}
                            <span className="right1">&#8377; {this.state.cart['subtotal']}</span></div>
                        <div className="details">
                            <span className="left1">Taxes:</span>
                            <span className="right1">&#8377; {this.state.cart['tax']}</span>
                        </div>
                        {/* Piyush Kumar added discount and delivey Charges */}
                        <div className="details">
                            <span className="left1">Coupon Discount:</span>
                            <span className="right1">&#8377; {this.state.cart['discount']}</span>
                        </div>
                        <div className="details">
                            <span className="left1">Delivery Charge:</span>
                            <span className="right1">&#8377; {this.state.cart['delivery']}</span>
                        </div>
                       
                    </div>
                    <div className="total">
                        <div className="details">
                            <span className="left1">Total:</span>
                            <span className="right1">&#8377; {this.state.cart['total']}</span>
                        </div>
                        {/* Ankit Start 13 April 2023 */}
                        {/* <ApplyPromo onPromoApplied={()=>{}}/> */}
                        {/* Ankit End 13 April 2023 */}
                        {/* {console.log("in check out and payment page.....\n",this.state.cart)} */}
                        {/* {this.state.cart && this.state.cart['coupons'] &&
                            <div className='total'>
                                <div className='details'>
                                    <span className='left1'>Applied Coupon</span>
                                    {(Object.keys(this.state.cart['coupons']).map((cpn)=>
                                            <Chip
                                                label={(this.state.cart['coupons'][cpn]['name'])}
                                                onDelete={()=>this.deleteCoupon(-1,parseInt(this.state.cart['coupons'][cpn]['id']),"deleteCoupon")}
                                            />
                                            ))}
                                </div>
                            </div>
                        } */}

                    </div>
                </div>
                }
            </>
        );
    }
}
 
export default CartSummary;
