import React from 'react';
import './OrderDelivery.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { fetchOrderDeliveryData,saveOrderDeliveryData } from '../../../Shared/consoleServices';
import { Button } from '@mui/material';
import Loader from '../../../Components/Shared/Loader';
import { Tab } from '@mui/material';
import { TabList,TabContext } from '@material-ui/lab';
import DeliverLines from './DeliverLines';
import { BackOrderPopup } from '../../../Components/Popup/Popup';

class OrderDelivery extends React.Component {

    state = {
        viewMode:true,
        orderId:null,
        deliveryData:null,
        updatedDeliveryData:{},
        errors:{},
        picking_form_options:{},
        fetchingData:false,
        updatingdeliveryData:false,
        selectedTab:"Detailed Operations",
        Tabs:['Detailed Operations','Operations','Addition Info','Note'],
        backOrderPopupData:{open:false}
    }

    componentDidMount(){
        changeAppTitle('Console | Order Picking');
        let orderId = this.props.match.params.orderId;
        let pickingId = this.props.match.params.pickingId;
        this.setState({orderId,pickingId});
        this.fetchOrderDeliveryData(orderId,pickingId);
    }

    fetchOrderDeliveryData=async(orderId,pickingId)=>{
        this.setState({fetchingData:true});
        let res  = await fetchOrderDeliveryData(orderId,true,pickingId);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({deliveryData:res.result.data.picking});
        }
        this.setState({fetchingData:false});
    }

    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedDeliveryData = this.state.updatedDeliveryData;
        updatedDeliveryData = {...updatedDeliveryData,...{[key]:value}};
        this.setState({updatedDeliveryData});
    }

    handleSubmit=async(e)=>{
        if(this.state.updatedDeliveryData && Object.keys(this.state.updatedDeliveryData).length>0){
            this.setState({fetchingData:true});
            let res = await saveOrderDeliveryData(this.state.deliveryData['id'],this.state.updatedDeliveryData);
            if(res.error || res.result.errorMessage){
                alert(res.error || res.result.errorMessage);
            }
            else{
                if(res.result.statusCode === 201)
                    this.setState({backOrderPopupData:{open:true,...res.result.data}});
                else
                    this.setState({deliveryData:res.result.data.picking});
            }
            this.setState({fetchingData:false,updatedDeliveryData:{}});
        }
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }
     

    onTabChange=(e,newValue)=>{
        this.setState({selectedTab:newValue});
    }

    getTableData=()=>{
        let data = null;
        let lines = null;
        // Ankit Start Bug-id=1579 05-09-2022
        // if(this.state.selectedTab === "Detailed Operations")
        //     lines = this.state.deliveryData.move_line_ids_without_package;
        // else
        //     lines = this.state.deliveryData.move_ids_without_package;
        
        if(this.state.selectedTab === "Detailed Operations")
            lines = this.state.deliveryData.move_line_ids_without_package;
        else if(this.state.selectedTab === "Operations")
            lines = this.state.deliveryData.move_ids_without_package;
        else if(this.state.selectedTab === "Addition Info")
            lines = this.state.deliveryData.additional_info_data;
        else if(this.state.selectedTab === "Note")
            lines = this.state.deliveryData.note;
           
        if(this.state.selectedTab === "Detailed Operations" || this.state.selectedTab === "Operations"){
         // Ankit End Bug-id=1579 05-09-2022
        if(Object.keys(lines).length>0){
            data = [];
            Object.keys(lines).forEach(lineId => {
                let newRow = lines[lineId];
                data = [...data,newRow];
            }); 
        }
         // Ankit Start Bug-id=1579 05-09-2022
        }
        else{
            data = lines;      
        }
         // Ankit End Bug-id=1579 05-09-2022
        return data;
    }

    // Ankit Start Bug-id 1579 05-09-2022
        
    // changeDeliveryLineData=(e,lineId,type)=>{
    //     let lines = {};
    //     let line = {};
    //     if(this.state.updatedDeliveryData.hasOwnProperty(type))
    //         lines = this.state.updatedDeliveryData[type];
    //     if(e){
    //         let key = e.target.name;
    //         let value = e.target.value;
    //         if(lines.hasOwnProperty(lineId))
    //             line = lines[lineId];
    //         line = {...line,...{[key]:parseFloat(value)}};
    //     }
    //     else{
    //         line = null;
    //     }
    //     lines = {...lines,...{[lineId]:line}};
    //     this.handleChange(null,type,lines);
    // }


        changeDeliveryLineData=(e,lineId,type)=>{
        
        let lines = {};
        let line = {};
        // Ankit Start bug-id 1602        
        if((e.target.name==='qty_done' || e.target.name==='quantity_done')  && parseInt(e.target.value) > parseInt(e.target.max)){
            return ;
        }
        // Ankit End bug-id 1602
        

        if(type === 'move_line_ids_without_package' || type === 'move_ids_without_package'){
            if(this.state.updatedDeliveryData.hasOwnProperty(type))
                lines = this.state.updatedDeliveryData[type];
            if(e){
                let key = e.target.name;
                let value = e.target.value;
                if(lines.hasOwnProperty(lineId))
                    line = lines[lineId];
                // Ankit Start 17 May 2023
                // line = {...line,...{[key]:parseFloat(value)}};
                if(isNaN(value) || value == ''){
                    line = {...line,...{[key]:''}};
                }else{
                    line = {...line,...{[key]:(parseFloat(value))}};
                }
                // Ankit End 17 May 2023
            }
            else{
                line = null;
            }
            lines = {...lines,...{[lineId]:line}};
            this.handleChange(null,type,lines);
            }
        else{
            if(e){
                let value = e.target.value;
                this.handleChange(null,type,value);
            }
        }
    }

    // Ankit End Bug-id=1579 05-09-2022 

    showBtn=(btnType)=>{
        let show = false;
        let btnStatus = this.state.deliveryData.btnStatus;
        if(btnType === "edit"){
            // # mohit  26-09-2022 start bug-ID -1539  issue of readonly of lines..
            // show = true;
            show = btnStatus.show_edit;
            // # mohit  26-09-2022 end bug-ID -1539  issue of readonly of lines..
        }
        else if(btnType === 'Check Availability'){
            show = btnStatus.show_check_availability;
        }
        else if(btnType === 'Validate'){
            show = btnStatus.show_validate;
        }
        else if(btnType === 'Unreserve'){
            show = btnStatus.show_unreserve;
        }
        else if(btnType === 'Unlock'){
            show = btnStatus.show_unlock;
        }
        else if(btnType === 'Scrap'){
            show = btnStatus.show_scrap;
        }
        else if(btnType === 'Cancel'){
            show = btnStatus.show_cancel;
        }
        else if(btnType === 'Done'){
            show = btnStatus.show_done;
        }
        else if(btnType === 'Lock'){
            show = btnStatus.show_lock;
        }
        return show;
    }

    onBtnClick=async(action)=>{
        let updatedDeliveryData = this.state.updatedDeliveryData;
        updatedDeliveryData = {...updatedDeliveryData,...{action}};
        await this.setState({updatedDeliveryData});
        this.handleSubmit();
    }
    
    handlePopupClose=async(e,popupAction=null)=>{
        let backOrderPopupData = this.state.backOrderPopupData;
        backOrderPopupData = {...backOrderPopupData,...{open:false}};
        this.setState({backOrderPopupData});
        if(popupAction){
            let updatedDeliveryData = this.state.updatedDeliveryData;
            updatedDeliveryData = {...updatedDeliveryData,...{popupAction}};
            await this.setState({updatedDeliveryData});
            this.handleSubmit();
        }
    }

    render() { 
        return (
            <div className="delivery-form">
                {this.state.deliveryData &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                <h3 className="delivery-form__heading">{this.state.deliveryData['name']}</h3>
                                {this.showBtn('edit') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                                        {`${this.state.viewMode?"Edit":"Save"}`}
                                    </Button>
                                } 
                                {this.showBtn('Check Availability') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("action_assign")}>
                                        Check Availability
                                    </Button>
                                } 
                                {this.showBtn('Validate') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("button_validate")}>
                                        Validate
                                    </Button>
                                } 
                                {this.showBtn('Unreserve') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("do_unreserve")}>
                                        Unreserve
                                    </Button>
                                } 
                                {/* Ankit Start 09 March 2023 */}
                                {/* {this.showBtn('Scrap') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("button_scrap")}>
                                        Scrap
                                    </Button>
                                }  */}
                                {/* Ankit End 09 March 2023 */}
                                {this.showBtn('Lock') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("action_toggle_is_locked")}>
                                        Lock
                                    </Button>
                                } 
                                {this.showBtn('Unlock') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("action_toggle_is_locked")}>
                                        Unlock
                                    </Button>
                                } 
                                {this.showBtn('Done') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("dispatch_to_delivered")}>
                                        Done
                                    </Button>
                                } 
                                {this.showBtn('Cancel') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.onBtnClick("action_cancel")}>
                                        Cancel
                                    </Button>
                                } 
                            </span>
                            <span className="header__right">
                                <h3>{this.state.deliveryData['state_picking']}</h3>
                            </span>
                        </header>   
                        <fieldset disabled={true} style={{border:"none"}}>
                            <span>
                                <div className="delivery-form__inputs" >
                                    <label htmlFor="partner_id">Delivery Address</label>
                                    <input
                                        id="partner_id"
                                        name="partner_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.deliveryData['partner_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["partner_id"]}</span>
                                </div>
                                <div className="delivery-form__inputs" >
                                    <label htmlFor="picking_type_id">Operation Type</label>
                                    <input
                                        id="picking_type_id"
                                        name="picking_type_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.deliveryData['picking_type_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["picking_type_id"]}</span>
                                </div>
                            </span>
                        </fieldset>
                        <fieldset disabled={true} style={{border:"none"}}>
                            <span>
                                <div className="delivery-form__inputs" >
                                    <label htmlFor="location_id">Source location_id</label>
                                    <input
                                        id="location_id"
                                        name="location_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.deliveryData['location_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["location_id"]}</span>
                                </div>
                                <div className="delivery-form__inputs" >
                                    <label htmlFor="origin">Origin</label>
                                    <input
                                        id="origin"
                                        name="origin"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.deliveryData['origin']}
                                    />
                                    <span className="input-error">{this.state.errors["origin"]}</span>
                                </div>
                            </span>
                        </fieldset>
                        <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                            <span>
                                <div className="delivery-form__inputs" >
                                    <label htmlFor="scheduled_date">Scheduled Date</label>
                                    <input
                                        id="scheduled_date"
                                        name="scheduled_date"
                                        type="datetime-local"
                                        onChange={this.handleChange}
                                        value={this.state.updatedDeliveryData['scheduled_date'] || this.state.deliveryData['scheduled_date']}
                                    />
                                    <span className="input-error">{this.state.errors["scheduled_date"]}</span>
                                </div>
                            </span>
                        </fieldset>
                        <TabContext value={this.state.selectedTab}>
                            <TabList 
                                onChange={this.onTabChange}                                 
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                {this.state.Tabs.map((tab)=>(
                                    <Tab 
                                        label={tab} 
                                        value={tab} 
                                    />
                                ))}
                            </TabList>
                        </TabContext>
                        <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                            {/*  // Ankit Start Bug-id=1579 05-09-2022 */}
                            {/* <DeliverLines 
                                rows = {this.getTableData()}
                                changeDeliveryLineData = {this.changeDeliveryLineData}
                                updatedDeliveryData = {this.state.updatedDeliveryData}
                                viewMode = {this.state.viewMode}
                                showMoves = {this.state.selectedTab === "Detailed Operations"}
                            /> */}

                            <DeliverLines 
                                rows = {this.getTableData()}
                                changeDeliveryLineData = {this.changeDeliveryLineData}
                                updatedDeliveryData = {this.state.updatedDeliveryData}
                                viewMode = {this.state.viewMode}
                                showMoves = {this.state.selectedTab}
                                current_status = {this.state.deliveryData['state']}
                                
                            /> 
                            {/* // Ankit End Bug-id=1579 05-09-2022*/}
                        </fieldset>
                    </form>
                }
                <BackOrderPopup 
                    open={this.state.backOrderPopupData['open']}
                    title={this.state.backOrderPopupData['title']}
                    contentText={this.state.backOrderPopupData['message']}
                    buttons={this.state.backOrderPopupData['buttons']}
                    handlePopupClose={this.handlePopupClose}
                />
                <Loader open={this.state.fetchingData || this.state.updatingdeliveryData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default OrderDelivery;
