import React from 'react';
import './StoreProducts.css';
import { storeProducts } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import { Button, Grid, Typography ,TextField} from '@mui/material';
import urls from '../../../urls';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField';

// # mohit  09-08-2022 start bug-ID -1539  button all, consum, service
const Header=(props)=>{
    return(
        <div className='console__header'>
             <div className='dropdown'>
             <Grid container spacing={1}>
                {Object.keys(props.typeOptions).map((type)=>(
                        <Grid item >
                        <Button 
                            variant={`${(props.selectedOptions['type'] === props.typeOptions[type]?'contained':'outlined')}`} 
                            size="small"
                            onClick={(e)=>props.handleChangeOptions(e,'type')}
                            value = {props.typeOptions[type]}
                            
                        >
                            {type}
                        </Button>
                        </Grid>
                    
                ))}
                </Grid>
            </div>
            
           
        </div>
    )
    
}
// # mohit  09-08-2022 end bug-ID -1539  button all, consum, service

class StoreProducts extends React.Component {

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getMoreData();            

        }        
      }

// # mohit  09-08-2022 start bug-ID -1539  button all, consum, service
    state = {
        products:null,fetchingData:false,total_products:0,
        manage_inventory:false,
        productType:null,
        selectedOptions:{type:null},
        typeOptions:{'All':'all','Storable Products':'product','Consumable Type':'consu','Service Type':'service'},        
        memory:{},productPage:0,SearchInput:[],
        // Ankit Start 19 March 2023
        user_select_option: false,
        // Ankit End 19 March 2023

    }

    componentDidMount(){
        changeAppTitle('Console | Products');
        this.storeProducts(this.state.productPage);
        this.setState({selectedOptions:{type:'product'},productType:'product'});
    }
    handleChangeOptions=async(e,option)=>{
        let value = '';
        if(typeof(e) === 'number')
            value = e;
        else
            value = e.target.value;
        let selectedOptions = this.state.selectedOptions;
        switch(option){
                        
            case 'type':
                selectedOptions = {...selectedOptions,...{type:value}}
                break;
            default:
                alert('Wrong input');
        }
        await this.setState({selectedOptions});
        // Ankit Start 19 March 2023
        // this.setState({productType:value});
        this.setState({productType:value,user_select_option:true});
        // Ankit End 19 March 2023
        // if(option!=='viewType'){
        //     this.changeUrlParams(option,value);
        // }
        this.storeProducts(this.state.productPage,null,value);
    }
    getDisplayImage(imageToUpload){
        if(typeof(imageToUpload) !== typeof('string'))
            imageToUpload = URL.createObjectURL(imageToUpload);
        else
            imageToUpload = urls.domain + imageToUpload;
        return imageToUpload;
    }

    storeProducts=async(page,searchbar=null,productType='product')=>{
        this.setState({fetchingData:true});
        // Ankit Start 19 March 2023
        // let res  = await storeProducts({page,searchbar,productType});
        let user_select = this.state.user_select_option
        let res  = await storeProducts({page,searchbar,productType,user_select});
        // Ankit End 19 March 2023
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        let type = searchParams.get('type');
        let selectedOptions = this.state.selectedOptions;
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            // Ankit Start 17 March 2023
            if(res.result.data.is_service_company == true && this.state.user_select_option == false){
                selectedOptions = {selectedOptions:{type:'service'},productType:'service'}
            }
            // Ankit End 17 March 202
            this.setState({products:res.result.data.products,
                            total_products:res.result.data.total_products,
                            manage_inventory:res.result.data.manage_inventory,
                            productPage:page,
                            // Ankit Start 17 March 2023
                            ...selectedOptions
                            // Ankit End 17 March 2023
                        });
        }
        this.setState({fetchingData:false});
    }
    // # mohit  09-08-2022 end bug-ID -1539  button all, consum, service
    goToProduct=(productId=false)=>{
        this.props.history.push(urls.extension + '/console/product/' + (productId || 'New'));
    }

    changeProductsPage=async(page)=>{
        if(page>=0 && this.state.total_products>=((this.state.productPage * 50) + Object.keys(this.state.products).length)){
            let memory = this.state.memory;
            memory[this.state.productPage] = this.state.products;
            this.setState({memory});
            if(page in memory){
                let products = memory[page];
                this.setState({products,productPage:page});
            }
            else
                this.storeProducts(page,this.state.SearchInput,this.state.productType);
        }
    }
    getMoreData = () =>{
        this.setState({productPage:0});
		// # mohit  09-08-2022 start bug-ID -1539  button all, consum, service
		this.storeProducts(this.state.productPage,this.state.SearchInput,this.state.productType)
        // # mohit  09-08-2022 end bug-ID -1539  button all, consum, service
	}
    GetInput = (SearchInput=null) =>{
        this.setState({SearchInput})
    }

    render() { 
        return (
            <div className="store-orders">
                <Grid container spacing={3}>
                    <Grid item >
                    <Typography variant='h6' sx={{ fontWeight: 'bold'}}>Products</Typography>
                    </Grid>
                    <Grid item >
                                
                                <Button 
                                    style={{background:"#02A457",color:"beige",fontSize: '13px'}} 
                                    onClick={()=>this.goToProduct()}
                                >
                                    Create 
                                </Button>
                    </Grid>
                    <Grid item >
                    {/* pankaj 09-aug-2022 add  search bar start  */}
                        
                        
                        {/* <input 
                        type='text' 
                        name='searchbar'
                        onChange={(e) => this.GetInput(e.target.value)} 
                        placeholder='enter product detail'  /> */}
                        </Grid>

                        <Grid item >
                        {/* <span  onClick={this.getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span> */}

                        <TextField onChange={(e) => this.GetInput(e.target.value)} 
                        id="outlined-search" label="Enter Product Name" 
                        name='searchbar'   
                        onKeyDown={this._handleKeyDown}                     
                        
                        type="search" size="small" InputProps={{
                            endAdornment: (
                                <SearchIcon onClick={this.getMoreData} size="Normal"/>
                            ),}} >
                        
                        
                        {/* <SearchIcon onClick={this.getMoreData} size="Normal"/> */}
                        </TextField>
                        
                        {/* <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.getMoreData}                                
                            edge="end"
                            ></IconButton> */}
                    
                    {/* search bar endd  */}
                               
                    </Grid>

                    <Grid item >
                        {/* // # mohit  09-08-2022 start     bug-ID -1539  button all, consum, service */}
                     <Header 
                    typeOptions = {this.state.typeOptions}
                    selectedOptions = {this.state.selectedOptions}
                    handleChangeOptions = {this.handleChangeOptions}
                    />
                    <br></br>
                    {/* // # mohit  09-08-2022 end bug-ID -1539  button all, consum, service */}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                <div style={{width:"100%"}}>                                                       
                    
                    {this.state.products && Object.keys(this.state.products).length>0 &&
                        <div className="products-pagination" style={{float:"right"}}>
                            <span>
                                <ArrowBack onClick={()=>this.changeProductsPage(this.state.productPage-1)} style={{cursor:'pointer'}}/>
                                <span>{`${(this.state.productPage * 50)+1}-${(this.state.productPage * 50) + Object.keys(this.state.products).length}/${this.state.total_products}`}</span>
                                <ArrowForward onClick={()=>this.changeProductsPage(this.state.productPage+1)} style={{cursor:'pointer'}}/>
                            </span>
                        </div>
                    }
                </div>
                </Grid>
                
                {this.state.products &&
                <Grid container >
                <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
                    {/* <table className="order-table" sx={{overflow:scroll}}> */}
                    {/* <div style={{overflowX:'auto'}} xs={12} sm={12} md={12} > */}
                    <table className="order-table" >
                        <thead>
                            <tr>
                                <th style={{padding:'6px'}}>Internal Reference</th>
                                <th style={{padding:'6px'}}>image</th>
                                <th style={{padding:'6px'}}>Name</th>
                                <th style={{padding:'6px'}}>Sales Price</th>
                                <th style={{padding:'6px'}}>Cost</th>
                                <th style={{padding:'6px'}}>Variants</th>
                                <th style={{padding:'6px'}}>Type</th>
                                {this.state.manage_inventory ?
                                <th style={{padding:'6px'}}>Qty(On hand)</th>                                
                                :''}
                                {this.state.manage_inventory?
                                <th style={{padding:'6px'}}>Qty(Forecasted)</th>
                                :''}
                                <th style={{padding:'6px'}}>Active</th>
                            </tr>
                        </thead>
                        <tbody >
                            {Object.keys(this.state.products).map((productId)=>(
                                <tr onClick={()=>this.goToProduct(productId)}>
                                    <td>{this.state.products[productId]['default_code']}</td>
                                    <td>
                                    
                                        <img style={{width:40}} src={this.getDisplayImage( this.state.products[productId]['image'])}  />
                                    </td>
                                    <td>{this.state.products[productId]['name']}</td>
                                    <td>&#8377; {this.state.products[productId]['list_price']}</td>
                                    <td>&#8377; {this.state.products[productId]['standard_price']}</td>
                                    <td>{this.state.products[productId]['product_variant_count']}</td>
                                    <td>{this.state.products[productId]['type']}</td>
                                    {this.state.manage_inventory?
                                    <td>{this.state.products[productId]['qty_available']}</td>
                                    :''}
                                    {this.state.manage_inventory ?
                                    <td>{this.state.products[productId]['virtual_available']}</td>
                                    :''}
                                    <td>
                                        <input
                                            name="active-product"
                                            type="checkbox"
                                            disabled={true}
                                            checked={this.state.products[productId]['active']}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                    {/* </div> */}
                    </Grid>
                    </Grid>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default StoreProducts;
