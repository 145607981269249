import React from 'react';
import SaleOrder from '../../Components/Shared/SaleOrder';
import urls from '../../urls';
import { changeAppTitle } from '../../Shared/utilities';
import { Button } from '@mui/material';
import './ReturnOrderPage.css';
import { Select,MenuItem,InputLabel } from '@material-ui/core';
import Loader from '../../Components/Shared/Loader';

class ReturnOrderPage extends React.Component {

    state = {
        cart:null,
        order_id:null,
        partner_id:null,
        return_reasons:null,
        selected_return_reason:0
    }

    componentDidMount(){
        changeAppTitle('Shopaas | Return Order');
        let orderDetails = this.props.match.params.orderDetails;
        orderDetails = orderDetails.split('-'); 
        let order_id = orderDetails.slice(-1);
        let partner_id = orderDetails.slice(-2,-1);
        let order_reference = orderDetails.slice(-3,-2);
        this.setState({order_id,partner_id,order_reference});
        this.fetchCart(order_id,partner_id);
    }


    fetchCart = async (order_id,partner_id) => {
        try {
            this.setState({fetchingData:true});
            const axios = require("axios");
            const response = await axios(urls.getReturnCart, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        sale_order_id:order_id,
                        partner_id:partner_id
                    }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                this.setState({originalOrderData:res.result.originalOrderData});
                if(res.result.statusCode === 201){
                    this.setState({message:res.result.message});
                }
                else{
                    this.setState({cart:res.result.saleOrderData,return_reasons:res.result.return_reasons});
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            alert(err.message);
            this.setState({fetchingData:false});
        }
    }


    handleQuantityChange=(value,line_id,interval=0)=>{
        if(value){
            value = (value === -1)?0:value;
            this.changeProductQty(line_id,value);
            // Piyush kumar bugid- 1705 30 nov 22 added to change amount on qty change
            this.changeAmount(line_id,value)
            // piyush kumar end
        }
    }

    changeProductQty=(line_id,new_qty)=>{
        let cart = this.state.cart;
        let sale_order_line = cart['sale_order_lines'][line_id];
        if(!sale_order_line['maxLimit'])
            sale_order_line['maxLimit'] = sale_order_line['product_uom_qty']
        if(sale_order_line['maxLimit']<new_qty){
            alert('Reached Max Limit');
        }
        else{
            sale_order_line['last_product_uom_qty'] = sale_order_line['product_uom_qty'];
            sale_order_line['product_uom_qty'] = parseInt(new_qty);
            cart = {...cart,...{[line_id]:sale_order_line}};
            this.setState({cart});
        }
    }
// piyush kumar bugid-1705 30 nov 22 added this fn to change amount in return case a/c to qty change
    changeAmount=(line_id,new_qty)=>{
        // let cart = this.state.cart;
        let sale_order_line = this.state.cart['sale_order_lines'][line_id];
        sale_order_line['price_subtotal'] = sale_order_line['product_uom_qty'] * sale_order_line['product_price']
        // cart = {...cart,...{[line_id]:sale_order_line}};
        this.setState({...this.state.cart,...{[line_id]:sale_order_line}});

    }
// Piyush kumar end 
    processOrder=async(action)=>{
        try {
            const axios = require("axios");
            this.setState({fetchingData:true});
            let params = {order_id:this.state.cart.id,partner_id:this.state.cart.partner_id,isConfirmed:(action === 'Confirm')}
            if(action === 'Confirm'){
                let sale_order_lines = this.state.cart.sale_order_lines;
                if(this.state.selected_return_reason && this.state.selected_return_reason!==0){
                    params = {...params,...{return_reason:this.state.selected_return_reason}};
                }
                params = {...params,...{line:sale_order_lines}};
            }
            const response = await axios(urls.processReturnOrder, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                this.props.history.push(urls.extension+'/my/orders');
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            this.setState({fetchingData:false});
            alert(err.message);
        }
    }

    handleReasonChange = (e)=>{
        this.setState({selected_return_reason:e.target.value});
    }

    render() { 
        return (
            <div className="return-order">
                {this.state.order_reference &&
                    <>
                        <div className="heading">Return Order</div>
                        {this.state.originalOrderData &&
                            <div className="order-info">
                                <div>
                                    <span>{this.state.originalOrderData['company']['name']}</span>
                                    <span style={{color:"#999999"}}>{this.state.originalOrderData['company']['company_type']}</span>
                                </div>
                                <div>
                                    <span>Order # :{this.state.originalOrderData['reference']}</span>
                                    <span>{this.state.originalOrderData['date_order']}</span>
                                </div>
                                <div>Total : &#8377; {this.state.originalOrderData['amount_total']}</div>
                            </div>
                        }
                    </>
                }
                {this.state.cart &&
                    <>
                        <SaleOrder 
                            cart={this.state.cart}
                            handleQuantityChange={this.handleQuantityChange}
                        />
                        {this.state.return_reasons && Object.keys(this.state.return_reasons).length>0 &&
                            <div className="return_reaons">
                                <InputLabel id="demo-simple-select-helper-label">Return Reason</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper-label"
                                    onChange={this.handleReasonChange}
                                    defaultValue={this.state.selected_return_reason}
                                    name='return-reason'
                                    style={{width:"200px"}}
                                >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {Object.keys(this.state.return_reasons).map((reasonId)=>(
                                        <MenuItem value={reasonId}>
                                            {this.state.return_reasons[reasonId]['name']}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            </div>
                        }
                        <div className="cta-btns">
                            <Button variant="contained" onClick={()=>this.processOrder("Cancel",this.state.order_id,this.state.partner_id)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={()=>this.processOrder("Confirm",this.state.order_id,this.state.partner_id)}>
                                Confirm
                            </Button>
                        </div>
                    </>
                }
                {this.state.message &&
                    <>
                        <div className="return-message">
                            {this.state.message}
                        </div>
                        <div className="back-btn">
                            <Button variant="contained" onClick={()=>this.props.history.push(urls.extension+'/my/orders')}>
                                Back
                            </Button>
                        </div>
                    </>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default ReturnOrderPage;


