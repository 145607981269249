import React from 'react';
import './DeliverLines.css';
// Ankit Start Bug-id=1579 05-09-2022
import TextField from '@mui/material/TextField';
import { Select,MenuItem } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// Ankit End Bug-id=1579 05-09-2022

class DeliverLines extends React.Component {

    getQuantity=(row,key)=>{
        let qty = 0;
        let lines = null;
        // Ankit Start Bug-id=1579 05-09-2022
        // if(!this.props.showMoves)
        //     lines = this.props.updatedDeliveryData.move_ids_without_package;
        // else
        //     lines = this.props.updatedDeliveryData.move_line_ids_without_package;
     
        if(this.props.showMoves === 'Operations'){
            lines = this.props.updatedDeliveryData.move_ids_without_package;
        }
        else if(this.props.showMoves === 'Detailed Operations'){
            lines = this.props.updatedDeliveryData.move_line_ids_without_package;
        }    
        // Ankit End Bug-id=1579 05-09-2022
        qty = row[key]; 
        console.log(key,qty);
        console.log('condition',(lines && lines[row['id']] && lines[row['id']][key]))
        // Ankit Start 17 May 2023
        // if(lines && lines[row['id']] && lines[row['id']][key])
        //     qty = lines[row['id']][key];

        if((lines && lines[row['id']] && lines[row['id']][key])>-1)
            qty = lines[row['id']][key];
        // Ankit End 17 May 2023
        return qty;
    }

    showLine=(row)=>{
        let lines = null;
        // Ankit Start Bug-id=1579 05-09-2022
        // if(this.props.showMoves)
        //     lines = this.props.updatedDeliveryData.move_ids_without_package;
        // else
        //     lines = this.props.updatedDeliveryData.move_line_ids_without_package;

        if(this.props.showMoves === 'Detailed Operations'){
        lines = this.props.updatedDeliveryData.move_ids_without_package;
        }
        else if(this.props.showMoves === 'Operations'){
        lines = this.props.updatedDeliveryData.move_line_ids_without_package;
        }
         // Ankit End Bug-id=1579 05-09-2022

        if(row === null)
            return false;
        else if(lines && lines.hasOwnProperty(row['id']) && lines[row['id']] === null)
            return false;
        return true;
    }

    // Ankit Start Bug-id=1579 05-09-2022
    getStatus=(arr=null)=>{
        if(arr){
            if(arr.includes(this.props.current_status)){
                return true
            }
        }
    }
    // Ankit End Bug-id=1579 05-09-2022

    render() { 
        return (
            <>
            {/* // Ankit Start Bug-id=1579 05-09-2022*/}
                {/* {this.props.showMoves && */}
                {this.props.showMoves === 'Detailed Operations' &&
                // Ankit End Bug-id=1579 05-09-2022
                    <table className="delivery-line">
                        <thead>
                            <th>Product</th>
                            <th>From</th>
                            <th>Lot/Serial Number</th>
                            <th>Reserved</th>
                            <th>Done</th>
                        </thead>
                        {this.props.rows && this.props.rows.length>0 && 
                            <tbody>
                                {this.props.rows.map((row)=>{
                                    if(!this.showLine(row))
                                        return (<></>);
                                    return(
                                        <tr>
                                            <td>{row['product_id']['name']}</td>
                                            <td>{row['location_id']['name']}</td>
                                            {/* Ankit Start 12 jan 2023 */}
                                            {/* <td>{row['lot_id']['name']}</td> */}
                                            <td>
                                                {/* {row['lot_id']['name']} */}
                                              
                                                {row.lot_options.length >0 &&
                                                <Select
                                                    id="lot_id"
                                                    // value={this.props.updatedDeliveryData?.move_line_ids_without_package?.[row.id]['lot_id'] ||row['lot_id']['id']}
                                                    value={this.props.updatedDeliveryData?.move_line_ids_without_package?.[row.id]?.lot_id ||row['lot_id']?.id}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, row['id'], 'move_line_ids_without_package')}
                                                    name='lot_id'>
                                                    {row.lot_options.map((option) => (
                                                        <MenuItem value={option?.lot_id}>
                                                            {option?.lot_name}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                }
                                            
                                            </td>
                                            {/* Ankit End 12 Jan 2023 */}
                                            <td>
                                                <input 
                                                    name="product_uom_qty"
                                                    type="number"
                                                    min={0}
                                                    // Ankit Start bug-id 1602
                                                    disabled={true}
                                                    // Ankit End bug-id 1602
                                                    value={this.getQuantity(row, 'product_uom_qty')}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, row['id'], 'move_line_ids_without_package')}
                                                />
                                            </td>
                                            <td>
                                                <input 
                                                    name="qty_done"
                                                    type="number"
                                                    min={0}
                                                    // Ankit Start bug-id 1602
                                                    max={this.getQuantity(row, 'product_uom_qty')}
                                                    disabled={this.getStatus(['Cancelled', 'Done'])}
                                                    // Ankit End bug-id 1602
                                                    value={this.getQuantity(row, 'qty_done')}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, row['id'], 'move_line_ids_without_package')}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                }
                {/* / // Ankit Start Bug-id=1579 05-09-2022 */}
                {/* {!this.props.showMoves && */}
                {this.props.showMoves === 'Operations' &&
                  // Ankit End Bug-id=1579 05-09-2022
                    <table className="delivery-line">
                        <thead>
                            <th>Product</th>
                            <th>Demand</th>
                            <th>Done</th>
                        </thead>
                        {this.props.rows && this.props.rows.length>0 && 
                            <tbody>
                                {this.props.rows.map((row)=>{
                                    if(!this.showLine(row))
                                        return (<></>);
                                    return(
                                        <tr>
                                            <td>{row['product_id']['name']}</td>
                                            <td>
                                                <input 
                                                    name="product_uom_qty"
                                                    type="number"
                                                    min={0}
                                                     // Ankit Start bug-id 1602
                                                     disabled={true}
                                                     // Ankit End bug-id 1602
                                                    value={this.getQuantity(row, 'product_uom_qty')}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, row['id'], 'move_ids_without_package')}
                                                />
                                            </td>
                                            <td>
                                                <input 
                                                    name="quantity_done"
                                                    type="number"
                                                    min={0}
                                                     // Ankit Start bug-id 1602
                                                    //  disabled={true}
                                                     // Ankit End bug-id 1602
                                                     // Ankit start bugid-1583 06-09-2022
                                                    max={this.getQuantity(row,'product_uom_qty')}
                                                    // Ankit End bugid-1583 06-09-2022
                                                    value={this.getQuantity(row, 'quantity_done')}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, row['id'], 'move_ids_without_package')}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                }
                {/*  // Ankit Start Bug-id=1579 05-09-2022 */}
                {this.props.showMoves === 'Addition Info' &&
                
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Grid container spacing={2} columns={16}>
                                <Grid item xs={8}>
                                    <div className='additional_info'>
                                        <h1>Shipping Information</h1>
                                        <div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}} >
                                                <label htmlFor="carrier_id">Carrier</label>
                                                <Select
                                                    labelId="carrier_id"
                                                    id="carrier_id"
                                                    value={this.props.updatedDeliveryData?.carrier_id || this.props.rows[0].carrier_id}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, this.props.rows[0], 'carrier_id')}
                                                    disabled={this.getStatus(['Cancelled', 'Done'])}
                                                    name='carrier_id'>
                                                    {Object.keys(this.props.rows[1]['carrier_id_options']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.props.rows[1]['carrier_id_options'][option]}
                                                        </MenuItem>
                                                    ))}

                                                </Select>

                                            </div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="carrier_tracking_ref">Tracking Reference</label>
                                                <input
                                                    id="carrier_tracking_ref"
                                                    name="carrier_tracking_ref"
                                                    type="text"
                                                    disabled={this.getStatus(['Cancelled', 'Done'])}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, this.props.rows[0], 'carrier_tracking_ref')}
                                                    value={this.props.updatedDeliveryData?.carrier_tracking_ref || this.props.rows[0].carrier_tracking_ref}
                                                />
                                            </div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="weight">Weight</label>
                                                <input
                                                    id="weight"
                                                    name="weight"
                                                    type="text"
                                                    disabled={true}
                                                    onChange=''
                                                    value={this.props.rows[0].weight}
                                                />
                                                <input
                                                    className='weight_style'
                                                    id="weight_uom_name_1"
                                                    name="weight_uom_name_1"
                                                    type="text"
                                                    disabled={true}
                                                    onChange=''
                                                    value={this.props.rows[0].weight_uom_name}
                                                />
                                            </div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="shipping_weight">Weight For Shipping</label>
                                                <input
                                                    id="shipping_weight"
                                                    name="shipping_weight"
                                                    type="text"
                                                    onChange=''
                                                    disabled={true}
                                                    value={this.props.rows[0].shipping_weight}
                                                />
                                                <br />
                                                <input
                                                    className='weight_style'
                                                    id="weight_uom_name_2"
                                                    name="weight_uom_name_2"
                                                    type="text"
                                                    onChange=''
                                                    disabled={true}
                                                    value={this.props.rows[0].weight_uom_name}
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </Grid>
                                <Grid item xs={8}>

                                    <div className='additional_info'>
                                        <h1>Other Information</h1>
                                        <div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="move_type">Shipping Policy</label>
                                                <Select
                                                    labelId="move_type"
                                                    id="move_type"
                                                    disabled={this.getStatus(['Cancelled', 'Done'])}
                                                    required={true}
                                                    value={this.props.updatedDeliveryData?.move_type || this.props.rows[0].move_type}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, this.props.rows[0], 'move_type')}
                                                    name='move_type' >
                                                    {Object.keys(this.props.rows[1]['move_type_options']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.props.rows[1]['move_type_options'][option]}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="priority">Priority</label>
                                                <Rating name="simple-controlled" className='rating-star'
                                                    readOnly={this.getStatus(['Cancelled', 'Done'])}
                                                    value={this.props.updatedDeliveryData?.priority || this.props.rows[0].priority}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, this.props.rows[0], 'priority')}
                                                    max={3} />
                                            </div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="user_id">Responsible</label>
                                                <Select
                                                    labelId="user_id"
                                                    id="user_id"
                                                    disabled={this.getStatus(['Cancelled', 'Done'])}
                                                    value={this.props.updatedDeliveryData?.user_id || this.props.rows[0].user_id}
                                                    onChange={(e) => this.props.changeDeliveryLineData(e, this.props.rows[0], 'user_id')}
                                                    name='user_id'
                                                >
                                                    {Object.keys(this.props.rows[1]['user_id_options']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.props.rows[1]['user_id_options'][option]}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </div>
                                            <div className="delivery-form__inputs" style={{width:'auto'}}>
                                                <label htmlFor="group_id">Procurement Group</label>
                                                <Select
                                                    labelId="group_id"
                                                    id="group_id"
                                                    disabled={true}
                                                    value={this.props.rows[0].group_id}
                                                    name='group_id'
                                                >
                                                    {Object.keys(this.props.rows[1]['group_id_options']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.props.rows[1]['group_id_options'][option]}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </div>
                                        </div>

                                    </div>
                                </Grid>
                            </Grid>
                        </Box>

                    </>
                }
                {this.props.showMoves === 'Note' &&
                    <>
                        <TextField fullWidth name='note' value={this.props.updatedDeliveryData?.note || this.props.rows.note} onChange={(e) => this.props.changeDeliveryLineData(e, this.props.rows.note, 'note')} label="Note" id="fullWidth" />
                    </>
                }
                {/* // Ankit End Bug-id=1579 05-09-2022 */}
            </>
        );
    }
}
 
export default DeliverLines;
