import React from 'react';
import './StoreProducts.css';
import { ProductLotData, SaveProductLotData } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { Button,Alert,TextField} from '@mui/material';
import urls from '../../../urls';
import { Select,MenuItem,Grid,Typography, FormControl} from '@material-ui/core';
import Stack from '@mui/material/Stack';



class ProductLot extends React.Component{
    state = {
        fetchingData : false,
        lotId:null,
        productId:null,
        lotData:false,
        viewMode:true,
        errors:false,
        updatedLotData:{},
        server_msg: false,
        severity: false,
    }

    componentDidMount(){
        changeAppTitle('Console | Lot Serial');
        const lotId = this.props.match.params.lotId;
        const productId = this.props.match.params.productId;
        this.setState({lotId,productId});
        this.fetchLotData({lotId,productId});
    }

    fetchLotData=async(ids)=>{
        this.setState({fetchingData:true});
        let res  = await ProductLotData(ids);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({lotData: res.result.data})
        }
        this.setState({fetchingData:false});
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedLotData = this.state.updatedLotData;
        let lotData = this.state.lotData;
        updatedLotData = {...updatedLotData,...{[key]:value}};
        lotData = {...lotData,...{[key]:value}};

        this.setState({updatedLotData,lotData});
    }

    handleSubmit=async (e)=>{
        if (Object.keys(this.state.updatedLotData).length) {
                this.setState({ fetchingData: true });
                let res = {}
                res = await SaveProductLotData({productId:this.state.productId, lotId:this.state.lotId,data:this.state.updatedLotData});
            
                if (res.error || res.errorMessage || res.result.error || res.result.errorMessage) {
                    alert(res.error || res.errorMessage);
                    this.setState({ server_msg: 'Failed !! ' + res.result.errorMessage, severity: 'error',fetchingData:false});
                }
                else{
                    const lotId = this.state.lotId;
                    const productId = this.state.productId;
                    if (lotId == 'New'){
                        this.props.history.push(urls.extension + '/product/lot/' +(this.state.productId)+'/'+(res.result.lotId || 'New'));
                        this.setState({lotId:res.result.lotId,server_msg: 'Success', severity: 'success',updatedLotData:{}})
                        this.fetchLotData({lotId:res.result.lotId,productId});

                    }else{
                    
                    this.fetchLotData({lotId,productId});
                    this.setState({ server_msg: 'Success', severity: 'success',updatedLotData:{}});
                    }
                }
            }

                this.setState({fetchingData:false});
    }
    
    discardPage = () => {
        // this.props.history.goBack();
        this.props.history.push(urls.extension + `/product/${this.state.productId}/lot/serials/tree`);
    }

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === "edit"){
            show = true;
        }
        return show;
    }

    noteVale=(value)=>{
        if(value)
            return value.replace( /(<([^>]+)>)/ig, '');
    }
    
    render(){
        return(
            <>
            <div className="console-form">
                {this.state.lotData &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                {this.showBtn('edit') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                                        {`${this.state.viewMode?"Edit":"Save"}`}
                                    </Button>
                                }
                                 {/* Ankit Start 18-11-2022 */}
                                <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.discardPage()} >Discard</Button>
                                {/* Ankit End 18-11-2022 */}
                                <h3 className="console-form__heading">{this.state.lotData['name']}</h3>

                            </span>
                            <span className="header__right">
                            </span>
                    </header>


                    <Stack sx={{ width: '100%' }} spacing={2}>
                            {this.state.server_msg ?
                                <Alert variant="filled" style={{ marginBottom: 10 }} onClose={() => { this.setState({ server_msg: false }) }} severity={this.state.severity}>{this.state.server_msg}</Alert>
                                : ''}
                    </Stack>

                       <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                        {/* <span>
                        <div className="console-form__inputs" >
                                        <label htmlFor="ref">Name</label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.updatedLotData.name || this.state.lotData['name']}
                                        />
                                        <span className="input-error">{this.state.errors["ref"]}</span>
                                    </div>



                                    <div className="console-form__inputs" >
                                        
                                        <label htmlFor="product_name">Product Name</label>
                                         <Select
                                            labelId="product_id"
                                            id="product_id"
                                            onChange={this.handleChange}
                                            value={this.state.updatedLotData.product_id ||this.state.lotData.product_id}
                                            name='product_id'
                                        >
                                            {this.state.lotData.products_data.map((option)=>(
                                                <MenuItem value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <span className="input-error">{this.state.errors["name"]}</span>
                                       
                                    </div>
                                    
                                </span> */}
                            
                            <Grid container style={{ marginBottom: 20 }}  >
                            <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1} >

                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}>Name </Typography>}
                                                id="name"
                                                name="name"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.updatedLotData.name || this.state.lotData['name']}
                                                size="small"
                                                style={{ marginBottom: 10, fontSize: 13 }}

                                            />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1}>
                                            <span className="input-error">{this.state.errors["name"]}</span>
                                        </Grid>
                                    </Grid>

                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={6} mr={1}>
                                            <FormControl xs={12} sx={{ minWidth: 220 }} >


                                            <Typography htmlFor="categ_id" variant="headline" style={this.labelStyleSelect}> Product Name </Typography>
                                            <Select
                                                labelId="product_id"
                                                id="product_id"
                                                onChange={this.handleChange}
                                                value={this.state.updatedLotData.product_id ||this.state.lotData.product_id}
                                                name='product_id'>
                                            {this.state.lotData.products_data.map((option)=>(
                                                <MenuItem value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 20 }}  >
                            <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1} >

                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}>Internal Reference</Typography>}
                                                id="ref"
                                                name="ref"
                                                type="text"
                                                onChange={this.handleChange}
                                            value={this.state.updatedLotData.ref || this.state.lotData['ref']}
                                                size="small"
                                                style={{ marginBottom: 10, fontSize: 13 }}

                                            />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1}>
                                            <span className="input-error">{this.state.errors["ref"]}</span>
                                        </Grid>
                                    </Grid>

                                    {this.state.lotId != 'New' && 

                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={13} mr={1} >
                                        <TextField
                                            label={<Typography variant="headline" style={this.labelStyle}>Quantity</Typography>}
                                            id="product_qty"
                                            name="product_qty"
                                            type="text"
                                            disabled={true}
                                            onChange={this.handleChange}
                                            value={this.state.updatedLotData.product_qty || this.state.lotData['product_qty']}
                                            size="small"
                                            style={{ marginBottom: 10, fontSize: 13 }}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={13} mr={1}>
                                        <span className="input-error">{this.state.errors["Quantity"]}</span>
                                    </Grid>
                                    </Grid>
    }
                                </Grid>
                                {this.state.lotId != 'New' && 
            
                        <TextField fullWidth name='note' value={this.noteVale(this.state.updatedLotData.note || this.state.lotData.note)} onChange={this.handleChange} label="Note" id="fullWidth" />
                                
                        }
                                
                                {/* <span>
                                     <div className="console-form__inputs" >
                                        <label htmlFor="ref">Internal Reference</label>
                                        <input
                                            id="ref"
                                            name="ref"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.updatedLotData.ref || this.state.lotData['ref']}
                                        />
                                        <span className="input-error">{this.state.errors["ref"]}</span>
                                    </div>
                                    {this.state.lotId != 'New' && 
                                    <>
                                    <div className="console-form__inputs" >
                                        <label htmlFor="product_qty">Quantity</label>
                                        <input
                                            id="product_qty"
                                            name="product_qty"
                                            type="text"
                                            disabled={true}
                                            onChange={this.handleChange}
                                            value={this.state.updatedLotData.product_qty || this.state.lotData['product_qty']}
                                        />
                                        <span className="input-error">{this.state.errors["name"]}</span>
                                    </div>

                        
                        <TextField fullWidth name='note' value={this.noteVale(this.state.updatedLotData.note || this.state.lotData.note)} onChange={this.handleChange} label="Note" id="fullWidth" />
                        </>
                    }
                        
                                </span> */}
                        </fieldset>
                        
            </form>
    }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>

            </div>
            </>
        )
    }

}

export default ProductLot;
