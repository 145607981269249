import React from 'react';
import './Promotion_Program.css';
import '../console.css';
import {fetchPromotionData,fetchSpecificData,savePromtionData} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import PromotionPopup from './PromotionPopup';


class Promotion extends React.Component {
    

    state = {
        viewMode:true, 
        promotion_name:'',
        promotionData:null,
        promotion_name:'',
        productId:null,
        fetchingData:false,
        form_options:{},
        getSpecificData:{},
        getProductId:{},
        getPartnerId:{},
        itemsList:{},
        partner_id:null,
        updatedPromotionData:{},
        productData:[],
        popupData:{open:false},
        input_val:[],
        allow_customer:[],
        allow_product:[],
        specific_discount_product:[],
        reward_product_id:[],
        errors:{},
        
    }
    componentDidMount(){
        let productId = this.props.match.params.productId;
        this.setState({productId});
        this.fetchPromotionData(productId);
    }

    fetchPromotionData=async(id)=>{
        this.setState({fetchingData:true});
        let res  = await fetchPromotionData();
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            await this.setState({promotionData:res.result.promotion_Data,form_options:res.result.form_options});
            await this.setState({productData:this.state.promotionData[this.state.productId]})
            // Bishu 29/8/22 added the condition to add data in allow products and allow cust list after fetching
            if (id !=='New'){
                if (this.state.promotionData[this.state.productId].product_id){
                    this.setInputAllowProduct(this.state.promotionData[this.state.productId].product_id)
                }
                if (this.state.promotionData[this.state.productId].partner_ids){
                    this.setInputAllowCustomer(this.state.promotionData[this.state.productId].partner_ids)
                }
                if (this.state.promotionData[this.state.productId].reward_product_id){
                    this.setInputFreeProduct(this.state.promotionData[this.state.productId].reward_product_id)
                }
                if (this.state.promotionData[this.state.productId].discount_specific_product_ids){
                    this.setInputSpecificProduct(this.state.promotionData[this.state.productId].discount_specific_product_ids)
                }

            }
            // Bishu 29/8/22 ends here
            
            this.handleChange(null,'productID',id)
        }
        this.setState({fetchingData:false});
    }
    handlePopupActions=async(selectedItems=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        
        this.setState({popupData});
        if(selectedItems){
            let updatedPromotionData = this.state.updatedPromotionData;
            if(popupData.type === "product_id"){
                this.setInputAllowProduct(selectedItems)
                
                updatedPromotionData = {...updatedPromotionData,...{product_id:selectedItems}}
                await this.setState({updatedPromotionData}); 
            }
            else if(popupData.type === "partner_id"){
                this.setInputAllowCustomer(selectedItems)
                updatedPromotionData = {...updatedPromotionData,...{partner_id:selectedItems}}
                await this.setState({updatedPromotionData}); 
            }
            else if(popupData.type==='specific_product_id'){
                this.setInputSpecificProduct(selectedItems)
                updatedPromotionData = {...updatedPromotionData,...{specific_product_id:selectedItems}}
                await this.setState({updatedPromotionData});
            }
            else if(popupData.type==='reward_product_id'){
                this.setInputFreeProduct(selectedItems)
                updatedPromotionData = {...updatedPromotionData,...{reward_product_id:selectedItems}}
                await this.setState({updatedPromotionData});
            }
                
        }
    }
    getListOfItems = (items) =>{
        console.log('hello worldddd')
        let fet = []
        if(this.state.popupData.type === 'partner_id'){
            this.setState({getPartnerId:items})
        }
        else if(this.state.popupData.type === 'product_id'){
            this.setState({getProductId:items})
        }

    }
    openTaxesPopup=(type)=>{
        let popupData = this.state.popupData;
        // let taxes_id = this.state.updatedPromotionData?.taxes_id || this.state.productData?.taxes_id || [];
        // let options = this.state.form_options['taxes_id'];
        popupData = {...popupData,...{open:true,type}};
        this.setState({popupData});
    }

    getfunc = async(dat) =>{
        console.log('performance')
        this.setState({fetchingData:true});
        let res  = await fetchSpecificData(dat);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({getSpecificData:res.result.getSpecific});
            if(dat === 'partner_id' || this.state.getSpecificData.partner_id.length > 0 ){
                this.setState({getPartnerId:this.state.getSpecificData.partner_id});
            }else if(dat === 'product_id' || this.state.getSpecificData.product.length > 0 ){
                this.setState({getProductId:this.state.getSpecificData.product_id});
            }
            
        }
        this.setState({fetchingData:false});
        
    }


    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            key = e.target.name;
            if(e.target.type === 'checkbox')
                value = e.target.checked;
            else{
                value = e.target.value;    
                e.preventDefault();
            }
        }
        if ((value==='is_all_cust' && key==='base_on_customers')){
            // let updatedPromotionData1 = this.state.updatedPromotionData;
            let selectedItems = [];
                this.setInputAllowCustomer(selectedItems)
        }
        // console.log(e,'checkssss',property,'propss',newValue,'new valuess')
        let updatedPromotionData = this.state.updatedPromotionData;
        let productData = this.state.productData;
        updatedPromotionData = {...updatedPromotionData,...{[key]:value}};
        productData = {...productData,...{[key]:value}};
        this.setState({updatedPromotionData});
        this.setState({productData});
    }



    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === "edit"){
            show = true;
        }
        return show;

    };
    handleSubmit=async(e)=>{
        if(this.state.updatedPromotionData && Object.keys(this.state.updatedPromotionData).length>0){
            this.setState({fetchingData:true});
            let res = await savePromtionData({id:this.state.promotionData['id'],updatedData:this.state.updatedPromotionData});
            if(res.result.error || res.result.errorMessage){
                alert(res.result.error || res.result.errorMessage);
            }
            else{
                
                if (this.state.productId==='New'){
                    this.setState({productUpdated:true});
                //     window.location.reload()
                }
                // this.setState({productData:res.result.data.product,form_options:res.result.data.form_options});
            }
            this.setState({fetchingData:false});
        }
    }
    getPromotionValue =(e) => {
        console.log('helo world')
        console.log(e.target.value,'this is mee');
        this.handleChange(null,'promotion',e.target.value);
    }
    getRadioCustomer = (e) =>{
        // console.log(e.target.value,'huyeeeee')
        let et = e.target
        let et_value =  et.value
        let et_name = et.name
        // if(et_value === "is_specific_cust"){
        //     this.getfunc('partner_id')
        // }
        
        this.handleChange(null,'base_on_customers',et_value)
    }
    getRadioProduct =(e) =>{
        // console.log(e.target.value,'huyeeeee')
        let et = e.target
        let et_value =  et.value
        let et_name = et.name
        // if(et_value === "is_specific"){
        //     this.getfunc('product_id')
        // }
        
        
        this.handleChange(null,'base_on_products',et_value)
    }
    getRadioPromoCode =(e) =>{
        // console.log(e.target.value,'huyeeeee')
        this.handleChange(null,'promo_code_usage',e.target.value)
    }
    getRadioApplicabilityCode =(e) =>{
        // console.log(e.target.value,'huyeeeee')
        this.handleChange(null,'promo_applicability',e.target.value)
    }
    getRadioRewardCode =(e) =>{
        // console.log(e.target.value,'huyeeeee')
        this.handleChange(null,'reward_type',e.target.value)
    }
    getRadioApplyDiscountCode=(e) =>{
        // console.log(e.target.value,'huyeeeee')
        this.handleChange(null,'discount_type',e.target.value)
    }
    // openTaxesPopup=()=>{
    //     let popupData = this.state.popupData;
    //     let taxes_id = this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id || [];
    //     let options = this.state.form_options['taxes_id'];
    //     // popupData = {...popupData,...{open:true,taxes_id,options}};
    //     // this.setState({popupData});
    // }
    removeTax=(tax_id)=>{
        let taxes = this.state.updatedProductData?.taxes_id;
        taxes = taxes.filter(i=>i!==tax_id);
        this.handleChange(null,'taxes_id',taxes);
    }
    setInputAllowCustomer=(e) =>{
        let evalue = Object.values(e);
        // console.log(e.target.value,'allow customer call')
        let get_list = Array.from(evalue,Number);
        let update_partner_id = [...this.state.allow_customer,get_list]
        this.handleChange(null,'partner_id',e)
        // this.handleChange(null,'partner_id',get_list)

        this.setState({allow_customer:e})
    }
    setInputAllowProduct=(e) =>{
        let evalue = Object.values(e);
       
        // let get_list = Array.from(evalue,Number);
        let get_list = Array.from(evalue);
        // console.log(this.state.form_options.partner_id,'allow customer call')
        let update_product_id = [...this.state.allow_product,get_list]
        this.handleChange(null,'product_id',get_list )
        // Bishu 29/8/22 added the new line to update product list and commented old one
        // this.setState({allow_product:update_product_id})
        this.setState({allow_product:e})
        // Bishu ends here
    
    }
    
    // Bishu added new function for coupon specific product handle
    setInputSpecificProduct=(e) =>{
        let evalue = Object.values(e);
       
        // let get_list = Array.from(evalue,Number);
        let get_list = Array.from(evalue);
        // console.log(this.state.form_options.partner_id,'allow customer call')
        let update_product_id = [...this.state.specific_discount_product,get_list]
        this.handleChange(null,'product_id',get_list )
        // Bishu 29/8/22 added the new line to update product list and commented old one
        // this.setState({allow_product:update_product_id})
        this.setState({specific_discount_product:e})
        // Bishu ends here
    
    }
    setInputFreeProduct=(e) =>{
        let evalue = Object.values(e);
       
        let get_list = Array.from(evalue,Number);
        // let get_list = Array.from(evalue);
        // console.log(this.state.form_options.partner_id,'allow customer call')
        let update_product_id = [...this.state.reward_product_id,get_list]
        this.handleChange(null,'product_id',get_list )
        // Bishu 29/8/22 added the new line to update product list and commented old one
        // this.setState({allow_product:update_product_id})
        this.setState({reward_product_id:e})
        // Bishu ends here
    
    }
    onClickDeleteRow = (id) => {
        // users.splice(index, 1); 
        const updateitem = this.state.allow_customer.filter((elem,ind) =>{
            return ind !== id;
        });
        this.setState({allow_customer:updateitem})
        this.handleChange(null,'partner_id',updateitem)
    };
    onClickDeleteProducts = (id) => {
        // users.splice(index, 1); 
        const updateitem = this.state.allow_product.filter((elem,ind) =>{
            return ind !== id;
        });
        this.setState({allow_product:updateitem})
        this.handleChange(null,'product_id',updateitem)
    };

    onClickDeleterewardProduct = (id) => {
        // users.splice(index, 1); 
        const updateitem = this.state.reward_product_id.filter((elem,ind) =>{
            return ind !== id;
        });
        this.setState({reward_product_id:updateitem})
        this.handleChange(null,'reward_product_id',updateitem)
    };

    onClickDeleteSpecificProducts = (id) => {
        // users.splice(index, 1); 
        const updateitem = this.state.specific_discount_product.filter((elem,ind) =>{
            return ind !== id;
        });
        this.setState({specific_discount_product:updateitem})
        this.handleChange(null,'specific_product_id',updateitem)
    };


    render() { 
        return (
            <>
            <div> 
            {!(this.state.productUpdated) &&
                <form onSubmit={this.handleSubmit} >
                <header>
                <span>
                    {/* <h3 className="console-form__heading">{this.state.productData['name']}</h3> */}
                    {this.showBtn('edit') &&
                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                            {`${this.state.viewMode?"Edit":"Save"}`}
                        </Button>
                    }
                    </span>
                    <span >
                    </span>
                </header>
                { 
                <fieldset  disabled={this.state.viewMode} style={{border:"none"}}>
                    <div className="">  
                    <span className="console-form__inputs" style={{float:"left"}} >
                    <label for="pname">Program Name:</label><br/>
                
                        <input
                        id="name"
                        name="promotion_name"
                        type="text"
                        onChange={this.handleChange}
                        // Bishu 29/8/22 added the code for promotion name 
                        value={((this.state.updatedPromotionData?.name) || (this.state.productData?.name)) }
                        // Bishu ends here
                        />
                        <span className="input-error">{this.state.errors["name"]}</span>
                    </span>
                    {/* check for coupan or programs */}
                    <span className="console-form__inputs " style={{margin:"0 auto",width:'40%'}} >
                    <label for="pname">Program For:
                    <select 
                        name="program_type" 
                        id="program_type" 
                        disabled={!(this.state.productId==='New')}
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.program_type || this.state.productData?.program_type }
                        >
                        <option value=""></option>
                        <option value="promotion_program">Promotion Program</option>
                        <option value="coupon_program">Coupon Program</option>
                        </select> 
                        </label>
                    </span>

                    {/* check for coupan or programs end here */}
                    </div>
                    {/* Second Container */}
                    <br/>
                    {/* Bishu 29/8/22 added overflow hidden */}
                    <div className="bg" style={{overflow:'hidden'}}>
                         {/*Bishu ends here  */}
                    <span className="console-form__inputs" style={{float:"left"}} >
                    <h3><u>Conditions</u></h3>
                    <span style={{width:'100%'}}></span>
                    <h4>Based On Customers:
                        {/* Bishu 29/8/22 added checked function in input  */}
                    <input type="radio" onChange={this.getRadioCustomer} 
                    id="is_all_cust" name="customer_based" 
                    value="is_all_cust"
                    checked={(() => {
                        if(this.state.productData?.base_on_customers === 'is_all_cust' || this.state.updatedPromotionData?.base_on_customers === 'is_all_cust'){
                            return "checked";
                        }
                    })()}/>
                    
                    <label for="is_all_cust">ALL</label>
                    <input type="radio" onChange={this.getRadioCustomer} id="is_specific_cust" 
                    name="customer_based" 
                    value="is_specific_cust"
                    checked={(() => {
                        if(this.state.productData?.base_on_customers === 'is_specific_cust' || this.state.updatedPromotionData?.base_on_customers === 'is_specific_cust'){
                            return "checked";
                        }
                    })()}/>
                    {/* Bishu ends here */}
                    <label for="is_specific_cust">Specific Customer</label>
                    </h4>
                    <br/>
                    <div>
                    {/* {this.state.getPartnerId && this.state.updatedPromotionData.base_on_customers == 'is_specific_cust' &&
                    <label>Allow Customer:
                        <Select name="customers"  onChange={this.setInputAllowCustomer} id="customers">
                        {/* <MenuItem value=""></MenuItem> */}
                        {/* {Object.keys(this.state.getPartnerId).map(attrId => ( 
                        <MenuItem value={attrId}>{this.state.getPartnerId[attrId]}</MenuItem>
                        ))}
                        </Select> */}
                        {/* </label>
                    } */} 
                    {/* Bishu 29/8/22 added a new line to render the button on a condition */}
                    {/* {this.state.getPartnerId && this.state.updatedPromotionData.base_on_customers == 'is_specific_cust' && */}
                    {((this.state.updatedPromotionData?.base_on_customers === "is_specific_cust") || (this.state.productData?.base_on_customers=== 'is_specific_cust')) &&
                    // Bishu ends here
                    <Button variant='secondary' onClick={() => this.openTaxesPopup('partner_id')}>
                        select Customers
                    </Button>
                    }
                    </div>
                    <br/>
                    <div>
                        {/* Bishu 29/8/22 added the condition to render the table and commented old code */}
                    {/* {this.state.getPartnerId && this.state.updatedPromotionData.base_on_customers == 'is_specific_cust' && */}
                    {((this.state.updatedPromotionData?.base_on_customers === "is_specific_cust") || (this.state.productData?.base_on_customers=== 'is_specific_cust')) &&
                        
                        <table className="order-table">
                        <tr>
                            <th style={{height: "30px",textAlign:'center'}}>Customers</th>
                            <th style={{height: "30px",textAlign:'center'}}></th>

                        </tr>
                        {
                        this.state.allow_customer.map((elem,ind) => {
                            return(
                                <tr key={ind} >
                                <td style={{textAlign:'center'}}>{elem['name']}</td>
                                {/* <td style={{textAlign:'center'}}>{this.state.getPartnerId[elem]}</td> */}
                                <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteRow(ind)}>&#10006;</td>
                                </tr>
                                );
                            })
                        }
                        </table>
                    }
                    </div>
                    </span>
                    {/* check for coupan or programs */}
                    <span className="console-form__inputs" style={{margin:"0 auto",width:'40%'}} >
                    <div> 
                        <h3><u>Validity</u></h3>
                        <label for="pname">Number of Times: <span style={{paddingRight:'10px'}}></span>
                        <input 
                        type="number" 
                        id="maximum_use_number" 
                        name="maximum_use_number"
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.maximum_use_number  || this.state.productData?.maximum_use_number }
                        />
                        </label>
                        <label for="pname">Coupon usage method:
                    <select 
                        name="coupon_use_type" 
                        id="coupon_use_type" 
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.coupon_use_type || this.state.productData?.coupon_use_type }
                        >
                        <option value=""></option>
                        <option value="per_cust">Per User</option>
                        <option value="overall_coupon">Overall coupon</option>
                        </select> 
                        </label>
                    </div>
                    <div> 
                        <h3><u>Order Count</u></h3>
                        <label for="pname">Number of Times Used: <span style={{paddingRight:'10px'}}></span>
                        <input 
                        type="number" 
                        id="order_count" 
                        name="order_count"
                        disabled={true}
                        value={this.state.productData?.order_count}
                        />
                        </label>
                    </div>
                    <br/>
                    <div>
                    <label for="rule_date_from">Starting Date: <span style={{paddingRight:'10px'}}></span>
                    <input type="date" 
                    id="rule_date_from"
                    name="rule_date_from" 
                    // value="2018-06-12T19:30"
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.rule_date_from || this.state.productData?.rule_date_from }
                    />
                    </label>
                    </div>
                    
                    <span style={{width:'100%'}}></span>
                    <div>
                    <label for="rule_date_to">Ending Date: <span style={{paddingRight:'10px'}}></span>
                    <input type="date" 
                    id="rule_date_to"
                    name="rule_date_to" 
                    // value="2018-06-12T19:30"
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.rule_date_to || this.state.productData?.rule_date_to }
                    />
                    </label>
                    </div>
                    </span>

                    {/* check for coupan or programs end here */}
                    </div>
                    {/* 3rd Container */}
                    <div className="" style={{marginTop:'20px'}}> 
                    <span className="console-form__inputs" style={{float:"left"}} >
                    <h3>Based On Products:
                    <input 
                    type="radio"
                    id="is_all"
                    onChange={this.getRadioProduct} 
                    name="product_based" 
                    checked={(() => {
                        if(this.state.productData?.base_on_products === 'is_all'){
                            return "checked";
                        }
                    })()}
                    value="is_all"/>
                    <label for="is_all">ALL</label>
                    <input 
                    type="radio" 
                    id="is_specific" 
                    onChange={this.getRadioProduct} 
                    name="product_based"
                    checked={(() => {
                        if(this.state.productData?.base_on_products === 'is_specific'){
                            return "checked";
                        }
                    })()}  
                    value="is_specific"/>
                    <label for="is_specific">Specific Products</label>
                    </h3>
                    <br/>
                    <div>
                    {/* {this.state.getProductId &&  this.state.updatedPromotionData.base_on_products == 'is_specific' && 
                        // <label>Allow Product:
                        //     <Select name="products"  onChange={this.setInputAllowProduct} id="products">
                        //     <MenuItem value=""></MenuItem>
                        //     {Object.keys(this.state.getProductId).map(attrId => ( 
                        //     <MenuItem  value={attrId}>{this.state.getProductId[attrId]}</MenuItem>
                        //     ))}
                        //     </Select>
                        //     </label>
                        } */}
                        {/* Bishu 29/8/22 commented the old code and rewrote a new logic */}
                    {/* {this.state.getProductId &&  this.state.updatedPromotionData.base_on_products == 'is_specific' &&  */}
                    {((this.state.updatedPromotionData?.base_on_products === "is_specific") || (this.state.productData?.base_on_products=== 'is_specific')) &&
                    <Button variant='secondary' onClick={() => this.openTaxesPopup('product_id')}>
                        select Products
                    </Button>
                    }
                    </div>
                    <br/>
                    <div>
                        {/* Bishu 28/8/22 commented the old code and rewrote the new condition */}
                    {/* {this.state.getProductId &&  this.state.updatedPromotionData.base_on_products == 'is_specific' &&  */}
                    {((this.state.updatedPromotionData?.base_on_products === "is_specific") || (this.state.productData?.base_on_products=== 'is_specific')) &&
                    // Bishu ends here
                        
                        <table className="order-table">
                        <tr>
                            <th style={{height: "30px",textAlign:'center'}}>Products</th>
                            <th style={{height: "30px",textAlign:'center'}}></th>

                        </tr>
                        {
                        this.state.allow_product.map((elem,ind) => {
                            // Bishu 29/8/22 returning a new div and data and commented old one
                            return(
                                <tr key={ind} >
                                <td style={{textAlign:'center'}}>{elem['name']}</td>
                                {/* <td style={{textAlign:'center'}}>{elem['name']}</td> */}
                                {/* <td style={{textAlign:'center'}}>{this.state.getProductId[j].name}</td> */}
                                <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteProducts(ind)}>&#10006;</td>
                                </tr>
                                )
                        })
                            // for (let i = 0; i <  elem.length; i++) {
                            //     for (let j = 0; j < this.state.getProductId.length; j++){
                            //         if(this.state.getProductId[j].id == elem[i]){
                            //             console.log('thidddd',this.state.getProductId[j].name)
                                    
                                    // return(
                                    //     <tr key={ind} >
                                    //     <td style={{textAlign:'center'}}>{elem}</td>
                                    //     {/* <td style={{textAlign:'center'}}>{this.state.getProductId[j].name}</td> */}
                                    //     <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteProducts(ind)}>&#10006;</td>
                                    //     </tr>
                                    //     )
                                    // }
                            //         }
                            //      };
                            
                            // } )
                            // BIshu ends here
                        }
                        </table>
                    }
                    </div>
                    </span>
                    <span className="console-form__inputs " style={{margin:"0 auto",width:'40%'}} >
                    <div> 
                    <label for="rule_min_quantity">Quantity: <span style={{paddingRight:'10px'}}></span>
                        <input 
                        type="number" 
                        id="rule_min_quantity" 
                        name="rule_min_quantity"
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.rule_min_quantity || this.state.productData?.rule_min_quantity }
                        />
                    </label>
                    </div>
                    <br/>
                    <div>
                    <label for="rule_minimum_amount">Minimum Purchase of :<span style={{paddingRight:'10px'}}></span>
                    <input 
                    type="number" 
                    id="rule_minimum_amount" 
                    name="rule_minimum_amount"
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.rule_minimum_amount || this.state.productData?.rule_minimum_amount }
                    />
                    </label>
                    <select 
                    name="rule_minimum_amount_tax_inclusion" 
                    id="rule_minimum_amount_tax_inclusion" 
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.rule_minimum_amount_tax_inclusion || this.state.productData?.rule_minimum_amount_tax_inclusion }
                    >
                    <option value=""></option>
                    <option value="tax_included">Tax Include</option>
                    <option value="tax_excluded">Tax Exclude</option>
                    </select> 
                    </div>
                    </span>
                    </div>
                    <br/>
                    {/* 4th Container */}
                    <br/>
                    <br/>
                    <br/>
                     <br/>
                    
                    <div className="" > 
                    <span className="console-form__inputs" style={{float:"left",marginTop:'10px'}} >
                    <p>Promo Code Usage:</p>
                    {/* Bishu 29/8/22 added a checked method */}
                        <input type="radio" id="no_code_needed" onChange={this.getRadioPromoCode} 
                        name="promo_codes" 
                        checked={(() => {
                            if(this.state.updatedPromotionData.promo_code_usage ==='no_code_needed' || this.state.productData?.promo_code_usage === 'no_code_needed'){
                                return "checked";
                            }
                        })()}
                        value='no_code_needed'/>
                        <label for="no_code_needed">Automatically Applied</label>
                        <input type="radio" id="code_needed" onChange={this.getRadioPromoCode}
                         name="" 
                        //  value={this.state.updatedPromotionData?.promo_code_usage|| this.state.productData?.promo_code_usage || 'code_needed'}
                        value="code_needed"
                        checked={(() => {
                            if(this.state.updatedPromotionData.promo_code_usage === 'code_needed' || this.state.productData?.promo_code_usage === 'code_needed'){
                                return "checked";
                            }
                        })()}/>
                        {/* Bishu ends here */}
                        <label for="code_needed">Use a code</label>
                        <br></br>
                        {((this.state.updatedPromotionData.promo_code_usage === 'code_needed') || (this.state.productData?.promo_code_usage === 'code_needed')) &&
                        <label for="rule_minimum_amount">promo_code :<span style={{paddingRight:'10px'}}></span>
                      
                        <input 
                        type="text" 
                        id="promo_code" 
                        name="promo_code"
                        onChange={this.handleChange}
                        // value='promo_code'
                        value={((this.state.updatedPromotionData?.promo_code) || (this.state.productData?.promo_code))}
                        />
                        </label>
                        }
                    
                    </span>
                    <span className="console-form__inputs " style={{margin:"0 auto",width:'40%'}} >
                    <div> 
                    </div>
                    </span>
                    </div>

                    {/* 5th Container */}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                     {/* Ankit start 15 May 2023  */}
                    {(this.state.updatedPromotionData?.program_type || this.state.productData?.program_type ) == 'coupon_program'                   
                     && 
                    <div className="" > 
                    <span className="console-form__inputs" style={{float:"left",marginTop:'20px'}} >
                    <div>
                    <h1>Reward Sections</h1>
                    <hr/>
                    </div>
                    <div>
                    {((this.state.updatedPromotionData?.program_type !== "coupon_program") || (this.state.productData?.program_type!== 'coupon_program')) &&                
                <div>
                    <div>
                        <h3>Applicalibity:</h3>
                            <input type="radio" id="on_current_order" onChange={this.getRadioApplicabilityCode}
                            name="on_current_order" value='on_current_order'
                            checked={(() => {
                                if((this.state.updatedPromotionData?.promo_applicability ==="on_current_order") || (this.state.productData?.promo_applicability === "on_current_order")){
                                    return "checked";
                                }
                            })()}/>
                            <label for="on_current_order">Apply On Current Order</label>
                            <input type="radio" id="on_next_order" onChange={this.getRadioApplicabilityCode}
                            name="on_next_order" value="on_next_order"
                            checked={(() => {
                                if((this.state.updatedPromotionData?.promo_applicability ==="on_next_order") || (this.state.productData?.promo_applicability === "on_next_order")){
                                    return "checked";
                                }
                            })()}/>
                            <label for="on_next_order">Apply On Next Order</label>
                     
                     </div>
                    </div>
    }
                        </div>
                    <br/>
                   
                    <div>
                    <h3>Reward:</h3>
                  
                    <input type="radio" id="discount" onChange={this.getRadioRewardCode}
                     name="discount" value="discount"
                     checked={(() => {
                        if((this.state.updatedPromotionData?.reward_type ==='discount') || (this.state.productData?.reward_type === 'discount')){
                            return "checked";
                        }
                    })()}/>
                    <label for="discount">discount</label>
                    <input type="radio" id="product" onChange={this.getRadioRewardCode} 
                    name="product" value="product"
                    checked={(() => {
                        if((this.state.updatedPromotionData?.reward_type ==='product') || (this.state.productData?.reward_type === 'product')){
                            return "checked";
                        }
                    })()}/>
                    <label for="product">Free Product</label>
                    <input type="radio" id="free_shipping" onChange={this.getRadioRewardCode}
                     name="free_shipping" value="free_shipping"
                     checked={(() => {
                        if((this.state.updatedPromotionData?.reward_type ==='free_shipping') || (this.state.productData?.reward_type === 'free_shipping')){
                            return "checked";
                        }
                    })()}/>
                    <label for="free_shipping">Free Shipping</label>
                    </div>
                     {/*for free product  */}
                     {/* Bishu for product id many2one field for free product */}
                     {((this.state.updatedPromotionData?.reward_type ==='product')|| (this.state.productData?.reward_type==='product')) &&
                    <div>
                    <Button onClick={()=>this.openTaxesPopup('reward_product_id')}>
                    Select Reward Product
                    </Button>
                    
                    </div>
                    
    }
                    {/* for showing button for free product end here */}
                    
                        {/* Bishu 28/8/22 commented the old code and rewrote the new condition */}
                    {/* {this.state.getProductId &&  this.state.updatedPromotionData.base_on_products == 'is_specific' &&  */}
                    {(((this.state.updatedPromotionData?.reward_product_id) && (this.state.updatedPromotionData?.reward_type ==='product')) || ((this.state.productData?.reward_product_id) && (this.state.productData?.reward_type==='product'))) &&
                        <div>                        
                        <table className="order-table">
                        <tr>
                            <th style={{height: "30px",textAlign:'center'}}>Products</th>
                            <th style={{height: "30px",textAlign:'center'}}></th>

                        </tr>
                        {
                        this.state.reward_product_id.map((elem,ind) => {
                            // Bishu 29/8/22 returning a new div and data and commented old one
                            return(
                                <tr key={ind} >
                                <td style={{textAlign:'center'}}>{elem['name']}</td>
                                {/* <td style={{textAlign:'center'}}>{elem['name']}</td> */}
                                {/* <td style={{textAlign:'center'}}>{this.state.getProductId[j].name}</td> */}
                                <td style={{textAlign:'center'}} onClick={() => this.onClickDeleterewardProduct(ind)}>&#10006;</td>
                                </tr>
                                )
                        })
                        }
                        </table>
                    <label for="reward_product_quantity">Quantity: <span style={{paddingRight:'10px'}}></span>
                    <input 
                    type="number" 
                    id="reward_product_quantity" 
                    name="reward_product_quantity"
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.reward_product_quantity || this.state.productData?.reward_product_quantity }
                    />
                    </label>
                    </div>
                        }
                    {/* <div> */}

                    
                    {/* </div> */}
                     {/* Bishu for limit_reward_product */}
                     {((this.state.updatedPromotionData?.reward_type === "product") || (this.state.productData?.reward_type=== 'product')) &&                 
                    <div>
                    <label for="limit_reward_product">Limit Product <span style={{paddingRight:'10px'}}></span>
                        <input 
                        type="number" 
                        id="limit_reward_product" 
                        name="limit_reward_product"
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.limit_reward_product || this.state.productData?.limit_reward_product }
                        />
                    </label>
                    </div>
                    }
                     
                     {/* for free product ends here */}

                    </span>
                    <span className="console-form__inputs " style={{margin:"0 auto",width:'40%'}} >
                    <div style={{marginTop:'90px'}}> 
                    <div> 
                    <label for="description">Discriptions: <span style={{paddingRight:'10px'}}></span>
                    <textarea 
                    id="description" 
                    value={((this.state.updatedPromotionData?.description) || (this.state.productData?.description))} 
                    onChange={this.handleChange}
                    name="description"
                    rows="1" cols="30">
                    </textarea>
                    </label>
                    </div>
                    <br/>
                    {((this.state.updatedPromotionData?.reward_type === "discount") || (this.state.productData?.reward_type=== 'discount')) &&
                    <div> 
                    <label for="discount_type">Apply Discount: <span style={{paddingRight:'10px'}}></span>
                    <select 
                        name="discount_type" 
                        id="discount_type" 
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.discount_type || this.state.productData?.discount_type }
                        >
                        <option value=""> </option>
                        <option value="fixed_amount">Fixed Amount</option>
                        <option value="percentage">Percentage</option>
                        </select> 
                    </label>
                    </div>}
                    <br/>
                    {(((this.state.updatedPromotionData?.discount_type === "fixed_amount") && (this.state.updatedPromotionData?.reward_type === "discount")) || ((this.state.productData?.reward_type=== 'discount') && (this.state.productData?.discount_type=== 'fixed_amount'))) &&
                    <div>
                    <label for="discount_fixed_amount">Fixed Amount: <span style={{paddingRight:'10px'}}></span>
                    <input 
                    type="number" 
                    id="discount_fixed_amount" 
                    name="discount_fixed_amount"
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.discount_fixed_amount || this.state.productData?.discount_fixed_amount}
                    />
                    </label>
                    </div>
                    }
                    
                    {/* Bishu added for discount percent */}
                    {(((this.state.updatedPromotionData?.discount_type === "percentage") && (this.state.updatedPromotionData?.reward_type === "discount")) || ((this.state.productData?.reward_type=== 'discount') && (this.state.productData?.discount_type=== 'percentage'))) &&

                    // {((this.state.updatedPromotionData?.discount_type === "percentage") || (this.state.productData?.discount_type=== 'percentage')) &&

                    <div>
                    <label for="discount_percentage">Discount Percentage: <span style={{paddingRight:'10px'}}></span>
                    <input 
                    type="number" 
                    id="discount_percentage" 
                    name="discount_percentage"
                    onChange={this.handleChange}
                    value={this.state.updatedPromotionData?.discount_percentage || this.state.productData?.discount_percentage}
                    />
                    </label>
                    </div>
    }
                    {/* Bishu added for apply discount on  */}
                    {/* Bishu selection for apply discount on */}
                    {(((this.state.updatedPromotionData?.discount_type === "percentage") && (this.state.updatedPromotionData?.reward_type === "discount")) || ((this.state.productData?.reward_type=== 'discount') && (this.state.productData?.discount_type=== 'percentage'))) &&
                    // {((this.state.updatedPromotionData?.discount_type === "percentage") || (this.state.productData?.discount_type=== 'percentage')) &&
                    <div> 
                    <label for="discount_apply_on">Apply Discount On: <span style={{paddingRight:'10px'}}></span>
                    <select 
                        name="discount_apply_on" 
                        id="discount_apply_on" 
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.discount_apply_on || this.state.productData?.discount_apply_on }
                        >
                        <option value="on_order">On Order</option>
                        <option value="cheapest_product">On Cheapest Product</option>
                        <option value="specific_products">On Specific Products</option>
                        </select> 
                    </label>
                    </div>
    }
                    <br/>
                    {/*  */}
                    {/* Bishu ends */}
                    {/* discount_max_amount */}
                    {/* Bishu added for max discount amount */}
                    {(((this.state.updatedPromotionData?.discount_type === "percentage") && (this.state.updatedPromotionData?.reward_type === "discount")) || ((this.state.productData?.reward_type=== 'discount') && (this.state.productData?.discount_type=== 'percentage'))) &&
                    // {((this.state.updatedPromotionData?.discount_type === "percentage") || (this.state.productData?.discount_type=== 'percentage')) &&
                    <div> 
                    <label for="discount_max_amount">Max Discount Amount: <span style={{paddingRight:'10px'}}></span>
                        <input 
                        type="number" 
                        id="discount_max_amount" 
                        name="discount_max_amount"
                        onChange={this.handleChange}
                        value={this.state.updatedPromotionData?.discount_max_amount || this.state.productData?.discount_max_amount }
                        />
                    </label>
                    </div>
    }
                    <br/>
                    
                    {/* Bishu ends here */}
                    {/* Bish added for specific products many2many field */}
                    {(((this.state.updatedPromotionData?.discount_apply_on === "specific_products") && (this.state.updatedPromotionData?.reward_type === "discount")) || ((this.state.productData?.reward_type=== 'discount') && (this.state.productData?.discount_apply_on=== 'specific_products'))) &&
                    // {((this.state.updatedPromotionData?.discount_apply_on === "specific_products") || (this.state.productData?.discount_apply_on=== 'specific_products')) &&
                    <div>
                    <Button variant='secondary' onClick={() => this.openTaxesPopup('specific_product_id')}>
                        select Products
                    </Button>
                    </div>
                     }
                    <br/>              
                    <div>
                        {/* Bishu 28/8/22 commented the old code and rewrote the new condition */}
                    {/* {this.state.getProductId &&  this.state.updatedPromotionData.base_on_products == 'is_specific' &&  */}
                    {(((this.state.updatedPromotionData?.discount_apply_on === "specific_products") && (this.state.updatedPromotionData?.reward_type === "discount")) || ((this.state.productData?.reward_type=== 'discount') && (this.state.productData?.discount_apply_on=== 'specific_products'))) &&

                    // {((this.state.updatedPromotionData?.discount_apply_on === "specific_products") || (this.state.productData?.discount_apply_on=== 'specific_products')) &&
                    // Bishu ends here
                        
                        <table className="order-table">
                        <tr>
                            <th style={{height: "30px",textAlign:'center'}}>Products</th>
                            <th style={{height: "30px",textAlign:'center'}}></th>

                        </tr>
                        {
                        this.state.specific_discount_product.map((elem,ind) => {
                            // Bishu 29/8/22 returning a new div and data and commented old one
                            return(
                                <tr key={ind} >
                                <td style={{textAlign:'center'}}>{elem['name']}</td>
                                {/* <td style={{textAlign:'center'}}>{elem['name']}</td> */}
                                {/* <td style={{textAlign:'center'}}>{this.state.getProductId[j].name}</td> */}
                                <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteSpecificProducts(ind)}>&#10006;</td>
                                </tr>
                                )
                        })
                        }
                        </table>
                    }
                    </div>
                    {/* Bishu ends */}
                   
    {/* } */}
                    </div>
                    </span>
                    </div>
              } 
                    {/*Ankit End 15 May 2023  */}

                </fieldset>

                }
            
                </form> 
    }
    {this.state.productUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coupon updated successfully
                    </div>
                }
                <PromotionPopup
                    data={this.state.popupData}
                    getListOfItems1 ={this.getListOfItems}
                    handlePopupActions={this.handlePopupActions}
                />
          
            </div>
            </>
        );

    }

}
export default Promotion;
