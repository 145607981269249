import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './DataTransfer.css';
import '../console.css';
import {getProductOptions,fetchSpecificData} from '../../../Shared/consoleServices';

export default function ItemPopup(props) {

	const [state,setState]  = useState({items:[],selectedItem:null});
	const [input,GetInput]  = useState([]);

	useEffect(() => {
		if(props.open === true){
			fetchItems();
		}
	}, [props.open])

	const fetchItems=async()=>{
		let type = (props.type === 'product'?'product':'product_category');
		type = props.type === 'lot'?'lot':type;
        let res = await getProductOptions(props.origin,props.id,type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.data.items});
        }
	}
	//pankaj add specfic data
	const fetchItems1=async(dat)=>{
		let type = (props.type === 'product'?'product':'product_category');
        let res = await fetchSpecificData(dat,type,props.id,props.origin);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.items});
			// let up = [...memory,res.result.items]
			// StoreAll(up)
			// props.getListOfItems1(res.result.items)
        }
	}

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}
// Piyush Kumar 5/6/2023 made changes for many 2 one to many 2 many
	const handleChange=(e,item)=>{
		// if(e.target.checked)
		// 	changeState({selectedItem:item});
		// else
		// 	changeState({selectedItem:null});

		let selectedItems = state.selectedItem || [];
		if(e.target.checked){
			selectedItems = [...selectedItems,item];
		}
		else{
			selectedItems = selectedItems.filter(i=>i!==item)
        }
		changeState({'selectedItem':selectedItems});
	}

	const isItemSelected=(item=null)=>{
        let selectedItems = state.selectedItem || [];
		if(selectedItems.includes(parseInt(item)) || selectedItems.includes(item)){
			return true
		}
    }
// Piyush KUmar 5/6/2023 End


	//pankaj add specfic data 
	const getMoreData = () =>{
		console.log('add itemssss')
		fetchItems1(input);
	}

	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
							{props.type === 'product'?'Select Product':(props.type === 'lot'?'Select Lot':'Select Category')}
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder='enter detail'/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Name</th>
								{props.type === 'product' && props.origin === 'product-data-transfer' &&
									<>
										<th>Sales Price</th>
										<th>Cost</th>
									</>
								}
								{props.type === 'lot' &&
									<>
										<th>Product</th>
										<th>Created On</th>
									</>
								}
							</thead>
							<tbody>
								{Object.values(state.items).map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												// Piyush Kumar 5/6/2023 
												// checked={state.selectedItem?.id === row['id']}
												checked={isItemSelected(row['id'])}
												// Piyush kumar End
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{row['name']}</td>
										{props.type === 'product' && props.origin === 'product-data-transfer' &&
											<>
												<th>{row['list_price']}</th>
												<th>{row['standard_price']}</th>
											</>
										}
										{props.type === 'lot' &&
											<>
												<th>{row['product']}</th>
												<th>{row['create_date']}</th>
											</>
										}
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handlePopupActions(state.selectedItem)} autoFocus disabled={!state.selectedItem}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
