import React from 'react';
import './Graphs.css';
import { Line } from 'react-chartjs-2';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend} from 'chart.js';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export class LineGraph extends React.Component {

    state = {
        options:{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              tooltips:{
                mode:'label',
                enabled:true,
                active:true
              },
              title: {
                display: true,
                text: this.props.title,
              },
            },
          }
    }

    render() { 
        return (
            <Line options={this.state.options} data={this.props.data} />
        );
    }
}
 







