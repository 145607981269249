import React from 'react';
import { fetchOrderDeliveryData } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';

class Pickings extends React.Component {


    state = {
        pickings:null,orderId:null,
        fetchingData:false,
    }

    componentDidMount(){
        changeAppTitle('Console | Pickings');
        let orderId = this.props.match.params.orderId;
        this.setState({orderId});
        this.fetchOrderDeliveryData(orderId);
    }

    fetchOrderDeliveryData=async(orderId)=>{
        this.setState({fetchingData:true});
        let res  = await fetchOrderDeliveryData(orderId);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({pickings:res.result.data.pickings});
        }
        this.setState({fetchingData:false});
    }

    goToPicking=(pickingId)=>{
        let newPageRoute = this.props.history.location.pathname;
        newPageRoute = newPageRoute.replace('pickings',('delivery/' + pickingId));
        this.props.history.push(newPageRoute);
    }

    render() { 
        return (
            <div className="store-orders">
                {this.state.pickings &&
                    <table className="order-table">
                        <thead>
                            <tr>
                                <th>Reference</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Contact</th>
                                <th>Scheduled Date</th>
                                <th>Source Document</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pickings.map((row)=>(
                                <tr onClick={()=>this.goToPicking(row['id'])}>
                                    <td>{row['name']}</td>
                                    <td>{row['location_id']['name']}</td>
                                    <td>{row['location_dest_id']['name']}</td>
                                    <td>{row['partner_id']['name']}</td>
                                    <td>{row['scheduled_date']}</td>
                                    <td>{row['origin']}</td>
                                    <td>{row['state']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default Pickings;