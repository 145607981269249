import urls from "../urls";

export async function fetchStoreOrders(filters){
    try {
        const axios = require("axios");
        const response = await axios(urls.getStoreOrders, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {...filters}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// Ankit Start 14 Feb 2023
export async function fetchCustomerOrderDetails(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.customerOrderDetails, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {...data}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// Ankit End 14 Feb 2023



export async function fetchOrderData(orderId){
    try {
        const axios = require("axios");
        const response = await axios(urls.getStoreOrderData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {orderId}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveOrderData(orderId,updatedOrderData){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveStoreOrderData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    orderId,
                    updatedOrderData
                }
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function fetchOrderDeliveryData(orderId,formData=false,pickingId=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.getOrderDeliveryData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {orderId,pickingId,formData}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveOrderDeliveryData(pickingId,updatedDeliveryData){
    try {
        const axios = require("axios");
        if(updatedDeliveryData.hasOwnProperty('scheduled_date')){
            let scheduled_date = updatedDeliveryData.scheduled_date;
            scheduled_date = scheduled_date.replace('T',' ')
            updatedDeliveryData = {...updatedDeliveryData,...{scheduled_date}}
        }
        const response = await axios(urls.saveOrderDeliveryData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    pickingId,
                    updatedDeliveryData
                }
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function fetchOrderInvoiceData(orderId,formData=false,invoiceId=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.getOrderInvoiceData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {orderId,invoiceId,formData}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveOrderInvoiceData(invoiceId,updatedInvoiceData){
    try {
        const axios = require("axios");
        if(updatedInvoiceData.hasOwnProperty('scheduled_date')){
            let scheduled_date = updatedInvoiceData.scheduled_date;
            scheduled_date = scheduled_date.replace('T',' ')
            updatedInvoiceData = {...updatedInvoiceData,...{scheduled_date}}
        }
        const response = await axios(urls.saveOrderInvoiceData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    invoiceId,
                    updatedInvoiceData
                }
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function companyDataTransfer(page){
    try {
        const axios = require("axios");
        const response = await axios(urls.companyDataTransfer, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    page
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveCompanyDataTransfer(page,transferId,updatedTransferData){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveCompanyDataTransfer, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    page,
                    transferId,
                    updatedTransferData
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// Ankit start 07-11-2022 bugid-1602

export async function getToCompanyData(updatedTransferData){
    try {
        const axios = require("axios");
        const response = await axios(urls.getToCompanyData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    updatedTransferData
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// Ankit start 07-11-2022 bugid-1602



export async function getProductOptions(origin,id,type,query=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.getProductOptions, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    origin,id,type,query
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function getProductDataUpdate(page){
    try {
        const axios = require("axios");
        const response = await axios(urls.productDataUpdate, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    page
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveProductDataUpdate(page,id,updateProductData){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveProductDataUpdate, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    page,
                    id,
                    updateProductData
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function getStockInventory(){
    try {
        const axios = require("axios");
        const response = await axios(urls.stockInventory, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveStockInventory(id,updatedData){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveStockInventory, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    id,updatedData
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function allowedCompanies(companyId){
    try {
        const axios = require("axios");
        const response = await axios(urls.allowedCompanies, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {companyId}
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
//pankaj react 09-08-2022 create user-tree function 
export async function fetchUsersTreeData(userId=null,data){
    try {
        const axios = require("axios");
        
        const response = await axios(urls.getUsersTreeData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {userId,method:'GET'}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function fetchUserData(id=null){
    try {
        const axios = require("axios");    
        const response = await axios(urls.getUserData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {id,method:'GET'}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function saveUserData(id,save_user,updatedData,company_id){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveUserData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    id,save_user,updatedData,company_id,...{method:'POST'}
                }
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
//pankaj react promotion get data on onclick of specific btn  end here 
export async function fetchSpecificData(userId=null,rt=null,id = null,origin = null,data){
    try {
        const axios = require("axios");
        const response = await axios(urls.getSpecificData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {userId,rt,id,origin,method:'GET'}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
//pankaj react variant
export async function fetchVariantData(variantData=null,valueData=null,data){
    try {
        const axios = require("axios");
        const response = await axios(urls.getVariantData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {variantData,valueData,method:'GET'}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

//pankaj react promotion savePromtionData
export async function fetchPromotionData(userId=null,data){
    try {
        const axios = require("axios");
        const response = await axios(urls.getPromotionData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                // params: {userId,method:'GET'}
                params: {userId,method:'GET',data}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
export async function savePromtionData(id,updatedData){
    try {
        const axios = require("axios");
        const response = await axios(urls.savePromtionData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    id,updatedData
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

//pankaj loyalty func  fetchLoyaltyData
export async function saveLoyaltyData(updatedData,id=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveLoyaltyData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    updatedData,id
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
export async function fetchLoyaltyData(type=null,data){
    try {
        const axios = require("axios");
        const response = await axios(urls.getLoyaltyData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {type,method:'GET'}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

//pankaj loyalty func  fetchLoyaltyData
export async function savePaymentData(updatedData,id=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.savePaymentData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    updatedData,id
                }
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
//Contact page 
export async function fetchContactData(type=null,data){
    try {
        const axios = require("axios");
        const response = await axios(urls.getContactUsData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {type,method:'GET'}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// # mohit  09-08-2022 start bug-ID -1539  button all, consum, service added productType
export async function storeProducts(data,searchbar=null,productType=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.storeProducts, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data,searchbar,productType
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// # mohit  09-08-2022 start bug-ID -1539  button all, consum, service

// Ankit Start bugid-1716 09-dec-2022
export async function LotSerialTreeData(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.LotSerialTree, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}


// Ankit Start 24-jan-2023
export async function ProductAttributeTree(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.ProductAttributeTree, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// Ankit End 24 Jan 2023

// Ankit Start 24-jan-2023
export async function ProductAttributeForm(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.ProductAttributeForm, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// Ankit End 24 Jan 2023


// Ankit Start 24-jan-2023
export async function SaveProductAttribute(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.SaveProductAttribute, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// Ankit End 24 Jan 2023

// Product Lot Data
export async function ProductLotData(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.prodcutLot, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// Product Lot Data
export async function SaveProductLotData(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.saveProdcutLot, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// Ankit End bugid-1716 09-dec-2022


// Ankit Start 10-1-2023
export async function UpdateQtyTree(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.UpdateQty, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}


export async function SaveUpdateQtyTree(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.SaveUpdateQty, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// Ankit End 10-1-2023


// Categories varients 
export async function storeProductVariantTree(data,searchbar=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.storeProductVarientTree, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data,searchbar
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// mohit product categories... start...
export async function saveStoreCategory(data){
    try {
        var bodyFormData = new FormData();
        if(data.id)
            bodyFormData.append('id', data.id);
            Object.keys(data.updatedData).forEach((key)=>{
                // # mohit  25-07-2022 start bug-ID -1007 Solving the issue of image upload of product
                if((typeof data.updatedData[key] != 'object') || (key=='reference_image'))
                    bodyFormData.append(key, data.updatedData[key]);
                // # mohit  25-07-2022 end bug-ID -1007 Solving the issue of image upload of product
               
                else
                    bodyFormData.append(key,JSON.stringify(data.updatedData[key]));                 
        })
        // Ankit 29-09-2022 Start bugid-1614 Adding Extra Media in Api while saving
        if(data.hasOwnProperty('CategoryType')){
                bodyFormData.append('CategoryType', data.CategoryType);
            }
        // Ankit 29-09-2022 End bugid-1614 Adding Extra Media in Api while saving
        const axios = require("axios");
        
        const response = await axios(urls.saveStoreCategory, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            },
            data: bodyFormData
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function storeCategories(data,searchbar=null,productType=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.storeCategories, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data,searchbar,productType
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}


export async function saveStoreBrand(data){
    try {
        var bodyFormData = new FormData();
        if(data.id)
            bodyFormData.append('id', data.id);
            Object.keys(data.updatedData).forEach((key)=>{
                // # mohit  25-07-2022 start bug-ID -1007 Solving the issue of image upload of product
                if((typeof data.updatedData[key] != 'object') || (key=='reference_image'))
                    bodyFormData.append(key, data.updatedData[key]);
                // # mohit  25-07-2022 end bug-ID -1007 Solving the issue of image upload of product
               
                else
                    bodyFormData.append(key,JSON.stringify(data.updatedData[key]));                 
        })
        // Ankit 29-09-2022 Start bugid-1614 Adding Extra Media in Api while saving
        if(data.hasOwnProperty('CategoryType')){
                bodyFormData.append('CategoryType', data.CategoryType);
            }
        // Ankit 29-09-2022 End bugid-1614 Adding Extra Media in Api while saving
        const axios = require("axios");
        
        const response = await axios(urls.saveStoreBrand, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            },
            data: bodyFormData
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function storeBrands(data,searchbar=null,productType=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.storeBrands, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data,searchbar,productType
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}


export async function saveUomCategory(data){
    try {
        var bodyFormData = new FormData();
        if(data.id)
            bodyFormData.append('id', data.id);
            Object.keys(data.updatedData).forEach((key)=>{
                // # mohit  25-07-2022 start bug-ID -1007 Solving the issue of image upload of product
                if((typeof data.updatedData[key] != 'object') || (key=='reference_image'))
                    bodyFormData.append(key, data.updatedData[key]);
                // # mohit  25-07-2022 end bug-ID -1007 Solving the issue of image upload of product
               
                else
                    bodyFormData.append(key,JSON.stringify(data.updatedData[key]));                 
        })
        // Ankit 29-09-2022 Start bugid-1614 Adding Extra Media in Api while saving
        if(data.hasOwnProperty('CategoryType')){
                bodyFormData.append('CategoryType', data.CategoryType);
            }
        // Ankit 29-09-2022 End bugid-1614 Adding Extra Media in Api while saving
        const axios = require("axios");
        
        const response = await axios(urls.saveUomCategory, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            },
            data: bodyFormData
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function storeUomCategory(data,searchbar=null,productType=null){
    try {
        const axios = require("axios");
        const response = await axios(urls.storeUomCategory, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data,searchbar,productType
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}




// mohit product categories end...



export async function saveStoreProducts(data){
    try {
        var bodyFormData = new FormData();
        if(data.id)
            bodyFormData.append('id', data.id);
            Object.keys(data.updatedData).forEach((key)=>{
                // # mohit  25-07-2022 start bug-ID -1007 Solving the issue of image upload of product
                if((typeof data.updatedData[key] != 'object') || (key=='reference_image'))
                    bodyFormData.append(key, data.updatedData[key]);
                // # mohit  25-07-2022 end bug-ID -1007 Solving the issue of image upload of product
               
                else
                    bodyFormData.append(key,JSON.stringify(data.updatedData[key]));                 
        })
        // Ankit 29-09-2022 Start bugid-1614 Adding Extra Media in Api while saving
        if(data.hasOwnProperty('UpdatedExtraMediaData')){
                bodyFormData.append('ExtraMediaData', JSON.stringify(data.UpdatedExtraMediaData));
            }
        // Ankit 29-09-2022 End bugid-1614 Adding Extra Media in Api while saving
        const axios = require("axios");
        const response = await axios(urls.saveStoreProducts, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            },
            data: bodyFormData
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// pankaj 26-08-2022  add savebutton value to store data 
export async function saveStoreProductsVarient(data){
    try {
        var bodyFormData = new FormData();
        if(data.id)
            bodyFormData.append('id', data.id);
            Object.keys(data.updatedData).forEach((key)=>{
                if((typeof data.updatedData[key] != 'object') || (key=='reference_image'))
                    bodyFormData.append(key, data.updatedData[key]);
                else
                    bodyFormData.append(key,JSON.stringify(data.updatedData[key]));                 
        })
        const axios = require("axios");
        const response = await axios(urls.saveStoreProductsVarient, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data"
            },
            data: bodyFormData
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function renewMembership(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.renewMembership, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data || {}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

export async function pricelists(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.pricelists, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}

// Piyush Kumar bugid - 1587
export async function changeActivePriceList(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.changeActivePriceList1, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
// Piyush Kumar End


export async function savePricelist(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.savePricelist, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: data
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}
