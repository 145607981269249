import React from 'react';
import './Pricelists.css';
import { pricelists } from '../../../Shared/consoleServices';
import { changeActivePriceList } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import { Button,Grid,Typography } from '@mui/material';
import urls from '../../../urls';

class Pricelists extends React.Component {


    state = {
        pricelists:null,fetchingData:false,total_pricelists:0,
        memory:{},pricelistPage:0,orderedList:[]
    }

    componentDidMount(){
        changeAppTitle('Console | Pricelists');
        this.pricelists(this.state.pricelistPage);
    }

    pricelists=async(page)=>{
        this.setState({fetchingData:true});
        let res  = await pricelists({page});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({pricelists:res.result.data.pricelists,
                            total_pricelists:res.result.data.total_pricelists,
                            pricelistPage:page,orderedList:res.result.data.orderedList
                        });
        }
        this.setState({fetchingData:false});
    }

    goToPricelist=(e=null,pricelistId=false)=>{
       
        // Piyush kumar bugid-1587
        // if (e.target.className === "stop_parent_call" || e.target.name=== "active-pricelist")
        if (e?.target?.name!= "active-pricelist")
        {
            this.props.history.push(urls.extension + '/console/pricelist/' + (pricelistId || 'New'));
        }
        // this.props.history.push(urls.extension + '/console/pricelist/' + (pricelistId || 'New'));
        // piyush kumar end
    }

    changePricelistsPage=async(page)=>{
        if(page>=0 && this.state.total_pricelists>((this.state.pricelistPage * 50) + Object.keys(this.state.pricelists).length)){
            let memory = this.state.memory;
            memory[this.state.pricelistPage] = this.state.pricelists;
            this.setState({memory});
            if(page in memory){
                let pricelists = memory[page];
                this.setState({pricelists,pricelistPage:page});
            }
            else
                this.storepricelists(page);
        }
    }

    // Piyush Kumar bugid-1587
    changeActive=async(pricelistId=false)=>{
        this.setState({fetchingData:true});
        let res  = await changeActivePriceList({pricelistId});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({pricelists:res.result.data.pricelists,
                            total_pricelists:res.result.data.total_pricelists,
                            orderedList:res.result.data.orderedList
                        });
        }
        this.setState({fetchingData:false});
    }
// Piyush Kumar End

    render() { 
        return (
            <div className="store-orders">
                <div style={{width:"100%"}}>
                <Typography style={{display: 'inline-block', marginRight:'10px',marginLeft:'10px'}} variant='h6' sx={{ fontWeight: 'bold'}}>Pricelist</Typography>

                    <Button 
                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.goToPricelist()}
                    >
                        Create 
                    </Button>
                    {this.state.pricelists && Object.keys(this.state.pricelists).length>0 &&
                        <div className="products-pagination" style={{float:"right"}}>
                            <span>
                                <ArrowBack onClick={()=>this.changePricelistsPage(this.state.pricelistPage-1)} style={{cursor:'pointer'}}/>
                                <span>{`${(this.state.pricelistPage * 50)+1}-${(this.state.pricelistPage * 50) + Object.keys(this.state.pricelists).length}/${this.state.total_pricelists}`}</span>
                                <ArrowForward onClick={()=>this.changePricelistsPage(this.state.pricelistPage+1)} style={{cursor:'pointer'}}/>
                            </span>
                        </div>
                    }
                </div>
                {this.state.pricelists &&
                  <Grid container >
                  <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
                    <table className="order-table">
                        <thead>
                            <tr>
                                <th style={{padding:'6px'}}>S.No.</th>
                                <th style={{padding:'6px'}}>Name</th>
                                <th style={{padding:'6px'}}>Created On/Updated On</th>
                                <th style={{padding:'6px'}}>Company</th>
                                <th style={{padding:'6px'}}>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderedList.map((pricelistId,idx)=>(
                                // Piyush kumar commented to stop parent call when childs function is called
                                // <tr onClick={()=>this.goToPricelist(pricelistId)}>
                                <tr onClick={(e)=>this.goToPricelist(e,pricelistId)}>
                                    <td>{idx+1}</td>
                                    <td>{this.state.pricelists[pricelistId]['name']}</td>
                                    <td>{this.state.pricelists[pricelistId]['updated_on']}</td>
                                    <td>{this.state.pricelists[pricelistId]['company']['name']}</td>
                                    <td className="stop_parent_call">
                                        <input
                                            name="active-pricelist"
                                            type="checkbox"
                                            // Piyush kumar bugid - 1587

                                            // desc- disabled is commented and onchange function added for activate the pricelist
                                            // disabled={true}
                                            checked={this.state.pricelists[pricelistId]['active']}
                                            onChange={()=> this.changeActive(pricelistId)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </Grid>
                    </Grid>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default Pricelists;
