import React from 'react';
import './Sliders.css';
import urls from '../../urls';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';

export class ProductSliders extends React.Component{
  
    // Ankit Start 21 may 2023
    makethumnails = ()=>{
        const imageThumbs = [];
        this.props.images.map(url=>{
            imageThumbs.push(urls.domain + url);
        });
        const thumbs = [...imageThumbs,...this.getVideoThumbs()];
        return thumbs;
    }

    getVideoThumbs=()=>{
        let videoThumbs = [];
        this.props.videos.map(url=>{
            let videoId =  url.split('?v=')[1]
            if (!videoId){
                let link = url.split('/')
                videoId = link[link.length-1]
            }
            if (videoId){
                videoThumbs.push(`https://img.youtube.com/vi/${videoId}/default.jpg`)
            }
            else{
                videoThumbs.push(false);
            }
        })

        return videoThumbs;
    }
    // Ankit def 21 may 2023

    render(){
        return(
            <div className="product-sliders">
                <Carousel
                    centerMode={true}
                    centerSlidePercentage={100}
                    stopOnHover={true}
                    showStatus={false}
                    // Ankit Start 21 may 2023
                    // showThumbs={this.props.images.length>1}
                    // showArrows={this.props.images.length>1}
                    showThumbs={(this.props.images.length+this.props.videos.length)>1}
                    showArrows={(this.props.images.length+this.props.videos.length)>1}
                    
                    renderThumbs={() => 
                        this.makethumnails()?.map((thumb)=>(
                            <img src={thumb} alt='thumb' loading="lazy"/>
                      ))
                    }
                >
                    {/* Ankit End 21 May 2023 */}
                    {this.props.images.map((image)=>(
                        <img 
                            src={urls.domain + image} 
                            alt={this.props.alt} 
                            loading="lazy"
                        />
                    ))}


                {/* Ankit Start 21 May 2023 */}
                {this.props?.videos?.map((video)=>(
                    <ReactPlayer 
                    width = "100%"
                    url= {video}
                    controls= {true} 
                    stopOnUnmount = {true}
                />
                    ))}
                {/* Ankit End 21 May 2023 */}

               
                </Carousel>
            </div>
        )
    }
}

export class AdvertisementSliders extends React.Component{

    state = {
        videoPlaying:false
    }

    render(){
        return(
            <div className="advetisement-sliders">
                <Carousel
                    autoPlay={true && !this.state.videoPlaying}
                    centerMode={true}
                    centerSlidePercentage={100}
                    stopOnHover={true}
                    showStatus={false}
                    showArrows={this.props.data.length>1 && !this.state.videoPlaying}
                    interval={2500}
                    infiniteLoop={this.props.data.length>1}
                    showThumbs={false}
                    showIndicators={false}
                    transitionTime={1000}
                >
                    {this.props.data.map((item)=>(
                        item['image']?
                            <img 
                                src={urls.domain + item['image']} 
                                alt={this.props.alt} 
                                loading="lazy"
                            />
                            :
                            <ReactPlayer 
                                width = "100%"
                                url= {item['video']}
                                controls= {true} 
                                stopOnUnmount = {true}
                                onPlay = {()=>this.setState({videoPlaying:true})}
                                onPause = {()=>this.setState({videoPlaying:false})}
                                onEnded = {()=>this.setState({videoPlaying:false})}
                            />
                    ))}
                </Carousel>
            </div>
        )
    }
}

export class HomePageSliders extends React.Component{


    render(){
        return(
            <div className= {`sliders ${this.props.className}`}>
                {this.props.title &&
                    <span className="sliders-title">{this.props.title}</span>
                }
                <Carousel
                    interval={2000}
                    infiniteLoop={this.props.items.length>2}
                    autoPlay={this.props.autoPlay}
                    showThumbs={false}
                    showStatus={false}
                    centerMode={true}
                    centerSlidePercentage={this.props.isSingle?100:40}
                    stopOnHover={true}
                    showIndicators={false}
                    transitionTime={600}
                >
                    {
                        this.props.items.map((item, idx) => {
                            return (
                                <>
                                <Item item={item}/>
                                {/* Piyush Kumar 2/6/2023 silders deatils */}
                                {item.details_on_sliders.length >=12 && 
                                    <ItemDetails item={item}/>
                                }
                                {/* Piyush Kumar End */}
                                </>
                            );
                        })
                    }
            </Carousel>
            </div>
        )
    }
}

export class CompanySliders extends React.Component{

    render(){
        return(
            <div className= {`sliders ${this.props.className}`} style={{maxHeight:"250px"}}>
                {this.props.title &&
                    <span className="sliders-title">{this.props.title}</span>
                }
                <Carousel
                    interval={2000}
                    infiniteLoop={this.props.items.length>2}
                    autoPlay={this.props.autoPlay}
                    showThumbs={false}
                    showStatus={false}
                    centerMode={true}
                    centerSlidePercentage={30}
                    stopOnHover={true}
                    showIndicators={false}
                >
                    {
                        this.props.items.map((item, idx) => {
                            return (
                                <Item 
                                    item={item}
                                    customClass="small-img"
                                />
                            );
                        })
                    }
            </Carousel>
            </div>
        )
    }
}

function Item(props){
    return (
        <div className={`sliders__item ${props.customClass}`}>
            <div>{props.item.display_name}</div>
            <img src={urls.domain + props.item.image} alt="" loading="lazy"/>
        </div>
    )
}

// Piyush Kumar 2/6/2023 Slider details 
function ItemDetails(props){
    return (
        <div 
        // className='sliders__itemDetails' 
        style={{
            top: '1px',
            position: 'absolute',
            margin:'210px 100px 100px 450px',
          }}
        >
            <div dangerouslySetInnerHTML={{__html: props.item.details_on_sliders}} />
        </div>
    )
}
// Piyush Kumar end
