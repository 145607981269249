import './OrderCard.css';

const Order=(props)=>{
    return(
        <div className="order" onClick={()=>props.goToOrder(props.order.id)}>
            <div className="highlight">
                <span>{props.order.name}</span>
                <span>&#8377; {props.order.amount_total}</span>
            </div>
            <div>
                <span>{props.order.partner['name']}</span>
            </div>
            <div>
                <span>{props.order.create_date}</span>
                <span className="state">{props.order.state}</span>
            </div>
        </div>
    )
}

export default Order;