import React from 'react';
import './CartPage.css';
import Progessbar from './Progressbar';
import { Cancel, ArrowBack, ArrowForward } from '@material-ui/icons';
import urls from '../../urls';
import { AuthContext } from '../../Shared/context';
import { changeAppTitle } from '../../Shared/utilities';
import ApplyPromo from '../../Components/ApplyPromo/ApplyPromo';
import SaleOrder from '../../Components/Shared/SaleOrder';
import Loader from '../../Components/Shared/Loader';
import { RedeemPointsPopup } from '../../Components/Popup/Popup';
import Webcam from "react-webcam";
import Chip from '@material-ui/core/Chip';
import { companyCompare } from '../../Shared/services';
import { Grid,Typography ,Box} from '@mui/material';
// Ankit Start 18 May 2023
import {CompanyComparePopup} from './CompanyComparePopup'
// Ankit End 18 May 2023

const videoConstraints = {
    width: 400,
    height: 300,
    facingMode: "user"
}

class CartPage extends React.Component {

    static contextType = AuthContext;

    state = {
        cart: null,
        fetchingData: false,
        intervalId: null,
        imagesToUpload: [],
        imagenameList: [],
        hideHomeDeveliery: false,
        comment: "",
        deliveryOption: null,
        applyPromo: false,
        updatingCart: false,
        sale_images: {},
        removedImgs: [],
        showCamera: false,
        redeemPopupData: { open: false, title: 'Loyalty Benefits!', contentText: '' },
        // Ankit Start 18 May 2023
        CompaniesPopup: {open : false}
        // Ankit Start 18 May 2023
    }

    componentDidMount() {
        changeAppTitle('Shopaas | Cart');
        this.fetchCart();

        this.context.setCartSummary(null);
    }

// Piyush Kumar 25/5/2023 added recompute cart price parameter in function and passed this in api param
    fetchCart = async (recompute_cart_price=true) => {
        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.getCart, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {recompute_cart_price}
                })
            }, 7000);
            const res = await response.data;
            
            if (!res.result.errorMessage && !res.error)
                if (res.result.data) {
                    this.setState({
                        cart: res.result.data,
                        comment: res.result.data['description'] || '',
                        deliveryOption: res.result.data['delivery_type'],
                        sale_images: res.result.data['sale_images'] || {}
                    });
                    // hide delivery otpion button 
                    let hideHomeDeveliery = res.result.data['hideHomeDeveliery'] || false
                    if (hideHomeDeveliery) {
                        this.setState({ hideHomeDeveliery: true })
                    }
                    if (res.result.data.cart_quantity) {
                        let custom_session_info = this.context.custom_session_info;
                        custom_session_info = { ...custom_session_info, ...{ cart_quantity: res.result.data.cart_quantity } };
                        this.context.set_custom_session_info(custom_session_info);
                    }
                    // Piyush Kumar 19/6/2023 
                    if (res.result.data){
                        this.checkProductAvailability()
                    }
                    // Piyush Kumar End
                }
                else {
                    this.props.history.push(urls.extension + '/home');
                }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({ fetchingData: false });
        }
        catch (err) {
            alert(err.message);
            this.setState({ fetchingData: false });
        }
    }

// Piyush Kumar 19/6/2023 added function to check product is published or not
    checkProductAvailability =() =>{
        {Object.keys(this.state.cart['sale_order_lines']).map((lineId,idx)=>(
            <>
            {/* Piyush Kumar 16/6/2023 added this to check product is published or not */}
            {!this.state.cart['sale_order_lines'][lineId]['is_published'] &&
                alert(this.state.cart['sale_order_lines'][lineId]['name'] + " is not available. Please Remove it from Cart.") 
            }
            </>
        ))}}


// Piyush Kumar End

    handleQuantityChange = (value, line_id, interval = 0) => {
        if (this.state.updatedOrderLine && this.state.updatedOrderLine !== line_id)
            return;
        clearInterval(this.state.intervalId);
        if (value && !this.state.updatingCart) {
            this.changeProductQty(line_id, value);
            this.setState({ updatedOrderLine: line_id });
            if (interval > 0) {
                let intervalId = setInterval(() => this.addProductToCart(line_id), parseInt(interval));
                this.setState({ intervalId });
            }
            else
                this.addProductToCart(line_id);
        }
    }

    changeProductQty = (line_id, new_qty) => {
        let cart = this.state.cart;
        let sale_order_line = cart['sale_order_lines'][line_id];
        sale_order_line['last_product_uom_qty'] = sale_order_line['product_uom_qty'];
        sale_order_line['product_uom_qty'] = parseInt(new_qty);
        cart = { ...cart, ...{ [line_id]: sale_order_line } };
        this.setState({ cart });
    }
    // Piyush Kumar 29/03/2023 added fun to delete coupon 
    deleteCoupon = (value, line_id, couponAction) => {
        this.addProductToCart(line_id,value,couponAction);
    }
    // Piyush Kumar End
    addProductToCart = async (line_id,val=null,key=null) => {
        try {
            clearInterval(this.state.intervalId);
            this.setState({ updatingCart: true });
            const axios = require("axios");
            // Piyush KUmar 29/3/2023 Variable defined outside of cond and if Else condition added
            // let sale_order_line = this.state.cart['sale_order_lines'][line_id];
            // let params = {
            //     order_line: line_id,
            //     product_id: sale_order_line['product_id'],
            //     add_qty: 0,
            //     set_qty: sale_order_line['product_uom_qty'],
            //     return_updated_data: true
            // }

            let params = {}
            let sale_order_line = null
            if(key == "deleteCoupon"){
                 params = {
                    order_line: line_id,
                    product_id: this.state.cart['coupons'][line_id]['product_id'],
                    add_qty: 0,
                    set_qty: val,
                    return_updated_data: true
                }
            }
            else{
                 sale_order_line = this.state.cart['sale_order_lines'][line_id];
                 params = {
                    order_line: line_id,
                    product_id: sale_order_line['product_id'],
                    add_qty: 0,
                    set_qty: sale_order_line['product_uom_qty'],
                    return_updated_data: true
                }
            }
            // Piyush Kumar End
            this.setState({ updatedOrderLine: null });
            const response = await axios(urls.addToCart, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if (!res.result.errorMessage && !res.error) {
                if (res.result.statusCode === 200) {
                    let cart = this.state.cart;
                    let sale_order_lines = this.state.cart['sale_order_lines'];
                    if (res.result.updated_order_line['product_uom_qty'] > 0) {
                        sale_order_line = { ...sale_order_line, ...res.result.updated_order_line };
                        sale_order_lines = { ...sale_order_lines, ...{ [line_id]: sale_order_line } };
                    }
                    else {
                        delete sale_order_lines[line_id];
                    }
                    // Piyush KUmar 31/3/2023 added delete condition for the coupon
                    let coupons = this.state.cart['coupons']
                    if(key === "deleteCoupon"){
                        delete coupons[line_id]
                    }
                    // Piyush Kumar End
                    cart = { ...cart, ...{ sale_order_lines }, ...res.result.updated_sale_order };
                    // Ankit Start June 1 2023
                    // this.setState({ cart });
                    let deliveryOption = res.result.delivery_type;
                    this.setState({ cart, deliveryOption });
                    // Ankit June 1 2023
                    console.log("after api call  cart details delievry chargess...\n",this.state.cart['delivery'])
                    let custom_session_info = this.context.custom_session_info;
                    custom_session_info = { ...custom_session_info, ...{ cart_quantity: res.result.cart_quantity } };
                    this.context.set_custom_session_info(custom_session_info);
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({ updatingCart: false });
        }
        catch (err) {
            this.setState({ updatingCart: false });
            alert(err.message);
        }
    }
    // pankaj remove duplicate
    addImageToUpload = (e) => {
        let newImage = e.target.files[0];
        if (newImage) {
            if (this.state.imagenameList.includes(newImage.name) == false) {
                let imageList = [...this.state.imagenameList, newImage.name];
                this.setState({ imagenameList: imageList });

                let imagesToUpload = [...this.state.imagesToUpload, newImage];
                this.setState({ imagesToUpload });
            }
        }
    }

    handleCommentChange = (e) => {
        this.setState({ comment: e.target.value });
    }



    // Ankit Start 15 May 2023
    addDelivery = async(value) => {
        this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.addDelivery, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {delivery_type:value}
                })
            }, 7000);
            const res = await response.data;
            console.log(res)
            if (!(res.result.errorMessage || res.error)) {
                let cart = res.result.data;
                console.log(cart);
               await this.setState({ cart });
            }
            else
                alert(res.result.errorMessage || res.error);
        this.setState({ fetchingData: false });
        
            
    };
    // Ankit End 15 May 2023

    changeDeliveryOption = (e) => {
        this.setState({ deliveryOption: e.target.value });
        // Ankit Start 15 May 2023
        this.addDelivery(e.target.value);
        // Ankit End 15 May 2023
    }

    changePage = async (action) => {
        if (action === "continue") {
            if (this.state.cart && this.state.cart.company) {
                let companyTypeId = this.state.cart['company']['company_type'][0];
                let companyTypeName = this.state.cart['company']['company_type'][1];
                let newPageRoute = companyTypeName + '-' + companyTypeId + '/';
                newPageRoute += this.state.cart['company']['name'].replace('/', ',') + '-' + companyTypeId + '-' + this.state.cart['company']['id'] + '?page=1';
                this.props.history.push(urls.extension + '/home/' + newPageRoute);
            }
            else {
                this.props.history.push(urls.extension + '/home/');
            }
        }
        else if (action === "checkout") {
            await this.context.setCartSummary(this.state.cart);
            this.props.history.push(urls.extension + '/shop/checkout');
        }
        else if (action === "payment") {
            await this.context.setCartSummary(this.state.cart);
            this.props.history.push(urls.extension + '/shop/payment');
        }
    }

    removeUploadImage = (idx = null, imgId = null) => {
        if (idx !== null) {
            let imagesToUpload = this.state.imagesToUpload;
            imagesToUpload.splice(idx, 1);
            this.setState({ imagesToUpload });
        }
        else if (imgId !== null) {
            let sale_images = this.state.sale_images;
            let removedImgs = [...this.state.removedImgs, imgId];
            this.setState({ removedImgs });
            delete sale_images[imgId];
            this.setState({ sale_images });
        }
    }

    handleProcessCheckout = async (e) => {
        if (!this.state.deliveryOption && !this.state.hideHomeDeveliery) {
            alert("select delivery option");
            return;
        }
        if (!this.isCartValid()) {
            alert("No items in cart");
            return;
        }
        // Piyush Kumar 19/6/2023 
        if (Object.values(this.state.cart['sale_order_lines']).some(k => !k.is_published)){
            this.checkProductAvailability()
            return
        }
        // Piyush Kumar End
        try {
            const axios = require("axios");
            this.setState({ fetchingData: true });
            var bodyFormData = new FormData();
            bodyFormData.append('description', this.state.comment);
            bodyFormData.append('delivery_type', this.state.deliveryOption);
            let removedImgs = this.state.removedImgs.join();
            bodyFormData.append('removed_imgs', removedImgs);
            this.state.imagesToUpload.forEach((image, idx) => {
                bodyFormData.append('image' + idx, image);
            })
            const response = await axios(urls.processCheckout, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "multipart/form-data"
                },
                data: bodyFormData
            }, 7000);
            const res = await response.data;
            if (!res.errorMessage && !res.error)
                if (res.statusCode === 201)
                    alert(res.message);
                else
                    this.changePage("checkout");
            else
                alert(res.errorMessage || res.error);
            this.setState({ fetchingData: false });
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
        }
    }

    onPromoApplied = (res) => {
        if (res?.result?.data) {
            this.setState({ cart: res.result.data });
            if (res?.result?.data?.cart_quantity) {
                let custom_session_info = this.context.custom_session_info;
                custom_session_info = { ...custom_session_info, ...{ cart_quantity: res.result.data.cart_quantity } };
                this.context.set_custom_session_info(custom_session_info);
            }
        }
    }

    takeSnapshot = () => {
        if (this.webcam) {
            let clickedImage = this.webcam.getScreenshot();
            if (clickedImage) {
                let imagesToUpload = [...this.state.imagesToUpload, clickedImage];
                this.setState({ imagesToUpload });
            }
        }
    }

    getDisplayImage(imageToUpload) {
        
        // console.log({ "imageToUpload": typeof (imageToUpload) });
        if (typeof (imageToUpload) !== typeof ('string'))
            imageToUpload = URL.createObjectURL(imageToUpload);
        return imageToUpload;
    }

    openRedeemPopup = () => {
        let contentText = this.state.cart['redeem']['message'];;
        let title = this.state.cart['redeem']['header'];
        let redeemPopupData = { ...this.state.redeemPopupData, ...{ open: true, contentText, title } };
        this.setState({ redeemPopupData });
    }

    handleRedeemPopupClose = async (e, redeem = false) => {
        let redeemPopupData = { ...this.state.redeemPopupData, ...{ open: false } };
        this.setState({ redeemPopupData });
        if (redeem === true) {
            try {
                this.setState({ updatingCart: true });
                const axios = require("axios");
                this.setState({ updatedOrderLine: null });
                const response = await axios(urls.redeemRewardPoints, {
                    method: 'POST',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        jsonrpc: "2.0",
                        params: {}
                    })
                }, 7000);
                const res = await response.data;
                if (!(res.result.errorMessage || res.error)) {
                    let cart = res.result.data;
                    this.setState({ cart });
                }
                else
                    alert(res.result.errorMessage || res.error);
                this.setState({ updatingCart: false });
            }
            catch (err) {
                this.setState({ updatingCart: false });
                alert(err.message);
            }
        }
    }

    // Ankit Start 18 May 2023

    // companyCompare = async (company_id) => {
    //     this.setState({ fetchingData: true });
    //     let data = { company_id, method: 'POST', addToCompare: true }
    //     let res = await companyCompare(data);
    //     if (res.error || res.result.errorMessage) {
    //         alert(res.error || res.result.errorMessage);
    //     }
    //     else {
    //         console.log({ res });
    //     }
    //     this.setState({ fetchingData: false });
    // }

    companyCompare = async (company_ids) => {
        this.setState({ fetchingData: true });
        let data = { company_ids, method: 'POST', addToCompare: true }
        let res = await companyCompare(data);
        if (res.error || res.result.errorMessage) {
            this.setState({ fetchingData: false });
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.props.history.push(urls.extension + '/company_compare');
        }
        this.setState({ fetchingData: false });
    }
    companyComparePopUp = () => {
        let CompaniesPopup = this.state.CompaniesPopup;
        let company_type = this.state.cart.company.main_company_type;
        let company_id = this.state.cart.company.id;
        CompaniesPopup = {...CompaniesPopup,open:true, company_type:company_type,company_id:company_id};
        this.setState({CompaniesPopup});
    }

    handleCompaniesDataPopupActions = (selected=null)=>{
        if(selected){
            selected = [...selected,this.state.cart.company.id]
            this.companyCompare(selected);
        }
        this.setState({CompaniesPopup:{open:false}})
    }
    // Ankit End 18 May 2023

    isCartValid = () => {
        let cartValid = false;
        if ((this.state?.imagesToUpload || []).length > 0)
            cartValid = true;
        else if (Object.keys(this.state.cart?.sale_images || {}).length > 0)
            cartValid = true;
        else if (this.state.cart?.sale_order_lines) {
            Object.keys(this.state.cart.sale_order_lines).forEach((line_id) => {
                if (this.state.cart.sale_order_lines[line_id].editable) {
                    cartValid = true;
                    return (cartValid);
                }
            })
        }
        return (cartValid);
    }

    render() {
        return (
            <div className="cart-page">
                <Grid container xs={12} md={12} justifyContent="center">
                    {this.state.cart &&
                        <>
                            <div className="company-name">Company - {this.state.cart?.company?.name}</div>
                            <Grid container xs={12} md={12} justifyContent="flex-start">
                                <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                                    <div className="progress-bar">
                                        <Progessbar stage={1} />
                                    </div>
                                </Grid>
                            </Grid>
                            {this.state.cart['sale_order_lines'] && Object.keys(this.state.cart['sale_order_lines']).length > 0 ?
                                <div className="cart-data" md={12} spacing={1}>
                                    <Grid container xs={12} md={11}  justifyContent="center">
                                        <Grid container xs={10} md={8}  justifyContent="flex-end" marginRight={2}>
                                            <Grid item xs={12} md={12}  >
                                                <SaleOrder
                                                    cart={this.state.cart}
                                                    updatingCart={this.state.updatingCart}
                                                    handleQuantityChange={this.handleQuantityChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={8} md={3} justifyContent="flex-start">
                                            <Grid item xs={10} md={12} >
                                                <div className="cart-value">
                                                    <div className="heading">Order Total</div>
                                                    <div className="subtotal">
                                                        <div className="details">
                                                            <span className="left1">Subtotal:</span>
                                                            <span className="right1">&#8377; {this.state.cart['subtotal']}</span></div>
                                                            {/* <span className="right1">&#8377; {this.state.cart['subtotal']-this.state.cart['discount']-this.state.cart['delivery']}</span></div> */}
                                                        <div className="details">
                                                            <span className="left1">Taxes:</span>
                                                            <span className="right1">&#8377; {this.state.cart['tax']}</span>
                                                        </div>
                                                         {/* Added this By Bishu */}
                                                         <div className="details">
                                                            <span className="left1">Coupon Discount:</span>
                                                            <span className="right1">&#8377; {this.state.cart['discount']}</span>
                                                        </div>
                                                        <div className="details">
                                                            <span className="left1">Delivery Charges:</span>
                                                            <span className="right1">&#8377; {this.state.cart['delivery']}</span>
                                                        </div>
                                                        {/* Bishu ends here */}
                                                    </div>
                                                    
                                                    <div className="total">
                                                        <div className="details">
                                                            <span className="left1">Total:</span>
                                                            <span className="right1">&#8377; {this.state.cart['total']}</span>
                                                        </div>
                                                        <ApplyPromo onPromoApplied={this.onPromoApplied} />
                                                    </div>
                                                    {this.state.cart && this.state.cart['coupons'] &&
                                                    // Ankit Start 13 April 2023
                                                    Object.keys(this.state.cart['coupons'])?.length>0 &&
                                                    // Ankit End 13 April 2023
                                                    <div className='total'>
                                                        <div className='details column'>
                                                            {/* {console.log('this is coupon', this.state.cart['coupons'])} */}
                                                            {/* Ankit Start 12 April 2023 */}
                                                            {/* <span className='left1'>Applied Coupon</span> */}
                                                            <div className="heading">Applied Coupon</div>
                                                            <div className='applied_coupons'>

                                                            {/* Ankit EEnd 12 April 2023 */}

                                                            {(Object.keys(this.state.cart['coupons']).map((cpn)=>
                                                            // {(this.state.cart['coupons']).map((cpn)=>(
                                                                // {console.log("coupon id ....\n",cpn)}
                                                                    <Chip
                                                                    // ANkit Start 13 April 2023
                                                                        // label={(this.state.cart['coupons'][cpn]['name'])}
                                                                        label={<Typography style={{whiteSpace: 'normal'}} variant='subtitle2'>{(this.state.cart['coupons'][cpn]['name'])}</Typography>}
                                                                        style={{height:"100%"}}
                                                                    // ANkit End 13 April 2023
                                                                        // onDelete={()=>this.handleQuantityChange(-1,parseInt(this.state.cart['coupons'][cpn]['id']))}
                                                                        onDelete={()=>this.deleteCoupon(-1,parseInt(this.state.cart['coupons'][cpn]['id']),"deleteCoupon")}
                                                                    />
                                                                 ))}
                                                                </div>
                                                        </div>
                                                        {/* onClick={(e)=>this.props.handleQuantityChange(-1,parseInt(lineId))} */}
                                                    </div>
                                                    }

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                :
                                <div className="cart-table-empty">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your cart is empty!
                                </div>
                            }
                            {/* Ankit bugid-1583 Start 06-09-2022 */}
                            <Grid container   >
                            {!this.state.hideHomeDeveliery &&
                            
                            
                                <div>
                                    
                                    <div className="camera-section">
                                        <div>
                                            {this.state.cart.redeem && this.state.cart.redeem['show'] &&
                                                <button onClick={() => this.openRedeemPopup()}>
                                                    {`Redeem Rewards ${this.state.cart['redeem']['symbol']} ${this.state.cart['redeem']['availableAmount']}`}
                                                </button>
                                            }
                                            {/* Ankit Start 18 May 2023 */}
                                            {/* {true &&
                                                <button onClick={() => this.companyCompare(this.state.cart.company.id)}>
                                                    Add to compare
                                                </button>
                                            } */}

                                            {this.context?.custom_session_info?.location &&
                                                <>
                                                <button onClick={() => this.companyComparePopUp()}>
                                                    Add to compare
                                                </button>

                                                </>
                                            }
                                            {/* Ankit End 18 May 2023 */}
                                        </div>
                                    </div>
                                    {this.state.cart && this.state.cart['allowOrderBySnapshot'] &&
                                        <>
                                            <div className="camera-section">
                                                <div>
                                                    <button onClick={() => this.setState({ showCamera: !this.state.showCamera })}>
                                                        {`${this.state.showCamera ? 'Close Camera' : 'Open Camera'}`}
                                                    </button>
                                                    {this.state.showCamera &&
                                                        <button onClick={() => this.takeSnapshot()}>Take Snapshot</button>
                                                    }
                                                </div>
                                                {this.state.showCamera &&
                                                    <Webcam className="webCam" audio={false} ref={node => this.webcam = node}
                                                        videoConstraints={videoConstraints}
                                                        screenshotFormat="image/png"
                                                    />
                                                }
                                            </div>
                                            <div className="upload-image-holder">
                                                {Object.keys(this.state.sale_images).map((imgId, idx) => (
                                                    <div className="uploaded-image">
                                                        <img className="preview" src={urls.domain + this.state.sale_images[imgId]} alt="upload" />
                                                        <Cancel id="close" onClick={(e) => this.removeUploadImage(idx = null, imgId)} />
                                                    </div>
                                                ))}
                                                {this.state.imagesToUpload.map((imageToUpload, idx) => (
                                                    <div className="uploaded-image">
                                                        <img className="preview" src={this.getDisplayImage(imageToUpload)} alt="upload" />
                                                        <Cancel id="close" onClick={(e) => this.removeUploadImage(idx)} />
                                                    </div>
                                                ))}
                                                <div className="input__container">
                                                    <input
                                                        type="file"
                                                        id="img"
                                                        name="img"
                                                        title="upload image"
                                                        accept="image/*"
                                                        onChange={this.addImageToUpload}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </>
                                    }
                                </div>
                                
                                
                            }
                            {/* Ankit bugid-1583 End 06-09-2022 */}


                            <div className="comment-section">
                                <label>Comment:</label>
                                <textarea
                                    type="text"
                                    id="comment"
                                    name="comment"
                                    maxLength="300"
                                    value={this.state.comment || ''}
                                    onChange={this.handleCommentChange}
                                    style={{ maxWidth: '100%', height: '70px' }}
                                />
                            </div>
                            </Grid>
                            <Grid container  md={12} spacing={3}  >
                            <div className="delivery-section">
                            <Grid item m={2} mb={5} >
                                {!this.state.hideHomeDeveliery &&
                                    <div className="top">
                                        {this.state.cart && this.state.cart['delivery_options'] && this.state.cart['delivery_options'].map((option) => (
                                            <span style={{ marginRight: "10px" }}>
                                                {
                                                    option == 'Home Delivery' &&
                                                    <>
                                                        <input type="radio" name={option} checked={this.state.deliveryOption === option}
                                                            onChange={this.changeDeliveryOption} value={option} />
                                                        <label>{this.state.cart['delivery_text']}</label>
                                                    </>
                                                }
                                                {
                                                    option == 'Pickup' &&
                                                    <>
                                                        <input type="radio" name={option} checked={this.state.deliveryOption === option}
                                                            onChange={this.changeDeliveryOption} value={option} />
                                                        <label>{this.state.cart['delivery_text_pickup']}</label>
                                                    </>
                                                }
                                                {
                                                    option == 'Appointment' &&
                                                    <>
                                                        <input type="radio" name={option} checked={this.state.deliveryOption === option}
                                                            onChange={this.changeDeliveryOption} value={option} />
                                                        <label>{this.state.cart['delivery_textAppointment']}</label>
                                                    </>
                                                }
                                                
                                                {
                                                    option == 'Request for Home Visit' &&
                                                    <>
                                                        <input type="radio" name={option} checked={this.state.deliveryOption === option}
                                                            onChange={this.changeDeliveryOption} value={option} />
                                                        <label>{this.state.cart['delivery_textRequest for Home Visit']}</label>
                                                    </>
                                                }
                                                {
                                                    option == 'Request for call' &&
                                                    <>
                                                        <input type="radio" name={option} checked={this.state.deliveryOption === option}
                                                            onChange={this.changeDeliveryOption} value={option} />
                                                        <label>{this.state.cart['delivery_textRequest for call']}</label>
                                                    </>
                                                }
                                                {/* <input type="radio" name={option} checked={this.state.deliveryOption===option} 
                                        onChange={this.changeDeliveryOption} value={option}/>
                                    <label>{option}</label> */}
                                            </span>
                                        ))}
                                    </div>
                                }
                                </Grid>
                                <Grid item md={12} padding={1}> 
                                <div className="bottom" >
                                    <button className="btn1" style={{marginRight:5}} onClick={() => this.changePage("continue")}>
                                        <ArrowBack /> Continue Shopping
                                    </button>

                                    <button className="btn2" style={{marginLeft:5}}
                                        onClick={() => this.handleProcessCheckout()}
                                    >
                                        Process Checkout <ArrowForward />
                                    </button>
                                </div>
                                </Grid>
                            </div>
                            </Grid>
                        </>
                    }
                </Grid>

                 {/* Ankit Start 18 May 2023  */}
                 <CompanyComparePopup 
                    data={this.state.CompaniesPopup} 
                    open={this.state.CompaniesPopup['open']}
                    handleCompaniesDataPopupActions = {this.handleCompaniesDataPopupActions}
                />
                {/* Ankit End 18 May 2023  */}

                <RedeemPointsPopup
                    open={this.state.redeemPopupData['open']}
                    title={this.state.redeemPopupData['title']}
                    contentText={this.state.redeemPopupData['contentText']}
                    handlePopupClose={this.handleRedeemPopupClose}
                />
                
               
                
                <Loader open={this.state.fetchingData || this.state.updatingCart} handleClose={() => { }} />


            </div>
        );
    }
}

export default CartPage;
