import React, { useState,useEffect } from 'react';
import './ScrollToTopBtn.css';
import { ArrowUpward } from '@material-ui/icons';


function ScrollToTopBtn() {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 200) 
          setShowButton(true);
        else 
          setShowButton(false);
      });
    }, []);
  
    // This function will scroll the window to the top 
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior:'smooth'
      });
    };



    return ( 
        <>
            {showButton && (
                <ArrowUpward  onClick={()=>scrollToTop()} className="back-to-top"/>
            )}
        </>
     );
}

export default ScrollToTopBtn;
