import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {fetchPromotionData,fetchSpecificData,savePromtionData} from '../../../Shared/consoleServices';
import '../console.css';

export default function PromotionPopup(props) {

	const [state,setState]  = useState({items:[],selectedItems:null});
	// Bishu date: 29/08/22 added the code to store selected product items
	const [product,setProduct]  = useState({items:[],selectedProductItems:null});
	// Bishu 29/8/22 ends here
	// Bishu added for coupon specific products
	const [specificproduct,setSpecficProduct]  = useState({items:[],selectedSpecificProductItems:null});
	const [rewardproduct,setRewardProduct]  = useState({items:[],selectedRewardProductItems:null});
	// ends here
	const [input,GetInput]  = useState([]);
	const [memory,StoreAll] = useState([]);
     

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}

	useEffect(() => {
		if(props.data.open === true){
            fetchItems();
		}
	}, [props.data.open])

    const fetchItems=async()=>{
		let type = ((props.data.type === 'product_id'||props.data.type ==='specific_product_id'||props.data.type ==='reward_product_id')?'product_id':'partner_id');
        let res = await fetchSpecificData(type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.items});
			// let up = [...memory,res]
			// StoreAll(up)
		
			// props.getListOfItems1(res.result.items)
        }
	}
	const fetchItems1=async(dat)=>{
		// let type = (props.data.type === 'product_id'?'product':'partner');
        let res = await fetchSpecificData(dat,props.data.type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.items});
			let up = [...memory,res.result.items]
			StoreAll(up)
			props.getListOfItems1(res.result.items)
        }
	}

	const handleChange=(e,item)=>{
        let selectedItems = state.selectedItems || [];
		let selectedProductItems = state.selectedProductItems || [];
		let selectedSpecificProductItems = state.selectedSpecificProductItems || []
		let selectedRewardProductItems = state.selectedRewardProductItems || []
		// Bishu 29/8/22 commented the old code and rewrote a new logic to store products
		if(e.target.checked){
			if (props.data.type == 'partner_id'){
				selectedItems = [...selectedItems,item];
				
			}
			else if (props.data.type== 'product_id'){
				selectedProductItems = [...selectedProductItems,item];
			}
			else if (props.data.type== 'specific_product_id'){
				selectedSpecificProductItems = [...selectedSpecificProductItems,item];
			}
			else if (props.data.type== 'reward_product_id'){
				selectedRewardProductItems = [...selectedRewardProductItems,item];
			};
            // selectedItems = [...selectedItems,item];
        }
		else{
			selectedItems = selectedItems.filter(i=>i!==item)
			selectedProductItems = selectedProductItems.filter(i=>i!==item)
			selectedRewardProductItems =selectedRewardProductItems.filter(i=>i!==item)
        }
		if (props.data.type == 'partner_id'){
			changeState({'selectedItems':selectedItems});
			
		}
		else if (props.data.type== 'product_id'){
			changeState({'selectedProductItems':selectedProductItems});
		}
		else if (props.data.type=='specific_product_id'){
			changeState({'selectedSpecificProductItems':selectedSpecificProductItems});
		}
		else if (props.data.type=='reward_product_id'){
			changeState({'selectedRewardProductItems':selectedRewardProductItems});
		};
		
        // changeState({'selectedItems':selectedItems});
		// 29/8/22 Bishu ends here
		
	}

    const isItemSelected=(item=null)=>{
        let selectedItems = state.selectedItems || [];
		// Bishu 29/8/22 added condition to return the selected items
		let selectedProductItems = state.selectedProductItems || [];
		let selectedSpecificProductItems = state.selectedSpecificProductItems || [];
		let selectedRewardProductItems = state.selectedRewardProductItems || [];
		if (props.data.type == 'partner_id'){
			let selected = selectedItems.includes(item);
			return(selected);
			
		}
		else if (props.data.type== 'product_id'){
			let selected = selectedProductItems.includes(item);
			return(selected);
			// 29/8/22 Bishu ends here
		}
		else if (props.data.type== 'specific_product_id'){
			let selected = selectedSpecificProductItems.includes(item);
			return(selected);
			// 29/8/22 Bishu ends here
		}
		else if (props.data.type== 'reward_product_id'){
			let selected = selectedRewardProductItems.includes(item);
			return(selected);
			// 29/8/22 Bishu ends here
		};
        // return(selected);
		// state.selectedItems = [];
		// console.log('selected is',selected)
    }
	const getMoreData = () =>{
		console.log('add itemssss')
		fetchItems1(input);
	}

	return (
		<div>
			
			<Dialog
				open={props.data.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
							{/* Bishu 29/8/22 added the condition to show product wise or partner wise string on the pop up page */}
                            Select {(props.data.type==="product_id" ||props.data.type=== 'specific_product_id' || props.data.type==='reward_product_id')? 'Product':'Partner'}
							{/* Bishu 29/8/22 ends here */}
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							// Bishu 29/8/22 added the condition to show dynamic placeholder on the search bar in pop up page
							placeholder={props.data.type=="partner_id"?'Enter Partner detail':'Enter product detail'}/>
							{/* Bishu ends here */}
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
							
						
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>{(props.data.type=="product_id" ||props.data.type=='specific_product_id' || props.data.type=='reward_product_id')? 'Product':'Partner'}</th>
							</thead>
							<tbody>
								{Object.values(state.items).map((row,idx)=>(
									<tr>
										<td>
											{/* Bishu 29/8/22 commented the code and rewrote the code using a new logic and return a row */}
										<input 
												type="checkbox"
												name="selectedItem"
												checked={isItemSelected(row)}
												onChange={(e)=>handleChange(e,row)}
												// checked={isItemSelected(row['id'])}
												// onChange={(e)=>handleChange(e,row['id'])}
											/>
											{/* <input 
												type="checkbox"
												name="selectedItem"
												checked={isItemSelected(row['id'])}
												onChange={(e)=>handleChange(e,row['id'])}
											/> */}
											{/* Bishu 29/8/22 ends here */}
										</td>
										<td>{row['name']}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{/* Bishu 29/8/22 commented the code and rewrote the new logic for condition rendering */}
					{((props.data.type==='product_id') ||(props.data.type==='partner_id')) &&
					<div>
					<Button variant="outlined" onClick={()=>props.data.type==='product_id'?props.handlePopupActions(state.selectedProductItems):props.handlePopupActions(state.selectedItems)}>
					{/* <Button variant="outlined" onClick={()=>props.data.type=='partner_id'?props.handlePopupActions(state.selectedItems):props.handlePopupActions(state.selectedProductItems)} autoFocus disabled={!state.selectedItems}> */}
						Select {(props.data.type=="product_id" ||props.data.type=='specific_product_id' || props.data.type=='reward_product_id')? 'Product':'Partner'}
					</Button>
					</div>
					}
					{((props.data.type==='specific_product_id')||(props.data.type==='reward_product_id')) &&
					<div>
					<Button variant="outlined" onClick={()=>props.data.type==='specific_product_id'?props.handlePopupActions(state.selectedSpecificProductItems):props.handlePopupActions(state.selectedRewardProductItems)}>
					{/* <Button variant="outlined" onClick={()=>props.data.type=='partner_id'?props.handlePopupActions(state.selectedItems):props.handlePopupActions(state.selectedProductItems)} autoFocus disabled={!state.selectedItems}> */}
						Select specific Product
					</Button>
					</div>
					}
					
					{/* (props.data.type=='specific_product_id'?props.handlePopupActions(state.selectedSpecificProductItems) */}
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
