import './Progressbar.css';

function Progessbar(props) {
    return ( 
        <div class="container">
            <ul class="progressbar">
            <li class={`${props.stage===1?"active":""}`}>Review Order</li>
            <li class={`${props.stage===2?"active":""}`}>Address</li>
            <li class={`${props.stage===3?"active":""}`}>Confirm Order</li>
            </ul>
        </div>
     );
}

export default Progessbar;