import React from 'react';
import '../../Pages/ProductsPage/ProductsPage.css';
import urls from '../../urls';
import { CompareArrows } from '@material-ui/icons';
import { addToCompare } from '../../Shared/services';
import { AuthContext } from '../../Shared/context';
import { ShoppingCart } from '@material-ui/icons';
import {addProductToCart,resetCart} from '../../Shared/services';
import {ResetCartPopup} from '../../Components/Popup/Popup';
import Loader from '../../Components/Shared/Loader';

class ProductCard extends React.Component {

    static contextType = AuthContext;

    state = {
        resetCartPopupData:{
            open:false,
            title:'',
            contentText:''
        }
    }


    clickHandler(product){
        let newPageRoute = product['name'].replace('/',',') + '-' + product['id'];
        if(product['company'] && product['companyType']){
            let x = product['companyType']['name'] + '-' + product['companyType']['id'] + '/' +
                    product['company']['name'] + '-' + product['companyType']['id'] + '-' + product['company']['id'] + '/';
            newPageRoute = x + newPageRoute;
            this.props.history.push(urls.extension + '/home/' +newPageRoute);
            this.props.clickHandler();
        }
        else{
            this.props.history.push(this.props.history.location.pathname + '/' +newPageRoute);
        }
    }

    addToCompare=async(product)=>{
        this.setState({fetchingData:true});
        let res = await addToCompare(product['defaultVariant']);
        this.setState({fetchingData:false});
        if(res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if(res.result.statusCode === 201)
            alert(res.result.message);
        else{
            let custom_session_info = this.context.custom_session_info;
            custom_session_info = {...custom_session_info,...{products:res.result.compareCount}};
            this.context.set_custom_session_info(custom_session_info);
        }
    }

    addProductToCart=async(product)=>{
        let product_id = product['defaultVariant'];
        let product_template_id = product['id'];
        let add_qty = 1;
        this.setState({fetchingData:true});
        let res = await addProductToCart(product_id,add_qty,product_template_id);
        this.setState({fetchingData:false});
        if(res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if(res){
            if(res.result.statusCode === 200){
                let custom_session_info = this.context.custom_session_info;
                custom_session_info = {...custom_session_info,...{cart_quantity:res.result.cart_quantity}};
                this.context.set_custom_session_info(custom_session_info);
                alert("Added");
            }
            else if(res.result.statusCode === 201)
                this.openResetPopup(res.result,{product_id,add_qty,product_template_id});
        }
    }

    openResetPopup=(data,productData=null)=>{
        let title = data.header;
        let contentText = data.message;
        let resetCartPopupData = {...this.state.resetCartPopupData,...{open:true,title,contentText,productData}};
        this.setState({resetCartPopupData});
    }

    handleResetPopupClose=async(e,reset=false)=>{
        let resetCartPopupData = {...this.state.resetCartPopupData,...{open:false,title:'',contentText:''}};
        let productData = resetCartPopupData.productData;
        this.setState({resetCartPopupData});
        if(reset === true){
            await this.resetCart(productData);
        }
    }

    resetCart=async(productData)=>{
        this.setState({fetchingData:true});
        let res = await resetCart(productData);
        if(res && res.result){
            let custom_session_info = this.context.custom_session_info;
            custom_session_info = {...custom_session_info,...{cart_quantity:res.result.cart_quantity}};
            this.context.set_custom_session_info(custom_session_info);
        }
        this.setState({fetchingData:false});
    }


    render() { 
        return (
            <div className="product-card" onClick={()=>this.clickHandler(this.props.prod)}>
                <img 
                    className="product-card__image"
                    src={urls.domain+this.props.prod.image}
                    alt={this.props.prod.name} 
                    loading="lazy"
                />
                <div className="compare-icon">
                    <ShoppingCart 
                        onClick={(e)=>{e.stopPropagation();this.addProductToCart(this.props.prod)}}
                    />
                    {this.props.prod['enable_comparison'] &&
                        <CompareArrows 
                            onClick={(e)=>{e.stopPropagation();this.addToCompare(this.props.prod)}}
                        />
                    }
                </div>
                {/* pankaj Change discounted price to discount price */}
                {this.props?.compare == 'compare' ? 
                <div className="product-card__details">
                    <span className="product-card__name">{this.props.prod.name}</span>
                    <span className="product-card__price">
                        <span className={`product-card__originalprice ${this.props.prod?.discountPrice!==false?'strike':''}`}>
                            &#8377; {this.props.prod.originalPrice}
                        </span>
                        {this.props.prod.discountPrice!==false && 
                            <span className="product-card__discountPrice">
                                &#8377;  {this.props.prod?.discountPrice}
                            </span>
                        }
                    </span>
                </div>
                :
                <div className="product-card__details">
                    <span className="product-card__name">{this.props.prod.name}</span>
                    <span className="product-card__price">
                        <span className={`product-card__originalprice ${this.props.prod?.discountedPrice!==false?'strike':''}`}>
                            &#8377; {this.props.prod.originalPrice}
                        </span>
                        {this.props.prod.discountedPrice !==false && 
                            <span className="product-card__discountedPrice">
                                &#8377;  {this.props.prod?.discountedPrice}
                            </span>
                        }
                    </span>
                </div>
                }
                <ResetCartPopup 
                    open={this.state.resetCartPopupData['open']}
                    title={this.state.resetCartPopupData['title']}
                    contentText={this.state.resetCartPopupData['contentText']}
                    handlePopupClose={this.handleResetPopupClose}
                />
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default ProductCard;
