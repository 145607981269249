import React from 'react';
import './SignUp.css';
import shopaasLogo from '../../images/shopaas.png';
import { Select,MenuItem } from '@material-ui/core';
import urls from '../../urls';
import { changeAppTitle,formValidation } from '../../Shared/utilities';
import { fetchLanguages } from '../../Shared/services';
import Loader from '../../Components/Shared/Loader';
import Alert from '@mui/material/Alert';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import SearchIcon from '@mui/icons-material/Search';
// import Input from '@material-ui/core/Input';
// import IconButton from '@mui/material/IconButton';
import {
    Paper,
    TextField,    
    Button,
    IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function isNumeric(n) {
    return !isNaN(parseInt(n)) && isFinite(n);
}
class SignUp extends React.Component {

    state = {
        signUpData:{
            id:null,
            name:"",
            country:{id:null,name:""},
            phone_code:'91',
            email:"",
            mobile:"",
            passwd:'',
            confirmPasswd:'',
            showPassword: false,
            
        },
        code:'',
        minutes: 0,
        seconds: 5,
        otpSuccessful:false,
        geoData:{
            country:{}
        },
        loadingData:true,
        errors:{}
    }

    componentDidMount(){
        changeAppTitle('Shopaas | SignUp');
        this.setState({loadingData:false});
        this.fetchGeoData();
        this.fetchLanguages();
    }

    fetchLanguages=async()=>{
        this.setState({loadingData:true});
        let res  = await fetchLanguages();
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            await this.setState({langs:res.result.data.langs});
            if(res.result.data.default){
                await this.handleChange(null,'language',String(res.result.data.default));
            }
        }
        this.setState({loadingData:false});
    }
    

    fetchGeoData=async(country_id=null,state_id=null)=>{
        try {
            //opt counter start

            //opt counter end


            this.setState({fetchingData:true});
            const axios = require("axios");
            const response = await axios(urls.getGeoData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        country_id,
                        state_id
                    }
                })
            }, 7000);
            const res = await response.data;
            this.setState({fetchingData:false});
            if(!res.result.errorMessage && !res.error){
                let geoData = {...this.state.geoData,...res.result.data};
                await this.setState({geoData});
                if(res.result.data.default){
                    this.handleChange(null,'country',res.result.data.default);
                }
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            this.setState({fetchingData:false});
            alert(err.message);
        }
    }


    handleValidation=async(key=null)=>{
        let errors = formValidation(this.state.errors,this.state.signUpData,key);        
        this.setState({errors});
        return(errors);
    }


    handleChange=async(e=null,x=null,val=null)=>{
        let signUpData = this.state.signUpData;
        let key = x;
        let value = val;
        if(!val){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        if(['country','state','city'].includes(key)){
            value = this.state.geoData[key][value];
            if(key==='country')
                signUpData = {...signUpData,...{'phone_code':value['phone_code']}};
        }
        if(['language'].includes(key)){
            value = this.state.langs[value];
        }
        signUpData = {...signUpData,...{[key]:value}};
        await this.setState({signUpData});
        this.handleValidation(key);
    }

    handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            let errors = await this.handleValidation();
            if(Object.keys(errors).length>0){
                alert('Please fill all fields properly')
                return;
            }
            this.setState({fetchingData:true});
            const axios = require("axios");
            let params = {...this.state.signUpData};
            const response = await axios(urls.SignUp, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            this.setState({fetchingData:false});
            if(!res.result.errorMessage && !res.error){
                alert('success');
                this.props.history.push(urls.extension + '/login');
            }
            else
                alert(res.result.errorMessage || res.error);
            return;
        } 
        catch (err) {
            this.setState({fetchingData:false});
            alert(err.message);
            return;
        }
    }


  
    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
        // setValues({ ...values, showPassword: !values.showPassword });
      };
      handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    render() { 
        return (
            <>
                <div className="signup-form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="application-icon">
                            <img src={shopaasLogo} alt="application-icon"/>
                        </div>
                        <div className="signup-form__inputs" >
                            <label htmlFor="email">Your Email</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={this.handleChange}
                                value={this.state.signUpData['email']}
                            />
                            <span className="input-error">{this.state.errors["email"]}</span>
                        </div>
                        {!this.state.loadingData &&
                            <div className="signup-form__inputs" >
                                <label htmlFor="country">Country</label>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper-label"
                                    onChange={this.handleChange}
                                    value={this.state.signUpData['country']['id']}
                                    style={{width:'100%'}}
                                    name='country'
                                >
                                    {Object.keys(this.state.geoData.country).map((country_id)=>(
                                        <MenuItem value={country_id}>
                                            {this.state.geoData.country[country_id]['name']}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                                <span className="input-error">{this.state.errors["country"]}</span>
                            </div>
                        }
                        {!this.state.loadingData &&
                            <div className="signup-form__inputs" >
                                <label htmlFor="language">language</label>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper-label"
                                    onChange={this.handleChange}
                                    value={this.state.signUpData['language']['id']}
                                    style={{width:'100%'}}
                                    name='language'
                                >
                                    {Object.keys(this.state.langs).map((language_id)=>(
                                        <MenuItem value={language_id}>
                                            {this.state.langs[language_id]['name']}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                                <span className="input-error">{this.state.errors["country"]}</span>
                            </div>
                        }
                        <div className="signup-form__inputs" >
                            <label htmlFor="mobile">Mobile</label>
                            <span style={{display:"flex",gap:"5px"}}>
                                <span className="prefix">{'+'+this.state.signUpData['phone_code']}</span>
                                <input
                                    id="mobile"
                                    name="mobile"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.signUpData['mobile']}
                                    style={{minWidth:'270px'}}
                                />
                            </span>
                            <span className="input-error">{this.state.errors["mobile"]}</span>
                        </div>
                        <div className="signup-form__inputs" >
                            <label htmlFor="name">Your Name</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.signUpData['name']}
                            />
                            <span className="input-error">{this.state.errors["name"]}</span>
                        </div>
                        <div className="signup-form__inputs" >
                            <label htmlFor="passwd">Password</label>
                            <Input
                                id="passwd"
                                name="passwd"
                                // type="password"
                                type={this.state.showPassword ? "text" : "password"}
                                onChange={this.handleChange}
                                value={this.state.signUpData['passwd']}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                      >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                    
                            <span className="input-error">{this.state.errors["passwd"]}</span>
                        </div>
                        <div className="signup-form__inputs" >
                            <label htmlFor="confirmPasswd">Confirm Password</label>
                            <input
                                id="confirmPasswd"
                                name="confirmPasswd"
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.signUpData['confirmPasswd']}
                            />
                            <span className="input-error">{this.state.errors["confirmPasswd"]}</span>
                        </div>
                        <button className="sign-up-btn" 
                            onClick={(e)=>this.handleSubmit(e)}
                        >
                            Sign Up
                        </button>
                        <div className="login-route-link">
                            <span onClick={()=>this.props.history.push(urls.extension + '/login')}>
                                Already have an account?
                            </span>
                        </div>
                    </form>
                </div>
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </>
        );
    }
}
 
export default SignUp;
