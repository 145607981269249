import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import '../console.css';
import {getProductOptions,fetchSpecificData} from '../../../Shared/consoleServices';

export default function ItemPopup(props) {

	const [state,setState]  = useState({items:[],selectedItems:{},type:''});
	const [input,GetInput]  = useState([]);

	useEffect(() => {
		if(props.open === true){
			fetchItems();
		}
	}, [props.open])

	const fetchItems=async()=>{
		let type = props.type;
		if(state.type !== type)
			changeState({items:[],'type':type});
        let res = await getProductOptions(props.origin,props.id,type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.data.items});
        }
	}
	//pankaj add specfic data
	const fetchItems1=async(dat)=>{
		let type = props.type;
		if(state.type !== type)
			changeState({items:[],'type':type});
		// let type = (props.type === 'product'?'product':'product_category');
        let res = await fetchSpecificData(dat,type,props.id,props.origin);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.items});
			// let up = [...memory,res.result.items]
			// StoreAll(up)
			// props.getListOfItems1(res.result.items)
        }
	}
	//pankaj add specfic data 
	const getMoreData = () =>{
		console.log('add itemssss')
		fetchItems1(input);
	}

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}

	const handleChange=(e,item)=>{
        let selectedItems = state.selectedItems;
        if(!selectedItems.hasOwnProperty(props.type))
            selectedItems = {[props.type]:{}}
		if(e.target.checked){
            selectedItems[props.type] = {...selectedItems[props.type],...{[item['id']]:item}};
        }
		else{
            delete selectedItems[props.type][item['id']];
        }
        changeState({'selectedItems':selectedItems});
	}

    const isItemSelected=(row=null)=>{
        let selectedItems = state.selectedItems;
        if(row){
            if(selectedItems.hasOwnProperty(props.type) && selectedItems[props.type].hasOwnProperty(row['id']))
                return(true);
            return(false);
        }
        else{
            if(selectedItems.hasOwnProperty(props.type) && Object.keys(selectedItems[props.type]).length>0)
                return(true);
            return(false);
        }
    }

	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>{`Select ${props.type}`}</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder='enter detail'/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Name</th>
							</thead>
							<tbody>
								{Object.values(state.items).map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItems"
												checked={isItemSelected(row)}
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{row['name']}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handlePopupActions(state.selectedItems,props.type)} autoFocus disabled={!isItemSelected()}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
