import React from 'react';
import './Invoice.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { fetchOrderInvoiceData,saveOrderInvoiceData } from '../../../Shared/consoleServices';
import { Button } from '@mui/material';
import Loader from '../../../Components/Shared/Loader';
import { Tab } from '@mui/material';
import { TabList,TabContext } from '@material-ui/lab';
import InvoiceLines from './InvoiceLines';
import {RegisterPaymentPopup} from '../../../Components/Popup/Popup';


class Invoice extends React.Component {

    state = {
        viewMode:true,
        orderId:null,
        invoiceData:null,
        updatedInvoiceData:{},
        errors:{},
        invoice_form_options:{},
        fetchingData:false,
        updatinginvoiceData:false,
        selectedTab:"Invoice Lines",
        Tabs:['Invoice Lines','Other Info'],
        paymentPopupData:{open:false,title:'Payment Register',data:null}
    }

    componentDidMount(){
        changeAppTitle('Console | Order Invoice');
        let orderId = this.props.match.params.orderId;
        // Ankit Start 24-11-2022 bugid-1697
        // let invoiceId = this.props.match.params.invoiceId;
        let invoiceId = this.props.match.params.pickingId;
        // Ankit End 24-11-2022 bugid-1697
        this.setState({orderId,invoiceId});
        this.fetchOrderInvoiceData(orderId,invoiceId);
    }

    fetchOrderInvoiceData=async(orderId,invoiceId)=>{
        this.setState({fetchingData:true});
        let res  = await fetchOrderInvoiceData(orderId,true,invoiceId);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({invoiceData:res.result.data.invoice});
        }
        this.setState({fetchingData:false});
    }

    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedInvoiceData = this.state.updatedInvoiceData;
        updatedInvoiceData = {...updatedInvoiceData,...{[key]:value}};
        this.setState({updatedInvoiceData});
    }

    handleSubmit=async(e)=>{
        if(this.state.updatedInvoiceData && Object.keys(this.state.updatedInvoiceData).length>0){
            this.setState({fetchingData:true});
            let res = await saveOrderInvoiceData(this.state.invoiceData['id'],this.state.updatedInvoiceData);
            if(res.error || res.result.errorMessage){
                alert(res.error || res.result.errorMessage);
            }
            else{
                if(res.result.data.hasOwnProperty('invoice'))
                    this.setState({invoiceData:res.result.data.invoice});
                if(this.state.updatedInvoiceData.hasOwnProperty('action') && this.state.updatedInvoiceData.action === 'validate'){
                    this.handlePopupClose('close');
                }
                else if(res.result.data.hasOwnProperty('paymentData')){
                    let paymentPopupData = res.result.data.paymentData;
                    paymentPopupData = {...this.state.paymentPopupData,...{data:paymentPopupData},...{open:true}};
                    this.setState({paymentPopupData});
                }
            }
            this.setState({fetchingData:false,updatedInvoiceData:{}});
        }
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    onTabChange=(e,newValue)=>{
        this.setState({selectedTab:newValue});
    }

    getTableData=()=>{
        let data = null;
        let lines = null;
        lines = this.state.invoiceData.invoice_line_ids;
        if(Object.keys(lines).length>0){
            data = [];
            Object.keys(lines).forEach(lineId => {
                let newRow = lines[lineId];
                data = [...data,newRow];
            }); 
        }
        return data;
    }

    changeInvoiceLineData=(e,lineId,type)=>{
        let lines = {};
        let line = {};
        // Ankit Start 30 March 2023
        if (type=='invoice_line_ids' && (parseInt(e.target.max) < parseInt(e.target.value))){
            return
        }
        // Ankit End 30 March 2023
        if(this.state.updatedInvoiceData.hasOwnProperty(type))
            lines = this.state.updatedInvoiceData[type];
        if(e){
            let key = e.target.name;
            let value = e.target.value;
            if(lines.hasOwnProperty(lineId))
                line = lines[lineId];
            line = {...line,...{[key]:parseFloat(value)}};
        }
        else{
            line = null;
        }
        lines = {...lines,...{[lineId]:line}};
        this.handleChange(null,type,lines);
    }

    showBtn=(btnType)=>{
        let show = false;
        let btnStatus = this.state.invoiceData.btnStatus;
        if(btnType === "edit"){
            show = true;
        }
        else if(btnType === 'show_post'){
            show = btnStatus.show_post;
        }
        else if(btnType === 'show_register_payment'){
            show = btnStatus.show_register_payment;
        }
        return show;
    }

    onBtnClick=async(action)=>{
        let updatedInvoiceData = this.state.updatedInvoiceData;
        updatedInvoiceData = {...updatedInvoiceData,...{action}};
        await this.setState({updatedInvoiceData});
        this.handleSubmit();
    }
    
    performAction=async(action)=>{
        let updatedInvoiceData = this.state.updatedInvoiceData;
        updatedInvoiceData = {...updatedInvoiceData,...{action}};
        await this.setState({updatedInvoiceData});
        this.handleSubmit();
    }

    handlePopupClose=async(e)=>{
        let paymentPopupData = {...this.state.paymentPopupData};
        if(e==="validate"){
            await this.handleChange(null,'payment_journal_id',this.state.paymentPopupData.data['journal_id']['id']);
            this.performAction("validate");
        }
        paymentPopupData = {...paymentPopupData,...{open:false,data:null}};
        this.setState({paymentPopupData});
    }

    handlePaymentDataChange=async(key,value)=>{
        let updatedPaymentData = this.state.paymentPopupData;
        let data = updatedPaymentData.data;
        if(key === 'journal_id'){
            value = data['options']['journal_id'].find(i=>parseInt(i['id']) === parseInt(value));
        }
        if(value){
            data = {...data,...{[key]:value}};
            updatedPaymentData = {...updatedPaymentData,...{data},...{open:true}};
            this.setState({paymentPopupData:updatedPaymentData});
        }
    }

    render() { 
        return (
            <div className="invoice-form">
                {this.state.invoiceData &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                <h3 className="invoice-form__heading">{this.state.invoiceData['name']}</h3>
                                {this.showBtn('edit') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                                        {`${this.state.viewMode?"Edit":"Save"}`}
                                    </Button>
                                } 
                                {this.showBtn('show_post') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("post")}>
                                        Post
                                    </Button>
                                }
                                {this.showBtn('show_register_payment') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("register_payment")}>
                                        Register Payment
                                    </Button>
                                }
                            </span>
                            <span className="header__right">
                                <h3>{this.state.invoiceData['state_invoice']}</h3>
                            </span>
                        </header>   
                        <fieldset disabled={true} style={{border:"none"}}>
                            <span>
                                <div className="invoice-form__inputs" >
                                    <label htmlFor="partner_id">Customer</label>
                                    <input
                                        id="partner_id"
                                        name="partner_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.invoiceData['partner_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["partner_id"]}</span>
                                </div>
                                <div className="invoice-form__inputs" >
                                    <label htmlFor="partner_shipping_id">Delivery Address</label>
                                    <input
                                        id="partner_shipping_id"
                                        name="partner_shipping_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.invoiceData['partner_shipping_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["partner_shipping_id"]}</span>
                                </div>
                                <div className="invoice-form__inputs" >
                                    <label htmlFor="invoice_date">Invoice Date</label>
                                    <input
                                        id="invoice_date"
                                        name="invoice_date"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.invoiceData['invoice_date']}
                                    />
                                    <span className="input-error">{this.state.errors["invoice_date"]}</span>
                                </div>
                            </span>
                            <span>
                                <div className="invoice-form__inputs" >
                                    <label htmlFor="ref">Reference</label>
                                    <input
                                        id="ref"
                                        name="ref"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.invoiceData['ref']}
                                    />
                                    <span className="input-error">{this.state.errors["ref"]}</span>
                                </div>
                                <div className="invoice-form__inputs" >
                                    <label htmlFor="invoice_payment_term_id">Payment terms</label>
                                    <input
                                        id="invoice_payment_term_id"
                                        name="invoice_payment_term_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.invoiceData['invoice_payment_term_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["invoice_payment_term_id"]}</span>
                                </div>
                                <div className="invoice-form__inputs" >
                                    <label htmlFor="company_id">Company</label>
                                    <input
                                        id="company_id"
                                        name="company_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.invoiceData['company_id']['name']}
                                    />
                                    <span className="input-error">{this.state.errors["company_id"]}</span>
                                </div>
                            </span>
                        </fieldset>
                        <TabContext value={this.state.selectedTab}>
                            <TabList 
                                onChange={this.onTabChange}                                 
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                {this.state.Tabs.map((tab)=>(
                                    <Tab 
                                        label={tab} 
                                        value={tab} 
                                    />
                                ))}
                            </TabList>
                        </TabContext>
                        {this.state.selectedTab === 'Invoice Lines' &&
                            <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                                <InvoiceLines 
                                    rows = {this.getTableData()}
                                    changeInvoiceLineData = {this.changeInvoiceLineData}
                                    updatedInvoiceData = {this.state.updatedInvoiceData}
                                    viewMode = {this.state.viewMode}
                                    // Ankit Start 27 March 2023
                                    totalAmounts = {{amount_untaxed_signed:this.state.invoiceData.amount_untaxed_signed,
                                        amount_total_signed:this.state.invoiceData.amount_total_signed,
                                        amount_by_group:this.state.invoiceData.amount_by_group}}
                                    invoiceState = {this.state.invoiceData.state}
                                    // Ankit End 27 March 2023
                                />
                            </fieldset>
                        }
                        {this.state.selectedTab !== 'Invoice Lines' &&
                            <>
                                <fieldset disabled={true} style={{border:"none"}}>
                                    <h4>Invoice</h4>
                                    <span>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="invoice_user_id">Salesperson</label>
                                            <input
                                                id="invoice_user_id"
                                                name="invoice_user_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['invoice_user_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["invoice_user_id"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="team_id">Sales team</label>
                                            <input
                                                id="team_id"
                                                name="team_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['team_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["team_id"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="invoice_origin">Source Document</label>
                                            <input
                                                id="invoice_origin"
                                                name="invoice_origin"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['invoice_origin']}
                                            />
                                            <span className="input-error">{this.state.errors["invoice_origin"]}</span>
                                        </div>
                                    </span>
                                </fieldset>
                                <fieldset disabled={true} style={{border:"none"}}>
                                    <h4>Accounting</h4>
                                    <span>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="invoice_incoterm_id">Incoterm</label>
                                            <input
                                                id="invoice_incoterm_id"
                                                name="invoice_incoterm_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['invoice_incoterm_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["invoice_incoterm_id"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="fiscal_position_id">Fiscal position</label>
                                            <input
                                                id="fiscal_position_id"
                                                name="fiscal_position_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['fiscal_position_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["fiscal_position_id"]}</span>
                                        </div>
                                    </span>
                                </fieldset>
                                <fieldset disabled={true} style={{border:"none"}}>
                                    <h4>Payments</h4>
                                    <span>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="invoice_payment_ref">Payment Ref.</label>
                                            <input
                                                id="invoice_payment_ref"
                                                name="invoice_payment_ref"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['invoice_payment_ref']}
                                            />
                                            <span className="input-error">{this.state.errors["invoice_payment_ref"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="invoice_partner_bank_id">Bank Account</label>
                                            <input
                                                id="invoice_partner_bank_id"
                                                name="invoice_partner_bank_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['invoice_partner_bank_id']['display_name']}
                                            />
                                            <span className="input-error">{this.state.errors["invoice_partner_bank_id"]}</span>
                                        </div>
                                    </span>
                                </fieldset>
                                <fieldset disabled={true} style={{border:"none"}}>
                                    <h4>Import/Export</h4>
                                    <span>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="l10n_in_shipping_port_code_id">Shipping port code</label>
                                            <input
                                                id="l10n_in_shipping_port_code_id"
                                                name="l10n_in_shipping_port_code_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['l10n_in_shipping_port_code_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["l10n_in_shipping_port_code_id"]}</span>
                                        </div>
                                    </span>
                                    <span>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="l10n_in_export_type">Export type</label>
                                            <input
                                                id="l10n_in_export_type"
                                                name="l10n_in_export_type"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['l10n_in_export_type']}
                                            />
                                            <span className="input-error">{this.state.errors["l10n_in_export_type"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="l10n_in_shipping_bill_number">Shipping bill number</label>
                                            <input
                                                id="l10n_in_shipping_bill_number"
                                                name="l10n_in_shipping_bill_number"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['l10n_in_shipping_bill_number']}
                                            />
                                            <span className="input-error">{this.state.errors["l10n_in_shipping_bill_number"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="l10n_in_shipping_bill_date">Shipping bill date</label>
                                            <input
                                                id="l10n_in_shipping_bill_date"
                                                name="l10n_in_shipping_bill_date"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['l10n_in_shipping_bill_date']}
                                            />
                                            <span className="input-error">{this.state.errors["l10n_in_shipping_bill_date"]}</span>
                                        </div>
                                    </span>
                                </fieldset>
                                <fieldset disabled={true} style={{border:"none"}}>
                                    <h4>Marketing</h4>
                                    <span>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="campaign_id">Campaign</label>
                                            <input
                                                id="campaign_id"
                                                name="campaign_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['campaign_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["campaign_id"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="medium_id">Medium</label>
                                            <input
                                                id="medium_id"
                                                name="medium_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['medium_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["medium_id"]}</span>
                                        </div>
                                        <div className="invoice-form__inputs" >
                                            <label htmlFor="source_id">Source</label>
                                            <input
                                                id="source_id"
                                                name="source_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.invoiceData['source_id']['name']}
                                            />
                                            <span className="input-error">{this.state.errors["source_id"]}</span>
                                        </div>
                                    </span>
                                </fieldset>
                            </>
                        }
 
                    </form>
                }
                <Loader open={this.state.fetchingData || this.state.updatinginvoiceData} handleClose={()=>{}}/>
                <RegisterPaymentPopup 
                    open={this.state.paymentPopupData['open']}
                    title={this.state.paymentPopupData['title']}
                    data={this.state.paymentPopupData['data']}
                    handlePopupClose={this.handlePopupClose}
                    handlePaymentDataChange = {this.handlePaymentDataChange}
                />
            </div>
        );
    }
}
 
export default Invoice;
