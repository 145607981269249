import React from 'react';
import './InvoiceLines.css';

class InvoiceLines extends React.Component {

    getQuantity=(row,key)=>{
        let qty = 0;
        let lines = null;
        lines = this.props.updatedInvoiceData.invoice_line_ids;
        qty = row[key]; 
        if(lines && lines[row['id']] && lines[row['id']][key])
            qty = lines[row['id']][key];
        return qty;
    }

    showLine=(row)=>{
        let lines = null;
        lines = this.props.updatedInvoiceData.invoice_line_ids;
        if(row === null)
            return false;
        else if(lines && lines.hasOwnProperty(row['id']) && lines[row['id']] === null)
            return false;
        return true;
    }

    render() { 
        return (
            <>
                <table className="invoice-line">
                    <thead>
                        <th>Product</th>
                        <th>Label</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        {/* Ankit Start 25 April 2023 */}
                        <th>Discount Price</th>
                        {/* Ankit End 25 April 2023 */}
                        <th>Taxes</th>
                        <th>Subtotal</th>
                    </thead>
                    {this.props.rows && this.props.rows.length>0 && 
                    <>
                        <tbody>
                            {this.props.rows.map((row)=>{
                                if(!this.showLine(row))
                                    return (<></>);
                                return(
                                    <tr>
                                        <td>{row['product_id']['name']}</td>
                                        <td>{row['name']}</td>
                                        <td>
                                            <input 
                                                name="quantity"
                                                type="number"
                                                min={0}
                                                // Ankit Start 30 March 2023
                                                max={row.max_qty}
                                                // Ankit End 30 March 2023
                                                value={this.getQuantity(row,'quantity')}
                                                onChange={(e)=>this.props.changeInvoiceLineData(e,row['id'],'invoice_line_ids')}
                                                // Ankit Start 28 March 2023
                                                disabled={this.props.invoiceState == 'Draft'?false:true}
                                                // Ankit End 28 March 2023
                                            />
                                        </td>
                                        <td>{row['price_unit']}</td>
                                        {/* Ankit Start 25 April 2023 */}
                                        <td>{row['price_reduce']}</td>
                                        {/* Ankit End 25 April 2023 */}
                                        <td>{row['tax_ids']['name']}</td>
                                        <td>{row['price_subtotal']}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                     {/* // Ankit Start 27 March 2023 */}
                        <tfoot>
                            <tr>
                                <td colSpan={4} style={{backgroundColor:'white', border:'none'}}></td>
                                <td>Untaxed Amount</td>
                                <td>{this.props.totalAmounts.amount_untaxed_signed?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{backgroundColor:'white', border:'none'}}></td>
                                <td>Taxes</td>
                                <td>{this.props.totalAmounts.amount_by_group.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{backgroundColor:'white', border:'none'}}></td>
                                <td>Total</td>
                                <td>{this.props.totalAmounts.amount_total_signed.toFixed(2)}</td>
                            </tr>
                        </tfoot>
                        {/* // Ankit End 27 March 2023 */}
                        </>
                    }
                </table>
            </>
        );
    }
}
 
export default InvoiceLines;
