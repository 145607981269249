import React from 'react';
// import { changeAppTitle } from '../../../Shared/utilities';
import { changeAppTitle } from '../../Shared/utilities';
import { fetchCustomerOrderDetails } from '../../Shared/consoleServices';
import { Button, Typography } from '@mui/material';
import './MyOrdersDetail.css';
import Loader from '../../Components/Shared/Loader';
import urls from '../../urls';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { Grid, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';





class MyOrdersDetails extends React.Component {

    state = {
        viewMode: true,
        orderId: null,
        OrderData: null,
        errors: {},
        form_options: {},
        fetchingData: false,
    }
    steps = {
        'sent': 'Draft',
        'sale': 'Confirmed',
        'dispatch': 'Dispatched',
        'delivered': 'Delivered',
        'done': 'Locked',
    };
    componentDidMount() {

        changeAppTitle('My Order Detail');
        let orderId = this.props.match.params.orderId;
        console.log(orderId)
        this.setState({ orderId });
        this.fetchOrderDetailData(orderId);
    }


    fetchOrderDetailData = async (orderId) => {
        this.setState({ fetchingData: true });
        let res = await fetchCustomerOrderDetails({ orderId });
        if (res.error || res.result.errorMessage) {
            alert(res.error || res.result.errorMessage);
        }
        else {
            console.log(res.result.orderData)
            this.setState({ OrderData: res.result.orderData });
        }
        this.setState({ fetchingData: false });
    }

    reportPage = ()=>{
        console.log('working')
        if(this.state.OrderData['reportUrl']){
            let reportUrl = urls.domain + this.state.OrderData['reportUrl'];
            window.open(reportUrl, '_blank');
        }
    }


    processOrder=async(action,customParams=null)=>{
        try{
            this.setState({fetchingData:true});
            let params = {};
            if(customParams)
                params = {...customParams};
            else
                params = {'partner_id':this.state.OrderData?.partner_id,'order_id':this.state.orderId};
            let url = (action === 'Cancel')?urls.cancelOrder:urls.reOrder; 
            const axios = require("axios");
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            this.setState({fetchingData:false});
            if(!res.result.errorMessage && !res.error){
                if(action==='Cancel')
                    this.fetchOrderDetailData(this.state.orderId);
            }
            else{
                alert(res.result.errorMessage || res.error);
            }
            
        }
        catch(err){
            this.setState({fetchingData:false});
            alert(err.message);
        }
	}

    render() {
        return (


            <Container maxWidth="md" style={{ marginBottom: '2.5%' }}>

                {this.state.OrderData != null &&
                    <>
                        <Typography variant="h5" component="h4" align="center" style={{ padding: '2.5%' }}>
                            Order Details
                        </Typography>



                        <Box sx={{ width: '100%', paddingBottom: '2.5%' }}>
                            {/* <Stepper activeStep={Object.keys(this.steps).indexOf(this.state.OrderData.current_state)} alternativeLabel>
                                {Object.keys(this.steps).map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{this.steps[label]}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper> */}

<Typography variant='body1' sx={{ mb: 1 }} color="text.primary">
                                           Current Status : {this.state.OrderData?.current_state}
                                        </Typography>

                        </Box>
                        {/* 
                <Stack direction="row" spacing={2} style={{ justifyContent: "center", display: "flex" }}>
                    <Avatar
                        alt="Remy Sharp"
                        src="http://be.localhost/web/image/product.template/489753/reference_image/Neuroway 1500 Injection"
                        sx={{ width: 80, height: 76 }}
                        
                    />
                   
                </Stack>
                <Typography variant="h6" component="h4" align="center" style={{ padding: '2.5%' }}>
                        Company Name
                    </Typography> */}
                        <Stack direction="row" spacing={2} style={{ justifyContent: "space-between", display: "flex" }}>
                            <Typography variant="body2" color="text.secondary">
                                OrderId#  {this.state.OrderData.name} | Ordered On {this.state.OrderData.date}
                            </Typography>

                        <Grid>
                            <Button variant="contained" onClick={() => this.reportPage()} style={{ paddingBottom: '0.3%', paddingTop: '0.3%', marginBottom: '0.5%', marginRight:'10px' }}>
                                Invoice
                            </Button>

                    {this.state.OrderData?.current_state == 'Draft' &&
                            <Button variant="contained" onClick={()=>this.processOrder('Cancel')} style={{ paddingBottom: '0.3%', paddingTop: '0.3%', marginBottom: '0.5%' }}>
                                Cancel
                            </Button>
                    }
                    </Grid>
                            
                        </Stack>

                        <Card variant="outlined" style={{ marginTop: '0.5%', marginBottom: '1%' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                <Grid item xs={6}>
                                    {/* <Card > */}
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Seller
                                        </Typography>

                                        <Stack direction="row" spacing={2} style={{ justifyContent: "center", display: "flex" }}>
                                            <Avatar
                                                alt="An"
                                                src={`${urls.domain}${this.state.OrderData.logo}`}
                                                sx={{ width: 80, height: 76 }}

                                            />
                                        </Stack>
                                        <Typography variant="body1" component="div">
                                            {this.state.OrderData['seller']?.name}
                                        </Typography>
                                        <Typography variant='body2' sx={{ mb: 1.5 }} color="text.secondary">
                                            {this.state.OrderData['seller']?.['address']}
                                        </Typography>
                                    </CardContent>
                                    {/* </Card> */}
                                    {/* Seller :
                        Ankit Med 1
                        D-2 galli no 26 Molad Band Badarpur Border Mumbai,
                        Maharashtra 110044, India 27AAAAP0267H2ZN */}
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Card > */}
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Customer
                                        </Typography>
                                        <Typography variant="body1" component="div">
                                            {this.state.OrderData['billing']?.name}
                                        </Typography>
                                        <Typography variant='body2' sx={{ mb: 1.5 }} color="text.secondary">
                                            {this.state.OrderData['billing']?.address}
                                        </Typography>
                                    </CardContent>
                                    {/* </Card>
                        <Card variant="outlined"> */}
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            Deliver To
                                        </Typography>
                                        <Typography variant="body1" component="div">
                                            {this.state.OrderData['deliver']?.name}
                                        </Typography>
                                        <Typography variant='body2' sx={{ mb: 1 }} color="text.secondary">
                                            {this.state.OrderData['deliver']?.address}
                                        </Typography>
                                    </CardContent>
                                    {/* </Card> */}
                                </Grid>

                            </Grid>
                        </Card>

                        <table className="order-table rounded-corner" style={{ marginTop: '2%', marginBottom: '2%', borderRadius: '10px' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '6px' }}>Product</th>
                                    <th style={{ padding: '6px' }}>Quantity</th>
                                    <th style={{ padding: '6px' }}>Discount</th>
                                    <th style={{ padding: '6px' }}>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                            {/* Piyush Kumar 15/06/2023 written code as datastructure changed  */}
                                {/* {this.state.OrderData.orderline.map(line => {
                                    return (
                                        <tr>
                                            <td>{line.product_name}</td>
                                            <td>{line.Quantity}</td>
                                            <td>{line.discount_price}</td>
                                            <td>{line.price_unit}</td>
                                        </tr>
                                    )
                                })} */}

                                {Object.keys(this.state.OrderData.orderline).map(lineId => {
                                    return (
                                        <tr>
                                            <td>{this.state.OrderData.orderline[lineId][0].product_name}</td>
                                            <td>{this.state.OrderData.orderline[lineId][0].Quantity}</td>
                                            <td>{this.state.OrderData.orderline[lineId][0].discount_price}</td>
                                            <td>{this.state.OrderData.orderline[lineId][0].price_unit}</td>
                                        </tr>
                                    )
                                })}
                        {/* Piyush Kumar End */}
                                {/* <tr>
                                    <td>Name1</td>
                                    <td>Name1</td>
                                    <td>Name1</td>

                                </tr> */}
                            </tbody>
                            <tfoot className='table-footer'>
                                <tr>
                                    <td colSpan={2}>Sub Total</td>
                                    <td colSpan={2}>{this.state.OrderData.amount_untaxed}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Taxes</td>
                                    <td colSpan={2}>{this.state.OrderData.amount_tax}</td>
                                </tr>
                                {/* Piyush Kumar 26/6/2023 added discount */}
                                <tr>
                                    <td colSpan={2}>Discount</td>
                                    <td colSpan={2}>{this.state.OrderData.discount}</td>
                                </tr>
                                {/* Piyush Kumar End */}
                                {/* Piyush Kumar 15/6/2023 added delivery */}
                                 <tr>
                                    <td colSpan={2}>Delivery</td>
                                    <td colSpan={2}>{this.state.OrderData.delivery}</td>
                                </tr>
                                {/* Piyush kumar End */}
                                <tr>
                                    <td colSpan={2}>Total</td>
                                    <td colSpan={2}>{this.state.OrderData.amount_total}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
                }

                <Loader open={this.state.fetchingData} handleClose={() => { }} />

            </Container>
        );
    }
}

export default MyOrdersDetails;
