import React, { Component } from 'react';
import './ProductsPage.css';
import urls from '../../urls';
import Pagination from '../../Components/Pagination/Pagination';
import { withRouter } from "react-router";
import { changeAppTitle } from '../../Shared/utilities';
import ProductCard from '../../Components/Shared/ProductCard';
import SearchBar from '../../Components/SearchBar/SearchBar';
import ProductCompare from '../ProductCompare/ProductCompare';
import Loader from '../../Components/Shared/Loader';
import { Select,InputLabel,MenuItem } from '@material-ui/core';
import { ResetCartPopup } from '../../Components/Popup/Popup';
import {resetCart} from '../../Shared/services';
import { AuthContext } from '../../Shared/context';
import { Star,ShoppingCart } from '@material-ui/icons';
import {CompanySliders} from '../../Components/Sliders/Sliders';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Grid } from '@mui/material';
const CompanyInfo=(props)=>{

    return(
        <>  
            {props.companyInfo && !props.companyInfo['sliders'] &&
                <div className="company-info">
                    <img className = "companyImg" src={urls.domain + props.companyInfo.logo} loading="lazy" alt={props.companyInfo.name}/>
                    <div>
                        <span className="companyName">{props.companyInfo.name}</span>
                        {props.companyInfo.tagline &&
                            <span style={{color:"red"}}>{props.companyInfo.tagline}</span>
                        }
                        <span>{props.companyInfo.address}</span>
                        <span className="deliveryOptions">
                            <ShoppingCart />
                            {props.companyInfo.deliveryOptions.map((option)=>(
                                <span>{option},</span>
                            ))}
                        </span>
                        <span className="rating">
                                <Star style={{color:"beige",width:"15px",height:"15px"}}/> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span>{props.companyInfo.rating}</span>
                            </span>
                            <span>{props.companyInfo.distance}</span>
                    </div>
                    {Object.keys(props.companyInfo.coupons).length>0 &&
                        <ul className="company-coupons">
                            {Object.keys(props.companyInfo.coupons).map((couponId)=>(
                                <li>
                                    {`${props.companyInfo.coupons[couponId]['name']} | Use Code: ${props.companyInfo.coupons[couponId]['promo_code']}`}
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            }
            {props.companyInfo && props.companyInfo['sliders'] &&
                <CompanySliders 
                    items={props.companyInfo['sliders']['sliders']} 
                    isSingle = {props.companyInfo['sliders']['sliders']['isSingle']}
                    autoPlay={false} 
                    className="top"
                />
            }
        </>
    );
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }

class ProductsPage extends Component {

    static contextType = AuthContext;

    state = {
            categories:{},
            selectedCategory:false,
            // # mohit  25-07-2022 start bug-ID -1006 added first_time_page
            first_time_page:{'name':'Offer'},
            // # mohit  25-07-2022 end bug-ID -1006 added first_time_page
            products:[],
            totalPages:0,
            fetchingData:false,
            currentPage:1,
            companyId:null,
            showCompareIcon:true,
            sortBy:null,
            sortByOptions:{1:{name:'Catalog Price: High to Low',value:'list_price desc'},
                            2:{name:'Catalog Price: Low to High',value:'list_price asc'},
                            3:{name:'Name: A to Z',value:'name asc'},4:{name:'Name: Z to A',value:'name desc'}},
            resetCartPopupData:{
                open:false,
                title:'',
                contentText:''
            },
            companyInfo:null,
            category_view:'vertical',
            mounted:false
    }

    componentDidMount(){
        this.setState({mounted:true});
        changeAppTitle('Shopaas | Products');
        const companyType = this.props.match.params.companyType;
        const company = this.props.match.params.company;
        let tempVar = companyType.split('-');
        let companyTypeId = null;
        let companyId = null;
        if(tempVar.length > 1)
            companyTypeId = parseInt(tempVar.slice(-1));
        if(!companyTypeId)
            this.props.history.push(urls.extension + '/home');
        else{
            tempVar = company.split('-');
            if(tempVar.length > 1)
                companyId = parseInt(tempVar.slice(-1));
            if(!companyId)
                this.props.history.push(urls.extension + '/home');
            else{
                let searchParams = this.props.location.search;
                searchParams = new URLSearchParams(searchParams);
                let sortBy = searchParams.get('sort_by') || '';
                this.setState({sortBy});
                this.getProducts(companyId,companyTypeId);
                
            }
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.search !== this.props.location.search){
            this.getProducts(this.state.companyId,this.state.companyTypeId);
        }
    }

    getCompanyInfo = async(companyId,companyTypeId)=>{
        try {
            const axios = require("axios");
            this.setState({fetchingData:true});
            const response = await axios(urls.getCompanyInfo, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            company_type_id:companyTypeId,
                            company_id:companyId
                        }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(res.result.statusCode===201){
                    this.setState({mounted:false});
                    alert(res.result.message);
                    this.props.history.push(urls.extension + '/home');
                }
                else
                    this.setState({companyInfo:res.result.data});
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            this.setState({fetchingData:false});
            alert(err.message);
        }
    }

    getProducts = async(companyId,companyTypeId) =>{
        if(!this.state.companyInfo)
            await this.getCompanyInfo(companyId,companyTypeId);
        if(this.state.mounted){
            let searchParams = this.props.location.search;
            searchParams = new URLSearchParams(searchParams);

            let selectedCategory = searchParams.get('category') || false;
            let currentPage = parseInt(searchParams.get('page') || 1);
            let sortBy = searchParams.get('sort_by') || null;
            if(selectedCategory){
                let tempVar = selectedCategory.split('-');
                selectedCategory = {'id':parseInt(tempVar.slice(-1)),'name':selectedCategory}
            }

    

            await this.setState({selectedCategory,currentPage,sortBy});
            await this.setState({companyId,companyTypeId});
            this.fetchProducts(currentPage);
        }
    }

    fetchProducts = async (currentPage=1) => {
        try {
            this.setState({fetchingData:true});
            const axios = require("axios");
            const response = await axios(urls.getProducts, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            company_type_id:this.state.companyTypeId,
                            company_id:this.state.companyId,
                            prod_categ:this.state.selectedCategory?this.state.selectedCategory['id']:false,
                            first_time_page:this.state.first_time_page,
                            page:currentPage,
                            loadingFirstTime: this.state.products.length===0 || currentPage === 1,
                            sortBy:this.state.sortBy?this.state.sortByOptions[this.state.sortBy]['value']:false
                        }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                let categories = this.state.categories;
                let category_view = this.state.category_view;
                if(res.result.categories)
                    categories = res.result.categories;
                if(res.result.category_view)
                    category_view = res.result.category_view;
                    // # mohit  25-07-2022 start bug-ID -1006 added first_time_page and selectedCategory to setstate
                this.setState({products:res.result.products,categories,category_view,currentPage:currentPage,
                    selectedCategory:(this.state.first_time_page)?res.result.selectedCategory:this.state.selectedCategory,
                    first_time_page:false,
                });
                // # mohit  25-07-2022 end bug-ID -1006
                
                if(parseInt(this.state.totalPages)!==parseInt(res.result.totalPages))
                    this.setState({totalPages:parseInt(res.result.totalPages)});
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            alert(err.message);
            this.setState({fetchingData:false});
        }
    }

    changePage=(page)=>{
        this.setState({currentPage:page});
        this.changeUrlParams("page",page);
    }

    changeProdCategory=async(category)=>{
        
        if(category.id !== this.state.selectedCategory.id){
            let selectedCategory = false;
            if(category){
                await this.changeUrlParams('category',category['name']+'-'+category['id']);
                selectedCategory = {'id':category['id'],'name':category['name'],'image':category['image']};
            }
            else
                this.changeUrlParams('category',null);
            await this.setState({selectedCategory});
            // # mohit  25-07-2022 bug-ID -1006 this is making double show of
            // product api in network only when  for all products clickeed 
            if (!selectedCategory){
            this.fetchProducts();    
            }
            // this.fetchProducts();
            // # mohit  25-07-2022 end bug-ID -1006
        }
    }

    changeUrlParams=(key,value)=>{
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        searchParams.delete(key)
        if(key && value)
            searchParams.append(key,value);
        searchParams = searchParams.toString();
        let newPageRoute = `?${searchParams}`;
        this.props.history.push(this.props.history.location.pathname +newPageRoute);
    }

    applySorting=(e)=>{
        let sortBy = e.target.value;
        this.setState({sortBy});
        this.changeUrlParams('sort_by',sortBy);
    }

    orderBySnapshot=async()=>{
        try {
            this.setState({fetchingData:true});
            const axios = require("axios");
            const response = await axios(urls.checkCart, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            if(res.result.statusCode === 200){
                this.props.history.push(urls.extension + '/shop/cart');
            }
            else if(res.result.statusCode === 201)
                this.openResetPopup(res.result,null,"cartpage");
            this.setState({fetchingData:false});
        } 
        catch (err) {
            alert(err.message);
            this.setState({fetchingData:false});
        }
    }

    openResetPopup=(data,productData=null,redirection=null)=>{
        let title = data.header;
        let contentText = data.message;
        let resetCartPopupData = {...this.state.resetCartPopupData,...{open:true,title,contentText,productData,redirection}};
        this.setState({resetCartPopupData});
    }

    handleResetPopupClose=async(e,reset=false)=>{
        let redirection = this.state.resetCartPopupData.redirection;
        let resetCartPopupData = {...this.state.resetCartPopupData,...{open:false,title:'',contentText:'',[redirection]:null}};
        let productData = resetCartPopupData.productData;
        this.setState({resetCartPopupData});
        if(reset === true)
            await this.resetCart(productData,redirection);
    }

    resetCart=async(productData,redirection=null)=>{
        this.setState({fetchingData:true});
        let res = await resetCart(productData);
        if(res && res.result){
            let custom_session_info = this.context.custom_session_info;
            custom_session_info = {...custom_session_info,...{cart_quantity:res.result.cart_quantity}};
            this.context.set_custom_session_info(custom_session_info);
            if(redirection === 'cartpage')
                this.props.history.push(urls.extension + '/shop/cart');
        }
        this.setState({fetchingData:false});
    }

    render() {
        var settings = {
            dots: false,
            infinite: false,
            arrows:true,
            accessibility:true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 4,
            initialSlide: 0,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  arrows:true,
                  slidesToScroll: 3,
                  infinite: true,
                  accessibility:true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (
            <div>
                <Grid container >
                <Grid item xs={12}>
                    <CompanyInfo 
                        companyInfo = {this.state.companyInfo}
                    />
                    </Grid>
                </Grid>
                <Grid container xs={11} justifyContent="center" alignItems="center" mb={2} >
                                   
                {/* <div className="product-navigation"> */}
                <Grid item xs={11} md={2}>
                    {/* <div> */}
                        <SearchBar 
                            type="product-search"
                            companyId={this.state.companyId}
                            companyTypeId={this.state.companyTypeId}    
                        />
                    {/* </div> */}
                    </Grid>
                    {/* <div> */}
                    <Grid item xs={11} md={2}>
                        {parseInt(this.state.totalPages) > 0 && 
                            <Pagination 
                                totalPages = {this.state.totalPages}
                                changePage = {this.changePage}
                                currentPage = {this.state.currentPage}
                                fetchingData = {this.state.fetchingData}
                            />
                        }
                    {/* </div> */}
                    </Grid>
                    <Grid item xs={10} md={2}>
                    {/* <div className="sort-by"> */}
                        <InputLabel id="demo-simple-select-outlined-label">Sort By</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.sortBy}
                            onChange={this.applySorting}
                        >
                            <MenuItem value={null}>None</MenuItem>
                            {Object.keys(this.state.sortByOptions).map((option)=>(
                                <MenuItem value={option}>{this.state.sortByOptions[option]['name']}</MenuItem>
                            ))
                            }
                        </Select>
                    {/* </div> */}
                    </Grid>
                {/* </div> */}
                </Grid>
                <div className="order-by-snapshot">
                    <button onClick={()=>this.orderBySnapshot()}>Order By Snapshot</button>
                </div>
                <div className={`main-content ${this.state.category_view === 'vertical'?'':'column'}`}>
                    {this.state.category_view === 'vertical'?
                        <div className='product-categories'>
                            <div className="content">
                                <span 
                                    className={`${this.state.selectedCategory === false?'selected':''}`} 
                                    onClick={()=>this.changeProdCategory(false)}
                                >
                                    All Products
                                </span>
                                {Object.keys(this.state.categories).map((categ)=>(
                                    // # mohit  25-07-2022 start bug-ID -1006 change in string selectedCategory.id
                                    <span 
                                        className={`${(this.state.selectedCategory.id) === (this.state.categories[categ].id)?'selected':''}`} 
                                        onClick={()=>this.changeProdCategory(this.state.categories[categ])}
                                    >
                                        
                                        {this.state.categories[categ]['name']}
                                    </span>
                                    // # mohit  25-07-2022 end bug-ID -1006
                                ))
                                }
                            </div>
                        </div>
                        :
                        // <div className='company-types' style={{maxWidth:"98vw"}}>
                        //     <Carousel
                        //         interval={1000}
                        //         autoPlay={false}
                        //         showThumbs={false}
                        //         showStatus={false}
                        //         centerMode={true}
                        //         centerSlidePercentage={10}
                        //         stopOnHover={true}
                        //         showArrows={true}
                        //         showIndicators={false}
                        //     >
                        //         <div className="company-type" onClick={()=>this.changeProdCategory(false)}>
                        //             <span 
                        //                 className={`${this.state.selectedCategory === false?'selected':''}`} 
                        //                 onClick={()=>this.changeProdCategory(false)}
                        //             >
                        //                 All Products
                        //             </span>
                        //         </div>
                        //         {Object.keys(this.state.categories).map((categ)=>(
                        //             <div className="company-type" onClick={()=>this.changeProdCategory(this.state.categories[categ])}>
                        //                 <img 
                        //                     src={urls.domain + this.state.categories[categ]['image']} 
                        //                     alt={this.state.categories[categ]['image']}
                        //                 />
                        //                 <span 
                        //                     className={`${this.state.selectedCategory === categ?'selected':''}`} 
                        //                 >
                        //                     {this.state.categories[categ]['name']}
                        //                 </span>
                        //             </div>
                        //         ))}
                        //     </Carousel>


                        //     {/* <div className="company-type" onClick={()=>this.changeProdCategory(false)}>
                        //         <span 
                        //             className={`${this.state.selectedCategory === false?'selected':''}`} 
                        //             onClick={()=>this.changeProdCategory(false)}
                        //         >
                        //             All Products
                        //         </span>
                        //     </div>
                        //     {Object.keys(this.state.categories).map((categ)=>(
                        //         <div className="company-type" onClick={()=>this.changeProdCategory(this.state.categories[categ])}>
                        //             <img 
                        //                 src={urls.domain + this.state.categories[categ]['image']} 
                        //                 alt={this.state.categories[categ]['image']}
                        //             />
                        //             <span 
                        //                 className={`${this.state.selectedCategory === categ?'selected':''}`} 
                        //             >
                        //                 {this.state.categories[categ]['name']}
                        //             </span>
                        //         </div>
                        //     ))} */}
                        
                        // </div>
                        
                        <div className='addBackgroundColor'>
                        {/* pankaj slider */}
                        <h5 style={{textAlign: "center"}}> All Product </h5>
                        <Slider {...settings}>
                        
                       {Object.keys(this.state.categories).map((categ)=>(
                                    <div className="company-type addBorderColor"  onClick={()=>this.changeProdCategory(this.state.categories[categ])}>
                                        <div > 
                                        <img className='center'
                                            src={urls.domain + this.state.categories[categ]['image']} 
                                            alt={this.state.categories[categ]['image']}
                                        />
                                        </div>
                                        <span 
                                            className={`${this.state.selectedCategory === categ?'selected':''}`} 
                                        >
                                            {this.state.categories[categ]['name']}
                                        </span>
                                    </div>
                                ))}
                        </Slider>
                        {/* pankaj silder */}
                         </div> 
                        
                    }
                    <div className={`products ${this.state.category_view === 'vertical'?'':"fullwidth"}`}>
                        {
                            this.state.products.map((prod,idx)=>(
                                <ProductCard 
                                    prod={prod}
                                    history={this.props.history}
                                    showCompareIcon={this.state.showCompareIcon}
                                />
                            )
                            )
                        }
                        <ProductCompare />
                    </div>
                </div>
                <ResetCartPopup 
                    open={this.state.resetCartPopupData['open']}
                    title={this.state.resetCartPopupData['title']}
                    contentText={this.state.resetCartPopupData['contentText']}
                    handlePopupClose={this.handleResetPopupClose}
                />
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        )
    }

}

export default withRouter(ProductsPage);
