import React from 'react';
import './Addressess.css';
import urls from '../../urls';
import { Check, Edit, Add } from '@material-ui/icons';
import { AuthContext } from '../../Shared/context';
import Loader from '../../Components/Shared/Loader';

class Addressess extends React.Component {

    static contextType = AuthContext;

    state = {
        addressData: { partners: null, billing_address: null, selected_shipping_address: null }
    }

    componentDidMount() {
        this.fetchAddressess();
    }

    fetchAddressess = async () => {
        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.getAddressess, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                let addressData = this.state.addressData;
                addressData = { ...addressData, ...res.result.data }
                this.setState({ addressData });
                if (this.props.setAddressess)
                    this.props.setAddressess(addressData);
            }
            else
                alert(res.result.errorMessage || res.error);
        }
        catch (err) {
            alert(err.message);
            this.setState({ fetchingData: false });
        }
    }

    selectAddress = async (addressId) => {
        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.selectShippingAddress, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        selected_shipping_address: addressId
                    }
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                let addressData = this.state.addressData;
                addressData = { ...addressData, ...{ selected_shipping_address: addressId } };
                this.setState({ addressData });
                if (this.props.setAddressess)
                    this.props.setAddressess(addressData);
            }
            else
                alert(res.result.errorMessage || res.error);
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
        }
    }

    addressBox = (address, highlightAddressId, showFooterBtn = false) => {
        return (
            <div className={`address-box ${highlightAddressId === address['id'] ? "highlight" : ""}`}>
                <div style={{ padding: "5px 10px" }}>
                    {address['name'] && <span>{address['name']} <br /></span>}
                    {address['street'] && <span>{address['street']} <br /></span>}
                    {address['street2'] && <span>{address['street2']} <br /></span>}
                    {`${address['city']['name']}`} &nbsp;&nbsp;
                    {`${address['zip']}`} <br />
                    {`${address['state']['name']}`} <br />
                    {`${address['country']['name']}`}
                </div>
                {highlightAddressId === address['id'] &&
                    // Ankit Start 13 Jan 2023 (Removing Edit Option From React)
                    <>
                        {address['is_editable'] &&
                            <Edit className="edit-address" onClick={() => this.props.addOrEditAddress(address)} />
                        }
                    </>
                    // Ankit End 13 Jan 2023 
                }
                {showFooterBtn &&
                    <div className="address-box__footer">
                        {highlightAddressId === address['id'] ?
                            <button className="selected"><Check /> Ship to this address</button>
                            :
                            <button onClick={() => this.selectAddress(address['id'])}>Select this address</button>
                        }
                    </div>
                }
            </div>
        );
    }

    render() {
        return (
            <div className="addressess">
                {this.state.addressData['partners'] && Object.keys(this.state.addressData['partners']).length > 0 &&
                    <div className="billing-address">
                        <h3 className="billing-address__heading">Billing Address</h3>
                        <div className="address-boxes">
                            {this.addressBox(
                                this.state.addressData['partners'][this.state.addressData['billing_address']],
                                this.state.addressData['billing_address']
                            )
                            }
                        </div>
                    </div>
                }
                <div className="shipping-address">
                    <h3 className="shipping-address__heading">Shipping Address</h3>
                    <button className="add-address" onClick={() => this.props.addOrEditAddress()}>
                        <Add style={{ width: "20px", height: "20px" }} /> Add an address
                    </button>
                    {this.state.addressData['partners'] && Object.keys(this.state.addressData['partners']).length > 0 &&
                        <div className="address-boxes">
                            {Object.keys(this.state.addressData['partners']).map((partner_id, idx) => {
                                return (
                                    this.addressBox(
                                        this.state.addressData['partners'][partner_id],
                                        this.state.addressData['selected_shipping_address'],
                                        true
                                    )
                                )
                            })}
                        </div>
                    }
                </div>
                <Loader open={this.state.fetchingData} handleClose={() => { }} />
            </div>
        );
    }
}

export default Addressess;
