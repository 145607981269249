import React from 'react';
import './CompanyRegistration.css';
import { AuthContext } from '../../Shared/context';
import { Select,MenuItem } from '@material-ui/core';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import urls from '../../urls';
import { changeAppTitle,formValidation } from '../../Shared/utilities';
import { Button } from '@mui/material';

class CompanyRegistration extends React.Component {

    static contextType = AuthContext;

    state = {
        companyRegistered:false,
        postRegistrationMessage:'Company Registered',
        companyData:{
            id:null,
            name:"",
            street:"",
            zip:null,
            city:{id:null,name:""},
            state:{id:null,name:""},
            country:{id:null,name:""},
            phone_code:'91',
            email:"",
            mobile:"",
            businessName:'',
            company_type:null,
            comment:''
        },
        geoData:{
            country:{},
            state:{},
            city:{}
        },
        loadingData:true,
        errors:{}
    }

    componentDidMount(){
        changeAppTitle('Shopaas | Company Register');
        this.setState({loadingData:false});
        this.fetchGeoData();
    }

    fetchGeoData=async(country_id=null,state_id=null)=>{
        try {
            const axios = require("axios");
            const response = await axios(urls.getGeoData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        country_id,
                        state_id
                    }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                let geoData = {...this.state.geoData,...res.result.data};
                await this.setState({geoData});
                if(res.result.data.default){
                    this.handleChange(null,'country',res.result.data.default);
                }
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }

    handleValidation=async(key=null)=>{
        let errors = formValidation(this.state.errors,this.state.companyData,key);        
        this.setState({errors});
        return(errors);
    }

    handleChange=async(e=null,x=null,val=null)=>{
        let companyData = this.state.companyData;
        let key = x;
        let value = val;
        if(!val){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let fetchGeoData = false;
        if(['country','state','city'].includes(key)){
            value = this.state.geoData[key][value];
            fetchGeoData = true;
            if(key==='country')
                companyData = {...companyData,...{'state':{},'city':{},'phone_code':value['phone_code']}};
            else if(key==='state')
                companyData = {...companyData,...{'city':{}}};
        }
        companyData = {...companyData,...{[key]:value}};
        await this.setState({companyData});
        this.handleValidation(key);
        if(fetchGeoData){
            this.fetchGeoData(this.state.companyData.country['id'],this.state.companyData.state['id']);
        }
    }

    handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            let errors = await this.handleValidation();
            if(Object.keys(errors).length>0){
                alert('Please fill all fields properly')
                return;
            }
            const axios = require("axios");
            const response = await axios(urls.registerCompany, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        ...this.state.companyData
                    }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                this.setState({companyRegistered:true,postRegistrationMessage:res.result.message});
            }
            else
                alert(res.result.errorMessage || res.error);
            return;
        } 
        catch (err) {
            alert(err.message);
            return;
        }
    }

    render() { 
        return (
            <>
                <div className="company-registration">
                    {!this.state.companyRegistered &&
                        <form onSubmit={this.handleSubmit}>
                            <h3 className="company-registration__heading">Register your company</h3>
                            <span>
                                <div className="company-registration__inputs" >
                                    <label htmlFor="name">Your Name</label>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.companyData['name']}
                                    />
                                    <span className="input-error">{this.state.errors["name"]}</span>
                                </div>
                                <div className="company-registration__inputs" >
                                    <label htmlFor="businessName">Company / Business Name</label>
                                    <input
                                        id="businessName"
                                        name="businessName"
                                        type="tel"
                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        onChange={this.handleChange}
                                        value={this.state.companyData['businessName']}
                                    />
                                    <span className="input-error">{this.state.errors["businessName"]}</span>
                                </div>
                            </span>
                            <span>
                                <div className="company-registration__inputs" >
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={this.handleChange}
                                        value={this.state.companyData['email']}
                                    />
                                    <span className="input-error">{this.state.errors["email"]}</span>
                                </div>
                                <div className="company-registration__inputs" >
                                    <label htmlFor="mobile">Mobile</label>
                                    <span style={{display:"flex",gap:"5px"}}>
                                        <span className="prefix">{'+'+this.state.companyData['phone_code']}</span>
                                        <input
                                            id="mobile"
                                            name="mobile"
                                            type="tel"
                                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                            onChange={this.handleChange}
                                            value={this.state.companyData['mobile']}
                                        />
                                    </span>
                                    <span className="input-error">{this.state.errors["mobile"]}</span>
                                </div>
                            </span>
                            <span>
                                <div className="company-registration__inputs">
                                    <label htmlFor="country">Company / Business Type</label>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper-label"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.companyData['company_type']}
                                        name='company_type'
                                    >
                                        {(this.context.companyTypes || []).map((comp_type)=>(
                                            <MenuItem value={comp_type['id']}>
                                                {comp_type['name']}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <span className="input-error">{this.state.errors["company_type"]}</span>
                                </div>
                            </span>
                            <span>
                                <div className="company-registration__inputs" >
                                    <label htmlFor="street">Address</label>
                                    <input
                                        id="street"
                                        name="street"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.companyData['street']}
                                    />
                                    <span className="input-error">{this.state.errors["street"]}</span>
                                </div>
                                
                            </span>
                            <span>
                                {!this.state.loadingData &&
                                    <div className="company-registration__inputs" style={{width:'fit-content'}}>
                                        <label htmlFor="country">Country</label>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper-label"
                                            onChange={this.handleChange}
                                            value={this.state.companyData['country']['id']}
                                            style={{width:'250px'}}
                                            name='country'
                                        >
                                            {Object.keys(this.state.geoData.country).map((country_id,idx1)=>(
                                                <MenuItem value={country_id}>
                                                    {this.state.geoData.country[country_id]['name']}
                                                </MenuItem>
                                            ))
                                            }
                                        </Select>
                                        <span className="input-error">{this.state.errors["country"]}</span>
                                    </div>
                                }
                                {this.state.companyData.country['id'] && this.state.companyData.country['id'] === this.state.geoData.country_id &&
                                    <div className="company-registration__inputs" style={{width:'fit-content'}}>
                                        <label htmlFor="email">State/Province</label>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper-label"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.companyData['state']['id']}
                                            style={{width:'250px'}}
                                            name='state'
                                        >
                                            {Object.keys(this.state.geoData.state).map((state_id,idx1)=>(
                                                <MenuItem value={state_id}>
                                                    {this.state.geoData.state[state_id]['name']}
                                                </MenuItem>
                                            ))
                                            }
                                        </Select>
                                        <span className="input-error">{this.state.errors["state"]}</span>
                                    </div>
                                }
                                {this.state.companyData.state['id'] && this.state.companyData.state['id'] === this.state.geoData.state_id &&
                                    <div className="company-registration__inputs"  style={{width:'fit-content'}}>
                                        <label htmlFor="city">City</label>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper-label"
                                            onChange={this.handleChange}
                                            style={{width:'250px'}}
                                            defaultValue={this.state.companyData['city']['id']}
                                            name='city'
                                        >
                                            {Object.keys(this.state.geoData.city).map((city_id,idx1)=>(
                                                <MenuItem value={city_id}>
                                                    {this.state.geoData.city[city_id]['name']}
                                                </MenuItem>
                                            ))
                                            }
                                        </Select>
                                        <span className="input-error">{this.state.errors["city"]}</span>
                                    </div>
                                }
                                {/* pankaj call change zip code div location before it was with address div   */}
                                <div className="company-registration__inputs">
                                    <label htmlFor="zip">Zip Code</label>
                                    <input
                                        id="zip"
                                        name="zip"
                                        type="number"
                                        onChange={this.handleChange}
                                        value={this.state.companyData['zip']}
                                    />
                                    <span className="input-error">{this.state.errors["zip"]}</span>
                                </div>
                            </span>
                            <div className="company-registration__inputs">
                                <label htmlFor="comment">Comment</label>
                                <textarea 
                                    type="text"
                                    id="comment"
                                    name="comment"
                                    maxLength="300"
                                    value={this.state.companyData['comment']}
                                    onChange={this.handleChange}
                                    style={{width:"50%",maxWidth:'50%',height:'70px'}}
                                />
                            </div>
                        </form>
                    }
                    {this.state.companyRegistered &&
                        <div className="company-registerd">
                            <div>{this.state.postRegistrationMessage}</div>
                            <Button variant="primary" style={{background:"#875A7B",color:"beige"}} 
                                onClick={()=>this.props.history.goBack()}
                            >
                                <ArrowBack />
                                Back
                            </Button>
                        </div>
                    }
                </div>
                {!this.state.companyRegistered &&
                    <div className="navigation-btns" style={{}}>
                        <button className="btn__back" 
                            onClick={()=>this.props.history.goBack()}
                        >
                            <ArrowBack />  Back
                        </button>
                        <button className="btn__next" 
                            onClick={(e)=>this.handleSubmit(e)}
                        >
                            Register  <ArrowForward />
                        </button>
                    </div>
                }
            </>
        );
    }
}
 
export default CompanyRegistration;
