import React from 'react'
import { withRouter } from "react-router";
import './ProductDetails.css';
import urls from '../../urls';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {  ShoppingCart } from '@material-ui/icons';
import blank from '../../images/blank.png';
import {ResetCartPopup} from '../../Components/Popup/Popup';
import { changeAppTitle } from '../../Shared/utilities';
import ProductCard from '../../Components/Shared/ProductCard';
import { addProductToCart,resetCart } from '../../Shared/services';
import {ProductSliders} from '../../Components/Sliders/Sliders';
import { AuthContext } from '../../Shared/context';
import Loader from '../../Components/Shared/Loader';
import axios from "axios";

let source = axios.CancelToken.source();


const ProductPrice=(props)=>{

    return(
        <div className="product-price">
            <p className={props.product['discountedPrice']?"strike-through":''}>
                &#8377; {props.product['originalPrice']}
            </p>
            {props.product['discountedPrice'] &&
                <p>&#8377; {props.product['discountedPrice']}</p>
            }
        </div>
    )
}

class ProductDetails extends React.Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
      }


    state = {
        productDetails: {}, 
        selectedProduct:null,
        company:null,
        product:null,
        companyTypeId:null,
        companyId:null,
        productTmplId:null,
        fetchingData:false,
        quantity:1,
        resetCartPopupData:{
            open:false,
            title:'',
            contentText:''
        },
        recentlyViewedProducts:[],
        alternativeProducts:[]
    }

    onPageLoad(){
        const company = this.props.match.params.company;
        const product = this.props.match.params.product;
        let companyTypeId = null;
        let companyId = null;
        let productId = null;
        let tempVar = company.split('-');
        if(tempVar.length > 2){
            companyTypeId = parseInt(tempVar.slice(-2,-1));
            companyId = parseInt(tempVar.slice(-1));
        }
        tempVar = product.split('-');
        if(tempVar.length > 1)
            productId = parseInt(tempVar.slice(-1));
        if(!companyTypeId || !companyId || !productId)
            this.props.history.push(urls.extension + '/home');
        else{
            this.getProductDetails(companyTypeId,companyId,company,productId,product);
        }
    }

    componentDidMount(){
        changeAppTitle('Shopaas | Product Details');
        let initialState = {...this.state};
        this.setState({initialState});
        this.onPageLoad();
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname && !this.state.fetchingData)
            this.onPageLoad();
    }

    componentWillUnmount(){
        if(source)
            source.cancel();
    }

    getProductDetails = async(companyTypeId,companyId,company,productTmplId,product) =>{
        await this.setState(this.state.initialState);
        await this.setState({companyTypeId,companyId,company,productTmplId,product});
        await this.fetchProductDetails();
        await this.fetchRelatedProducts(companyId);
        await this.fetchRelatedProducts(companyId,productTmplId);
    }

    fetchProductDetails = async () => {
        try {
            this.setState({fetchingData:true});
            const response = await axios(urls.getProductDetails, {
                cancelToken: source.token,
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        companyTypeId: this.state.companyTypeId,
                        companyId: this.state.companyId,
                        prodTmplId: this.state.productTmplId,
                    }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                await this.setState({productDetails:res.result.productDetails});
                this.selectProduct();
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            if(!axios.isCancel(err))
                alert(err.message);
            this.setState({fetchingData:false});
        }
    }

    fetchRelatedProducts = async (company_id,product_tmpl_id=null) => {
        // this function fetches recently viewed products as well as alternative products based on its args
        try {
            let params = {company_id};
            let url = urls.relatedProducts;
            if(product_tmpl_id)
                params = {...params,...{product_tmpl_id}};
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(product_tmpl_id)
                    this.setState({alternativeProducts:res.result.products});
                else
                    this.setState({recentlyViewedProducts:res.result.products});
            }
        } 
        catch (err) {
        }
    }

    selectProduct = () =>{
        let defaultVariant = this.state.productDetails['defaultVariant'];
        let productVariant = this.state.productDetails['prodVariants'][defaultVariant];
        this.setState({selectedProduct:productVariant});
    }

    getDefaultAttrValue=(attr)=>{
        let defaultVariant = this.state.productDetails['defaultVariant'];
        let productVariant = this.state.productDetails['prodVariants'][defaultVariant];
        let combinationIndices = productVariant['combinationIndices'];
        let valueIds = Object.keys(this.state.productDetails['valueIds'][attr]);
        let defaultValue = combinationIndices.filter(indice=>valueIds.includes(indice))[0];
        return defaultValue;
    }

    handleVariantChange=(event,attr)=>{
        let attrValues = Object.keys(this.state.productDetails['valueIds'][attr]);
        let combinationIndices = [...this.state.selectedProduct['combinationIndices']];
        let lastValue = combinationIndices.find(indice=>attrValues.includes(indice));
        let index = combinationIndices.indexOf(lastValue);
        if(index !== -1){
            combinationIndices[index] = event.target.value;
            let prodVariants = this.state.productDetails['prodVariants'];
            let selectedProduct = { id:null,displayImage:blank,combinationIndices};
            for(let prod in prodVariants){
                if(prodVariants[prod]['combinationIndices'].join('::') === combinationIndices.join('::')){
                    selectedProduct = prodVariants[prod];
                    break;
                }
            }
            this.setState({selectedProduct});
        }
    }

    onVariantChange=(variantId)=>{
        let selectedProduct = this.state.productDetails['prodVariants'][parseInt(variantId)];
        this.setState({selectedProduct});
    }

    handleQuantityChange=(event)=>{
        let quantity = null;
        if (event === 'increment')
            quantity = parseInt(this.state.quantity + 1);
        else if (event === 'decrement')
            quantity = parseInt(this.state.quantity - 1);
        else
            quantity = parseInt(event.target.value);
        if(quantity && quantity>0)
            this.setState({quantity});
    }

    addProductToCart=async(e)=>{
        let product_id = this.state.selectedProduct['id'];
        let add_qty = this.state.quantity;
        let product_template_id = this.state.productTmplId;
        this.setState({fetchingData:true});
        let res = await addProductToCart(product_id,add_qty,product_template_id);
        this.setState({fetchingData:false});
        if(res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if(res){
            if(res.result.statusCode === 200){
                let custom_session_info = this.context.custom_session_info;
                custom_session_info = {...custom_session_info,...{cart_quantity:res.result.cart_quantity}};
                this.context.set_custom_session_info(custom_session_info);
                alert("Added");
            }
            else if(res.result.statusCode === 201)
                this.openResetPopup(res.result,{product_id,add_qty,product_template_id});
        }
    }

    resetCart=async(productData)=>{
        this.setState({fetchingData:true});
        let res = await resetCart(productData);
        if(res && res.result){
            let custom_session_info = this.context.custom_session_info;
            custom_session_info = {...custom_session_info,...{cart_quantity:res.result.cart_quantity}};
            this.context.set_custom_session_info(custom_session_info);
        }
        this.setState({fetchingData:false});
    }


    openResetPopup=(data,productData=null)=>{
        let title = data.header;
        let contentText = data.message;
        let resetCartPopupData = {...this.state.resetCartPopupData,...{open:true,title,contentText,productData}};
        this.setState({resetCartPopupData});
    }

    handleResetPopupClose=async(e,reset=false)=>{
        let resetCartPopupData = {...this.state.resetCartPopupData,...{open:false,title:'',contentText:''}};
        let productData = resetCartPopupData.productData;
        this.setState({resetCartPopupData});
        if(reset === true){
            await this.resetCart(productData);
        }
    }

    clickProductHandler(){
    }

    getAttrValues=()=>{
        let allValueIds = {};
        let valueIds = Object.values(this.state.productDetails['valueIds']);
        valueIds.forEach(value =>(
            allValueIds = {...allValueIds,...value}
        ))
        return(allValueIds);
    }

    getCombinationString=(variantId)=>{
        // let allValueIds = this.getAttrValues();
        // let combinationIndices = this.state.productDetails['prodVariants'][variantId]['combinationIndices'];
        // let combinationString = combinationIndices.map(attr => {
        //     return(allValueIds[attr] || '')
        // });
        // combinationString = '(' + combinationString.join(',') + ')'
        let variant_name = this.state.productDetails['prodVariants'][variantId]['variant_name'];
        return(variant_name);
    }

    render() { 
        return (
            <div className="product-details" >
                {this.state.selectedProduct && 
                    <div className="product-data">
                        <div className="left">
                            {/* Ankit Start 21 May 2023 Adding videos on Product Page*/}
                            {/* <ProductSliders images={this.state.selectedProduct['displayImage']} alt={this.state.productDetails['name']}/> */}
                            <ProductSliders videos={this.state.selectedProduct['displayVideo']} images={this.state.selectedProduct['displayImage']} alt={this.state.productDetails['name']}/>
                            {/* Ankit End 21 May 2023 */}
                        </div>
                        <div className="right">
                            <div className="product-name">{this.state.productDetails['name']}</div>
                            {this.state.productDetails['brand_name'] &&
                            <div className="brand-name">Brand : {this.state.productDetails['brand_name']}</div>
                            }
                            {this.state.productDetails['uom_name'] &&
                            <div className="uom-name">Unit of Measure : {this.state.productDetails['uom_name']}</div>
                            }
                            {this.state.productDetails['display_message'] &&
                            <div className="display_message" style={{color:'Red', marginTop:5}}>{this.state.productDetails['display_message']}</div>
                            }
                            {this.state.productDetails['showListView'] && Object.keys(this.state.productDetails['attrs']).map((attr)=>(
                                <>
                                    <InputLabel id="demo-simple-select-helper-label" >{this.state.productDetails['attrs'][attr]}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper-label"
                                        defaultValue={()=>this.getDefaultAttrValue(attr)}
                                        onChange={(e)=>this.handleVariantChange(e,attr)}
                                        style={{width:'100px'}}
                                    >
                                        {Object.keys(this.state.productDetails['valueIds'][attr]).map((val,idx1)=>(
                                            <MenuItem value={val}>
                                                {this.state.productDetails['valueIds'][attr][val]}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select><br/>
                                </>
                            ))}
                            {!this.state.productDetails['showListView'] && Object.keys(this.state.productDetails['prodVariants']).length>1 &&
                                <div className="variants-radio-view">
                                    {
                                        Object.keys(this.state.productDetails['prodVariants']).map((variantId)=>(
                                            <div>
                                                <input 
                                                    type="radio"
                                                    checked={parseInt(variantId) === parseInt(this.state.selectedProduct['id'])}
                                                    onChange={()=>this.onVariantChange(variantId)}
                                                />
                                                <span>{this.getCombinationString(variantId)}</span>
                                                <ProductPrice product={this.state.productDetails['prodVariants'][variantId]} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {this.state.selectedProduct['id'] &&
                                <>
                                    {(this.state.productDetails['showListView'] || Object.keys(this.state.productDetails['prodVariants']).length<=1) &&
                                        <ProductPrice product={this.state.selectedProduct} />
                                    }
                                    <div className="quantity">
                                        <button onClick={()=>this.handleQuantityChange("decrement")} disabled={this.state.quantity<=1}>
                                            -
                                        </button>
                                        <input 
                                            type="number"
                                            id="quantity"
                                            name="quantity"
                                            min="1"
                                            value={this.state.quantity} 
                                            onChange={this.handleQuantityChange}
                                        />
                                        <button onClick={()=>this.handleQuantityChange("increment")}>+</button>
                                    </div>
                                </>
                            }
                            {!this.state.selectedProduct['id'] &&
                                <div className="combination-noexists">
                                    This combination doesn't exists
                                </div>
                            }
                            <Button id="add-to-cart" 
                                disabled={!this.state.selectedProduct['id']}
                                onClick={()=>this.addProductToCart()}
                                > 
                                <ShoppingCart style={{marginRight:'5px'}}/>
                                Add to Cart 
                            </Button>
                        </div>
                    </div>
                }
                {this.state.recentlyViewedProducts.length>0 &&
                    <div className="recently-viewed-products">
                        <div className="heading">Recently viewed Products</div>
                        {this.state.recentlyViewedProducts.map((prod,idx)=>(
                            <ProductCard 
                                prod={prod}
                                history={this.props.history}
                                clickHandler = {()=>this.clickProductHandler()}
                            />
                        ))}
                    </div>
                }
                {this.state.alternativeProducts.length>0 &&
                    <div className="recently-viewed-products">
                        <div className="heading">Suggested Alternatives</div>
                        {this.state.alternativeProducts.map((prod,idx)=>(
                            <ProductCard 
                                prod={prod}
                                history={this.props.history}
                                clickHandler = {()=>this.clickProductHandler()}
                            />
                        ))}
                    </div>
                }
                <ResetCartPopup 
                    open={this.state.resetCartPopupData['open']}
                    title={this.state.resetCartPopupData['title']}
                    contentText={this.state.resetCartPopupData['contentText']}
                    handlePopupClose={this.handleResetPopupClose}
                />
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default withRouter(ProductDetails);
