import React from 'react';
import './OrderTables.css'; 
import { Tooltip } from '@material-ui/core';
import {Grid} from '@mui/material';

class OrderTables extends React.Component {

    render() { 
        return (
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} sm={12} md={10} style={{overflow:'scroll'}}>
                    <table className="order-table">
                            <thead style={{cursor:'pointer'}}>
                                <th style={{padding:'6px'}}>Quotation Number</th>
                                <Tooltip title="click to sort by order date" arrow>
                                    <th style={{padding:'6px'}} onClick={(e)=>this.props.handleChangeOptions(1,'sortBy')}>Order Date</th>
                                </Tooltip>
                                <Tooltip title="click to sort by delivery date" arrow>
                                    <th style={{padding:'6px'}} onClick={(e)=>this.props.handleChangeOptions(2,'sortBy')}>Delivery Date</th>
                                </Tooltip>
                                <Tooltip title="click to sort by expected date" arrow>
                                    <th style={{padding:'6px'}} onClick={(e)=>this.props.handleChangeOptions(3,'sortBy')}>Expected Date</th>
                                </Tooltip>
                                <th style={{padding:'6px'}}>Customer</th>
                                <Tooltip title="click to sort by order value" arrow>
                                    <th style={{padding:'6px'}} onClick={(e)=>this.props.handleChangeOptions(4,'sortBy')}>Total(In &#8377;)</th>
                                </Tooltip>
                                <Tooltip title="click to sort by status" arrow>
                                    <th style={{padding:'6px'}} onClick={(e)=>this.props.handleChangeOptions(5,'sortBy')}>Status</th>
                                </Tooltip>
                            </thead>
                        <tbody>
                            {this.props.rows.map((row)=>(
                                <tr onClick={()=>this.props.goToOrder(row['id'])}>
                                    <td>{row['name']}</td>
                                    <td>{row['date_order']}</td>
                                    <td>{row['commitment_date']}</td>
                                    <td>{row['expected_date']}</td>
                                    <td>{row['partner']['name']}</td>
                                    <td>{row['amount_total']}</td>
                                    <td>{row['state_sale']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
            </Grid>
                    </Grid>
        );
    }
}
 
export default OrderTables;
