import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import '../console.css';
import {fetchSpecificData} from '../../../Shared/consoleServices';


export default function TaxesPopup(props) {

	const [state,setState]  = useState({selectedItems:null});
	// Ankit Start 7 March 2023
	const [input,GetInput]  = useState([]);
	const [options,setOptions]  = useState({data:null});
	// Ankit End 7 March 2023

	// Ankit Start 7 March 2023 (All Popup Showing Same Label)
	const popupLabels = {taxes_id : 'Taxes', optional_product_ids:'Optional Products', 
						public_categ_ids:'Categories', alternative_product_ids:'Alternative Products',
						accessory_product_ids:'Accessory Products',website_style_ids:'Style'};
	//Ankit End 7 March 2023

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}

	useEffect(() => {
		if(props.data.open === true){
			// Ankit  bugid-1564  Start 30-08-22
            // changeState({'selectedItems':props.taxes_id});
			changeState({'selectedItems':props.data.idss});
			// Ankit  bugid-1564  Start 30-08-22
			// Ankti Start 07 March 2023
			setOptions({data:props.data.options})
			// Ankti End 07 March 2023
		}
	}, [props.data.open])

	const handleChange=(e,item)=>{
        let selectedItems = state.selectedItems || [];
		if(e.target.checked){
		// Ankit Start 15 Feb 2023
		if (props.header == 'taxes_id'){
		for (let element of state.selectedItems){
			if(props.data.options[element].split(" ")[0] == props.data.options[item].split(" ")[0]){
				alert('You Can only choose one IGST and one GST');
				e.target.checked = false;
				return;
			}
		}
		}
		// Ankit End 15 Feb 2023
            selectedItems = [...selectedItems,item];
        }
		else{
			selectedItems = selectedItems.filter(i=>i!=item)
        }
        changeState({'selectedItems':selectedItems});
	}

    const isItemSelected=(item=null)=>{
        let selectedItems = state.selectedItems || [];
		// Ankit  bugid-1564  start 30-08-22
        // let selected = selectedItems.includes(item);
        // return(selected);
		if(selectedItems.includes(parseInt(item)) || selectedItems.includes(item)){
			return true
		}
		// Ankit  bugid-1564  End 30-08-22
    }

	// Ankit Start 07 March 2023
	const fetchItems=async(dat=null)=>{
		console.log(props.header)
        let res = await fetchSpecificData(dat,props.header);
		if(res.error || res.result.errorMessage){
			alert(res.error || res.result.errorMessage);
		}
		else{
			setOptions({data:res.result.items})
		}

	}
	const getMoreData = () =>{
		// debugger

		fetchItems(input);	
	}
	// Ankit End 07 March 2023


	return (
		<div>
			<Dialog
				open={props.data.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
                            Select {popupLabels[props.header]}
						</div>
						{/*Ankit Start 07 March 2023 search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder ={'Search' + ' ' + popupLabels[props.header]}
							/>
							</span>
							<span>
								{/* <span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span> */}
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
						
							</span>
						</div>
						{/*Ankit End 07 March 2023 search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Tax</th>
							</thead>
							<tbody>
								{/* {props?.data?.options && Object.keys(props.data.options).map((tax_id,idx)=>( */}
								{options?.data && Object.keys(options?.data).map((tax_id,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={isItemSelected(tax_id)}
												onChange={(e)=>handleChange(e,tax_id)}
											/>
										</td>
										{/* <td>{props.data.options[tax_id]}</td> */}
										<td>{options.data[tax_id]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{/* Ankit bugid-1564  Start 30-08-22 */}
					{/* <Button variant="outlined" onClick={()=>props.handlePopupActions(state.selectedItems)} autoFocus disabled={!state.selectedItems}>
						Select
					</Button> */}
					<Button variant="outlined" onClick={()=>props.handlePopupActions(state.selectedItems,props.header)} autoFocus disabled={!state.selectedItems}>
						Select
					</Button>
					{/* Ankit  bugid-1564 End 30-08-22 */}
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
