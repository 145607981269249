import React from 'react';
// import './Promotion_Program.css';
import '../console.css';
import {fetchLoyaltyData} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button,Grid } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import urls from "../../../urls";
// Ankit Start 19 Jan 2023
import '../../../Components/Tables/OrderTables.css'
import './Loyalty.css'
// Ankit End 19 Jan 2023

class Loyalty_tree extends React.Component {
    

    state = {
        viewMode:true, 
        loyaltyId:null,
        fetchingData:false,
        form_options:{},
    }
    componentDidMount(){
        
        this.fetchLoyaltyData();
    }

    fetchLoyaltyData=async()=>{
        this.setState({fetchingData:true});
        let res  = await fetchLoyaltyData();
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({form_options:res.result.form_options});
        }
        this.setState({fetchingData:false});
    }
    goToProduct=(loyaltyId=false)=>{
        this.props.history.push(urls.extension + '/console/Loyalty_program/' + (loyaltyId || 'New'));
    }


    // Ankit Start 29-11-2022
    discardPage = ()=>{    
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022
    render() { 
        return (
            <>
            {/* create function  start here */}
            <div style={{width:"100%", margin:'10px'}}>
                    <Button 
                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.goToProduct()}
                    >
                        Create 
                    </Button>
                    {/* Ankit Start 29-11-2022 */}
                    <Button 
                        style={{background:"#02A457",color:"beige",margin:'0 10px',maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.discardPage()}
                    >
                        Discard 
                    </Button>
                    {/* Ankit End 29-11-2022 */}
                </div>
            {/* create function end here */}
            {/* tree view start here  */}
            <div>
            {this.state.form_options.loyalty_grp && 
            // Ankit Start 19 Jan 2023
            <Grid container >
            <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
            {/* // Ankit End 19 Jan 2023 */}
            <table className="order-table">
                        <thead>
                            <tr>
                                <th style={{textAlign:'center',padding:'6px'}}>Name</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Minimum Purchase</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Company</th>
                                <th style={{textAlign:'center',padding:'6px'}}>One Time Policy</th>
                              
                            </tr>
                        </thead>
                        <tbody>
                        {
                        this.state.form_options.loyalty_grp.map((elem,ind) => {
                            return(
                                <tr key={ind} onClick={()=>this.goToProduct(elem.id)} >
                                <td style={{textAlign:'center'}}>{elem.name}</td>
                                <td style={{textAlign:'center'}}>{elem.minimum_purchase}</td>
                                <td style={{textAlign:'center'}}>{elem.company}</td>
                                <td style={{textAlign:'center'}}>{elem.loyalty_policy}</td>
                                </tr>
                                )
                            } )
                        }
                        </tbody>
                    </table>
                {/* // Ankit Start 19 Jan 2023 */}
                </Grid>
                </Grid>
                // Ankit End 19 Jan 2023
            }
            </div>
            {/* tree view end here */}
            
            </>
        );

    }

}
export default Loyalty_tree;
