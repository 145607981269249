import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import './Popup.css';
import urls from '../../urls';
import { AdvertisementSliders } from '../Sliders/Sliders';
import { Select,MenuItem } from '@material-ui/core';
import { useAuth } from '../../Shared/context';
// Ankit Start 4 April 2023
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// Ankit End 4 April 2023


export function ResetCartPopup(props) {

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.contentText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={(e)=>{e.stopPropagation();props.handlePopupClose(e)}}>
						Don't Reset
					</Button>
					<Button variant="outlined" onClick={(e)=>{e.stopPropagation();props.handlePopupClose(e,true)}} autoFocus>
						Reset
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function RedeemPointsPopup(props) {

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.contentText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={(e)=>props.handlePopupClose(e,true)} autoFocus>
						Redeem
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function GetLocationPopup(props) {

	const [searchLocationText, setSearchLocationText] = useState('');
	const [locations,setLocations] = useState(null);
	const [fetchingData,setFetchingData] = useState(false);
	const [selectedAddress,setSelectedAddress] = useState(null);
	const {custom_session_info,set_custom_session_info} = useAuth();

	const searchLocation=async(e)=>{
        try{
			let location = e.target?e.target.value:'';
			let position = e.target?null:e;
			setSearchLocationText(location);
			if(location.length===0)
				clearSearch();
			if((location.length>2 & !fetchingData) || position){
				let params = {
					text:location
				}
				if(position)
					params = {...params,...{position:{'lat':position.coords.latitude,'long':position.coords.longitude}}}
				setFetchingData(true);
				const axios = require("axios");
				const response = await axios(urls.getLocation, {
					method: 'POST',
					withCredentials:true,
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					data: JSON.stringify({
						jsonrpc: "2.0",
						params: params
					})
				}, 7000);
				const res = await response.data;
				setFetchingData(false);
				if(!res.result.errorMessage && !res.error){
					setLocations(res.result.data.location);
				}
				else
					alert(res.result.errorMessage || res.error);
			}
        }
        catch(err){
            setFetchingData(false);
            alert(err.message);
        }
	}

	const getAddrHeading=(addr)=>{
		return (addr.split(',')[0]);
	}

	const clearSearch=()=>{
		setSearchLocationText('');
		setLocations(null);
		setFetchingData(false);
		setSelectedAddress(null);
	}

	const confirmLocation=async(e)=>{
        try{
			setFetchingData(true);
			const axios = require("axios");
			const response = await axios(urls.confirmLocation, {
				method: 'POST',
				withCredentials:true,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				data: JSON.stringify({
					jsonrpc: "2.0",
					params: {
							addressId:selectedAddress['id'],	
							address:selectedAddress['name']
						}
				})
			}, 7000);
			const res = await response.data;
			setFetchingData(false);
			if(!res.result.errorMessage && !res.error){
				clearSearch();
				let tempVar = {...custom_session_info,...{location:res.result?.data?.location || ''}};
				set_custom_session_info(tempVar);
				props.handlePopupClose();
			}
			else
				alert(res.result.errorMessage || res.error);
        }
        catch(err){
            setFetchingData(false);
            alert(err.message);
        }
	}

	const addrClickHandler=(addr)=>{
		setSearchLocationText(addr['name']);
		setSelectedAddress(addr);
	}

	const getUserGPSLocation=()=> {
		try{
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(searchLocation);
			  } else { 
				alert("Geolocation is not supported by this browser.");
			  }
		}
        catch(err){
            alert(err.message);
        }
	}
	  
	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				maxWidth="md"
			>
				<div className="locations">
					<div className="locations__heading">
						<span style={{paddingLeft:"10px"}}>{props.title}</span>
					</div>
					<div className='location__main-content'>
						<div className="input__box">
							<input 
								type="text" 
								value = {searchLocationText}
								onChange={searchLocation}
								placeholder="Search by location ..."
							/>
							<Button variant="contained" onClick={clearSearch}>Clear</Button>
						</div>
						<div className="location__options">
							<div style={{padding:"10px"}}>
								{!locations &&
									<span onClick={()=>getUserGPSLocation()}>
										<div className="main_location">
											Get Current Location
										</div>
										<div className="helper__location">Using GPS</div>
									</span>
								}
								{locations && Object.keys(locations).map((location)=>(
									<span onClick={()=>addrClickHandler({'id':location,'name':locations[location]['address']})}>
										<div className="main_location">
											{getAddrHeading(locations[location]['address'])}
										</div>
										<div className="helper__location borderBottom">
											{locations[location]['address']}
										</div>
									</span>
								))
								}
								</div>
						</div>
					</div>
				</div>
				<DialogActions>
					<Button 
						variant="contained"  
						style={{backgroundColor:selectedAddress?"#007A77":"",color:selectedAddress?"beige":""}}
						onClick={confirmLocation}
						disabled={!selectedAddress}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function UserFeedbackPopup(props) {

	const [startCount,setStartCount] = useState(0);
	const [message,setMessage] = useState('');
	const [readOnly,setReadOnly] = useState(false);

	useEffect(() => {
		setReadOnly(props.order['rating_given']);
		if(props.order['rating_given']){
			setStartCount(props.order['star_rating_count']);
			setMessage(props.order['rating_text_view']);
		}
	}, []);

	const sendFeedback=async(e)=>{
        try{
			const axios = require("axios");
			const response = await axios(urls.sendFeedback, {
				method: 'POST',
				withCredentials:true,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				data: JSON.stringify({
					jsonrpc: "2.0",
					params: {
						partner_id:props.order['partner']['id'],
						order_id:props.order['id'],
						rating:startCount,
						message:message
					}
				})
			}, 7000);
			const res = await response.data;
			if(!res.result.errorMessage && !res.error){
				setReadOnly(true);
				props.handlePopupClose({'order':props.order,startCount,message});
			}
			else
				alert(res.result.errorMessage || res.error);
        }
        catch(err){
            alert(err.message);
        }
	}

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={()=>props.handlePopupClose()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={'xs'}
				fullWidth={true}
			>
				<DialogTitle id="alert-dialog-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					<Typography component="legend">Rating</Typography>
					<Rating
						name="simple-controlled"
						value={startCount}
						onChange={(event, newValue) => {
							setStartCount(newValue);
						}}
						size='large'
						readOnly={readOnly}
					/><br/><br/>
					<Typography component="legend">Message</Typography>
					<textarea 
                            type="text"
                            id="feedback-message"
                            name="feedback-message"
                            maxLength="500"
							value={message}
							onChange={(e)=>setMessage(e.target.value)}
                            style={{width:'100%',maxWidth:'100%',height:'200px',maxHeight:'200px'}}
							readOnly={readOnly}
                        />
				</DialogContent>
				<div className="custom-dialog_actions">
					<Button variant="contained" color="secondary" onClick={()=>props.handlePopupClose()}>Close</Button>
					<Button variant="contained" color="primary" onClick={()=>sendFeedback()} disabled={readOnly} autoFocus>
						Submit
					</Button>
				</div>
			</Dialog>
		</div>
	);
}

export function BasicPopup(props) {

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.contentText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handlePopupClose()} autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function AdvertisementPopup(props) {

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{/* Ankit Start 4 April 2023 */}
				<DialogTitle sx={{ m: 0, p: 2 }}>
						<IconButton
						aria-label="close"
						onClick={props.handlePopupClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
						>
						<CloseIcon />
						</IconButton>
				</DialogTitle>
				{/* Ankit End 4 April 2023 */}

				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<AdvertisementSliders 
							data = {props.data}
						/>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export function CouponsPopup(props) {

	const [selectedCoupon,setSelectedCoupon] = useState(null);

	useEffect(() => {
		setSelectedCoupon(null);
	}, []);


	const onChangeInput=(input)=>{
		if(input !== selectedCoupon)
			setSelectedCoupon(input);
		else
			setSelectedCoupon(null);
	}

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{Object.keys(props.coupons).length>0?
							<div style={{height:"300px",overflow:"auto"}}>
								{Object.keys(props.coupons).map((couponId)=>(
									<div className="available-coupons__1">
										<input 
											type="checkbox"
											checked={couponId === selectedCoupon}
											onChange={(e)=>onChangeInput(e.target.value)}
											value={couponId}
										/>
										<div>
											<span className="code">{props.coupons[couponId]['promo_code']}</span>
											<span className="name">{props.coupons[couponId]['name']}</span>
											<span className="details">{props.coupons[couponId]['description']}</span>
											{props.coupons[couponId]['rule_date_to'] && 
												<span>Expires on: {props.coupons[couponId]['rule_date_to']}</span>											
											}
										</div>
									</div>
								))}
							</div>
							:
							<span>
								No available coupons
							</span>
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{Object.keys(props.coupons).length>0 &&
						<Button 
							disabled={!selectedCoupon} 
							variant="contained" 
							color="primary" 
							onClick={()=>props.selectCoupon(props.coupons[selectedCoupon])}
						>
							Select Coupon
						</Button>
					}
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function RegisterPaymentPopup(props) {

	// Ankit Start 21-nov-2022 bugid-1697
	const [journal,setJournal] = useState()

	const handelChanges = (e) =>{
		setJournal(e.target.value)
		props.handlePaymentDataChange('journal_id',journal);
	}
	
	// Ankit End 21-nov-2022 bugid-1697

	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.data && 
							<div className="payment-form">
								<fieldset style={{border:"none"}}>
									<span>
										<div className="payment-form__inputs" >
											<label htmlFor="journal_id">Journal</label>
											<Select
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper-label"
												// Ankit start 24-11-2022 bugid-1697
												// onChange={props.handlePaymentDataChange}
												onChange={(e)=>handelChanges(e)}
												// value={props.data['journal_id']['id']}
												value={journal ||props.data['journal_id']['id']}
												// Ankit End 24-11-2022 bugid-1697
												style={{width:'250px'}}
												name='journal_id'
											>
												{props.data['options']['journal_id'].map((journal)=>(
													<MenuItem value={journal['id']}>
														{journal['name']}
													</MenuItem>
												))}
											</Select>
										</div>
									</span>
								</fieldset>
								<fieldset disabled={true} style={{border:"none"}}>
									<span>
										<div className="payment-form__inputs" >
											<label htmlFor="amount">Amount</label>
											<input
												id="amount"
												name="amount"
												type="text"
												onChange={props.handlePaymentDataChange}
												value={props.data['amount']}
											/>
										</div>
										<div className="payment-form__inputs" >
											<label htmlFor="payment_date">Date</label>
											<input
												id="payment_date"
												name="payment_date"
												type="text"
												onChange={props.handlePaymentDataChange}
												value={props.data['payment_date']}
											/>
										</div>
										<div className="payment-form__inputs" >
											<label htmlFor="communication">Memo</label>
											<input
												id="communication"
												name="communication"
												type="text"
												onChange={props.handlePaymentDataChange}
												value={props.data['communication']}
											/>
										</div>
									</span>
								</fieldset>
							</div>
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={(e)=>{e.preventDefault();props.handlePopupClose("validate")}} autoFocus>
						Validate
					</Button>
					<Button variant="outlined" onClick={(e)=>{e.preventDefault();props.handlePopupClose()}} autoFocus>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function BackOrderPopup(props) {

	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.contentText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{props.buttons && Object.keys(props.buttons).map((action)=>(
						<Button variant="contained" color="primary" onClick={(e)=>{e.stopPropagation();props.handlePopupClose(e,props.buttons[action])}}>
							{action}
						</Button>
					))}
					<Button onClick={(e)=>{e.stopPropagation();props.handlePopupClose(e)}} autoFocus>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
