import React from 'react';
// import { Tab } from '@mui/material';
// import { TabList,TabContext } from '@material-ui/lab';
import { Button } from '@mui/material';
import urls from '../../urls';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { formValidation } from '../../Shared/utilities';
import { fetchContactData } from '../../Shared/consoleServices';


class ContactUs extends React.Component {
    state = {
        contactusData:{
            // name:"",
            // phone:"",
            // email:"",
            // subject:"",
            // QA:""
        },
        errors:{},
        fetchingData:false,
        form_options:{},
        dataSubmit:true
    }
    // componentDidMount(){
    //     this.fetchContactInfo();
    // }
    fetchContactInfo = async()=>{

        this.setState({fetchingData:true});
        let res  = await fetchContactData();
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({form_options:res.result.form_options});
        }
        this.setState({fetchingData:false});

    }
    handleValidation=async(key=null)=>{
        let errors = formValidation(this.state.errors,this.state.contactusData,key);        
        this.setState({errors});
        return(errors);
    }
    
    // changeViewMode=async()=>{
    //     let viewMode = this.state.viewMode;
    //     this.setState({viewMode:!viewMode});
    //     if(!viewMode){
    //         this.handleSubmit();
    //     }
    // }
    
    handleChange=async(e=null,x=null,val=null)=>{
        let contactusData = this.state.contactusData;
        
        let key = x;
        let value = val;
        if(!val){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        
        contactusData = {...contactusData,...{[key]:value}};
        await this.setState({contactusData});
        this.handleValidation(key);


        // let key = property;
        // let value = newValue;
        // // if(!newValue){
        // //     key = e.target.name;
        // //     console.log(e.target.type)
        // //     // if(e.target.type === 'phone')
        // //     //     value = e.target.checked;
        // //     // else{
        // //     //     value = e.target.value;    
        // //     //     e.preventDefault();
        // //     // }
        // // }
        // value = e.target.value;    
        // let contactusData = this.state.contactusData;
        // contactusData = {...contactusData,...{[key]:value}};
        // this.handleValidation(key);
        // this.setState({contactusData});
        
        
        
    }
    handleValidation=async(key=null)=>{
        let errors = formValidation(this.state.errors,this.state.contactusData,key);        
        this.setState({errors});
        return(errors);
    }
    handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            let errors = await this.handleValidation();
            if(Object.keys(errors).length>0){
                alert('Please fill all fields properly')
                return;
            }
            this.setState({fetchingData:true});
            const axios = require("axios");
            let params = {...this.state.contactusData};
            const response = await axios(urls.sendContactusData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            this.setState({fetchingData:false});
            if(!res.result.errorMessage && !res.error){
                alert('success');
                this.setState({'dataSubmit':false})
                this.props.history.push(urls.extension + '/contactus');
            }
            else
                alert(res.result.errorMessage || res.error);
            return;
        } 
        catch (err) {
            this.setState({fetchingData:false});
            alert(err.message);
            return;
        }
    
        
        // this.setState({'dataSubmit':false})
    }
    // SaveContactInfo=async(e)=>{
    //     e.preventDefault();
    //     console.log('saved')
    // }
       
    render() { 
        return (
            <>
            <div className="console-form">
            <header>
                {/* <span>
                    {this.showBtn('edit') &&
                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                            {`${this.state.viewMode?"Edit":"Save"}`}
                        </Button>
                    }
                    </span> */}
                </header>
          
            {/* <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
            
            </fieldset> */}
            {this.state.dataSubmit ? 
            <Box sx={{ width: '75%' }}> 
            <Grid container spacing={8}>
            <Grid item xs={8}>
                <div>
                    <h2>Contact us</h2>
                    <h3>Contact us about anything related to our company or services.
                        We'll do our best to get back to you as soon as possible. </h3>
                    <div>
                        <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                        <form onSubmit={this.handleSubmit}>
                            <label for="name">Your Name:  <span style={{paddingBottom:'10px'}}></span>
                            <span className="console-form__inputs">
                                <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.contactusData?.name}
                                required
                                />
                                <span className="input-error">{this.state.errors["name"]}</span>
                            </span>
                            </label>
                            <label for="phone">Phone NO :  <span style={{paddingBottom:'10px'}}></span>
                            <span className="console-form__inputs">
                                <input
                                id="phone"
                                name="phone"
                                type="number"
                                onChange={this.handleChange}
                                max="10"
                                required
                                />
                                <span className="input-error">{this.state.errors["phone"]}</span>
                            </span>
                            </label>
                            
                            <label for="email">Email:  <span style={{paddingBottom:'10px'}}></span>
                            <span className="console-form__inputs">
                                <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={this.handleChange}
                                value={this.state.contactusData?.email}
                                required
                                />
                                <span className="input-error">{this.state.errors["email"]}</span>
                            </span>
                            </label>
                            <label for="yourCompanyName">Your Company:  <span style={{paddingBottom:'10px'}}></span>
                            <span className="console-form__inputs">
                                <input
                                id="yourCompanyName"
                                name="yourCompanyName"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.contactusData?.yourCompanyName}
                                />
                                <span className="input-error">{this.state.errors["yourCompanyName"]}</span>
                            </span>
                            </label>
                            <label for="subject">Subject:  <span style={{paddingBottom:'10px'}}></span>
                            <span className="console-form__inputs">
                                <input
                                id="subject"
                                name="subject"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.contactusData?.subject}
                                required
                                />
                                <span className="input-error">{this.state.errors["subject"]}</span>
                            </span>
                            </label>
                            <label for="QA">Your Question:  <span style={{paddingBottom:'10px'}}></span>
                            <span className="console-form__inputs">
                            <textarea id="QA" onChange={this.handleChange} name="QA" rows="4" cols="50" required >{this.state.contactusData?.QA}</textarea>
                                <span className="input-error">{this.state.errors["QA"]}</span>
                            </span>
                            </label>
                            <Button 
                                    style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                                    onClick={(e)=>this.handleSubmit(e)}
                                >
                                    Send
                            </Button>
                            </form>
                        </fieldset>
                    </div>
                </div>
            </Grid>
            <Grid item xs={4}>
            <h2>Details </h2>
            {this.state.form_options &&
                
                <div style={{marginTop:'30px'}}>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> <PersonIcon/></h3></Grid>
                        <Grid item xs={9}><h3>{this.state.form_options?.name}Chadha Industries Pvt Ltd</h3></Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> <EmailIcon/></h3></Grid>
                        <Grid item xs={9}><h3>{this.state.form_options?.email} info@arkefilters.com</h3></Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> </h3></Grid>
                        <Grid item xs={9}><h3>{this.state.form_options?.email}38, DLF Industrial Area
                                Kirti Nagar, Najafgarh Road
                        New Delhi - 110015</h3></Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> <ContactPhoneIcon/></h3></Grid>
                        <Grid item xs={9}><h3>{this.state.form_options?.phone}+91 - 11 - 4848 3700</h3></Grid>
                    </Grid>
                    {/* <h1>{this.state.form_options?.name}</h1>
                    <h1>{this.state.form_options?.email}</h1>
                    <h3>{this.state.form_options?.address}</h3>
                    <h3>{this.state.form_options?.phone}</h3>
                    <h3>{this.state.form_options?.number}</h3> */}
                </div>
            }
            </Grid>
            </Grid>
            </Box>
            :
            <div>
                <h2>THANKS</h2>
                <p>We will get back to you shortly</p>
            </div>
            }
            </div>
            </>
         
        )
    }
        
}
export default ContactUs;
