

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

export function changeAppTitle(title){
  document.title = title;
}

export function formValidation(errors,data,key){
    let phoneRegex = (/^\d{10}$/);
    let emailRegex = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    let pinCodeRegex = (/^\d{4}$|^\d{6}$/);
    if((key ==="email" || !key) && data.hasOwnProperty('email')){
      if(!data['email'])
          errors = {...errors,...{'email':'Required field'}}
      else if(!emailRegex.test(data['email']))
          errors = {...errors,...{'email':'Invalid email address'}}
      else
          delete errors['email']
    }
    if((key ==="otp" || !key) && data.hasOwnProperty('otp')){
        if(!data['otp'])
            errors = {...errors,...{'otp':'Required field'}}
        else if(data['otp'].length<4)
            errors = {...errors,...{'otp':'OTP length must be of 4 digits'}}
        else
            delete errors['otp']
    }
    if((key ==="passwd" || !key) && data.hasOwnProperty('passwd')){
        if(!data['passwd'])
            errors = {...errors,...{'passwd':'Required field'}}
        else if(data['passwd'].length<8)
            errors = {...errors,...{'passwd':'Minimum 8 characters'}}
        else
            delete errors['passwd']
        if(data['confirmPasswd'] === data['passwd'])
            delete errors['confirmPasswd']
    }
    if((key ==="confirmPasswd" || !key) && data.hasOwnProperty('confirmPasswd')){
        if(!data['confirmPasswd'])
            errors = {...errors,...{'confirmPasswd':'Required field'}}
        else if(data['confirmPasswd'] !== data['passwd'])
            errors = {...errors,...{'confirmPasswd':'Password not matching'}}
        else
            delete errors['confirmPasswd']
    }
    if((key ==="name" || !key) && data.hasOwnProperty('name')){
      if(!data['name'])
          errors = {...errors,...{'name':'Required field'}}
      else
          delete errors['name']
    }
    if((key ==="businessName" || !key) && data.hasOwnProperty('businessName')){
      if(!data['businessName'])
          errors = {...errors,...{'businessName':'Required field'}}
      else
          delete errors['businessName']
    }
    if((key ==="street" || !key) && data.hasOwnProperty('street')){
      if(!data['street'])
          errors = {...errors,...{'street':'Required field'}}
      else
          delete errors['street']
    }
    if((key ==="company_type" || !key) && data.hasOwnProperty('company_type')){
      if(!data['company_type'])
          errors = {...errors,...{'company_type':'Required field'}}
      else
          delete errors['company_type']
    }
    if((key ==="country" || !key) && data.hasOwnProperty('country')){
      if(!data['country'] || !data['country']['id'])
          errors = {...errors,...{'country':'Required field'}}
      else
          delete errors['country']
    }
    if((key ==="state" || !key) && data.hasOwnProperty('state')){
      if(!data['state'] || !data['state']['id'])
          errors = {...errors,...{'state':'Required field'}}
      else
          delete errors['state']
    }
    if((key ==="city" || !key) && data.hasOwnProperty('city')){
      if(!data['city'] || !data['city']['id'])
          errors = {...errors,...{'city':'Required field'}}
      else
          delete errors['city']
    }
    if((key ==="mobile" || !key) && data.hasOwnProperty('mobile')){
      if(!data['mobile'])
          errors = {...errors,...{'mobile':'Required field'}}
      else if(!phoneRegex.test(data['mobile']))
          errors = {...errors,...{'mobile':'Invalid number'}}
      else
          delete errors['mobile']
    }
    if((key ==="phone" || !key) && data.hasOwnProperty('phone')){
      if(!data['phone'])
          errors = {...errors,...{'phone':'Required field'}}
      else if(!phoneRegex.test(data['phone']))
          errors = {...errors,...{'phone':'Invalid number'}}
      else
          delete errors['phone']
    }
    if((key ==="zip" || !key) && data.hasOwnProperty('zip')){
      if(!data['zip'])
          errors = {...errors,...{'zip':'Required field'}}
      else if(!pinCodeRegex.test(data['zip']))
          errors = {...errors,...{'zip':'Invalid pincode'}}
      else
          delete errors['zip']
    }
    return(errors);
}
