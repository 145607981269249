import React from 'react';
import { Button } from '@mui/material';
import {getProductDataUpdate,saveProductDataUpdate} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import '../DataTransfer/DataTransfer';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import ItemPopup from '../DataTransfer/ItemPopup';
import Chip from '@material-ui/core/Chip';
import '../console.css';

class UpdateProduct extends React.Component {

    state = {
        productData:null,
        fetchingData:false,
        updatingData:false,
        viewMode:true,
        productPage:0,
        allPublished:false,
        memory:{},
        popupData:{open:false},
        selectedItem:null,
        toggleUpdateButton:false,
        productUpdated:false
    }

    componentDidMount(){
        this.getProductFormData();
    }

    getProductFormData=async()=>{
        this.setState({fetchingData:true});
        let res = await getProductDataUpdate(this.state.productPage);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({productData:res.result.data,form_options:res.result.form_options});
        }
        this.setState({fetchingData:false});
    }

    handleSubmit=async(e)=>{
        let updateProductData = this.state.updateProductData;
        if(updateProductData && Object.keys(updateProductData).length>0){
            this.setState({fetchingData:true});
            let res = await saveProductDataUpdate(this.state.productPage,this.state.productData['id'],updateProductData);
            if(res.error || res.result.errorMessage){
                alert(res.error || res.result.errorMessage);
            }
            else{
                if(res.result.data)
                    this.setState({productData:res.result.data,updateProductData:{},memory:{}});
                else
                    this.setState({productUpdated:true});
            }
            this.setState({fetchingData:false});
        }
    }

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === 'show_start')
            show = this.state.productData.btnStatus.show_start;
        else if(btnType === 'show_update')
            show = this.state.productData.btnStatus.show_update;
        else if(btnType === 'show_cancel')
            show = this.state.productData.btnStatus.show_update;
        return show;
    }

    handleChange=async(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updateProductData = this.state.updateProductData;
        updateProductData = {...updateProductData,...{[key]:value}};
        await this.setState({updateProductData});
        if(Object.keys(updateProductData).includes('filter')){
            this.handleSubmit();
            this.setState({selectedItem:null});
        }
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    performAction=async(action)=>{
        let updateProductData = this.state.updateProductData;
        updateProductData = {...updateProductData,...{action}};
        let selectedItem = this.state.selectedItem;
        if(selectedItem){
            selectedItem = selectedItem.id;
            updateProductData = {...updateProductData,...{selectedItem}};
        }
        await this.setState({updateProductData});
        this.handleSubmit();
        await this.setState({selectedItem:null});

    }

    changeProductsPage=async(page)=>{
        if(page>=0 && this.state.productData.total_line_ids<((this.state.productPage * 50) + Object.keys(this.state.productData.product_update_line_ids).length)){
            let memory = this.state.memory;
            memory[this.state.productPage] = this.state.productData.product_update_line_ids;
            this.setState({memory});
            this.setState({fetchingData:true});                
            let productData = this.state.productData;
            if(page in memory){
                let product_update_line_ids = {};
                product_update_line_ids = memory[page];
                productData = {...productData,...{product_update_line_ids:product_update_line_ids}}
            }
            else{
                let res = await getProductDataUpdate(page);
                if(res.error || res.result.errorMessage)
                    alert(res.error || res.result.errorMessage);
                else
                    productData = {...productData,...{product_update_line_ids:res.result.data.product_update_line_ids}}
            }
            this.setState({productData,fetchingData:false,productPage:page});
        }
    }

    getLineData=(field,row)=>{
        let updateProductData = this.state.updateProductData || {}; 
        let line = updateProductData[row['id']] || {};
        // Ankit start 21-nov-2022 (for checkbox update)
        if (Object.values(line)!=0 && field=='is_published'){
            return line['is_published']
        }
        // Ankit End 21-nov-2022
        let value = (line && line[field]) || row[field];
        return value;
    }


    handleLineChange=(e,row)=>{
        let value = false;
        let key = e.target.name;
        if(key === 'price')
            value = e.target.value;
            this.setState({toggleUpdateButton:true});
        if(key === 'is_published')
            value = e.target.checked;
            this.setState({toggleUpdateButton:true});
        let updateProductData = this.state.updateProductData || {};
        let line = updateProductData[row['id']] || {};
        line = {...line,...{[key]:value}};
        let id = row['id']; 
        updateProductData = {...updateProductData,...{[id]:line}};
        this.setState({updateProductData});
    }

    filterAppliedOn=(option)=>{
        let checked = (this.state.updateProductData?.filter || this.state.productData['filter']) === option;
        return(checked);
    }

    openPopup=()=>{
        let popupData = this.state.popupData;
        let type = 'category';
        if(this.state.productData.filter === 'product')
            type = 'product';
        else if(this.state.productData.filter === 'lot')
            type = 'lot';
        popupData = {...popupData,...{open:true,type}};
        this.setState({popupData});
    }

    handlePopupActions=(selectedItem=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if(selectedItem)
            this.setState({selectedItem});
    }
    isLineSelected = () =>{
        let selected = true;
        return(selected);
    }


     // Ankit Start 29-11-2022
     discardPage = ()=>{
        
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    render() { 
        return (
            <div className="console-form">
                {this.state.productData && !this.state.productUpdated &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                {this.showBtn('show_start') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("start")}>
                                        Start
                                    </Button>
                                }
                                { this.state.toggleUpdateButton ? 
                                <div> 
                                    {this.showBtn('show_update') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("update")}>
                                        Update
                                    </Button>
                                    }
                                </div>:null }
                                {this.showBtn('show_cancel') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("cancel")}>
                                        Cancel
                                    </Button>
                                }
                                {/* Ankit Start 29-11-2022 */}
                                 <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.discardPage()}>
                                        Discard
                                    </Button>
                                {/* Ankit End 29-11-2022 */}
                            </span>
                            <span className="header__right"></span>
                        </header>   
                        <fieldset disabled={this.showBtn('show_update')} style={{border:"none"}}>
                            <span>
                                <div className="console-form__inputs" style={{alignItems:'flex-start'}}>
                                    <label htmlFor="from_company_id">Applied On</label>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        {Object.keys(this.state.form_options['filter']).map((option)=>(
                                            <span>
                                                <input
                                                    id="filter"
                                                    name="filter"
                                                    type="checkbox"
                                                    onChange={this.handleChange}
                                                    checked={this.filterAppliedOn(option)}
                                                    value={option}
                                                />
                                                <label htmlFor={option}>{this.state.form_options['filter'][option]}</label>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </span>
                            {this.showBtn('show_start') &&
                                <span>
                                    {this.state.productData['filter'] === 'category' &&
                                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup()}>
                                            Select Category
                                        </Button>
                                    }
                                    {this.state.productData['filter'] === 'product' &&
                                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup()}>
                                            Select Product
                                        </Button>
                                    }
                                    {this.state.productData['filter'] === 'lot' &&
                                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup()}>
                                            Select Lot
                                        </Button>
                                    }
                                    {this.state.selectedItem &&
                                        <Chip
                                            label={this.state.selectedItem.name}
                                            onDelete={()=>this.setState({selectedItem:null})}
                                        />
                                    }
                                </span>
                            }
                        </fieldset>
                        {this.state.productData.total_line_ids>0 &&
                            <>
                                <div className="products-pagination">
                                    <span>
                                        <ArrowBack onClick={()=>this.changeProductsPage(this.state.productPage-1)} style={{cursor:'pointer'}}/>
                                        <span>{`${(this.state.productPage * 50)+1}-${(this.state.productPage * 50) + Object.keys(this.state.productData.product_update_line_ids).length}/${this.state.productData.total_line_ids}`}</span>
                                        <ArrowForward onClick={()=>this.changeProductsPage(this.state.productPage+1)} style={{cursor:'pointer'}}/>
                                    </span>
                                </div>
                                <fieldset disabled={false} style={{border:"none"}}>
                                    <table className="console-table-line">
                                        <thead>
                                            <th style={{padding:'6px'}}>Product</th>
                                            <th style={{padding:'6px'}}>Product Type</th>
                                            <th style={{padding:'6px'}}>Product Category</th>
                                            <th style={{padding:'6px'}}>Price</th>
                                            <th style={{padding:'6px'}}>Lot/Serial</th>
                                            <th style={{padding:'6px'}}>Published</th>
                                        </thead>
                                        <tbody>
                                            {Object.values(this.state.productData.product_update_line_ids).map((row)=>(
                                                <tr>
                                                    <td>{row['product_id']['name']}</td>
                                                    <td>{row['type']}</td>
                                                    <td>{row['categ_id']['name']}</td>
                                                    <td>
                                                        <input 
                                                            id="price"
                                                            name="price"
                                                            type="number"
                                                            min={0}
                                                            value={this.getLineData('price',row)}
                                                            onChange={(e)=>this.handleLineChange(e,row)}
                                                        />
                                                    </td>
                                                    <td>{row?.lot_id?.name}</td>
                                                    <td>
                                                        <input 
                                                            id="is_published"
                                                            name="is_published"
                                                            type="checkbox"
                                                            // Ankit Start 21-nov-2022
                                                            checked={this.getLineData('is_published',row)}
                                                            // checked={()=>this.isPublished('is_published',row)}
                                                            // Ankit End 21-nov-2022
                                                            onChange={(e)=>this.handleLineChange(e,row)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </fieldset>
                            </>
                        }
                    </form>
                }
                {this.state.productUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Products updated successfully
                    </div>
                }
                <ItemPopup 
                    origin="product-data-update"
                    open={this.state.popupData['open']}
                    type={this.state.popupData['type']}
                    handlePopupActions={this.handlePopupActions}
                    id = {this.state.productData?.id}
                />
                <Loader open={this.state.fetchingData || this.state.updatingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default UpdateProduct;
