import React from 'react';
import './MyAccount.css';
import urls from '../../urls';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useHistory } from "react-router-dom";
import { fetchContactData } from '../../Shared/consoleServices';
import { AuthContext } from '../../Shared/context';
import i18next from 'i18next';
import { Translation } from 'react-i18next'


class MyAccount extends React.Component {
    static contextType = AuthContext;
    state = {
        contactusData:{},
        errors:{},
        fetchingData:false,
        form_options:{},
        dataSubmit:true
    }
    componentDidMount(){
        console.log('this is myacount', this.context.UserLang)
        this.handleLanguageChange();
        this.fetchContactInfo();
    }
    fetchContactInfo = async()=>{

        this.setState({fetchingData:true});
        let res  = await fetchContactData('address');
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({form_options:res.result.form_options});
        }
        this.setState({fetchingData:false});

    }
    handleLanguageChange() {
        console.log('this is inlannffnfnfnfnside', this.context.UserLang)
        if (this.context.UserLang.lang1 !=='en'){
            console.log('this is inside')
            i18next.changeLanguage(this.context.UserLang.lang1);
        }
        
    }
    getstrdata(l){
        return l
    }

    render() { 
        return (
            <Translation>

                    {(t, { i18n, }) =>
            <div className="my-orders">
               <h3>MyAccount</h3> 
                <hr/>

            <Box sx={{ width: '85%' }}> 
            <Grid container spacing={8}>
            <Grid item xs={8}>
                <div>
                    <h2>{t('Documents')}</h2>
                    
                    
                </div>
            </Grid>
            <Grid item xs={4}>
                <h2> {t('Details')} </h2>
            {this.state.form_options &&
                <div style={{marginTop:'20px'}}>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> <PersonIcon/></h3></Grid>
                        <Grid item xs={9}><h3> {t(this.state.form_options?.name)}</h3></Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> <EmailIcon/></h3></Grid>
                        <Grid item xs={9}><h3>{t(this.state.form_options?.email)}</h3></Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={3}><h3> <ContactPhoneIcon/></h3></Grid>
                        <Grid item xs={9}><h3>{t(this.state.form_options?.phone)}</h3></Grid>
                    </Grid>
                    
                    {/* <h3>{this.state.form_options?.number}</h3>  password change */}
                    <Button 
                        style={{background:"#02A457",marginTop:'20px',color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.props.history.push(urls.extension + '/reset_password')}
                    >
                        Reset Password
                    </Button>
                </div>
            }
            </Grid>
            </Grid>
            </Box>

            <div>
                
            </div>
            
            </div>
        }
        </Translation>
        );
    }
}
 
export default MyAccount;
