import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import '../console.css';
import {getProductOptions} from '../../../Shared/consoleServices';

export default function ItemPopup(props) {

	const [state,setState]  = useState({items:[],selectedItem:null});
	// Piyush Kumar
	const [input,GetInput]  = useState([]);

	useEffect(() => {
		if(props.data.open === true){
			fetchItems();
		}
	}, [props.data.open])

	// const fetchItems=async()=>{
	// 	let type = (props.data.type === 'product'?'product':'product_category');
	// 	type = props.data.type === 'product_variant'?'product_variant':type;
    //     let res = await getProductOptions(props.data.origin,null,type);
    //     if(res.error || res.result.errorMessage){
    //         alert(res.error || res.result.errorMessage);
    //     }
    //     else{
    //         changeState({items:res.result.data.items});
    //     }
	// }
// pk
	const fetchItems=async(dat=null)=>{
		let type = (props.data.type === 'product'?'product':'product_category');
		type = props.data.type === 'product_variant'?'product_variant':type;
        let res = await getProductOptions(props.data.origin,dat,type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.data.items});
        }
	}

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}

	const handleChange=(e,item)=>{
		if(e.target.checked)
			changeState({selectedItem:item});
		else
			changeState({selectedItem:null});
	}

	// Piyush Kumar 
	const getMoreData = () =>{
		fetchItems(input);	
	}
	// Piyush Kumar end
	return (
		<div>
			<Dialog
				open={props.data.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
							Select
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder='enter product detail'/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Name</th>
							</thead>
							<tbody>
								{Object.values(state.items).map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={state.selectedItem?.id === row['id']}
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{row['name']}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handlePopupActions(state.selectedItem)} autoFocus disabled={!state.selectedItem}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
