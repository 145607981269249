import React from 'react';
import './StoreProduct.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { storeCategories, saveStoreCategory } from '../../../Shared/consoleServices';
import { Button, Alert, Typography } from '@mui/material';

import { Select, MenuItem } from '@material-ui/core';
import Loader from '../../../Components/Shared/Loader';
import urls from '../../../urls';
import Stack from '@mui/material/Stack';

import { CardActionArea, Input, TextField, Grid, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
// import Chip from '@material-ui/core/Chip';
// import urls from "../../../urls";
// import { Tab } from '@mui/material';
// import { TabList,TabContext } from '@material-ui/lab';



// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import { CardActionArea } from '@mui/material';



class StoreCategory extends React.Component {

    state = {
        viewMode: true,
        categoryId: null,
        categoryData: null,
        updatedcategoryData: {},
        errors: {},
        form_options: {},
        fetchingData: false,
        updatingCategoryData: false,
        categoryUpdated: false,
        popupData: { open: false },
        selectedTab: "General Info",
        Tabs: ['General Info'],
        CategoryType: 'public_product_category',
        popupName: "",
        server_msg: false,
        severity: false



    }

    componentDidMount() {

        changeAppTitle('Console | Public Product Category');
        let categoryId = this.props.match.params.categoryId;
        this.setState({ categoryId });
        this.fetchcategoryData(categoryId);
    }

    fetchcategoryData = async (id, CategoryType = this.state.CategoryType) => {
        this.setState({ fetchingData: true });
        let res = await storeCategories({ id, CategoryType });
        if (res.error || res.result.errorMessage) {
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({ categoryData: res.result.data.category, form_options: res.result.data.form_options });

        }
        this.setState({ fetchingData: false });
    }
    getFieldValue = (field) => {
        let value = this.state.productData[field];
        if (this.state.updatedProductData && this.state.updatedProductData.hasOwnProperty(field))
            value = this.state.updatedProductData[field];
        return (value);
    }
    handleChange = (e, property = null, newValue = null) => {
        let key = property;
        let value = newValue;

        if (!newValue) {
            key = e.target.name;
            if (e.target.type === 'checkbox')
                value = e.target.checked;
            else {
                value = e.target.value;
                e.preventDefault();
            }
        }
        let updatedcategoryData = this.state.updatedcategoryData;
        updatedcategoryData = { ...updatedcategoryData, ...{ [key]: value } };
        this.setState({ updatedcategoryData });
    }
    // Added by mohit dec 27 for validtion of name, passsword and email
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        const array = ["name",];
        for (let x of array) {

            if (document.getElementsByName(x)[0].value == "") {
                formIsValid = false;
                errors[x] = "Name cannot be empty";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit = async (e) => {

        if ((this.state.updatedcategoryData && Object.keys(this.state.updatedcategoryData).length > 0)) {
            if (this.handleValidation()) {
                alert("Form submitted");
                this.setState({ fetchingData: true });
                let res = {}

                res = await saveStoreCategory({ id: this.state.categoryData['id'], updatedData: this.state.updatedcategoryData, CategoryType: this.state.CategoryType });

                if (res.error || res.errorMessage) {
                    this.setState({ server_msg: 'Failed !! ' + res.errorMessage, severity: 'error' });
                    alert(res.error || res.errorMessage);
                }
                else {
                    if (res.statusCode === "201") {

                        let categoryId = res.data.category.id;
                        this.props.history.replace(urls.extension + '/console/publicproductcategory/' + (categoryId));
                        this.setState({ categoryUpdated: false });
                        this.setState({ categoryData: res.data.category, form_options: res.data.form_options });
                        this.setState({ server_msg: 'Success Created Category', severity: 'success' });
                    }
                    else {
                        this.setState({ categoryUpdated: false });
                        this.setState({ server_msg: 'Success', severity: 'success' });
                        let categoryId = this.props.match.params.categoryId;
                        this.setState({ categoryId });
                        this.fetchcategoryData(categoryId);

                    }
                }
            } else {
                alert("Please fill fields email, name etc")

            }

            this.setState({ fetchingData: false });

        }
    }

    changeViewMode = async () => {
        let viewMode = this.state.viewMode;
        this.setState({ viewMode: !viewMode });
        if (!viewMode) {
            this.handleSubmit();
        }
    }

    showBtn = (btnType) => {
        let show = false;
        if (btnType === "edit") {
            show = true;
        }
        return show;
    }



    getFieldValue = (field) => {
        let value = this.state.categoryData[field];
        if (this.state.updatedcategoryData && this.state.updatedcategoryData.hasOwnProperty(field))
            value = this.state.updatedcategoryData[field];
        return (value);
    }

    // Ankit Start 29-11-2022
    discardPage = () => {
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    render() {
        return (
            <div className="console-form">
                {this.state.categoryData && !this.state.categoryUpdated &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left" style={{ alignItems: 'flex-start' }}>
                                
                                {/* <h3 className="console-form__heading">{this.state.categoryData['name']}</h3> */}
                                {this.showBtn('edit') &&
                                    <Button style={{ background: "#02A457", color: "beige", marginRight: 5 }} onClick={() => this.changeViewMode()}>
                                        {`${this.state.viewMode ? "Edit" : "Save"}`}
                                    </Button>
                                }
                                <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.discardPage()}>
                                    Discard
                                </Button>
                                <Typography mr={1} sx={{ overflow: 'auto', wordWrap: 'break-word', fontSize: 15 }} variant="h6" component="h6">
                                    Ecommerce Category / {this.state.categoryData['name']}
                                </Typography>
                            </span>
                            <span className="header__right">
                            </span>
                        </header>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            {this.state.server_msg ?
                                <Alert variant="filled" style={{ marginBottom: 10 }} onClose={() => { this.setState({ server_msg: false }) }} severity={this.state.severity}>{this.state.server_msg}</Alert>
                                : ''}
                        </Stack>

                        {/* {this.state.categoryUpdated ? <Alert severity='success'>{server_msg.msg}</Alert> : ''} */}
                        {/* {this.state.server_msg ? <Alert onClose={handleClose}severity='success'>Success</Alert> : ''} */}
                        {this.state.selectedTab === 'General Info' &&
                            <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                                <Grid container style={{ marginBottom: 10 }}  >
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1} >
                                            <TextField
                                                label="Ecommerce Category Name"
                                                id="name"
                                                name="name"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.updatedcategoryData?.name || this.state.categoryData['name']}
                                                required
                                                size="small"
                                                style={{ marginBottom: 10 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1} >
                                            <span htmlFor="name" className="input-error">{this.state.errors["name"]}</span>
                                        </Grid>
                                    </Grid>

                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid xs={12} sm={4} md={3} >

                                            <FormControlLabel style={{ margin: 0 }} control={<Checkbox
                                                id="active"
                                                name="active"
                                                type="checkbox"
                                                onChange={this.handleChange}
                                                checked={this.getFieldValue('active')}
                                            />} label="Active" />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid container style={{ marginBottom: 10 }}  >
                                    <Grid container md={3} style={{ marginBottom: 15 }}>
                                        <Grid item xs={12} md={13} mr={1} >

                                            <FormControl sx={{ mr: 3, minWidth: 220 }} variant="filled">
                                                <label htmlFor="parent_id">Parent Category</label>
                                                <Select
                                                    labelId="parent_id"
                                                    id="parent_id"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedcategoryData?.parent_id || this.state.categoryData['parent_id'] || false}
                                                    name='parent_id'
                                                >
                                                    <MenuItem value="false">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {Object.keys(this.state.form_options['parent_id']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.state.form_options['parent_id'][option]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Grid item xs={12} md={13} mr={1} style={{ marginBottom: 10 }} ></Grid>
                                        </Grid>

                                    </Grid>


                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid xs={12} sm={4} md={12} >
                                            <TextField
                                                label="Sequence"
                                                id="sequence"
                                                name="sequence"
                                                type="number"
                                                min={0}
                                                onChange={this.handleChange}
                                                value={this.state.updatedcategoryData?.sequence || this.state.categoryData['sequence']}
                                                size="small"
                                                style={{ marginBottom: 10 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1} >
                                            <span className="input-error">{this.state.errors["sequence"]}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </fieldset>
                        }



                    </form>
                }

                {/* {this.state.categoryUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Category created successfully
                    </div>
                } */}

                <Loader open={this.state.fetchingData || this.state.updatingCategoryData} handleClose={() => { }} />
            </div>
        );
    }
}

export default StoreCategory;
