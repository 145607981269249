import React from 'react';
import './Pricelists.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { pricelists,savePricelist } from '../../../Shared/consoleServices';
import { Button,TextField } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
import Loader from '../../../Components/Shared/Loader';
import Chip from '@material-ui/core/Chip';
import urls from "../../../urls";
import { Tab } from '@mui/material';
import { TabList,TabContext} from '@material-ui/lab';
import ItemPopup from './ItemPopup';
// Piyush kumar bugid - 1587
import DeleteIcon from '@mui/icons-material/Delete';
// piyush kumar end
// Ankit Start 5 jan 2023
import './Pricelist.css';
import Box from '@mui/material/Box';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// Ankit End 5 jan 2023

class Pricelist extends React.Component {

    state = {
        viewMode:true,
        pricelistId:null,
        pricelistData:null,
        updatedPricelistData:{},
        errors:{},
        form_options:{},
        fetchingData:false,
        updatingPricelistData:false,
        pricelistUpdated:false,
        popupData:{open:false},
        selectedTab:"Price Rules",
        Tabs:['Price Rules','Configuration'],
        selectedPriceRule:null,
        priceRule:{}
    }

    componentDidMount(){
        changeAppTitle('Console | Pricelist');
        let pricelistId = this.props.match.params.pricelistId;
        this.setState({pricelistId});
        this.pricelists(pricelistId);
    }

    pricelists=async(id)=>{
        this.setState({fetchingData:true});
        let res  = await pricelists({id});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({pricelistData:res.result.data.pricelist,form_options:res.result.data.form_options});
        }
        this.setState({fetchingData:false});
    }

    // Piyush Kumar bugid -1587 del param, type= remove & if else   added
    handleChange=(e,property=null,newValue=null,del=null)=>{

        let key = property;
        let value = newValue;
        if(!property){
            key = e.target.name;
            if(e.target.type === 'checkbox')
                value = e.target.checked;
            else{
                value = e.target.value;    
                e.preventDefault();
            }
        }
        let updatedPricelistData = this.state.updatedPricelistData;
        if (del){
           let type = 'remove';
           updatedPricelistData = {...updatedPricelistData,...{[key]:value},...{type}};
        }
        else{
            updatedPricelistData = {...updatedPricelistData,...{[key]:value}};
        }
        
        this.setState({updatedPricelistData});
    }


    handleSubmit=async(e)=>{
        this.setState({fetchingData:true});
        let res = await savePricelist({id:this.state.pricelistData['id'],updatedData:this.state.updatedPricelistData});
        this.setState({fetchingData:false});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
            return(false);
        }
        else{
            // this.setState({pricelistUpdated:true});
            return(true);
        }
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        if(!viewMode){
            let res = await this.handleSubmit();
            if(res)
                this.setState({viewMode:!viewMode});
        }
        else
            this.setState({viewMode:!viewMode});
    }

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === "edit"){
            show = true;
        }
        return show;
    }

    openOptionsPopup=(option_category)=>{
        let popupData = this.state.popupData;
        let origin = "pricelist";
        let type = option_category==='2_product_category'?'2_product_category':(option_category==='1_product'?'product':'product_variant');
        popupData = {...popupData,...{open:true,origin,type}};
        this.setState({popupData});
    }

    handlePopupActions=async(selectedItem=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if(selectedItem){
            let priceRule = this.state.priceRule;
            priceRule = {...priceRule,...{selectedItem}}
            await this.setState({priceRule});
        }
    }


    onTabChange=async(e,newValue)=>{
        this.setState({selectedTab:newValue});
    }

    // Ankit Start 12 Jan 2022
    handleValidation = ()=>{
        const product_limit = this.state.priceRule?.pricelist_product_limit;
        const min_quantity = this.state.priceRule?.min_quantity;
        let flag = true;
        if(min_quantity > product_limit){
            alert("Min Quantity Cannot Be Greater then Product Limit");
            flag = false;
        }
        return flag;
    }
    // Ankit End 12 Jan 2023
    savePriceRule=()=>{
        // Ankit Start 12 Jan 2023
        if(this.handleValidation()){
        // Ankit End 12 Jan 2023
        if(this.state.selectedPriceRule){
            let pricelistData = {...this.state.pricelistData};
            let item_ids = pricelistData.item_ids;
            let priceRule = this.state.priceRule;
            priceRule.name = 'All Products'
            if(priceRule.applied_on === '2_product_category')
                priceRule.name = 'Category: ' + priceRule.selectedItem.name;
            else if(priceRule.applied_on === '1_product')
                priceRule.name = 'Product: ' + priceRule.selectedItem.name;
            else if(priceRule.applied_on === '0_product_variant')
                priceRule.name = 'Product Variant: ' + priceRule.selectedItem.name;
            let selectedPriceRule = this.state.selectedPriceRule;
            let updatedPriceRule = {[selectedPriceRule]:priceRule};
            item_ids = {...item_ids,...updatedPriceRule};
            pricelistData = {...pricelistData,...{item_ids}};
            this.setState({pricelistData,'priceRule':{},'selectedPriceRule':null});
            let updatedPricelistData = this.state.updatedPricelistData;
            let temp = updatedPriceRule?.item_ids || {};
            temp = {...temp,...updatedPriceRule};
            
            // Piyush kumar commented bugid-1587 commented below code as pankaj rana instruction
            this.handleChange(null,'item_ids',temp);
            //pankaj editing 
            // if(temp.New0.hasOwnProperty("date_start") == false){
            //     alert('start date must be required');
            //     return false;
            // }
            // else if(temp.New0.hasOwnProperty("date_end") == false){
            //     alert('end date must be required');
            //     return false;
            // }
            // else{
            //     this.handleChange(null,'item_ids',temp);
            // }
            //pankaj end 
            // Piyush kumar end
            
        }
        // Ankit Start 12 Jan 2023
        }
        // Ankit End 12 Jan 2023
    }

    putPriceRule=async(price_rule=null)=>{
        let selectedPriceRule = price_rule || ('New' + Object.keys(this.state.pricelistData.item_ids).length);
        this.setState({selectedPriceRule});
        if(price_rule){
            let priceRule = this.state.pricelistData.item_ids[price_rule];
            this.setState({priceRule});
        }
        else{
            let compute_price = Object.keys(this.state.form_options['compute_price'])[0];
            let applied_on = Object.keys(this.state.form_options['applied_on'])[0];
            let pricelist_product_limit = 0;
            let min_quantity = 0;
            let priceRule = {compute_price,applied_on,pricelist_product_limit,min_quantity};
            this.setState({priceRule});
        }
    }

    handlePriceRuleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!property){
            key = e.target.name;
            if(e.target.type === 'checkbox')
                value = e.target.checked;
            else{
                value = e.target.value;    
                e.preventDefault();
            }
        }
        let priceRule = this.state.priceRule;
        priceRule = {...priceRule,...{[key]:value}};
        this.setState({priceRule});
    }

    // Piyush kumar bugid - 1587
    onClickDeleteRow = (id) => {

        let pricelistData = {...this.state.pricelistData};
        let remove = this.state.pricelistData.item_ids;
        // const updateitem = remove.filter((item_ids,itemId) =>{
        //     return itemId !== id;
        // });
        
        // let type = this.state.pricelistData.type = 'remove';
        // let updatedPricelistData = {...updatedPricelistData,...{type}};
        // this.setState({updatedPricelistData});
        // console.log(updateitem1);
        // let updateitem2 = updateitem1.type = 'remove';
        let updateitem = delete remove[id];
        pricelistData = {...pricelistData,...{updateitem}};
        this.setState({pricelistData,'priceRule':{},'selectedPriceRule':null});
        this.handleChange(null,'item_ids',remove,true)
    };
    // Piyush Kumar End

    render() { 
        return (
            <div className="console-form">
                {this.state.pricelistData &&  !this.state.pricelistUpdated &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                <h3 className="console-form__heading">{this.state.pricelistData['name']}</h3>
                                {this.showBtn('edit') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                                        {`${this.state.viewMode?"Edit":"Save"}`}
                                    </Button>
                                }
                            </span>
                        </header>
                        <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                        <Box component="form"
                                sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}} 
                                noValidate autoComplete="off">
                            <span>
                                {/* <div className="console-form__inputs" > */}
                                    {/* <label htmlFor="name">Name</label> */}
                                    <TextField
                                        label='Name'
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.updatedPricelistData.hasOwnProperty('name')?
                                                this.state.updatedPricelistData['name']:
                                                this.state.pricelistData['name']
                                            }
                                        size="small"
                                    />
                                {/* </div> */}
                                <div className="console-form__inputs selection-field-css" >
                                {/* <div> */}
                                    <label htmlFor="discount_policy">Discount Policy</label>
                                    <Select
                                        labelId="discount_policy"
                                        id="discount_policy"
                                        onChange={(e)=>this.handleChange(e)}
                                        value={this.state.updatedPricelistData.discount_policy || this.state.pricelistData.discount_policy}
                                        name='discount_policy'
                                    >
                                        {Object.keys(this.state.form_options['discount_policy']).map((option)=>(
                                            <MenuItem value={option}>
                                                {this.state.form_options['discount_policy'][option]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                {/* </div> */}
                            </span>
                            </Box>
                        </fieldset>
                        <div className='rule-btn-div'>
                        {!this.state.viewMode &&
                            <Button 
                                
                                className='rule-btn'
                                style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px',marginRight:"10px"}}
                                onClick={()=>this.putPriceRule()}
                            >
                                Add Rule
                            </Button>
                        }
                        {this.state.selectedPriceRule &&
                            <Button 
                                className='rule-btn' 
                                style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}}
                                onClick={()=>this.savePriceRule()}
                            >
                                Save Rule
                            </Button>
                        }
                        </div>
                        {this.state.selectedPriceRule && 
                            <div>
                                <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                                    <span style={{marginBottom:'10px'}}>
                                        {/* <div className="console-form__inputs" >
                                            <label htmlFor="name">Price Rule Name</label> */}
                                              <Box component="form"
                                sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}} 
                                noValidate autoComplete="off">
                                            <TextField
                                                label='Price Rule Name'
                                                id="name"
                                                name="name"
                                                type="text"
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.name}
                                                disabled={true}
                                                size="small"
                                            />
                                        {/* </div> */}
                                        {/* <div className="console-form__inputs" > */}
                                            {/* <label htmlFor="min_quantity">Min. Quantity</label> */}
                                            <TextField
                                                label='Min. Quantity'
                                                id="min_quantity"
                                                name="min_quantity"
                                                type="number"
                                                // Ankit Start 12 Jan 2023 (Min && Max Attributes Not Workes in MUI)
                                                // min={0}
                                                // InputProps={{ inputProps: { max: (this.state.priceRule?.pricelist_product_limit), min:0}}}
                                                InputProps={{ inputProps: {min:0}}}
                                                // Ankit End 12 Jan 2023
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.min_quantity}
                                                size="small"
                                            />
                                        {/* </div> */}
                                        {/* <div className="console-form__inputs" > */}
                                            {/* <label htmlFor="pricelist_product_limit">Product Limit</label> */}
                                            <TextField
                                                label='Product Limit'
                                                id="pricelist_product_limit"
                                                name="pricelist_product_limit"
                                                type="number"
                                                // Ankit Start 12 Jan 2023 (Min && max Attributes Not works in MUI)
                                                // min={0}
                                                InputProps={{ inputProps: { min: this.state.priceRule?.min_quantity } }}
                                                // Ankit End 12 Jan 2023
                                                size="small"
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.pricelist_product_limit}
                                            />
                                         </Box>
                                        {/* </div> */}
                                    </span>
                                    <span>
                                        {/* <div className="console-form__inputs" > */}
                                        <Box component="form"
                                sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}} 
                                noValidate autoComplete="off">
                                    <div className='date-time-div'>
                                            <label htmlFor="name">Start Date</label>
                                            <input
                                                id="date_start"
                                                name="date_start"
                                                type="date"
                                                // piyush kumar bugid - 1587
                                                max={this.state.priceRule?.date_end}
                                                // piyush end
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.date_start}
                                            />
                                    </div>
                                             {/* <DesktopDatePicker
                                                label="Date desktop"
                                                inputFormat="YYYY/MM/DD"
                                                id="date_start"
                                                name="date_start"
                                                max={this.state.priceRule?.date_end}
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.date_start}
                                                renderInput={(params) => <TextField {...params} />}
                                                /> */}
                                        {/* </div> */}
                                        {/* <div className="console-form__inputs" > */}
                                        <div className='date-time-div'>
                                            <label htmlFor="date_end">End Date</label>
                                            <input
                                                id="date_end"
                                                name="date_end"
                                                type="date"
                                                // piyush kumar bugid - 1587
                                                min={this.state.priceRule?.date_start}
                                                // piyush end
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.date_end}
                                            />
                                            </div>
                                    </Box>
                                        {/* </div> */}
                                    
                                    </span>
                                    <span>
                                    <Box component="form"
                                                    sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}} 
                                                    noValidate autoComplete="off">
                                        <div className="" style={{display:'inline-block', paddingTop:'10px', marginRight:'10px'}} >
                                        {/* <div className="console-form__inputs" > */}
                                            <label htmlFor="compute_price" style={{marginRight:'10px'}}>Compute Price</label>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper-label"
                                                onChange={(e)=>this.handlePriceRuleChange(e)}
                                                value={this.state.priceRule?.compute_price}
                                                name='compute_price'
                                            >
                                                {Object.keys(this.state.form_options['compute_price']).map((option)=>(
                                                    <MenuItem value={option}>
                                                        {this.state.form_options['compute_price'][option]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {/* <div className="console-form__inputs" > */}
                                            {/* <label htmlFor="min_quantity">
                                                {this.state.priceRule?.compute_price === 'fixed'?'Fixed Price':'Percentage Price(%)'}
                                            </label> */}
                                            
                                            <TextField
                                                label={this.state.priceRule?.compute_price === 'fixed'?'Fixed Price':'Percentage Price(%)'}
                                                name={this.state.priceRule?.compute_price === 'fixed'?'fixed_price':'percent_price'}
                                                type="number"
                                                min={0}
                                                max={this.state.priceRule?.compute_price === 'fixed'?false:100}
                                                onChange={this.handlePriceRuleChange}
                                                value={this.state.priceRule?.compute_price === 'fixed'?this.state.priceRule?.fixed_price:this.state.priceRule?.percent_price}
                                                size="small"
                                           />
                                           </Box>
                                        {/* </div> */}
                                    </span>
                                    <span>
                                        <div className="console-form__inputs" >
                                            <label htmlFor="applied_on">Apply On</label>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper-label"
                                                    onChange={(e)=>this.handlePriceRuleChange(e)}
                                                    name='applied_on'
                                                    value={this.state.priceRule?.applied_on}
                                                >
                                                    {Object.keys(this.state.form_options['applied_on']).map((option)=>(
                                                        <MenuItem value={option}>
                                                            {this.state.form_options['applied_on'][option]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                        </div>
                                        {this.state.priceRule?.applied_on !== '3_global' &&
                                            <div className="console-form__inputs" >
                                                <Button 
                                                    style={{background:"#02A457",color:"beige",marginRight:"10px",maxHeight: '30px',fontSize: '13px'}}
                                                    onClick={()=>this.openOptionsPopup(this.state.priceRule?.applied_on)}
                                                >
                                                    {`${this.state.priceRule?.applied_on==='2_product_category'
                                                        ?
                                                        "Product Category"
                                                        :
                                                        (this.state.priceRule?.applied_on==='1_product'?'Product':'Product Variant')
                                                        }`}
                                                </Button>        
                                                {this.state.priceRule?.selectedItem &&
                                                    <Chip
                                                        label={this.state.priceRule?.selectedItem.name}
                                                        onDelete={()=>this.handlePriceRuleChange(null,'selectedItem',null)}
                                                    />
                                                }
                                            </div>
                                        }
                                    </span>
                                </fieldset>
                            </div>
                        } 
                        <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                            <table className="order-table">
                                <thead>
                                    <tr>
                                        <th style={{padding:'6px'}}>Applicable On</th>
                                        <th style={{padding:'6px'}}>Min. Quantity</th>

                                        {/* Ankit Start 06-dec-2022 */}
                                        {/* <th style={{padding:'6px'}}>Price</th> */}
                                        {/* <th style={{padding:'6px'}}>Price/Percentage</th> */}
                                        <th style={{padding:'6px'}}>Compute Price</th>
                                        {/* <th style={{padding:'6px'}}>{this.state.priceRule?.compute_price === 'fixed'?'Price':'Percentage'}</th> */}
                                        {/* Ankit End 06-dec-2022 */}

                                        <th style={{padding:'6px'}}>Start Date</th>
                                        <th style={{padding:'6px'}}>End Date</th>
                                        <th style={{padding:'6px'}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(this.state.pricelistData.item_ids).map((itemId,idx)=>(
                                            
                                            <tr onClick={()=>this.putPriceRule(itemId)}>
                                            <td>{this.state.pricelistData.item_ids[itemId]['name']}</td>
                                            <td>{this.state.pricelistData.item_ids[itemId]['min_quantity']}</td>
                                            {/* pankaj change price to  fixed price or percent price   */}
                                            {/* Ankit Start 13 Feb 2023 */}
                                            {/* {this.state.pricelistData.item_ids[itemId].hasOwnProperty("fixed_price") == true ?      
                                            <td>{this.state.pricelistData.item_ids[itemId]['fixed_price']}</td>:
                                            <td>{this.state.pricelistData.item_ids[itemId]['percent_price']}</td>
                                            } */}
                                            {this.state.pricelistData.item_ids[itemId].compute_price == 'fixed' ?      
                                            <td>{this.state.pricelistData.item_ids[itemId]['fixed_price']} price</td>:
                                            <td>{this.state.pricelistData.item_ids[itemId]['percent_price']} percent</td>
                                            }
                                            {/* Ankit End 13 Feb 2023 */}
                                            <td>{this.state.pricelistData.item_ids[itemId]['date_start']}</td>
                                            <td>{this.state.pricelistData.item_ids[itemId]['date_end']}</td>
                                            <td>
                                                {/* piyush kumar bugid-1587 */}
                                                <DeleteIcon
                                                    onClick={() => this.onClickDeleteRow(itemId)}
                                                />
                                                {/* piyush kumar end */}
                                            </td>
                                        </tr> 
                                        
                                    ))}
                                </tbody>
                            </table>
                        </fieldset>
                    </form>
                }
                {this.state.pricelistUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pricelist created/updated successfully
                    </div>
                }
                <ItemPopup
                    data={this.state.popupData}
                    handlePopupActions={this.handlePopupActions}
                />
                <Loader open={this.state.fetchingData || this.state.updatingPricelistData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default Pricelist;
