import React from 'react';
import { Delete } from '@material-ui/icons';
import './OrderLines.css';
import { Button,Grid } from '@mui/material';
import ItemPopup from './ItemPopup';

class OrderLines extends React.Component {

    state = {
        popupData:{open:false},
        // texts:'',
        hideReturnQty :true
    }

    openPopup=()=>{
        let popupData = this.state.popupData;
        let type = 'product';
        popupData = {...popupData,...{open:true,type}};
        this.setState({popupData});
    }

    handlePopupActions=(selectedItem=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if(selectedItem){
            let new_line_data = {
                'product_id':{'id':selectedItem.id,'name':selectedItem.name},
                'product_template_id':selectedItem.product_template_id,
                'name':'','product_uom_qty':1,'qty_delivered':0,
                // 'return_quantity':0,'price_unit':0,'tax_id':{},'price_subtotal':0
                'return_quantity':0,'price_unit':selectedItem.list_price,'tax_id':{},'price_subtotal':selectedItem.list_price
            }
            this.props.addNewOrderLine(new_line_data);
        }
    }

    getQuantity=(row,key)=>{
        let order_lines = this.props.updatedOrderData.order_lines;
        let qty = row[key]; 
        if(order_lines && order_lines[row['id']] && order_lines[row['id']][key])
            qty = order_lines[row['id']][key];
        return qty;
    }

    showOrderLine=(row)=>{
        let order_lines = this.props.updatedOrderData.order_lines;
        if(row === null)
            return false;
        else if(order_lines && order_lines.hasOwnProperty(row['id']) && order_lines[row['id']] === null)
            return false;
        return true;
    }

    get_taxes=(ts1)=>{
        // console.log(ts1.tax_ids);
        if(ts1.hasOwnProperty('tax_ids')){
            let texts = []
            let add_space = ' '
            ts1['tax_ids'].map((row1)=>{
                let di = row1['tax_id']['name']
                texts = [...texts,add_space,di]
            })
        // Ankit Start 11 jan 2023
        // if(!this.state.texts){
            // this.setState({texts})
        // Ankit Start 11 jan 2023
        // }

            return(
               
                // <p>{this.state.texts}</p>
                <p>{texts}</p>
                
              )
        }
        else{
            return(
                <p></p>
              )
        }
        
    }


    render() { 
        return (
            <>
            <Grid container >
            <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
            <table className="store-order-line">
                <thead>
                    <tr>
                    <th>Product</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Delivered Quantity</th>
                    <th>Return Quantity</th>
                    <th>Unit Price</th>
                    {/* Ankit Start 18 April 2023 */}
                    <th>Discount Price</th>
                    {/* Ankit End 18 April 2023 */}
                    <th>Taxes</th>
                    <th>Subtotal</th>
                    {!this.props.viewMode &&
                        <th></th>
                    }
                    </tr>
                </thead>
                {this.props.rows && this.props.rows.length>0 && 
                    <tbody>
                        {this.props.rows.map((row)=>{
                            if(!this.showOrderLine(row))
                                return (<></>);
                            return(
                                <tr key={row['product_id']}>
                                    <td>{row['product_id']['name']}</td>
                                    <td>{row['name']}</td>
                                    <td>
                                        <input 
                                            name="product_uom_qty"
                                            type="number"
                                            min={0}
                                            value={this.getQuantity(row,'product_uom_qty')}
                                            onChange={(e)=>this.props.changeOrderLineData(e,row['id'])}
                                        />
                                    </td>
                                    <td>
                                        <input 
                                            name="qty_delivered"
                                            type="number"
                                            min={0}
                                            // Ankit start bugid-1583 06-09-2022
                                            max={this.getQuantity(row,'product_uom_qty')}
                                            // Ankit End bugid-1583 06-09-2022
                                            disabled={this.props.saleOrderType=='return'}
                                            value={this.getQuantity(row,'qty_delivered')}
                                            onChange={(e)=>this.props.changeOrderLineData(e,row['id'])}
                                        />
                                    </td>
                                    <td>
                                        <input 
                                            name="return_quantity"
                                            type="number"
                                            min={0}
                                            // Ankit start bugid-1588 06-09-2022
                                            max={this.getQuantity(row,'product_uom_qty')}
                                            // Ankit End bugid-1588 06-09-2022
                                            disabled={this.props.saleOrderType=='order'}
                                            value={this.getQuantity(row,'return_quantity')}
                                            onChange={(e)=>this.props.changeOrderLineData(e,row['id'])}
                                        />
                                    </td>
                                      {/* # mohit  22-09-2022 start bug-ID -1539  solve bug issue of company price_editable */}
                                      <td>
                                        <input 
                                            name="price_unit"
                                            type="number"                                                                                        
                                            step="0.01"
                                            // oninput="limitDecimalPlaces(event, 2)"
                                            disabled={this.props.saleOrderType && !this.props.unit_price_editable}
                                            value={this.getQuantity(row,'price_unit')}
                                            onChange={(e)=>this.props.changeOrderLineData(e,row['id'])}
                                            
                                        />
                                        
                                    </td>
                                    {/* <td>{row['price_unit']}</td> */}
                                    {/* # mohit  22-09-2022 start bug-ID -1539  solve bug issue of company price_editable */}

                                    {/* change taxes structure */}
                                    {/* <td>{row['tax_id']['name'] || ''}</td> */}

                                    {/* Ankit Start 19 April 2023 */}
                                    <td>
                                        {/* <input 
                                            name="price_reduce"
                                            type="number"                                                                                        
                                            step="0.01"
                                            disabled={true}
                                            value={row['price_reduce']}
                                        /> */}
                                       {row['price_reduce']}
                                    </td>
                                    {/* Ankit End 19 April 2023 */}

                                    <td>
                                    {this.get_taxes(row)} 
                                    
                                    </td>
                                    <td>{row['price_subtotal']}</td>
                                    
                                    {!this.props.viewMode && row['sale_order_line_type']==='so_snap' ?
                                        
                                        
                                            <td>
                                                <Delete 
                                                    // onDelete={() => this.removeFromAllowedCompanies(compId)}
                                                     onClick={()=>this.props.removeFromAllowedCompanies(row['id'],-1)}
                                                    // onClick={(e)=>this.props.changeOrderLineData(e,row['id'],-1)}
                                                    // onClick={(e)=>this.props.handleQuantityChange(-1,parseInt(lineId))}
                                                />
                                            </td>
                                        
                                        
                                    :'' }
                                
                                </tr>
                            )
                        })}
                    </tbody>
                }
                {!this.props.viewMode &&
                    <Button 
                        onClick={()=>this.openPopup()}
                        size = "small"
                        variant = "contained"
                        style={{marginTop:'5px'}}
                    >
                        Add New Line
                    </Button>
                }
                <ItemPopup 
                    origin="store_order_form"
                    open={this.state.popupData['open']}
                    type={this.state.popupData['type']}
                    handlePopupActions={this.handlePopupActions}
                    id = {this.props.saleOrderId}
                />
            </table>
            </Grid>
            </Grid>
            </>
        );
    }
}
 
export default OrderLines;
