import React from 'react';
import './MyOrders.css';
import urls from '../../urls';
import { changeAppTitle } from '../../Shared/utilities';
import {UserFeedbackPopup,BasicPopup} from '../../Components/Popup/Popup';
import { useLocation } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import { Select,InputLabel,MenuItem } from '@material-ui/core';
import { ResetCartPopup } from '../../Components/Popup/Popup';
import Loader from '../../Components/Shared/Loader';

const OrderCard=(props)=>{

    const [popupData, setPopupData] = React.useState({open:false,title:'User Rating'});
    const [basicPopupData, setBasicPopupData] = React.useState({open:false,title:'Return Order',contentText:''});
    const [resetCartPopupData,setResetCartPopupData] = React.useState({open:false,title:'',contentText:''});
    const location = useLocation();

    const handlePopupClose=(orderData=null)=>{
        let newPopupData = {...popupData,...{open:false}};
        setPopupData(newPopupData);
        if(orderData){
            let orderId = orderData['order']['id'];
            let updatedOrderData = {...orderData['order'],...{'rating_given':true,
                                    'star_rating_count':orderData['startCount'],'rating_text_view':orderData['message']}};
            props.changeOrderData(orderId,updatedOrderData);
        }
    }

    const handleBasicPopupClose=()=>{
        let newBasicPopupData = {...basicPopupData,...{open:false}};
        setBasicPopupData(newBasicPopupData);
    }

	const processOrder=async(action,customParams=null)=>{
        try{
            props.setLoaderState(true);
            let params = {};
            if(customParams)
                params = {...customParams};
            else
                params = {'partner_id':props.order['partner']['id'],'order_id':props.order['id']};
            let url = ""
            if(action ==='short_close'){
                url = urls.shortClose;
            }else{
                url = (action === 'Cancel')?urls.cancelOrder:urls.reOrder; 
            }
            const axios = require("axios");
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            props.setLoaderState(false);
            if(!res.result.errorMessage && !res.error){
                if(action==='Cancel')
                    props.changeOrderState(props.order['id'],'Cancelled');
                // Piyush Kumar 26/5/2023
                else if(action ==='short_close')
                    props.changeOrderState(props.order['id'],'Short Closed');
                // Piyush Kumar End
                else{
                    if(res.result.statusCode===201){
                        let title = res.result.header;
                        let contentText = res.result.message;
                        let orderData = {...params};
                        let temp = {...resetCartPopupData,...{open:true,title,contentText,orderData}};
                        setResetCartPopupData(temp);
                    }
                    else
                        props.goToCartPage();
                }
            }
            else
                alert(res.result.errorMessage || res.error);
        }
        catch(err){
            props.setLoaderState(false);
            alert(err.message);
        }
	}

    const handleResetPopupClose=async(e,reset=false)=>{
        let params = resetCartPopupData.orderData;
        let temp = {...resetCartPopupData,...{open:false,title:'',contentText:''}};
        setResetCartPopupData(temp);
        if(reset === true){
            params = {...params,reset}
            await processOrder('Re-Order',params);
        }
    }

    const getReturnCartStatus = async (order) => {
        try {
            props.setLoaderState(true);
            const axios = require("axios");
            const response = await axios(urls.getReturnCart, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        sale_order_id:order['id'],
                        partner_id:order['partner']['id'],
                        statusOnly:true
                    }
                })
            }, 7000);
            const res = await response.data;
            props.setLoaderState(false);
            if(!res.result.errorMessage && !res.error){
                if(res.result.statusCode === 201){
                    let newBasicPopupData = {...basicPopupData,...{open:true,title:'Return Order',contentText:res.result.message}};
                    setBasicPopupData(newBasicPopupData);
                }
                else{
                    let reference = order['reference'];
                    reference = reference.replaceAll("/",'');
                    let newPageRoute = 'return/'+ reference + '-' + order['partner']['id'] + '-' + order['id'];
                    props.history.push(location.pathname + '/' +newPageRoute);
                }
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            props.setLoaderState(false);
            alert(err.message);
        }
    }

    const goToOrderDetails=(order,comp=null)=>{
        if (comp == 'comp'){
            //pankaj 09-08-2022 add url for company when user click 
            let newPageRoute = order['company']['company_type'] + '-' + order['company']['company_type_id'] + '/';
            newPageRoute += order['company']['name'].replace('/',',') + '-' + order['company']['company_type_id'] + '-' +  order['company']['id'] + '?page=1';
            props.history.push(urls.extension + '/home/'+ newPageRoute);
        }
        else if(order['reportUrl']){
            // console.log(order)
            // let reportUrl = urls.domain + order['reportUrl'];
            // window.open(reportUrl, '_blank');
            props.history.push(urls.extension + '/my/orders/detail/' + (order.id));
        }
  
    }


    return(
        <div className={`order__card ${props.order['state']}`}>
            <div className="comp-type">{props.order['company']['company_type']}</div>
            <div>
                {/* pankaj 09-08-2022 add comp argument to link for company page    */}
                <span className="comp-name" onClick={()=>goToOrderDetails(props.order,'comp')}>{props.order['company']['name']}</span>
                <span className={`order__state ${props.order['state']}`}>{props.order['state']}</span>
            </div>
            <div>
                <span>
                    <span className="highlight">Order # : </span>
                    <span className="reference" onClick={()=>goToOrderDetails(props.order)}>{props.order['reference']}</span>
                </span>
                <span>{props.order['date_order']}</span>
            </div>
            <div className="highlight">
                <span>Total: &#8377; {props.order['amount_total']}</span>
                {props.order['rating_given'] &&
                    <span>
                        <Rating
                            name="simple-controlled"
                            value={props.order['star_rating_count']}
                            size='small'
                            readOnly={true}
                        />
                    </span>
                }
            </div>
            <div className="cta-btn">
                {/* Ankit Start bugid-1588 08-09-2022 */}
                {/* {['Draft','Locked'].includes(props.order['state']) ? */}
                {['Draft'].includes(props.order['state']) ?
                // Ankit End bugid-1588 08-09-2022
                    <button style={{backgroundColor: '#C82333'}} onClick={()=>processOrder('Cancel')}>
                        Cancel
                    </button>

                    //  Ankit Start bugid-1588 20-09-2022                    
                    : !['Locked'].includes(props.order['state']) ?
                    <button style={{backgroundColor: '#00A09D'}} onClick={()=>processOrder('Re-Order')}>
                        Re-Order
                    </button> 
                    : ""
                    //  Ankit End bugid-1588 20-09-2022                    
                }
                
                {props.order['show_return_btn'] &&
                    <button style={{backgroundColor: '#00A09D'}} onClick={()=>getReturnCartStatus(props.order)}>
                        Return
                    </button>
                }
                 {(props.order['state'] === 'Delivered') &&
                    <button 
                        style={{backgroundColor: '#FAAF00',color:'black'}} 
                        onClick={()=>setPopupData({...popupData,...{open:true,order:props.order}})}
                    >
                            Rating
                    </button>
                }
                {/* Piyush Kumar 26/5/2023 */}
                {props.order['show_short_close_btn'] &&
                    <button style={{backgroundColor: '#00A09D'}} onClick={()=>processOrder('short_close')}>
                        Short Close
                    </button>
                }
            </div>
            <UserFeedbackPopup 
                    open={popupData['open']}
                    title={popupData['title']}
                    handlePopupClose={handlePopupClose}
                    order = {props.order}
                />
            <BasicPopup 
                open={basicPopupData['open']}
                title={basicPopupData['title']}
                handlePopupClose={handleBasicPopupClose}
                contentText = {basicPopupData['contentText']}
            />
            <ResetCartPopup 
                open={resetCartPopupData['open']}
                title={resetCartPopupData['title']}
                contentText={resetCartPopupData['contentText']}
                handlePopupClose={handleResetPopupClose}
            />
        </div>
    );
}


class MyOrders extends React.Component {

    state = {
        orders:null,
        orderList:null,
        sortBy:1,
        sortByOptions:{1:{name:'Order Date',value:'date'},
                        2:{name:'Reference',value:'name'},
                        3:{name:'Stage',value:'stage'}}
    }

    componentDidMount(){
        changeAppTitle('Shopaas | My Sales Orders');
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        let sortBy = searchParams.get('sort_by') || 1;
        this.setState({sortBy});
        this.fetchUserOrders();
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.search !== this.props.location.search){
            this.fetchUserOrders();
        }
    }

    changeOrderData=(orderId,newOrderData)=>{
        let orders = this.state.orders;
        let updatedOrders = {...orders,...{[orderId]:newOrderData}};
        this.setState({orders:updatedOrders});
    }

    fetchUserOrders=async(e)=>{
        try {
            this.setLoaderState(true);
            const axios = require("axios");
            const response = await axios(urls.getUserOrders, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            sortBy:this.state.sortBy?this.state.sortByOptions[this.state.sortBy]['value']:false
                        }
                })
            }, 7000);
            const res = await response.data;
            this.setLoaderState(false);
            if(!res.result.errorMessage && !res.error){
                let orders = res.result.data;
                let orderList = res.result.orderList;
                this.setState({orders,orderList});
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            this.setLoaderState(false);
            alert(err.message);
        }
    }

    changeOrderState=(orderId,state)=>{
        let orders = this.state.orders;
        let updatedOrder = {}
        if(state === 'Short Closed'){
            updatedOrder = {...orders[orderId],...{state,'show_short_close_btn':false}};
        }else{
            updatedOrder = {...orders[orderId],...{state}};
        }
        orders = {...orders,...{[orderId]:updatedOrder}};
        this.setState({orders});
    }

    goToCartPage=()=>{
        this.props.history.push(urls.extension + '/shop/cart');
    }

    changeUrlParams=(key,value)=>{
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        searchParams.delete(key)
        if(key && value)
            searchParams.append(key,value);
        searchParams = searchParams.toString();
        let newPageRoute = `?${searchParams}`;
        this.props.history.push(this.props.history.location.pathname +newPageRoute);
    }

    applySorting=(e)=>{
        let sortBy = e.target.value;
        this.setState({sortBy});
        this.changeUrlParams('sort_by',sortBy);
    }

    setLoaderState=(newState)=>{
        this.setState({fetchingData:newState});
    }

    render() { 
        return (
            <div className="my-orders">
                {this.state.orderList && this.state.orderList.length>0 &&
                    <>
                        <div className="my-orders__header">
                            <span>Sales Orders</span>
                            <span>
                            <div className="sort-by">
                                <InputLabel id="demo-simple-select-outlined-label">Sort By</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.sortBy}
                                    onChange={this.applySorting}
                                >
                                    {Object.keys(this.state.sortByOptions).map((option)=>(
                                        <MenuItem value={option}>{this.state.sortByOptions[option]['name']}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </div>
                            </span>
                        </div>
                        <div className="my-orders__cards">
                            {this.state.orderList.map((orderId,idx)=>(
                                <>
                                    <OrderCard 
                                        order={this.state.orders[orderId]} 
                                        changeOrderState={this.changeOrderState}
                                        goToCartPage={this.goToCartPage}
                                        history={this.props.history}
                                        changeOrderData = {this.changeOrderData}
                                        setLoaderState = {this.setLoaderState}
                                    />
                                </>
                                ))
                            }
                        </div>
                    </>
                }
                {this.state.orderList && this.state.orderList.length===0 &&
                    <div className="no-orders">
                        There are currently no orders.
                    </div>
                } 
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default MyOrders;
