import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import {getToCompanyData} from '../../../Shared/consoleServices';
// import {getProductOptions} from '../../../Shared/consoleServices';
import {getProductOptions} from '../../Shared/consoleServices';
import urls from '../../urls';


export function CompanyComparePopup(props) {


	const [input,GetInput]  = useState([]);
	const [state,setState]  = useState([]);
	const [selected,setSelected]  = useState([]);
    const [companies, setCompanies] = useState([])
    const [fetchingData, setFetchingData] = useState(false)


	useEffect(()=>{
		if(props.open && props.data){
            fetchCompanies(props.data.company_type, props.data.company_id)
		}
	},[props.open])
	
	const getMoreData = async () => {
			if(input){	
				let res = await getProductOptions(props.origin,props.id, props.type,input);
            	console.log(res);
				if(res.result.data){
                	const data = {...res.result.data.items};
                    // setSearchVal(data);
        	}
		}
	}
	
    async function fetchCompanies(companyTypeId,company_id,search_location_input=null,page=1){
        try {
            setFetchingData(true);
            const axios = require("axios");
            let url = urls.get_company_options;
            let params = {
                'companyTypeId':companyTypeId,
                'filterBy':false,
                'page':page,
				'company_id' :company_id
            };
            if(search_location_input){
                url = urls.searchCompany;
                params ={...params,...{search_location_input}};
            }
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(parseInt(res.result.statusCode) === 200){
                    console.log(res.result.companies);
                    setCompanies(res.result.companies);
                }
                else if(parseInt(res.result.statusCode) === 201){
                    alert(res.result.message);
                    // props.history.push(urls.extension + '/home');
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            setFetchingData(false);
        } 
        catch (err) {
            setFetchingData(false);
            alert(err.message);
        }
    }


    const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}


	const handleChange=(e,item)=>{
		if(state.includes(item)){
            let newState = state;
            let ind = newState.findIndex(e => e == item);
            newState.shift(ind,1);
            setState([...newState]);
        }
        else{
            setState([...state,item]);
        }
        
	}
	






	return (
		<div>
            {console.log(props)}
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
						Company Search
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder={'Enter Company name'}/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Name</th>
							</thead>
							<tbody>
								{companies.map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={state.includes(row.id)}
												onChange={(e)=>handleChange(e,row.id)}
											/>
										</td>
										<td>{row.name}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined"
                    disabled = {state.length>0?false:true}
                    onClick={()=>props.handleCompaniesDataPopupActions(state)}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handleCompaniesDataPopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
