import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './DataTransfer.css';
import '../console.css';
import {getToCompanyData} from '../../../Shared/consoleServices';



export default function CompanySearchPopup(props) {

	const [input,GetInput]  = useState('');
	const [state,setState]  = useState({selectedItem:null});
    const [searchVal, setSearchVal] = useState({})

	
	const getMoreData = async () => {
			if(input){	
				let res = await getToCompanyData({'search': input, 'selected_from_company': props.data.id});
            	if(res.result.search_values){
                	const to_company_id = {...res.result.search_values};
                    setSearchVal(to_company_id);
        	}
		}
	}
	

    const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}


	const handleChange=(e,item)=>{
		if(e.target.checked)
			changeState({selectedItem:item});
		else
			changeState({selectedItem:null});
	}
	


	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
							Company Search
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder='Enter Company name'/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Name</th>
							</thead>
							<tbody>
								{Object.values(searchVal).map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={state.selectedItem?.id === row['id']}
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{row['name']}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handleCompanyPopupActions(state.selectedItem)} autoFocus disabled={!state.selectedItem}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handleCompanyPopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
