import React from 'react';
import './ProductAttribute.css';
import { ProductAttributeForm, SaveProductAttribute } from '../../../../Shared/consoleServices';
import { changeAppTitle } from '../../../../Shared/utilities';
import Loader from '../../../../Components/Shared/Loader';
import { Button,TextField} from '@mui/material';
import urls from '../../../../urls';
import { Select,MenuItem } from '@material-ui/core';



class ProductAttributeFormView extends React.Component{
    state = {
        fetchingData : false,
        attributeId:null,
        attributeData:[],
        valuesData:[], 
        viewMode:true,
        updatedattributeData:{values:{}},
        attributeUpdated:false,
        errors:{}
    }

    componentDidMount(){
        changeAppTitle('Console | Product Attribute');
        const attributeId = this.props.match.params.attributeId;
        this.setState({attributeId});
        // if(attributeId!=='New')
            this.fetchattributeData({attributeId});
    }

    fetchattributeData=async(ids)=>{
        this.setState({fetchingData:true});
        let res  = await ProductAttributeForm(ids);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({attributeData: res.result.attribute_data,valuesData:res.result.values_data})
        }
        this.setState({fetchingData:false});
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedattributeData = this.state.updatedattributeData;
        updatedattributeData = {...updatedattributeData,...{[key]:value}};
        this.setState({updatedattributeData});
    }

    handleValidation=()=>{
        const name = this.state.updatedattributeData['name']||this.state.attributeData['name'];
        if(name=="" || name == undefined ){
            this.setState({errors:{name:'Name Cannot Be Empty'}});
            return false;
        }
        if(this.state.valuesData.length==0){
            alert('Values Table Cannot Be Empty');
            return false;
        }
        return true;
    }

    handleSubmit=async (e)=>{
        if(this.handleValidation()){
        if (this.state.updatedattributeData) {
                this.setState({ fetchingData: true });
                let res = {}
                res = await SaveProductAttribute({attributeId:this.state.attributeId,Updatedata:this.state.updatedattributeData});
                if (res.error || res.result.errorMessage) {
                    alert(res.error || res.result.errorMessage);
                }
                else{
                    this.setState({attributeUpdated:true});
                    // this.setState({productData:res.result.data.product,form_options:res.result.data.form_options});
                }
            }
            }

                this.setState({fetchingData:false});
    }
    
    discardPage = () => {
        this.props.history.goBack();
    }

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === "edit"){
            show = true;
        }
        return show;
    }

    getLineData=(field,row)=>{
        let updatedattributeData = this.state.updatedattributeData || {}; 
        let line = (updatedattributeData['values']&& updatedattributeData['values'][row.id]) || {};
        let value = (line[field]) || row[field];
        return(value);
    }

    addNewLine=()=>{
        let len = this.state.valuesData.length || 0
        let new_line = {
        id : `New${len}`,
        name:'',
        is_custom:false,
        company_name:this.state.attributeData.company_name,
        company_type_name:this.state.attributeData.company_type_name,
        type:'new'
        }
        
        let exp = [...this.state.valuesData,new_line]
        this.setState({valuesData:exp})
    }   

    changeValueLineData = (e,id)=>{
        e.preventDefault();
        let key = e.target.name;
        let value = e.target.value;
        console.log(id);
        let updatedattributeData = this.state.updatedattributeData;
        // let values = this.state.valuesData;
        // let row = values.find(i=>i.id==id)
        // console.log(row);
        updatedattributeData.values[id] = {[key]:value}
        // console.log(updatedattributeData);
        this.setState(updatedattributeData);
        // updatedattributeData['values'] = 
    }
    
    onClickDeleteRow = (id) => {
        let values = this.state.valuesData;
        let row = values.find(i=>i.id==id);
        let ind = values.findIndex(i=>i.id==id);
        console.log(row,'\n',ind)
        values.splice(ind,1);
        if(!id.toString().includes('New')){
            let updatedattributeData = this.state.updatedattributeData;
            updatedattributeData.values[`delete${id}`] = id
            this.setState({updatedattributeData});
        }
        this.setState({valuesData:values});
    }

    render(){
        return(
            <>
            <div className="console-form">
                {!this.state.attributeUpdated &&
                <form onSubmit={this.handleSubmit}>
                <header>
                    <span className="header__left">
                        <h3 className="console-form__heading">{}</h3>
                        {this.showBtn('edit') &&
                            <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                                {`${this.state.viewMode?"Edit":"Save"}`}
                            </Button>
                        }
                         {/* Ankit Start 18-11-2022 */}
                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.discardPage()} >Discard</Button>
                        {/* Ankit End 18-11-2022 */}
                    </span>
                    <span className="header__right">
                    </span>
            </header>
               <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                <span>
                            <div className="console-form__inputs" >
                                <label style={{padding:'5px'}} htmlFor="name">Attribute Name</label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.updatedattributeData['name']||this.state.attributeData['name']}
                                />
                                 
                                <span className="input-error">{this.state.errors["name"]}</span>
                               
                            </div>
                        </span>
             
             {/* Tree View For Values Ids */}
             <div className='table-div'>
                                {/* <table className="console-table-line"> */} 
                                {/* Ankit End 19 Jan 2023 */}
                                <table className='table-style' style={{marginBottom:'10px'}}>
                                    <thead>
                                        <th style={{padding:'6px'}}>Value</th>
                                        <th style={{padding:'6px'}}>Is_custom</th>
                                        <th style={{padding:'6px'}}>Company</th>
                                        <th style={{padding:'6px'}}>Company_type</th>
                                        <th style={{padding:'6px'}}></th>
                                    </thead>
                                    <tbody>
                                        {this.state.valuesData.map((row)=>(
                                            <tr>
                                                {/* <td>{row.name}</td> */}
                                                <td>
                                                <input 
                                                        className='table-input'
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        value={this.getLineData('name',row)}
                                                        onChange={(e)=>this.changeValueLineData(e,row['id'])}
                                                    />
                                                </td>
                                                <td>{row.is_custom?'True' : 'False'}</td>
                                                <td>{row.company_name}</td>
                                                <td>{row.company_type_name}</td>
                                                <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteRow(row.id)}>&#10006;</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                             {/* Ankit Start 19 Jan 2022 */}
                                </div>
                            
                                <Button style={{background:"#02A457",color:"beige"}} variant='secondary' onClick={() => this.addNewLine()}>
              Add New Line  
        </Button>

                </fieldset>
                
    </form>             
                }
                {this.state.attributeUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Products Attribute created successfully
                    </div>
                }

                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>

            </div>
            </>
        )
    }

}

export default ProductAttributeFormView;
