import React from 'react';
import './StoreOrder.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { fetchOrderData, saveOrderData } from '../../../Shared/consoleServices';
import { Button,  Typography  } from '@mui/material';
import { Select, MenuItem } from '@material-ui/core';
import Loader from '../../../Components/Shared/Loader';
import { Tab } from '@mui/material';
import { TabList, TabContext } from '@material-ui/lab';
import OrderLines from './OrderLines';
import { LocalShipping } from '@material-ui/icons';
import ReceiptIcon from '@mui/icons-material/Receipt';
import urls from '../../../urls';
import { ZoomIn } from '@material-ui/icons';
import { TextField, Grid, Checkbox, FormControlLabel } from '@mui/material';
class StoreOrder extends React.Component {

    state = {
        viewMode: true,
        unit_price_editable: false,
        sale_state: '',
        orderId: null,
        orderData: null,
        updatedOrderData: {},
        errors: {},
        order_form_options: {},
        fetchingData: false,
        updatingOrderData: false,
        selectedTab: "Order Lines",
        Tabs: ['Order Lines', 'Other Info'],
        sale_images: {},
        isOpen: false,
        image_set_url :"",
    }

    componentDidMount() {
        changeAppTitle('Console | Order');
        let orderId = this.props.match.params.orderId;
        this.setState({ orderId });
        this.fetchOrderData(orderId);

    }

    fetchOrderData = async (orderId) => {
        this.setState({ fetchingData: true });
        let res = await fetchOrderData(orderId);

        if (res.error || res.result.errorMessage) {
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({ orderData: res.result.data.order, order_form_options: res.result.data.order_form_options,
                 unit_price_editable: res.result.data.order.unit_price_editable ,
                sale_images: res.result.data.order['sale_images'] || {}
            });
            
        }
        this.setState({ fetchingData: false });

    }
    // performAction = async (action) => {
    handleShowDialog  =  async (e, image_url) => {
        await this.setState({ isOpen: !this.state.isOpen,image_set_url: image_url});
        console.log("cliked");
      };
   
   



    handleChange = (e, property = null, newValue = null) => {
        let key = property;
        let value = newValue;
        if (!newValue) {
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedOrderData = this.state.updatedOrderData;
        updatedOrderData = { ...updatedOrderData, ...{ [key]: value } };
        this.setState({ updatedOrderData });
    }

    handleSubmit = async (e) => {
        if (this.state.updatedOrderData && Object.keys(this.state.updatedOrderData).length > 0) {
            this.setState({ fetchingData: true });
            let res = await saveOrderData(this.state.orderData['id'], this.state.updatedOrderData);
            if (res.error || res.result.errorMessage) {
                alert(res.error || res.result.errorMessage);
            }
            else {
                this.setState({ orderData: res.result.data.order });
            }
            this.setState({ fetchingData: false, updatedOrderData: {} });
        }
    }

    changeViewMode = async () => {
        let viewMode = this.state.viewMode;
        this.setState({ viewMode: !viewMode });
        if (!viewMode) {
            this.handleSubmit();
        }
    }

    performAction = async (action) => {
        let updatedOrderData = this.state.updatedOrderData;
        updatedOrderData = { ...updatedOrderData, ...{ action } };
        await this.setState({ updatedOrderData });
        this.handleSubmit();
    }
    

    onTabChange = (e, newValue) => {
        this.setState({ selectedTab: newValue });
    }

    getTableData = () => {
        let data = null;
        let order_lines = this.state.orderData.order_lines;
        order_lines = { ...(this.state.updatedOrderData?.order_lines || {}), ...order_lines };
        if (Object.keys(order_lines).length > 0) {
            data = [];
            Object.keys(order_lines).forEach(lineId => {
                // Ankit Start 14 Feb 2023
                if (order_lines[lineId] != null){
                // Ankit End 14 Feb 2023
                    let newRow = order_lines[lineId];
                    data = [...data, newRow];
                }
            });
        }
        return data;
    }


    // Ankit Start 14 Feb 2023

    // removeFromAllowedCompanies = async (lineId, value=null) => {
    //     let updatedUserData = this.state.updatedOrderData;
    //     let company_ids = updatedUserData.order_lines;
    //     delete company_ids[lineId];
    //     updatedUserData = { ...updatedUserData, ...{ company_ids } }
    //     await this.setState({ updatedUserData });
    // }



    removeFromAllowedCompanies = async (lineId, value=null) => {
        let updatedUserData = this.state.updatedOrderData;
        let company_ids = updatedUserData.order_lines || {};
        if(company_ids.hasOwnProperty(lineId)){
            delete company_ids[lineId];
            updatedUserData = { ...updatedUserData, ...{ company_ids } }
            await this.setState({ updatedUserData });
        }else{
            let orderData = this.state.orderData;
            let order_lines = orderData.order_lines;
            let copy_to_updateOrder = {...order_lines[lineId]};
            delete order_lines[lineId];
            let updatedOrderData = { ...updatedUserData,['order_lines']:{ ...updatedUserData.order_lines,[lineId]: null}}
            orderData = { ...orderData, order_lines}
            await this.setState({ updatedOrderData,orderData });
        }
    }

    // Ankit End 14 Feb 2023

    changeOrderLineData = async (e, lineId, value=null,) => {
        
        if (value==-1 && typeof(lineId)=="string" && lineId.slice(0,3)=="New"){                    
            // let userOptions = this.updatedOrderData;    
            
            // delete this.updatedOrderData.order_lines[lineId]                        
            // this.setState({updatedOrderData:userOptions});
            // return
            let updatedUserData = this.updatedOrderData;
            let company_ids = updatedUserData.order_lines;
            delete company_ids[lineId];
            updatedUserData = { ...updatedUserData, ...{ company_ids } }
            await this.setState({ updatedUserData });
        //     const copyCarValues= {...this.state.CarValues}
        //     delete copyCarValues[key]
        //    this.setState({
        //       CarValues: copyCarValues,
        //    })

            // let selectedItems = this.updatedOrderData.order_lines;
            // let items = selectedItems[itemType];
            // delete items[parseInt(id)];
            // let updatedData = {[itemType]:items}
            // selectedItems = {...selectedItems,...updatedData};
            // this.setState({selectedItems});
        //     let updatedOrderData = this.state.updatedOrderData;
        // updatedOrderData = { ...updatedOrderData, ...{ [key]: value } };
        // this.setState({ updatedOrderData });
        }else{
        
        // Ankit Start bugid-1583 06-09-2022
        if (e.target.name === 'qty_delivered' && parseInt(e.target.value) > parseInt(e.target.max))
            return;
        // Ankit End 06-09-2022
        // Ankit Start bugid-1583 08-09-2022
        if (e.target.name === 'return_quantity' && parseInt(e.target.value) > parseInt(e.target.max))
            return;
        // Ankit End  bugid-1588 08-09-2022

        let order_lines = {};
        let line = {};
        if (this.state.updatedOrderData.hasOwnProperty('order_lines'))
            order_lines = this.state.updatedOrderData.order_lines;
        if (e) {
            let key = e.target.name;
            let value = e.target.value;
            if (order_lines.hasOwnProperty(lineId))
                line = order_lines[lineId];
            if (value)
                value = parseFloat(value)
            line = { ...line, ...{ [key]: value } };
        }
        else {
            line = null;
        }
        order_lines = { ...order_lines, ...{ [lineId]: line } };
        this.handleChange(null, 'order_lines', order_lines);
        }
    }

    addNewOrderLine = (new_line_data) => {
        let order_lines = {};
        // var new_keys = Object.keys(this.state.orderData.order_lines)
        // if (new_keys.indexOf("1373") > -1){
        //     return;
        // }

        for (const [key, value] of Object.entries(this.state.orderData.order_lines)) {
            //if (object1[key]["product_id"]["id"] ===492966){
            if (this.state.orderData.order_lines[key]["product_id"]["id"]== new_line_data['product_id']['id'])
            {
                alert(new_line_data['product_id']['name'] + " already exist in order lines")
                return;
            }                      
          }

        if (this.state.updatedOrderData.hasOwnProperty('order_lines'))
            order_lines = this.state.updatedOrderData.order_lines;
        let lineId = 'New0';
        let already_present = false;
        while (order_lines.hasOwnProperty(lineId)) {
            // if (order_lines[lineId].product_template_id ==new_line_data.product_template_id){
            if (order_lines[lineId]["product_id"]["id"] ==new_line_data["product_id"]["id"]){
                new_line_data['product_uom_qty'] = new_line_data['product_uom_qty'] + 1
                new_line_data['price_subtotal'] =  new_line_data['price_unit'] * new_line_data['product_uom_qty'] 
                already_present = true
                break
                     }
            lineId = lineId.split("")
            lineId = parseInt(lineId[lineId.length - 1]) + 1;
            lineId = 'New' + lineId;
        }
        new_line_data['sale_order_line_type']  ='so_snap'

        new_line_data = { ...new_line_data, ...{ 'id': lineId } };
        order_lines = { ...order_lines, ...{ [lineId]: new_line_data } };
        this.handleChange(null, 'order_lines', order_lines);
    }

    showBtn = (btnType) => {

        let show = false;
        if (btnType === "edit") {
            // # mohit  26-09-2022 start bug-ID -1539  issue of readonly of lines..
            // show = true; this was previous code...
            if (this.state.orderData.btnStatus.show_edit) {
                show = true;
            }
            // # mohit  26-09-2022 end bug-ID -1539  issue of readonly of lines..
        }
        else if (btnType === 'show_confirm') {

            if (this.state.orderData.btnStatus.show_confirm)
                show = true;
        }
        else if (btnType === 'show_done') {

            if (this.state.orderData.btnStatus.show_done)
                show = true;
        }
        else if (btnType === 'show_cancel') {
            if (this.state.orderData.btnStatus.show_cancel)
                show = true;
        }
        else if (btnType === 'show_create_invoice') {
            if (this.state.orderData.btnStatus.show_create_invoice)
                show = true;
        }
        else if (btnType === 'show_set_to_quotation') {
            if (this.state.orderData.btnStatus.show_set_to_quotation)
                show = true;
        }
        else if (btnType === 'delivery') {
            if (this.state.orderData.delivery_count)
                show = true;
        }
        else if (btnType === 'invoice') {
            if (this.state.orderData.invoice_count)
                show = true;
        }
        // Piyush Kumar 26/5/2023 
        else if (btnType === 'short_close') {
            if (this.state.orderData.btnStatus.show_short_close)
                show = true;
        }
        // Piyush Kumar End
        return show;
    }

    goToDeliveryPage = (orderId) => {
        let newPageRoute = '';
        if (this.state.orderData.delivery_count <= 1)
            newPageRoute = '/delivery/';
        else
            newPageRoute = '/pickings';
        this.props.history.push(this.props.history.location.pathname + newPageRoute);
    }

    goToInvoicePage = (orderId) => {
        let newPageRoute = '';
        if (this.state.orderData.invoice_count <= 1)
            newPageRoute = '/invoice/';
        else
            newPageRoute = '/invoices';
        this.props.history.push(this.props.history.location.pathname + newPageRoute);
    }


    render() {
        return (
            <div className="order-form">
                {this.state.orderData &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            {/* <span className="header__left"> */}
                            <span className="header__left" style={{ alignItems: 'flex-start', marginTop: 2 }}  >
                                {/* <h3 className="order-form__heading">{this.state.orderData['name']}</h3> */}
                                <Typography mr={1} sx={{ overflow: 'auto', wordWrap: 'break-word', fontSize: 15 }} variant="h6" component="h6">
                                    SO/ {this.state.orderData['name']}
                                </Typography>
                                {this.showBtn('edit') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.changeViewMode()}>
                                        {`${this.state.viewMode ? "Edit" : "Save"}`}
                                    </Button>
                                }
                                {/* Piyush Kumar 26/5/2023 */}
                                {this.showBtn('short_close') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.performAction("short_close")}>
                                        Short Close
                                    </Button>
                                }
                                {this.showBtn('show_set_to_quotation') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.performAction("set_to_quotation")}>
                                        Set to Quotation
                                    </Button>
                                }
                                {this.showBtn('show_create_invoice') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.performAction("create_invoice")}>
                                        Create Invoice
                                    </Button>
                                }
                                {this.showBtn('show_confirm') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.performAction("confirm")}>
                                        Confirm
                                    </Button>
                                }
                                {this.showBtn('show_done') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.performAction("done")}>
                                        Done
                                    </Button>
                                }
                                {this.showBtn('show_cancel') &&
                                    <Button style={{ background: "#ECECEC", color: "gray" }} onClick={() => this.performAction("cancel")}>
                                        Cancel
                                    </Button>
                                }
                                
                            </span>
                            <span className="header__right" style={{ alignItems: 'flex-start', marginTop: 2 }} >
                                {this.showBtn('delivery') &&
                                    <>
                                        <Button style={{ background: "#02A457", color: "beige", marginLeft: "15px" }} onClick={() => this.goToDeliveryPage(this.state.orderData['id'])}>
                                            <LocalShipping />
                                            &nbsp; Delivery
                                        </Button>
                                    </>
                                }
                                {this.showBtn('invoice') &&
                                    <>
                                        <Button style={{ background: "#02A457", color: "beige", marginLeft: "15px" }} onClick={() => this.goToInvoicePage(this.state.orderData['id'])}>
                                            <ReceiptIcon />
                                            &nbsp; Invoice
                                        </Button>
                                    </>
                                }
                            </span>
                        </header>
                        <fieldset disabled={true} style={{ border: "none" }}>
                            {/* <span> */}
                            {/* <div className="order-form__inputs" >

                                    <label htmlFor="order-form__sale_order_type">Order Type</label> */}

                            <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Order Type </Typography>}
                                        id="order-form__sale_order_type"
                                        name="sale_order_type"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['sale_order_type']}
                                        size="small"

                                    />
                                    <span className="input-error">{this.state.errors["sale_order_type"]}</span>
                                    {/* </div> */}
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    {/* <div className="order-form__inputs" > */}
                                    {/* <label htmlFor="carrier_id">Delivery Method</label> */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Delivery Method</Typography>}
                                        id="carrier_id"
                                        name="carrier_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['carrier_id']['name']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["carrier_id"]}</span>
                                    {/* </div> */}
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    {/* <div className="order-form__inputs" > */}
                                    {/* <label htmlFor="date_order">Quotation Date</label> */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Quotation Date </Typography>}
                                        id="date_order"
                                        name="date_order"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['date_order']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["date_order"]}</span>
                                    {/* </div> */}
                                    {/* </span> */}
                                </Grid>

                            </Grid>
                            {/* <span>
                                
                                    <label htmlFor="partner">Customer</label> */}
                            <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2} >
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Customer</Typography>}
                                        id="partner"
                                        name="partner"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['partner']['name']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["partner"]}</span>

                                </Grid>

                                <Grid item xs={12} md={2}>
                                    {/* <label htmlFor="partner_invoice_id">Invoice Address</label> */}
                                    {/* <input */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Invoice Address</Typography>}
                                        id="partner_invoice_id"
                                        name="partner_invoice_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['partner_invoice_id']['name']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["partner_invoice_id"]}</span>

                                </Grid>
                                <Grid item xs={12} md={2}>

                                    {/* <label htmlFor="partner_shipping_id">Shipping Address</label> */}
                                    {/* <input */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Shipping Address</Typography>}
                                        id="partner_shipping_id"
                                        name="partner_shipping_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['partner_shipping_id']['name']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["partner_shipping_id"]}</span>
                                </Grid>
                            </Grid>

                            {/* </span> */}
                            {/* <span> */}
                            <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2}>

                                    {/* <label htmlFor="payment_term_id">Payment Terms</label> */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Payment Terms </Typography>}
                                        id="payment_term_id"
                                        name="payment_term_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['payment_term_id']['name']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["payment_term_id"]}</span>
                                </Grid>
                                <Grid item xs={12} md={2}>

                                    {/* <label htmlFor="payment_mode">Payment Mode</label> */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Payment Mode </Typography>}
                                        id="payment_mode"
                                        name="payment_mode"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['payment_mode']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["payment_mode"]}</span>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    {/* <label htmlFor="payment_status">Payment Status</label> */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Payment Status </Typography>}
                                        id="payment_status"
                                        name="payment_status"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['payment_status']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["payment_status"]}</span>
                                </Grid>
                            </Grid>
                            {/* </span> */}


                            {/* <span> */}
                            <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2}>

                                    {/* <label htmlFor="name">SO Snaps</label> */}

                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> SO Snaps </Typography>}
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["name"]}</span>
                                    
                                </Grid>
                                <Grid item xs={12} md={2}>

                                    {/* <label htmlFor="pricelist_id">Pricelist</label> */}
                                    {/* <input */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Pricelist </Typography>}
                                        id="pricelist_id"
                                        name="pricelist_id"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.orderData['pricelist_id']['name']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["pricelist_id"]}</span>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {/* <label htmlFor="so_return_reason">Return Reason</label> */}
                                    {/* <input */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Return Reason </Typography>}
                                        id="so_return_reason"
                                        name="so_return_reason"
                                        type="text"
                                        onChange={this.handleChange}
                                        value={this.state.updatedOrderData['so_return_reason'] || this.state.orderData['so_return_reason']}
                                        size="small"
                                    />

                                    <span className="input-error">{this.state.errors["so_return_reason"]}</span>
                                </Grid>
                            </Grid>
                                        <div className="upload-image-holder">
                                                {Object.keys(this.state.sale_images).map((imgId, idx) => (
                                                    <div className="uploaded-image">
                                                        <img key="{imgId}" className="preview" onClick={(e) => this.handleShowDialog(e,urls.domain + this.state.sale_images[imgId])} src={urls.domain + this.state.sale_images[imgId]} alt="upload"  />
                                                        {/* <ZoomIn id="close"  /> */}
                                                        
                                                    </div>
                                                ))}
                                                {this.state.isOpen && (
                                                        <dialog
                                                            className="dialog"
                                                            style={{ position: "absolute" }}
                                                            open
                                                            
                                                        >
                                                            <img
                                                            className="image"
                                                            src={this.state.image_set_url}
                                                            
                                                            alt="upload"
                                                            />
                                                        </dialog>
                                                        )}
                                                                                               
                                            </div>

                            {/* </span> */}
                        </fieldset>
                        <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                            {/* <span> */}
                            <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >

                                {this.state.orderData['sale_order_type'] === 'return' &&
                                    // <div className="order-form__inputs" >
                                    <Grid item xs={12} md={2}>
                                        <label htmlFor="return_payment_mode">Return Mode</label>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper-label"
                                            onChange={this.handleChange}
                                            value={this.state.updatedOrderData['return_payment_mode'] || this.state.orderData['return_payment_mode']}
                                            name='return_payment_mode'
                                        >
                                            {Object.keys(this.state.order_form_options['return_payment_mode']).map((option) => (
                                                <MenuItem value={option}>
                                                    {this.state.order_form_options['return_payment_mode'][option]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <span className="input-error">{this.state.errors["payment_mode"]}</span>
                                        {/* </div> */}
                                    </Grid>
                                }

                                {/* Ankit Start 22 March 2023 */}
                                <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={1}  >
                              <Grid xs={12} md={5}>
                                    <TextField
                                        fullWidth
                                        label={<Typography variant="headline" style={this.labelStyle}> Note</Typography>}
                                        id="note"
                                        name="note"
                                        type="text"
                                        value={this.state.orderData['note']}
                                        disabled = {true}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["note"]}</span>
                                </Grid>
                                </Grid>
                                {/* Ankit 
                                

                                 22 March 2023 */}



                                {/* <div className="order-form__inputs" > */}
                                <Grid item xs={12} md={2}>
                                    {/* <label htmlFor="return_amount">Return Amount</label> */}
                                    {/* <input */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Return Amount</Typography>}
                                        id="return_amount"
                                        name="return_amount"
                                        type="number"
                                        min={0}
                                        onChange={this.handleChange}
                                        value={this.state.updatedOrderData['return_amount'] || this.state.orderData['return_amount']}
                                        size="small"
                                    />
                                    <span className="input-error">{this.state.errors["return_amount"]}</span>
                                </Grid>
                                {/* </div> */}
                                {/* <div className="order-form__inputs" > */}
                                <Grid item xs={12} md={2}>

                                    {/* <label htmlFor="validity_date">Expiration</label> */}
                                    {/* <label htmlFor="expected_date">Expected Delivery Date</label> */}
                                    {/* <input */}
                                    <TextField
                                        label={<Typography variant="headline" style={this.labelStyle}> Expected Delivery Date</Typography>}
                                        disabled
                                        id="validity_date"
                                        name="validity_date"
                                        type="date"
                                        onChange={this.handleChange}
                                        value={this.state.updatedOrderData['expected_date'] || this.state.orderData['expected_date']}
                                        size="small"
                                    />

                                    <span className="input-error">{this.state.errors["validity_date"]}</span>
                                </Grid>
                            </Grid>
                            {/* </div> */}
                            {/* </span> */}
                        </fieldset>
                        <TabContext value={this.state.selectedTab}>
                            <TabList
                                onChange={this.onTabChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                            >
                                {this.state.Tabs.map((tab) => (
                                    <Tab
                                        label={tab}
                                        value={tab}
                                    />
                                ))}
                            </TabList>
                        </TabContext>
                        <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                            <Grid item xs={12} sm={12} md={12} style={{ overflow: 'scroll' }}>
                                {this.state.selectedTab === 'Order Lines' &&
                                    <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                                        <OrderLines
                                            rows={this.getTableData()}
                                            changeOrderLineData={this.changeOrderLineData}
                                            removeFromAllowedCompanies={this.removeFromAllowedCompanies}
                                            updatedOrderData={this.state.updatedOrderData}
                                            viewMode={this.state.viewMode}
                                            saleOrderId={this.state.orderData['id']}
                                            addNewOrderLine={this.addNewOrderLine}
                                            unit_price_editable={this.state.unit_price_editable}
                                            // hideOrderLine = 
                                            saleOrderType={this.state.orderData['sale_order_type'] || false}
                                        // saleOrderType = {this.state.orderData['sale_order_type'] === 'order' ?  this.state.orderData['sale_order_type'] :false }
                                        />
                                        {/* // /* Bishu order total in sale order */}

                                        <table className="store-order-total" style={{ border: "1px" }}>
                                        <tbody>
                                            <tr>
                                                <th>Amount Untaxed</th><td>{this.state.orderData['amount_untaxed']?.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <th>Amount Tax</th><td>{this.state.orderData['amount_tax']?.toFixed(2)}</td>
                                            </tr>
                                            {/* Piyush Kumar 16/6/2023 added delievry  */}
                                            <tr>
                                                <th>Discount</th><td>{this.state.orderData['discount']?.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <th>Delivery</th><td>{this.state.orderData['delivery']?.toFixed(2)}</td>
                                            </tr>
                                            {/* Piyush Kumar End */}
                                            <tr>
                                                <th>Total Amount</th><td>{this.state.orderData['amount_total']?.toFixed(2)}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </fieldset>

                                }
                            </Grid>
                        </Grid>


                        {this.state.selectedTab === 'Other Info' &&
                            <div disabled={this.state.viewMode} style={{ border: "none" }}>
                                <fieldset disabled={true} style={{ border: "none" }}>
                                    <h4>Sales</h4>
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                        <Grid item xs={12} md={2}>

                                            {/* <label htmlFor="user_id">Salesperson</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Salesperson </Typography>}
                                                id="user_id"
                                                name="user_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['user_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["user_id"]}</span>
                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="team_id">Sales team</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Sales team </Typography>}
                                                id="team_id"
                                                name="team_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['team_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["team_id"]}</span>
                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="company_id">Company</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Company</Typography>}
                                                id="company_id"
                                                name="company_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['company_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["company_id"]}</span>
                                        </Grid>

                                    </Grid>
                                    {/* </span> */}
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                        <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="require_signature">Online signature</label> */}
                                            <FormControlLabel style={{ margin: 0, padding: 0 }} control={<Checkbox
                                                id="require_signature"
                                                name="require_signature"
                                                type="checkbox"
                                                onChange={this.handleChange}
                                                checked={this.state.orderData['require_signature']}
                                            />} label="Online signature" />

                                        </Grid>

                                        <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="require_payment">Online payment</label> */}
                                            <FormControlLabel style={{ margin: 0, padding: 0 }} control={<Checkbox
                                                id="require_payment"
                                                name="require_payment"
                                                type="checkbox"
                                                onChange={this.handleChange}
                                                checked={this.state.orderData['require_payment']}
                                            />} label="Online payment" />
                                        </Grid>
                                    </Grid>
                                    {/* </span> */}
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2}>
                                        
                                            {/* <label htmlFor="reference">Payment Ref.</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Payment Ref. </Typography>}
                                                id="reference"
                                                name="reference"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['reference']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["reference"]}</span>
                                        
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="client_order_ref">Customer Ref.</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Customer Ref. </Typography>}
                                                id="client_order_ref"
                                                name="client_order_ref"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['client_order_ref']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["client_order_ref"]}</span>
                                            </Grid>
                                            </Grid>
                                    {/* </span> */}
                                </fieldset>
                                <fieldset disabled={true} style={{ border: "none" }}>
                                    <h4>Invoicing</h4>
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="fiscal_position_id">Fiscal position</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Fiscal position </Typography>}
                                                id="fiscal_position_id"
                                                name="fiscal_position_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['fiscal_position_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["fiscal_position_id"]}</span>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                        
                                            {/* <label htmlFor="l10n_in_journal_id">Journal</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Journal </Typography>}
                                                id="l10n_in_journal_id"
                                                name="l10n_in_journal_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['l10n_in_journal_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["l10n_in_journal_id"]}</span>
                                            </Grid>
                                            </Grid>
                                    {/* </span> */}
                                </fieldset>
                                <fieldset disabled={true} style={{ border: "none" }}>
                                    <h4>Delivery</h4>
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                        <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="warehouse_id">Warehouse</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Warehouse </Typography>}
                                                id="warehouse_id"
                                                name="warehouse_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['warehouse_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["warehouse_id"]}</span>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                        
                                            {/* <label htmlFor="picking_policy">Shipping policy</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Shipping policy</Typography>}
                                                id="picking_policy"
                                                name="picking_policy"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['picking_policy']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["picking_policy"]}</span>
                                        
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="commitment_date">Delivery Date</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Delivery Date </Typography>}
                                                id="commitment_date"
                                                name="commitment_date"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['commitment_date']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["commitment_date"]}</span>
                                            </Grid>
                                            </Grid>
                                    {/* </span> */}
                                </fieldset>
                                <fieldset disabled={true} style={{ border: "none" }}>
                                    <h4>Reporting</h4>
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                <Grid item xs={12} md={2}>
                                            {/* <label htmlFor="origin">Source Document</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Source Document </Typography>}
                                                id="origin"
                                                name="origin"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['origin']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["origin"]}</span>
                                            </Grid>
                                            </Grid>
                                    {/* </span> */}
                                    {/* <span> */}
                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                    <Grid item xs={12} md={2}>
                                        
                                            {/* <label htmlFor="opportunity_id">Opportunity</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Opportunity </Typography>}
                                                id="opportunity_id"
                                                name="opportunity_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['opportunity_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["opportunity_id"]}</span>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                        
                                            {/* <label htmlFor="campaign_id">Campaign</label> */}
                                            
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Campaign </Typography>}
                                                id="campaign_id"
                                                name="campaign_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['campaign_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["campaign_id"]}</span>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                        
                                            {/* <label htmlFor="source_id">Source</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Source </Typography>}
                                                id="source_id"
                                                name="source_id"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.orderData['source_id']['name']}
                                                size="small"
                                            />
                                            <span className="input-error">{this.state.errors["source_id"]}</span>
                                            </Grid>
                                            </Grid>


                                    {/* </span> */}
                                </fieldset>

                            </div>
                        }
                    </form>
                }
                <Loader open={this.state.fetchingData || this.state.updatingOrderData} handleClose={() => { }} />
            </div>
        );
    }
}

export default StoreOrder;


