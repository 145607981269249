import React from 'react';
import Progessbar from '../CartPage/Progressbar';
import CartSummary from '../../Components/CartSummary/CartSummary';
import Addressess from '../../Components/Addressess/Addressess';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { withRouter } from "react-router";
import urls from '../../urls';
import './CheckoutPage.css';
import { AuthContext } from '../../Shared/context';
import { changeAppTitle } from '../../Shared/utilities';
import Loader from '../../Components/Shared/Loader';
import { validateDelivery } from '../../Shared/services';
import { Grid } from '@material-ui/core';

class CheckoutPage extends React.Component {

    static contextType = AuthContext;

    state = {
        action: null,
        addresses: null,
        loading: false
    }

    componentDidMount() {
        changeAppTitle('Shopaas | Checkout');
        let action = this.props.match.params.action;
        this.setState({ action });
        this.context.setNewAddress(null);
        this.setState({ loading: true });
    }


    addOrEditAddress = (address = null) => {
        let addressId = "";
        if (address) {
            addressId = address['id'];
            this.context.setNewAddress(address);
        }
        this.props.history.push(urls.extension + '/shop/address?id=' + addressId);
    }

    validateDelivery = async () => {
        this.setState({ loading: true });
        let res = await validateDelivery();
        this.setState({ loading: false });
        if (res?.result?.statusCode === '200')
            this.props.history.push(urls.extension + '/shop/payment');
        else
            alert(res);
    }

    setAddressess = (addresses) => {
        this.setState({ loading: false });
        this.setState({ addresses });
        if (!addresses || !addresses.partners || !addresses.billing_address || !addresses.partners.hasOwnProperty(addresses.billing_address)) {
            // this.props.history.push(urls.extension + '/shop/address?id=');
        }
        else if (!addresses.partners[addresses.billing_address]['street'])
            this.addOrEditAddress(addresses.partners[addresses.billing_address]);
    }

    checkAddresses = () => {
        let addresses = this.state.addresses;
        if (!addresses || !addresses.partners || !addresses.billing_address || !addresses.partners.hasOwnProperty(addresses.billing_address)) {
            return (true);
        }
        return (false);
    }

    render() {
        return (
            <div className="checkout-page">
                <Grid container xs={12} md={10} justifyContent="center">
                    <Grid container xs={12} md={12} justifyContent="flex-start">
                        <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                            <div className="progress-bar">
                                <Progessbar stage={2} />
                            </div>
                        </Grid>
                    </Grid>

                    <div className="checkout-details">
                        <Grid container xs={12} md={10} justifyContent="center">
                            <Grid container xs={10} md={8} justifyContent="flex-end">
                                <Grid item xs={12} md={12}  >
                                    <div className="checkout-details__left">
                                        <Addressess addOrEditAddress={this.addOrEditAddress} setAddressess={this.setAddressess} />

                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container xs={10} md={4} justifyContent="flex-start">
                                <Grid item xs={12} md={12} >
                                    <div className="checkout-details__right">
                                        <CartSummary />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container xs={12} md={12} justifyContent="flex-start">
                        <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                            <div className="navigation-btns">
                                <button className="btn__back"
                                    onClick={() => this.props.history.push(urls.extension + '/shop/cart')}
                                >
                                    <ArrowBack />  Return to Cart
                                </button>
                                <button
                                    className={`btn__next ${this.checkAddresses() ? 'disabled' : ''}`}
                                    disabled={this.checkAddresses()}
                                    onClick={() => this.validateDelivery()}
                                >
                                    Confirm  <ArrowForward />
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                    <Loader open={this.state.loading} handleClose={() => { }} />
                </Grid>
            </div>
        );
    }
}

export default withRouter(CheckoutPage);

