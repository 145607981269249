import React, { useState,useEffect } from "react";
import "./Login.css";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import shopaasLogo from '../../images/shopaas.png';
import { useAuth } from "../../Shared/context";
import urls from "../../urls";
import { changeAppTitle } from '../../Shared/utilities';
import Loader from '../../Components/Shared/Loader';

function Login(props) {

    const [Username, setUsername] = useState("");
    const [Password, setPassword] = useState("");
    // Bishu date: 1st oct 22 changes related to the auth login options
    const [Authdata, setAuthdata] = useState("");
    // Bishu ends here
    const [Error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const {custom_session_info,set_custom_session_info,setSignInData} = useAuth();

    useEffect(()=>{
        changeAppTitle('Shopaas | Login');
    },[]);

    async function fetchlogindata() {
        setLoading(true);
        setError("");
        console.log('this is it')
        try{
            const axios = require("axios");
            console.log('inside')
            const response = await axios(urls.authlogin, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            "login": Username,
                            "password": Password,
                        }
                })
            }, 7000);
            const res = await response.data;
            setLoading(false);
            setAuthdata(response.data.result.providers)
            // console.log(res)
            // console.log(response)
            console.log(response.data.result.providers)
            console.log(Authdata)
            
        }
        // Bishu ends here
        catch(err){
            setLoading(false);
            alert(err.message);
        }
    };
        
    async function SubmitBtn(){
        setLoading(true);
        setError("");
        try{
            const axios = require("axios");
            const response = await axios(urls.login, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            "login": Username,
                            "password": Password,
                        }
                })
            }, 7000);
            const res = await response.data;
            setLoading(false);
            if(!res.result.errorMessage && !res.error){
                let tempVar = {...custom_session_info,...{cart_quantity:res.result.custom_session_info['cart_quantity'],products:0},
                                ...{langs:res.result.custom_session_info['langs']},...{location:''},
                                // // Ankit start 8 Feb 2023
                                ...{user_type:res.result.custom_session_info['user_type']},
                                // // Ankit End 8 Feb 2023
                            };
                set_custom_session_info(tempVar);
                setError('Logged In');
                setSignInData(res.result);
                props.history.push(urls.extension + '/home');
            }
            else
                setError(res.result.errorMessage || res.error);
        }
        catch(err){
            setLoading(false);
            alert(err.message);
        }
    };


    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            if(Username && Password)
                SubmitBtn();
        }
    }

    return ( 
        <form className="form">
            <div className="application-icon">
                <img src={shopaasLogo} alt="application-icon"/>
            </div>
            <TextField
                className="input"
                id="standard-password-input"
                label="Username"
                required
                type="text"
                autoComplete="current-password"
                value={Username}
                onChange={(e) => setUsername(e.target.value)}
                // onKeyPress={handleKeyPress}
            />
            <TextField
                className="input input_2"
                id="standard-password-input"
                required
                label="Password"
                type="password"
                autoComplete="current-password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                style={{marginBottom:'25px'}}
                onKeyPress={handleKeyPress}
            />
            {Error &&
                <div className="login-error">
                    {Error}
                </div>
            }
            <Button 
                className="form__custom-button"
                onClick={SubmitBtn}
                disabled={!Username || !Password}
            >
                {loading?'Signing In':'Sign in'}
            </Button>
            <div className="link register">
                <span onClick={()=>props.history.push(urls.extension + '/register_company')}>
                    Register your company.
                </span>
            </div>
            <div className="link account">
                <span onClick={()=>props.history.push(urls.extension + '/signup')}>
                    Don't have an account?
                </span>
                <span onClick={()=>props.history.push(urls.extension + '/reset_password')}>
                    Reset Password
                </span>
            </div>
            {/* Bishu BI: 1376 1st Nov 22 changes related to the auth login providers */}
            <div className="link account">
                <span onClick={fetchlogindata}>Login Options</span>

            </div>
            {Authdata &&
            <div className="link account">
                {Authdata.map((user) => (
                <a className={user.css_class} href={user.auth_link}>{user.body}</a>
                )
                )}
            </div>
            }
            {/* Bishu ends here */}
            
            
            
            <Loader open={loading} handleClose={()=>{}}/>
        </form>
     );
}

export default Login;
