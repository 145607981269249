import React from 'react';
import './RenewMembership.css';
import { renewMembership } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { Select,MenuItem } from '@material-ui/core';
import { Button } from '@mui/material';
import urls from '../../../urls';

class RenewMembership extends React.Component {
    state = { 
        products:null,
        fetchingData:false,
        selectedItems:[]
     } 

    componentDidMount(){
        changeAppTitle('Renew Membership');
        this.renewMembership({'method':'GET'});
    }

    renewMembership=async(data)=>{
        this.setState({fetchingData:true});
        let res  = await renewMembership({data});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            if(data['method'] === 'GET')
                this.setState({products:res.result.products});
            else
                return(true);
        }
        this.setState({fetchingData:false});
    }

    handleOptionChange=async(variant_id,prod_id)=>{
        let products = this.state.products;
        let product = products[prod_id];
        product = {...product,...{'default':variant_id}};

        products = {...products,...{[prod_id]:product}};
        this.setState({products});
    }

    proceedToCart=async()=>{
        let data = {'products':[],'method':'POST'};
        let products = [];
        Object.keys(this.state.products).forEach((prod_id)=>{
            if(this.state.selectedItems.includes(prod_id))
                products = [...products,this.state.products[prod_id]['default']];
        })
        data = {...data,...{products}};
        let res = await this.renewMembership(data);
        if(res)
            this.props.history.push(urls.extension + '/shop/cart');
    }

    selectItem=(item)=>{
        let temp = this.state.selectedItems;
        let selectedItems = [];
        if(temp.includes(item))
            selectedItems = temp.filter((i)=>i!==item);
        else
            selectedItems = [...temp,item];
        this.setState({selectedItems});
    }

    render() { 
        return (
            <div className='renew-form'>
                {this.state.products &&
                    <>
                        <table className="renew-table-line">
                            <thead style={{top:'20'}}>
                                <th style={{padding:'6px'}}>Select</th>
                                <th style={{padding:'6px'}}>Service</th>
                                <th style={{padding:'6px'}}>Option</th>
                                <th style={{padding:'6px'}}>Price</th>
                            </thead>
                            <tbody>
                                {Object.keys(this.state.products).map((prod_id,idx)=>(
                                    <tr>
                                        <td>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={this.state.selectedItems.includes(prod_id)}
												onChange={()=>this.selectItem(prod_id)}
											/>
										</td>
                                        <td>{this.state.products[prod_id]['name']}</td>
                                        <td>
                                            {Object.keys(this.state.products[prod_id]['variants']).length>1 &&
                                            <Select
                                                id={prod_id}
                                                onChange={(e)=>this.handleOptionChange(e.target.value,prod_id)}
                                                value = {this.state.products[prod_id]['default']}
                                            >
                                                {Object.keys(this.state.products[prod_id]['variants']).map((variant_id)=>(
                                                    <MenuItem value={variant_id}>
                                                        {this.state.products[prod_id]['variants'][variant_id]['name']}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            }
                                        </td>
                                        <td>
                                            {this.state.products[prod_id]['variants'][this.state.products[prod_id]['default']]['price']}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button 
                            variant="contained" 
                            onClick={()=>this.proceedToCart()}
                            disabled={this.state.selectedItems.length<=0}
                        >
                            Proceed to cart
                        </Button>
                    </>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default RenewMembership;
