import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Order from './OrderCard';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',

    background: isDragging ? 'lightgreen' : '',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    padding: grid,
    display:'flex',
    flexDirection:'column',
    gap:'5px',
    minWidth:'200px'
});

const kanbanHeader = {
    width:"100%",
    fontWeight:"500",
    fontSize:"16px",
};

class Kanban extends React.Component {
    state = {
        columns:[]
    };

    componentDidMount(){
        let columns = Object.keys(this.props.data);
        columns = columns.filter(col=>col!=='Quotation');
        this.setState({columns});
    }

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }


        if (source.droppableId !== destination.droppableId) {
            this.props.moveOrder(source,destination);
        } 
        else{
            return ;
        }
    };


    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                {this.state.columns.map((column)=>(
                    <Droppable droppableId={column}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                <div style={kanbanHeader}>
                                    <span>
                                        {column}({this.props.data[column].orderList.length})
                                    </span>
                                     <hr />
                                </div>
                                {this.props.data[column].orderList && this.props.data[column].orderList.map((orderId, index) => (
                                    <Draggable
                                        key={String(orderId)}
                                        draggableId={String(orderId)}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <span
                                                ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                <Order 
                                                    order={this.props.data[column].orders[orderId]} 
                                                    goToOrder={this.props.goToOrder}
                                                />
                                            </span>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ))}
            </DragDropContext>
        );
    }
}

export default Kanban;