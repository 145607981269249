import React from 'react';
import './Promotion_Program.css';
import '../console.css';
import {fetchPromotionData,savePromtionData} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button, Grid, Typography ,TextField } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import urls from "../../../urls";
import SearchIcon from '@mui/icons-material/Search';



class Promotion_tree extends React.Component {
    
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getMoreData();            

        }        
    }


    state = {
        viewMode:true, 
        promotion_name:'',
        promotionData:null,
        promotion_name:'',
        fetchingData:false,
        form_options:{},
        partner_id:null,
        updatedPromotionData:{},
        productData:[],
        input_val:[],
        allow_customer:[],
        allow_product:[],
        // Ankit Start 28 April 2023
        SearchInput:[],
        // Ankit End 28 April 2023
        
    }
    componentDidMount(){
        this.fetchPromotionData();
    }
    // Ankit Start 28 April 2023
    // fetchPromotionData=async()=>{
    //     this.setState({fetchingData:true});
    //     let res  = await fetchPromotionData();
    //     if(res.error || res.result.errorMessage){
    //         alert(res.error || res.result.errorMessage);
    //     }
    //     else{
    //         this.setState({promotionData:res.result.promotionData,form_options:res.result.form_options});
    //         this.setState({fetchingData:false});
    //     }
    //     this.setState({fetchingData:false});
    // }


    fetchPromotionData=async(search=null)=>{
        this.setState({fetchingData:true});
        let res  = await fetchPromotionData(null,search);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({promotionData:res.result.promotionData,form_options:res.result.form_options});
            this.setState({fetchingData:false});
        }
        this.setState({fetchingData:false});
    }
    // Ankit End 28 April 2023

    goToProduct=(productId=false)=>{
        this.props.history.push(urls.extension + '/console/promotion_program/' + (productId || 'New'));
    }


    getMoreData = () =>{
		
		this.fetchPromotionData(this.state.SearchInput)
        
	}
    GetInput = (SearchInput=null) =>{
        this.setState({SearchInput})
    }

    render() { 
        return (
            <>
            {/* create function  start here */}
            <div style={{width:"100%", display:'flex'}}>
                    <Button 
                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.goToProduct()}
                    >
                        Create 
                    </Button>
                    {/* {this.state.products && Object.keys(this.state.products).length>0 &&
                        <div className="products-pagination" style={{float:"right"}}>
                            <span>
                                <ArrowBack onClick={()=>this.changeProductsPage(this.state.productPage-1)} style={{cursor:'pointer'}}/>
                                <span>{`${(this.state.productPage * 50)+1}-${(this.state.productPage * 50) + Object.keys(this.state.products).length}/${this.state.total_products}`}</span>
                                <ArrowForward onClick={()=>this.changeProductsPage(this.state.productPage+1)} style={{cursor:'pointer'}}/>
                            </span>
                        </div>
                    } */}

                    <Grid item  sx={{ px: '2rem' }} >

                    <TextField onChange={(e) => this.GetInput(e.target.value)} 
                    id="outlined-search" label="Enter Promotion Name" 
                    name='searchbar'   
                    onKeyDown={this._handleKeyDown}                     

                    type="search" size="small" InputProps={{
                        endAdornment: (
                            <SearchIcon onClick={this.getMoreData} size="Normal"/>
                        ),}} >


                    </TextField>
                    </Grid>
{/* Ankit End 27 April 2023 */}
                </div>
            {/* create function end here */}
            {/* tree view start here  */}
            <div>
            {this.state.form_options.sal_coupan_grp && 
            <table className="order-table">
                        <thead>
                            <tr>
                                <th style={{textAlign:'center',padding:'6px'}}>Name</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Promo Code</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Start Date</th>
                                <th style={{textAlign:'center',padding:'6px'}}>End Date</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        this.state.form_options.sal_coupan_grp.map((elem,ind) => {
                            return(
                                <tr key={ind} onClick={()=>this.goToProduct(elem.id)} >
                                <td style={{textAlign:'center'}}>{elem.name}</td>
                                <td style={{textAlign:'center'}}>{elem.promo_code}</td>
                                <td style={{textAlign:'center'}}>{elem.rule_date_from}</td>
                                <td style={{textAlign:'center'}}>{elem.rule_date_to}</td>
                                <td>
                                <input
                                    name="active-product"
                                    type="checkbox"
                                    disabled={true}
                                    checked={elem.active}
                                    />
                                </td>
                                </tr>
                                )
                            } )
                        }
                        </tbody>
                    </table>
            }
            <Loader open={this.state.fetchingData } handleClose={()=>{}}/>
            </div>
            {/* tree view end here */}
            
            </>
        );

    }

}
export default Promotion_tree;
