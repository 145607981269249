import React from 'react';
import './UserProfile.css';
import './nav.css';
import { AuthContext } from '../../Shared/context';
import urls from '../../urls';
import { ClickAwayListener } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
import Loader from '../../Components/Shared/Loader';

// for multilingual support
import { Translation } from 'react-i18next'
import i18next from 'i18next';
// Ankit Start 26-12-2022
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
// Ankit End 26-11-2022


class UserProfile extends React.Component {

    static contextType = AuthContext;

    state = {
        userOptions: {
            1: 'My Orders',
            2: 'Manage Addresses',
            3: 'Help and Support',
            4: 'My Coupons',
            5: 'My Account',
            6: 'Logout',
            7: 'Go to Console',
        },
        // Ankit Start 26-12-2022
        // showUserOption: false,
        showUserOption:false,
        // Ankit End 26-12-2022
        fetchingData: false,
        // Ankit Start 8 feb 2023
        toggleUser: false,
        // Ankit End 8 Feb 2023
    }

    // Ankit Start 8 Feb 2023
    componentDidUpdate(prevProps) {
        if (prevProps.signInData !== this.props.signInData) {
            if (this.props.signInData && this.props.signInData.user_companies && this.props.signInData.user_companies.current_company) {
                this.internalUserFeatures(true);
            }
            else {
                this.internalUserFeatures(false);
            }
             // Ankit Start 8 Feb 2023
            //  if(this.context.custom_session_info.user_type == 'internal'){
            //     this.addUserToggle()
            // }else{
            //     this.addUserToggle(true)
            // }
            // Ankit End 8 Feb 2023
        }
    }

    
    componentDidMount() {
        if (!this.context.signInData)
            this.getSessionInfo();
        
        // Ankit Start 19 Jan 2023
        if (this.props.mobileView){
            this.setState({showUserOption:true});
        }
        // Ankit End 19 Jan 2023
       
    }

    // addUserToggle =(remove=false)=>{
    //     let userOptions = this.state.userOptions;
    //     console.log(remove)
    //     if (!remove){
    //     if (this.props.signInData && this.props.signInData.user_companies && this.props.signInData.user_companies.current_company) {
    //         userOptions = {...userOptions,...{ 0: 'Become Customer' }}
    //     }else{
    //         userOptions = {...userOptions,...{ 0: 'Become Employee' }}
    //     }
    //     }else{
    //         if(userOptions.hasOwnProperty(0)){
    //             delete userOptions[0];
    //         }
    //     }

    //     this.setState({ userOptions });
    // }

    // Ankit start 13 Feb 2023
    // internalUserFeatures(enable) {
    //     let userOptions = this.state.userOptions;
    //     if (enable)
    //         userOptions = { ...userOptions, ...{ 7: 'Go to Console' } }
    //     else
    //         delete userOptions[7];
    //     this.setState({ userOptions });
    // }

    internalUserFeatures(enable) {
        let userOptions = this.state.userOptions;
        if (enable){
            userOptions = { ...userOptions, ...{ 0: 'Become Customer',7: 'Go to Console' } }
        }
        else{
            delete userOptions[7];
            if(this.context.custom_session_info.user_type == 'internal'){
                userOptions = { ...userOptions, ...{ 0: 'Become Employe'}}
            }else{
                if(userOptions.hasOwnProperty(0)){
                    delete userOptions[0];
                }
            }
        }

        // Ankit Start 06-09-2023 (Admin User Cannot Switch between company and user)
        if (this.context?.signInData?.uid == 2){

            if(userOptions.hasOwnProperty(0)){
                delete userOptions[0];
            }
        }
        // Ankit Start 06-09-2023

        this.setState({ userOptions });
    }
    // Ankit End 13 Feb 2023

    getSessionInfo = async () => {
        try {
            const axios = require("axios");
            const response = await axios(urls.getSessionInfo, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            if (res && res.result.custom_session_info) {
                let custom_session_info = this.context.custom_session_info;
                custom_session_info = {
                    ...custom_session_info, ...{ products: res.result.custom_session_info['compare'].length },
                    ...{ cart_quantity: res.result.custom_session_info['cart_quantity'] },
                    ...{ langs: res.result.custom_session_info['langs'] },
                    // Ankit start 8 Feb 2023
                    ...{user_type:res.result.custom_session_info['user_type']},
                    // Ankit End 8 Feb 2023
                    ...{ location: res.result.custom_session_info?.location || '' }
                };

                this.context.set_custom_session_info(custom_session_info);
            }
            if (!res.result.errorMessage && !res.error) {
                this.context.setSignInData(res.result.session_info);
            }
        }
        catch (err) {
        }
    }

    logout = async () => {
        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.logout, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                this.context.setSignInData(null);
                let custom_session_info = this.context.custom_session_info;
                custom_session_info = { ...custom_session_info, ...{ products: 0, cart_quantity: 0, location: '' } };
                this.context.set_custom_session_info(custom_session_info);
                this.props.history.push(urls.extension + '/home');
            }
            else
                alert(res.result.errorMessage || res.error)
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
        }
    }

    // Ankit Start 8 Feb 2023
    toggleCompanyUser=async()=>{
        let data = {}
        if (this.props.signInData && this.props.signInData.user_companies && this.props.signInData.user_companies.current_company) {
            data = {become:'portal'}
        }else{
           data = {become:'internal'}
        }

        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.toggleCompanyUser, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: data
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                await this.getSessionInfo();
                
                if (data.become == 'internal'){
                    this.internalUserFeatures(true);
                }
                else{
                    this.internalUserFeatures(false);
                }
                // await this.addUserToggle();
            }
            else
                alert(res.result.errorMessage || res.error)
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
        }
        
    }
    // Ankit End 8 Feb 2023


    toggleShowUserOption = () => {
        this.setState({ showUserOption: !this.state.showUserOption });
    }

    handleClickAway = () => {
        this.toggleShowUserOption();
    }

    onSelectUserOption = (option) => {
        this.toggleShowUserOption();

        option = parseInt(option);
        this.toggleShowUserOption();
        switch (option) {
            case 1:
                this.props.history.push(urls.extension + '/my/orders');
                break;
            case 2:
                this.props.history.push(urls.extension + '/my/address');
                break;
            case 3:
                this.props.history.push(urls.extension + '/help');
                break;
            case 4:
                this.props.history.push(urls.extension + '/my/coupons');
                break;
            case 5:
                this.props.history.push(urls.extension + '/my/account');
                break;
            case 6:
                this.logout();
                break;
            case 7:
                this.props.history.push(urls.extension + '/console');
                break;
            // Ankit Start 8 Feb 2023
            case 0:
                this.toggleCompanyUser();
                break;
            // Ankit End 8 Feb 2023
            default:
                alert('wrong input');
        }
    }


    handleLanguageChange(lang) {
        i18next.changeLanguage(lang);
        let UserLang = this.context.UserLang;
        UserLang = { ...UserLang, ...{ lang1: lang}}
        this.context.setUserLang(UserLang);
    }

    render() {
        return (
            <Translation>

                    {(t, { i18n }) =>
                        <>

                            {this.props.mobileView &&
                                <>
                                {!this.context.signInData && this.props.mobileView &&
                                <ListItemButton
                                    onClick={() => {this.props.goToSignInPage(); this.props.toggleOpenState(false)}}
                                    className="sign-in"
                                >
                                    
                                 <ListItemText primary={t('Sign In')} />
                                </ListItemButton>
                                }
                                {this.context.signInData && this.state.showUserOption &&
                                                <>
                                                    {Object.keys(this.state.userOptions).map((option) => (
                                                        // <span>
                                                            <ListItemButton className="option" onClick={() => {this.onSelectUserOption(option); this.props.toggleOpenState(false)}}>
                                                             <ListItemText primary={t(this.state.userOptions[option])}/>
                                                            </ListItemButton>
                                                        // </span>
                                                    ))
                                                    }
                                                </>
                                        }
                                    <Divider sx={{ mb: 1 }} />
                                    {this.context.custom_session_info?.langs && this.props.mobileView &&
                                <span>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Language Option"
                                        defaultValue={localStorage.getItem('i18nextLng') || "en"}
                                        onChange={(e) => this.handleLanguageChange(e.target.value)}
                                        style={{ color: "black", fontSize: '11px', fontWeight: 'bold', marginLeft: '5px' }}
                                    >
                                        {Object.values(this.context.custom_session_info.langs.langs).map((lang) => (
                                            <MenuItem value={lang['name']}>
                                                {lang['name']}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </span>
                            }
                                    
                                </>
                            }

                            {!this.context.signInData && !this.props.mobileView &&
                                <button
                                    onClick={() => this.props.goToSignInPage()}
                                    className="sign-in"
                                >
                                    {t('Sign In')}
                                </button>
                            }
                            {this.context.signInData && !this.props.mobileView &&
                                <span className="user-options">
                                    <span className="sign-in userName" onClick={() => this.toggleShowUserOption()}>
                                        {this.context.signInData['name']}
                                    </span>
                                    {this.state.showUserOption &&
                                        <ClickAwayListener onClickAway={this.handleClickAway}>
                                            <div>
                                                {Object.keys(this.state.userOptions).map((option) => (
                                                    <span>
                                                        <button className="option" onClick={() => this.onSelectUserOption(option)}>
                                                            {t(this.state.userOptions[option])}
                                                        </button>
                                                        <br />
                                                    </span>
                                                ))
                                                }
                                            </div>
                                        </ClickAwayListener>
                                    }
                                </span>
                            }
                            {this.context.custom_session_info?.langs && !this.props.mobileView &&
                                <span>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Language Option"
                                        defaultValue={localStorage.getItem('i18nextLng') || "en"}
                                        onChange={(e) => this.handleLanguageChange(e.target.value)}
                                        style={{ color: "black", fontSize: '11px', fontWeight: 'bold', marginLeft: '5px' }}
                                    >
                                        {Object.values(this.context.custom_session_info.langs.langs).map((lang) => (
                                            <MenuItem value={lang['name']}>
                                                {lang['name']}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </span>
                            }

                            <Loader open={this.state.fetchingData} handleClose={() => { }} />
                        </>
                    }
            </Translation>
        );
    }
}

export default UserProfile;

