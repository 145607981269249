import React, { useEffect } from 'react';
import './Home.css';
import urls from '../../urls';
import {HomePageSliders} from '../../Components/Sliders/Sliders';
import { changeAppTitle } from '../../Shared/utilities';
import {useAuth} from '../../Shared/context';

function Home(props) {

    const [sliders, setSliders] = React.useState(null);
    const [companyTypes, setCompanyTypes] = React.useState([]);
    const {signInData} = useAuth();


    useEffect(()=>{
        changeAppTitle('Shopaas | Home');
        fetchCompanyTypes();
        if(!sliders && companyTypes.length===0)
            fetchSliders();
    },[signInData])

    async function fetchCompanyTypes(){
        try {
            setCompanyTypes([]);
            const axios = require("axios");
            const response = await axios(urls.getHomepageData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({})
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                setCompanyTypes(res.result.companyTypes);
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }

    async function fetchSliders(){
        try {
            const axios = require("axios");
            const response = await axios(urls.getSliders, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({})
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error)
                setSliders(res.result.data.sliders);
        } 
        catch (err) {
            console.log(err.message);
        }
    }

    function clickHandler(companyType){
        let newPageRoute = companyType['name'].replace('/',',') + '-' + companyType['id'];
        props.history.push(urls.extension + '/home/'+newPageRoute);  
    }

    return (
        <div className="home">
            {sliders &&
                    <HomePageSliders 
                        items={sliders['main_slider']} 
                        autoPlay={true}
                        className="top"
                        isSingle = {sliders['isSingle']}
                    />
            }
            <div className="company-types">
                {companyTypes.map((companyType,idx)=>(
                    <div 
                        className="company-type"
                        onClick={()=>clickHandler(companyType)}
                    >
                        <img src={urls.domain + companyType.image} alt={companyType.name} />
                        <span>{companyType.name}</span>
                    </div>
                ))
                }
            </div>
            {sliders && Object.keys(sliders['homepage_sliders']).map((sliderId)=>(
                    <>
                        <HomePageSliders 
                            title={sliders['homepage_sliders'][sliderId]['name']}
                            items={sliders['homepage_sliders'][sliderId]['sliders']} 
                            isSingle = {sliders['homepage_sliders'][sliderId]['isSingle']}
                            autoPlay={false} 
                            className="bottom"
                        />
                        <br/>
                    </>
            ))
            }
        </div>
    );

}

 
export default Home;
