import React from 'react';
import { CompareArrows } from '@material-ui/icons';
import { AuthContext } from '../../Shared/context';
import './ProductCompare.css';
import { withRouter } from "react-router";
import urls from '../../urls';
import { changeAppTitle } from '../../Shared/utilities';
import { addToCompare } from '../../Shared/services';
import ProductCard from '../../Components/Shared/ProductCard';
import { Button } from '@mui/material';

class ProductCompare extends React.Component {

    static contextType = AuthContext;

    state = {
        showPage:this.props.location.pathname.includes('product_compare'),
        products:[],
        attributes:null
    }

    componentDidMount(){
        if(this.state.showPage){
            changeAppTitle('Shopaas | Products Compare');
            this.getComparisionData();
        }
    }

    goToProductComparePage=()=>{
        this.props.history.push(urls.extension + '/product_compare');
    }

    getComparisionData = async () => {
        let res = await addToCompare(null,'GET');
        if(res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if(res.result.statusCode === 201)
            alert(res.result.message);
        else{
            this.setState({products:res.result.compareProducts,attributes:res.result.attributes});
            console.table(res.result.attributes);
            let custom_session_info = this.context.custom_session_info;
            custom_session_info = {...custom_session_info,...{products:custom_session_info['products']}};
            this.context.set_custom_session_info(custom_session_info);
        }
    }

    removeProduct=async(product)=>{
        let res = await addToCompare(product['defaultVariant'],'POST',false);
        if(res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if(res.result.statusCode === 201)
            alert(res.result.message);
        else{
            let products = this.state.products;
            products = products.filter(prod=>prod['id']!==product['id']);
            this.setState({products});
            let custom_session_info = this.context.custom_session_info;
            custom_session_info = {...custom_session_info,...{products:res.result.compareCount}};
            this.context.set_custom_session_info(custom_session_info);
        }
    }

    productClickHandler=()=>{}

    getAttributeValue=(prod,attr)=>{
        let attrs = Object.keys(this.state.attributes[attr]);
        let isAttrValue = attrs.find(value => prod.combination_indices.includes(parseInt(value)));
        if(isAttrValue)
            return(this.state.attributes[attr][isAttrValue]);
        return("");
    }

    render() { 
        return (
            <>
                {this.context.custom_session_info['products']>0 && !this.state.showPage &&
                    <div className="compare-btn" onClick={()=>this.goToProductComparePage()}>
                        <CompareArrows />
                        <div>Compare</div>
                        <div className="compare-count">{this.context.custom_session_info['products']}</div>
                    </div>
                }
                {this.state.showPage &&
                    <div className="product-compare">
                        <div className="product-compare-table">
                            {this.state.products.length>0 &&
                                <table>
                                    <caption>Compare Products</caption>
                                    <tr>
                                        <th></th>
                                        { this.state.products.map((prod)=>(
                                            <th>
                                                {this.state.products.length>1 &&
                                                    <Button 
                                                        onClick={()=>this.removeProduct(prod)} 
                                                        className="delete-icon"
                                                        style={{backgroundColor:"#00A09D"}}
                                                    >
                                                        Remove
                                                    </Button>
                                                }
                                                <ProductCard 
                                                    prod={prod}
                                                    compare='compare'
                                                    history={this.props.history}
                                                    showCompareIcon={false}
                                                    clickHandler = {this.productClickHandler}
                                                />
                                            </th>
                                            ))
                                        }
                                    </tr>
                                    {this.state.attributes && Object.keys(this.state.attributes).map((attr)=>(
                                        <tr>
                                            <th>{attr}</th>
                                            {this.state.products.map((prod)=>(
                                                <td>{this.getAttributeValue(prod,attr)}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </table>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}
 
export default withRouter(ProductCompare);
