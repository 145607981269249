import React from 'react';
import './StoreProduct.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { storeProducts, saveStoreProducts } from '../../../Shared/consoleServices';
// import {  } from '@mui/material';
import { Select, MenuItem } from '@material-ui/core';
import Loader from '../../../Components/Shared/Loader';
import TaxesPopup from './TaxesPopup';
import Chip from '@material-ui/core/Chip';
import urls from "../../../urls";
import { Tab,Alert } from '@mui/material';
import { TabList, TabContext } from '@material-ui/lab';
import VariantSelection from './VariantProduct';
// Ankit Start bugid-1614 27-09-2022
import ExtraMediaPopup from './ExtraMediaPopup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { Button, CardActionArea, Input, TextField, Grid, FormControl, InputLabel, Checkbox, FormControlLabel, Typography } from '@mui/material';
// Ankit End bugid-1614 27-09-2022
// Ankit Start 27 April 2023
import ProductDataPopup from './productDataPopup';
// Ankit End 27 April 2023


class StoreProduct extends React.Component {

    state = {
        viewMode: true,
        productId: null,
        productData: null,
        updatedProductData: {},
        errors: {},
        form_options: {},
        fetchingData: false,
        updatingProductData: false,
        productUpdated: false,
        popupData: { open: false },
        selectedTab: "General Info",
        // Ankit  bugid-1564 Start 24-08-2022 Added eCommerce Tab  
        // Tabs:['General Info','Variants','Extra Media']
        // Piyush Kumar  bugid-1564 Start 24-08-2022 Added sales Tab 
        // Tabs:['General Info','Variants','Extra Media','eCommerce'],
        Tabs: ['General Info', 'Variants', 'sales', 'eCommerce', 'inventory'],
        // Piyush Kumar End
        popupName: "",
        // Ankit bugid-1564 End 24-08-2022 Added eCommerce Tab
        // Ankit Start bug-id 1600
        VariantProductData: [],
        // Ankit End bug-id 1600
        // Ankit Start bug-1d 1614
        AddExtraMedia: { open: false },
        ExtraMediaData: [],
        UpdatedExtraMediaData: [],
        ExtraMedia: false,
        // Ankit End Bug-id 1614
        // Ankit start bugid-1674
        ecommerce_api: false,
        // Ankit End bugid-1674
        server_msg: false,
        severity: false,
        // Ankit Start 27 April 2023
        productFormData:{open:false,form_data:null,origin:null,selected_value:null},
        // Ankit End 27 April 2023
        // Ankit Start 12 May 2023
        ExtraMediaFormData:{}
        // Ankit End 12 May 2023
    }
    labelStyle = { fontSize: 18 }
    labelStyleSelect = { fontSize: 15 }

    componentDidMount() {
        changeAppTitle('Console | Product');
        let productId = this.props.match.params.productId;
        if (productId =='New'){
        this.setState({ viewMode: false });
        }
        this.setState({ productId });
        
        this.fetchProductData(productId);
    }

    fetchProductData = async (id) => {
        this.setState({ fetchingData: true });
        let res = await storeProducts({ id });
        if (res.error || res.result.errorMessage) {
            alert(res.error || res.result.errorMessage);
        }
        else {
            // // Ankit Start bugid-1613 28-09-2022 (Added extra_media in state)
            // if ('extra_media' in res.result.data) {
            //     const ExtraMediaData = res.result.data.extra_media
            //     this.setState({ ExtraMediaData, ExtraMedia:true });
            // }
            // // Ankit End bugid-1613 28-09-2022
            this.setState({ productData: res.result.data.product, form_options: res.result.data.form_options });
        }
        this.setState({ fetchingData: false });
    }

    handleChange = (e, property = null, newValue = null) => {
        let key = property;
        let value = newValue;
        // Ankit Start 27 April 2023
        if(e && e.target.value === 'Search_More'){
            this.openProductDataPopup(e.target.name);
            return
        }
        // Ankit End 27 April 2023
        if (!newValue) {
            key = e.target.name;
            if (e.target.type === 'checkbox')
                value = e.target.checked;
            else {
                value = e.target.value;
                e.preventDefault();
            }
        }
        let updatedProductData = this.state.updatedProductData;
        // Ankit Start 27-08-2022 bugid 27-12-2022
        let productData = this.state.productData;
        // Ankit End 27-08-2022 bugid 27-12-2022
        updatedProductData = { ...updatedProductData, ...{ [key]: value } };
        productData = { ...productData, ...{ [key]: value } };
        this.setState({ updatedProductData, productData });
    }


    // Ankit  bugid-1564 24-08-2022
    openPopup = (type) => {
        let popupData = this.state.popupData;
        let idss = this.state.updatedProductData?.[type] || this.state.productData?.[type] || [];
        let options = this.state.form_options[type];
        popupData = { ...popupData, ...{ open: true, idss, options } };
        this.setState({ popupData, popupName: type });

    }
    // Ankit bugid-1564 code End 24-08-2022

    changeImage = (e) => {
        let newImage = e.target.files[0];
        if (newImage) {
            this.handleChange(null, e.target.name, newImage);
        }
    }

    // Ankit Start 27-12-2022 bugid - 1736
    handleValidation = () => {


        const UI_Fields_name = {
            name: 'Product Name',
            standard_prie: 'Cost', list_price: 'Sale Price'
        };

        let flag = true;
        let errors = this.state.errors;
        for (let element of Object.keys(UI_Fields_name)) {
            if (this.state.updatedProductData[element] != undefined && this.state.updatedProductData[element] == '' ||
                this.state.productData[element] != undefined && this.state.productData[element] == '') {

                errors = { ...errors, [element]: `${UI_Fields_name[element]} Cannot be Empty` }
                alert(`${UI_Fields_name[element]} cannot be blank!`);
                flag = false;
            }
        }
        if (!flag) {
            this.setState({ errors });
            return false
        } else {
            return true;
        }
    }

    handelProductCategory = () => {
        if (this.state.updatedProductData.type != undefined || this.state.updatedProductData.categ_id != undefined) {
            let updatedProductData = this.state.updatedProductData;
            if (this.state.updatedProductData.type == undefined) {
                updatedProductData = { ...updatedProductData, type: this.state.productData.type }
            } else if (this.state.updatedProductData.categ_id == undefined) {
                updatedProductData = { ...updatedProductData, categ_id: this.state.productData.categ_id };
            }
            this.setState({ updatedProductData });
        }
    }

    // Ankit End 27-12-2022 bugid - 1736
    handleSubmit = async (e) => {

        // Ankit Start bugid-1614 29-09-2022
        if ((this.state.updatedProductData && Object.keys(this.state.updatedProductData).length > 0) || this.state.UpdatedExtraMediaData.length) {
            // if (this.state.updatedProductData && Object.keys(this.state.updatedProductData).length > 0) {
            // Ankit End bugid-1614 29-09-2022
            // Ankit Start 27-dec-2022 bugid 1736

            if (this.handleValidation()) {
                // in case of new Product
                if (this.state.productId == 'New') {
                    await this.handelProductCategory();
                }

                // Ankit End 27-Dec-2022 bugid 1736
                this.setState({ fetchingData: true });
                // Ankit Start bugid-1614 29-09-2022
                let res = {}
                if (this.state.ExtraMediaData.length) {
                    res = await saveStoreProducts({ id: this.state.productData['id'], updatedData: this.state.updatedProductData, UpdatedExtraMediaData: this.state.UpdatedExtraMediaData });
                } else {
                    res = await saveStoreProducts({ id: this.state.productData['id'], updatedData: this.state.updatedProductData });
                }
                // let res = await saveStoreProducts({ id: this.state.productData['id'], updatedData: this.state.updatedProductData });
                // Ankit End bugid-1614 29-09-2022
                if (res.error || res.errorMessage) {
                    this.setState({ server_msg: 'Failed !! ' + res.errorMessage, severity: 'error',fetchingData:false});
                    // alert(res.error || res.errorMessage);

                }
                else {
                    // this.setState({ productUpdated: true });
                    // this.setState({productData:res.result.data.product,form_options:res.result.data.form_options});
                     // Ankit Start 20 March 2023
               
                // Ankit End 20 March 2023
                    const productId = this.state.productId;
                    if (productId == 'New'){
                        this.props.history.push(urls.extension + '/console/product/' + (res.product_id || 'New'));
                        // Ankit Start 2023 14-04-2023
                        await this.setState({ecommerce_api:false,selectedTab: "General Info"})
                        // Ankit Start 2023 14-04-2023
                        await this.fetchProductData(res.product_id);
                        // Ankit Start 20 march 2023 (image is getting blank)
                        if (this.state.updatedProductData?.reference_image){
                            let productData = this.state.productData
                            productData = {...productData,image:this.state.updatedProductData?.reference_image}
                           await this.setState({productData})
                        }
                        // Ankit End 20 march 2023 (image is getting blank)
                        await this.setState({productId:res.product_id,server_msg: 'Success', severity: 'success',updatedProductData:{},ecommerce_api:false,selectedTab: "General Info"});
                    }else{
                    // Ankit Start 14 April 2023
                    await this.setState({ecommerce_api:false,selectedTab: "General Info"})
                    await this.fetchProductData(productId);
                
                    // Ankit Start 20 March 2023
                    if (this.state.updatedProductData?.reference_image){
                        let productData = this.state.productData
                        productData = {...productData,image:this.state.updatedProductData?.reference_image}
                        await this.setState({productData})
                    }
                    // Ankit End 20 March 2023
                    await this.setState({server_msg: 'Success', severity: 'success',updatedProductData:{}});
                    }
                }
                this.setState({ fetchingData: false });
            }
        }
    }

    changeViewMode = async () => {
        let viewMode = this.state.viewMode;
        this.setState({ viewMode: !viewMode });
        if (!viewMode) {
            this.handleSubmit();
        }
    }

    showBtn = (btnType) => {
        let show = false;
        if (btnType === "edit") {
            show = true;
        }
        return show;
    }

    // Ankit bugid-1564 24-08-2022
    // openTaxesPopup=()=>{
    //     console.log('Working');
    //     let popupData = this.state.popupData;
    //     let taxes_id = this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id || [];
    //     let options = this.state.form_options['taxes_id'];
    //     popupData = {...popupData,...{open:true,taxes_id,options}};
    //     this.setState({popupData});
    // }


    // handlePopupActions=async(selectedItems=null)=>{
    //     let popupData = this.state.popupData;
    //     popupData = {...popupData,...{open:false}};
    //     this.setState({popupData});
    //     if(selectedItems){
    //         let updatedProductData = this.state.updatedProductData;
    //         updatedProductData = {...updatedProductData,...{taxes_id:selectedItems}}
    //         console.log(updatedProductData);
    //         await this.setState({updatedProductData});
    //     }
    // }

    handlePopupActions = async (selectedItems = null, type = null) => {
        let popupData = this.state.popupData;
        popupData = { ...popupData, ...{ open: false } };
        this.setState({ popupData });
        if (selectedItems && type) {
            let updatedProductData = this.state.updatedProductData;
            updatedProductData = { ...updatedProductData, ...{ [type]: selectedItems } };
            await this.setState({ updatedProductData });
        }
    }

    // removeTax=(tax_id)=>{
    //     let taxes = this.state.updatedProductData?.taxes_id;
    //     taxes = taxes.filter(i=>i!==tax_id);
    //     this.handleChange(null,'taxes_id',taxes);
    // }

    removeTax = (tax_id, type) => {
        let remove = this.state.updatedProductData?.[type] || this.state.productData?.[type];
        remove = remove.filter(i => i !== tax_id);
        this.handleChange(null, type, remove);
    }

    // Ankit  bugid-1564 24-08-2022

    getDisplayImage(imageToUpload) {
        if (typeof (imageToUpload) !== typeof ('string'))
            imageToUpload = URL.createObjectURL(imageToUpload);
        else
            imageToUpload = urls.domain + imageToUpload;
        return imageToUpload;
    }

    getFieldValue = (field) => {
        let value = this.state.productData[field];
        if (this.state.updatedProductData && this.state.updatedProductData.hasOwnProperty(field))
            value = this.state.updatedProductData[field];
        return (value);
    }

    onTabChange = async (e, newValue) => {
        let getSpecificData = false;
        // Ankit start 15-11-2022 bugid=1674
        // if(newValue === 'Variants' && !this.state.form_options?.attrs){
        if ((newValue === 'Variants' || newValue === 'eCommerce') && !this.state.form_options?.attrs) {
            getSpecificData = true;
        }
        // Ankit End 15-11-2022 bugid=1674
        if (getSpecificData) {
            this.setState({ fetchingData: true });
            // Ankit Start 15-11-2022 bugid-1674
            let res = false
            if (newValue === 'Variants') {
                res = await storeProducts({ id: this.state.productId, 'getSpecificData': "getVariantData" });
                if (res.error || res.result.errorMessage) {
                    alert(res.error || res.result.errorMessage);
                }
                else {
                    let form_options = this.state.form_options;
                    form_options = { ...form_options, ...res.result.data.form_options }
                    this.setState({ form_options });
                    this.setState({ selectedTab: newValue });
                }
            } else if (newValue === 'eCommerce' && !this.state.ecommerce_api) {
                res = await storeProducts({ id: this.state.productId, 'getSpecificData': "Ecommerce" });
                if (res.error || res.result.errorMessage) {
                    alert(res.error || res.result.errorMessage);
                } else {
                    let productData = { ...this.state.productData, ...res.result.all_ecommerce_data.ecommerce_data };
                    let form_options = { ...this.state.form_options, ...res.result.all_ecommerce_data.ecommerce_form_options };
                    this.setState({ productData, form_options });
                    this.setState({ selectedTab: newValue, ecommerce_api: true });
                    // Ankit Start bugid-1613 28-09-2022 (Added extra_media in state)
                    if ('extra_media' in res.result.all_ecommerce_data) {
                        const ExtraMediaData = res.result.all_ecommerce_data.extra_media
                        // Ankit Start 12 May 2023
                        // this.setState({ ExtraMediaData, ExtraMedia: true });
                        const ExtraMediaFormData = res.result.all_ecommerce_data.extra_media_form_option
                        this.setState({ ExtraMediaData,ExtraMediaFormData,ExtraMedia: true });
                        // Ankit End 12 May 2023
                    }
                    // Ankit End bugid-1613 28-09-2022
                }
            } else {
                this.setState({ selectedTab: newValue });
            }
        } else {
            this.setState({ selectedTab: newValue });
        }
        // Ankit End bugid-1613 28-09-2022
        // Ankit End 15-11-2022 bugid-1674
        // if(res.error || res.result.errorMessage){
        //     alert(res.error || res.result.errorMessage);
        // }
        // else{
        //     let form_options = this.state.form_options;
        //     form_options = {...form_options,...res.result.data.form_options}
        //     this.setState({form_options});
        //     this.setState({selectedTab:newValue});
        // }
        this.setState({ fetchingData: false });
    }

    // pankaj add variant value  
    getVariantList1 = (e) => {
        // let con = [e];
        // console.log(con,'this is call from parent11111')
        // Ankit Start bug-id 1600

        const ProductData = [...e, ...this.state.VariantProductData.reverse()];
        const VariantProductData = ProductData.reduce((currentVal, nextVal) => {
            let obj = currentVal.find(item => item.id === nextVal.id);

            // return the object
            if (obj) {
                return currentVal;
            }
            return currentVal.concat([nextVal]);
        }, []);

        this.setState({ VariantProductData });
        // this.handleChange(null,'attribute_value',e)
        this.handleChange(null, 'attribute_value', VariantProductData);
        // Ankit End bug-id 1600

    }

    // Ankit Start bugid-1614 27-09-2022

    handelExtraMediaChanges = (updated_media = null, action_type = null) => {
        if (updated_media) {
            if (action_type === 'delete') {
                const ExtraMediaData = [...this.state.ExtraMediaData];
                let obj = ExtraMediaData.findIndex(item => item.id === updated_media.id);
                if (obj > -1) {
                    const UpdatedExtraMediaData = [...this.state.UpdatedExtraMediaData, { id: updated_media.id, action_type: 'delete' }]
                    delete ExtraMediaData[obj];
                    this.setState({ ExtraMediaData, UpdatedExtraMediaData });
                }

            } else {
                const UpdatedExtraMediaData = [...this.state.UpdatedExtraMediaData, updated_media]
                const ExtraMediaList = [updated_media, ...this.state.ExtraMediaData];
                if (updated_media.id) {
                    const ExtraMediaData = ExtraMediaList.reduce((currentVal, nextVal) => {
                        let obj = currentVal.find(item => item.id === nextVal.id);
                        if (obj) {
                            return currentVal;
                        }
                        return currentVal.concat([nextVal]);
                    }, []);
                    this.setState({ ExtraMediaData, UpdatedExtraMediaData });
                }
                else {
                    const ExtraMediaData = [...ExtraMediaList];
                    this.setState({ ExtraMediaData, UpdatedExtraMediaData });
                }
            }
        }
        const AddExtraMedia = { ...this.state.AddExtraMedia, open: false };
        this.setState({ AddExtraMedia });
    }

    openExtraMedia = (e, selected_media = null) => {
        // Ankit Start 12 May 2023
        // const AddExtraMedia = { open: true, selected_media };
        const form_option = this.state.ExtraMediaFormData;
        const AddExtraMedia = { open: true, selected_media,form_option};
        // Ankit End 12 May 2023
        this.setState({ AddExtraMedia });
    }



    getDisplayMediaImage = (imageToUpload) => {
        if (imageToUpload) {
            if (imageToUpload.startsWith("data:image"))
                return imageToUpload;
            else
                return 'data:image/png;base64,' + imageToUpload;
        }
    }

    // Ankit End bugid-1614 27-09-2022

    // Ankit Start 18-11-2022
    discardPage = () => {

        this.props.history.goBack();
    }
    // Ankit End 18-11-2022

    // Ankit Start 10-01-2023
    updatedQtyPage = ()=>{
        this.props.history.push(urls.extension + `/Update/Quantity/${this.state.productId}`);
    }
    // Ankit End 10-01-2023
    // Ankit Start bugid-1716 09-12-2022
    goToLotSerialTree = () => {
        this.props.history.push(urls.extension + `/product/${this.state.productId}/lot/serials/tree`);
    }
    // Ankit End bugid-1716 09-12-2022

    // Ankit Start 24 Jan 2023
    productAttributePage = () => {
        this.props.history.push(urls.extension + `/product/attributes/tree`);
    }
    // Ankit End 24 Jan 2023

    // Ankit Start 27 Jan 2023
    variantsPage = ()=>{
        this.props.history.push(`${urls.extension}/console/productvarienttree/${this.state.productId}`)
    }
    // Ankit Start 27 Jan 2023

    // Ankit Start 27 April 2023
    handleProductDataPopupActions = (type=null,selected=null,option=null)=>{
        if (type){
            let form_options = this.state.form_options;
            let updatedProductData = this.state.updatedProductData;
            updatedProductData = {...updatedProductData,[type]:selected};
            form_options = {...form_options,[type]:{...form_options[type],...option}};
            this.setState({updatedProductData,form_options});
        }
        let productFormData = {...this.state.productFormData,open:false}
          
        this.setState({productFormData})
    }

    openProductDataPopup = (val)=>{
        let productFormData = {open:true,
            form_data:this.state.form_options[val],
            origin:val,
            selected_value:(this.state.updatedProductData?.[val] || this.state.productData[val])};
        this.setState({productFormData});
    }

    // Ankit End 27 April 2023

    render() {
        return (
            <div className="console-form">
                {this.state.productData && !this.state.productUpdated &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                            {/* {this.state.productId!='New' &&
                            <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.variantsPage()} >Variants</Button>
                            } */}
                                {this.showBtn('edit') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.changeViewMode()}>
                                        {`${this.state.viewMode ? "Edit" : "Save"}`}
                                    </Button>
                                }
                                {/* Ankit Start 18-11-2022 */}
                                <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.discardPage()} >Discard</Button>
                                {/* Ankit End 18-11-2022 */}


                                {/* Ankit Start 9 jan 2023 */}
                                {this.state.productId!='New' &&
                                <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.updatedQtyPage()} >Update Quantity</Button>
                                }   
                                {/* Ankit End 9 jan 2023  */}
                                {/* Ankit Start 24 Jan 2023 */}
                                <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.productAttributePage()} >Product Attributes</Button>
                                {/* Ankit End 24 Jan 2023 */}

                                {/* Ankit Start 27 Jan 2023 */}
                                {this.state.productId!='New' &&
                                <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.variantsPage()} >Variants</Button>
                                }
                                {/* Ankit End 27 Jan 2023 */}
                                 {/* Ankit Start 27-12-2022  bugid-1736*/}
                                {/* <h3 className="console-form__heading">{this.state.productData['name']}</h3> */}
                                <h3 className="console-form__heading">Product / {this.state.productData['name']}</h3>
                                {/* Ankit End 27-12-2022  bugid-1736*/}
                            </span>
                            <span className="header__right">
                            </span>
                        </header>
                        {/* Ankit Start 17 March 2023 */}
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            {this.state.server_msg ?
                                <Alert variant="filled" style={{ marginBottom: 10 }} onClose={() => { this.setState({ server_msg: false }) }} severity={this.state.severity}>{this.state.server_msg}</Alert>
                                : ''}
                        </Stack>
                        {/* Ankit Start 17 March 2023 */}
                        <TabContext value={this.state.selectedTab}>
                            <TabList
                                variant="scrollable"
                                onChange={this.onTabChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                                scrollButtons="true"
                                allowScrollButtonsMobile
                            >
                                {this.state.Tabs.map((tab) => (
                                    <Tab
                                        label={tab}
                                        value={tab}
                                    />
                                ))}
                            </TabList>
                        </TabContext>
                        {this.state.selectedTab === 'General Info' &&
                            <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                                <span>
                                    {(this.state.updatedProductData?.reference_image || this.state.productData?.image) &&
                                        <div className="uploaded-image">
                                            <img className="preview" src={this.getDisplayImage(this.state.updatedProductData?.reference_image || this.state.productData?.image)} alt="upload" />
                                        </div>
                                    }

                                    <Input
                                        type="file"
                                        id="reference_image"
                                        name="reference_image"
                                        title="upload image"
                                        accept="image/*"
                                        onChange={this.changeImage}
                                    />
                                </span>
                                {/* <span> */}
                                <Grid container justifyContent="flex-start" alignItems="center" mb={2} marginLeft={3}  >
                                    <Grid item xs={12} md={2}>
                                        {/* <div className="console-form__inputs" > */}
                                        <FormControlLabel style={{ margin: 0, padding: 0 }} control={<Checkbox
                                            id="sale_ok"
                                            name="sale_ok"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('sale_ok')}
                                            style={{ padding: 0 }}
                                        />} label="Can be Sold" />

                                        {/* <label htmlFor="sale_ok">Can be Sold</label>
                                        <Input
                                            id="sale_ok"
                                            name="sale_ok"
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('sale_ok')}
                                        /> */}
                                        {/* </div> */}
                                    </Grid>

                                    <Grid item xs={12} md={2}>
                                        {/* <div className="console-form__inputs" > */}

                                        <FormControlLabel style={{ margin: 0 }} control={<Checkbox
                                            id="purchase_ok"
                                            name="purchase_ok"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('purchase_ok')}
                                            style={{ padding: 0 }}
                                        />} label="Can be Purchased" />
                                        {/* <label htmlFor="purchase_ok">Can be Purchased</label>
                                        <input
                                            id="purchase_ok"
                                            name="purchase_ok"
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('purchase_ok')}
                                        /> */}
                                        {/* </div> */}
                                    </Grid>


                                    <Grid item xs={12} md={3}>
                                        {/* <div className="console-form__inputs" > */}

                                        <FormControlLabel style={{ margin: 0 }} control={<Checkbox
                                            id="is_published"
                                            name="is_published"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('is_published')}
                                            style={{ padding: 0 }}
                                        />} label="Publish" />
                                        {/* <label htmlFor="is_published">Publish</label>
                                        <input
                                            id="is_published"
                                            name="is_published"
                                            type="checkbox"
                                            onChange={this.handleChange}
                                            checked={this.getFieldValue('is_published')}
                                        /> */}
                                        {/* </div> */}
                                    </Grid>

                                </Grid>
                                <Grid container style={{ marginBottom: 5 }}  >
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1} >

                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Product Name </Typography>}
                                                // label={ <Typography variant="headline" component="h3"> Product Name </Typography>}
                                                id="name"
                                                name="name"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.updatedProductData?.name || this.state.productData['name']}
                                                size="small"
                                                style={{ marginBottom: 10, fontSize: 13 }}

                                            />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1}>
                                            <span className="input-error">{this.state.errors["name"]}</span>
                                        </Grid>
                                    </Grid>

                                    <Grid container md={3} style={{ marginBottom: 3 }} >
                                        <Grid item xs={12} md={13} mr={1}>
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Internal Reference </Typography>}
                                                id="default_code"
                                                name="default_code"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.updatedProductData?.default_code || this.state.productData['default_code']}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={13} mr={1}>
                                            <span className="input-error">{this.state.errors["default_code"]}</span>
                                        </Grid>

                                    </Grid>
                                </Grid>


                                <Grid container style={{ marginBottom: 10 }}  >
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1} >

                                            <TextField
                                                // label="Sales Price"
                                                label={<Typography variant="headline" style={this.labelStyle}> Sales Price </Typography>}
                                                id="list_price"
                                                name="list_price"
                                                type="number"
                                                min={0}
                                                onChange={this.handleChange}
                                                value={this.state.updatedProductData?.list_price || this.state.productData['list_price']}
                                                size="small"
                                                style={{ marginBottom: 10 }}


                                            />
                                            <span className="input-error">{this.state.errors["list_price"]}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={13} mr={1}>
                                            {this.state.productData['product_variant_count'] < 2 ?
                                            <TextField

                                                label={<Typography variant="headline" style={this.labelStyle}> Cost </Typography>}
                                                id="standard_price"
                                                name="standard_price"
                                                type="number"
                                                min={0}
                                                required
                                                onChange={this.handleChange}
                                                value={this.state.updatedProductData?.standard_price || this.state.productData['standard_price']}
                                                size="small"

                                            />
                                            :<div></div>}
                                            <span className="input-error">{this.state.errors["standard_price"]}</span>
                                        
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 20 }}  >
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={6} mr={1} >
                                            <FormControl sx={{ mr: 3, minWidth: 220 }} variant="filled">


                                                <Typography htmlFor="type" variant="headline" style={this.labelStyleSelect}> Type </Typography>
                                                <Select
                                                    // label="Type"                                            
                                                    id="type"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.type || this.state.productData['type']}
                                                    name='type'
                                                    margin="normal"

                                                >
                                                    {Object.keys(this.state.form_options['type']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.state.form_options['type'][option]}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={6} mr={1}>
                                            <FormControl xs={12} sx={{ minWidth: 220 }} >


                                                <Typography htmlFor="categ_id" variant="headline" style={this.labelStyleSelect}> Product Category </Typography>
                                                <Select
                                                    // label="Product Category"
                                                    labelId="categ_id"
                                                    id="categ_id"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.categ_id || this.state.productData['categ_id']}
                                                    name='categ_id'
                                                    margin="normal"

                                                >
                                                    {Object.keys(this.state.form_options['categ_id']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.state.form_options['categ_id'][option]}
                                                        </MenuItem>
                                                    ))}
                                        {/* /* Ankit start 27 April 2023 */}
                                        {Object.keys(this.state.form_options['categ_id']).length > 10?
                                             <MenuItem value='Search_More'> Search More  </MenuItem> : <MenuItem> </MenuItem>
                                            }
                                        {/* Ankit End 27 April 2023*/}   

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/* Ankit Start 27 Feb 2023 Adding Brand and Unit Of Measure */}
                                <Grid container style={{ marginBottom: 20 }}  >
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={6} mr={1} >
                                            <FormControl sx={{ mr: 3, minWidth: 220 }} variant="filled">


                                                <Typography htmlFor="type" variant="headline" style={this.labelStyleSelect}> Brand </Typography>
                                                <Select
                                                    // label="Type"                                            
                                                    id="company_brand_id"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.company_brand_id || this.state.productData['company_brand_id']}
                                                    name='company_brand_id'
                                                    margin="normal"

                                                >
                                                    {Object.keys(this.state.form_options['company_brand_id']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.state.form_options['company_brand_id'][option]}
                                                        </MenuItem>
                                                    ))}
                                                       {/* /* Ankit start 27 April 2023 */}
                                        {Object.keys(this.state.form_options['company_brand_id']).length > 10?
                                             <MenuItem value='Search_More'> Search More  </MenuItem> : <MenuItem> </MenuItem>
                                            }
                                        {/* Ankit End 27 April 2023*/}   

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container md={3} style={{ marginBottom: 3 }}>
                                        <Grid item xs={12} md={6} mr={1}>
                                            <FormControl xs={12} sx={{ minWidth: 220 }} >


                                                <Typography htmlFor="categ_id" variant="headline" style={this.labelStyleSelect}>Unit of Measure</Typography>
                                                <Select
                                                    // label="Product Category"
                                                    labelId="uom_id"
                                                    id="uom_id"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.uom_id || this.state.productData['uom_id']}
                                                    name='uom_id'
                                                    margin="normal"

                                                >
                                                    {Object.keys(this.state.form_options['uom_id']).map((option) => (
                                                        <MenuItem value={option}>
                                                            {this.state.form_options['uom_id'][option]}
                                                        </MenuItem>
                                                    ))}
                                                {/* /* Ankit start 27 April 2023 */}
                                        {Object.keys(this.state.form_options['uom_id']).length > 10?
                                             <MenuItem value='Search_More'> Search More  </MenuItem> : <MenuItem> </MenuItem>
                                            }
                                        {/* Ankit End 27 April 2023*/}    
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Ankit End 27 Feb 2023 */}

                                <span>
                                    <Grid container style={{ marginBottom: 10 }}  >
                                        <Grid container md={3} style={{ marginBottom: 3 }}>
                                            <Grid item xs={12} md={13} mr={1} >
                                                {/* <div className="console-form__inputs" > */}
                                                {/* <label htmlFor="l10n_in_hsn_code">HSN/SAC Code</label> */}
                                                <TextField
                                                    // label="HSN/SAC Code"
                                                    label={<Typography variant="headline" style={this.labelStyle}> HSN/SAC Code </Typography>}
                                                    id="l10n_in_hsn_code"
                                                    name="l10n_in_hsn_code"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.l10n_in_hsn_code || this.state.productData['l10n_in_hsn_code']}
                                                    size="small"
                                                    style={{ marginBottom: 10 }}

                                                />
                                                <span className="input-error">{this.state.errors["l10n_in_hsn_code"]}</span>
                                            </Grid>
                                        </Grid>
                                        {/* </div> */}
                                        {/* <div className="console-form__inputs" > */}
                                        {/* <label htmlFor="l10n_in_hsn_description">HSN/SAC Description</label> */}
                                        <Grid container md={3} style={{ marginBottom: 3 }}>
                                            <Grid item xs={12} md={13} mr={1}>
                                                <TextField
                                                    id="l10n_in_hsn_description"
                                                    // label={<Typography variant="headline" style={this.labelStyle}> HSN/SAC Description </Typography>}
                                                    label={<Typography variant="headline" style={this.labelStyle}> Product Description </Typography>}
                                                    name="l10n_in_hsn_description"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.l10n_in_hsn_description || this.state.productData['l10n_in_hsn_description']}
                                                    size="small"
                                                    style={{ marginBottom: 10 }}

                                                />
                                                <span className="input-error">{this.state.errors["l10n_in_hsn_description"]}</span>
                                                {/* </div> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </span>
                                <span style={{ display: 'flex', alignItems: 'center', flex: 'wrap' }}>
                                    <label htmlFor="taxes_id">Customer Taxes</label>
                                    {/* Ankit  bugid-1564 24-08-2022 */}
                                    {/* <Button 
                                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                                        onClick={()=>this.openTaxesPopup()}
                                    >
                                        Select
                                    </Button> */}
                                    <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("taxes_id")}>
                                        Select Tax
                                    </Button>


                                    {/* {(this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id).map((tax_id)=>(
                                        <Chip
                                            label={this.state.form_options['taxes_id'][tax_id]}
                                            onDelete={()=>this.removeTax(tax_id)}
                                        />
                                    ))} */}
                                    {(this.state.updatedProductData?.taxes_id || this.state.productData?.taxes_id).map((tax_id) => (
                                        <Chip
                                            label={this.state.form_options['taxes_id'][tax_id]}
                                            onDelete={() => this.removeTax(tax_id, 'taxes_id')}
                                        />
                                    ))}
                                    {/* Ankit bugid-1564 24-08-2022 */}
                                </span>
                            </fieldset>
                        }
                        {this.state.selectedTab === 'Variants' &&
                            <>
                                <div>
                                    {/* Ankit Start Bug-id 1600 */}
                                    {/* <VariantSelection getVariantList2={this.getVariantList1}/> */}
                                    <VariantSelection getVariantList2={this.getVariantList1} viewMode={this.state.viewMode} tableLines={[...this.state.VariantProductData, ...this.state.productData?.attribute_line_ids]} />
                                    {/* Ankit End Bug-id 1600 */}
                                </div>
                            </>
                        }
                        {/* Piyush kumar bugid-1564 Start 31-08-22 */}
                        {this.state.selectedTab === 'sales' &&
                            <>
                                <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                                    <Grid container style={{ marginBottom: 15 }}>
                                        <Grid item xs={12} sm={2} md={2}>
                                            <FormControl sx={{ mb: 1, minWidth: 150 }} variant="filled">
                                                {/* <InputLabel htmlFor="type">Product Type</InputLabel> */}
                                                {/* <label htmlFor="invoice_policy">Invoicing Policy</label> */}
                                                <Typography htmlFor="invoice_policy" variant="headline" style={this.labelStyleSelect}> Invoicing Policy</Typography>
                                                {/* <div className="console-form__inputs" >
                                <label htmlFor="invoice_policy">Invoicing Policy</label> */}
                                                <Select
                                                    labelId="invoice_policy"
                                                    id="invoice_policy"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.invoice_policy || this.state.productData['invoice_policy']}
                                                    name='invoice_policy'
                                                    sx={{ mb: 1, minWidth: 150 }}

                                                >
                                                    <MenuItem value="order">Ordered Quantities</MenuItem>
                                                    <MenuItem value="delivery">Delivered Quantities</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {/* </div> */}
                                        {/* <div className="console-form__inputs" > */}
                                        <Grid item xs={12} >
                                            {/* <label htmlFor="optional_product_ids">Optional Products</label> */}
                                            <Typography htmlFor="optional_product_ids" variant="headline" style={this.labelStyleSelect}> Optional Products </Typography>
                                            <div>
                                                <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("optional_product_ids")}>
                                                    Optional Products
                                                </Button>
                                                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '5px' }}>
                                                    {(this.state.updatedProductData?.optional_product_ids || this.state.productData?.optional_product_ids).map((ids) => (
                                                        <Chip
                                                            label={this.state.form_options['optional_product_ids'][ids]}
                                                            onDelete={() => this.removeTax(ids, 'optional_product_ids')}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {/* </div> */}

                                    <Grid container style={{ marginBottom: 10 }}>
                                        <Grid xs={12} >
                                            {/* <label htmlFor="description_sale">Sales Description</label> */}
                                            <TextField
                                                label={<Typography variant="headline" style={this.labelStyle}> Sales Description </Typography>}
                                                // label="Sales Description"
                                                id="description_sale"
                                                name="description_sale"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.updatedProductData?.description_sale || this.state.productData['description_sale']}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>

                                </fieldset>
                            </>
                        }
                        {/* piyush end */}

                        {/* Ankit  bugid-1564 Start 24-08-22 */}
                        {this.state.selectedTab === 'eCommerce' &&
                            <>
                                <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                                    <h1>Shop</h1>
                                    {/* <div className="console-form__inputs" >
                                <label htmlFor="public_categ_ids">Categories</label> */}
                                    <Grid container style={{ marginBottom: 10 }}>
                                        <Grid item xs={12} >
                                            {/* <label htmlFor="public_categ_ids">Categories</label> */}
                                            <Typography htmlFor="public_categ_ids" variant="headline" style={this.labelStyleSelect}> Categories</Typography>
                                            <div>
                                                <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("public_categ_ids")}>
                                                    Categories
                                                </Button>
                                                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '5px' }}>
                                                    {(this.state.updatedProductData?.public_categ_ids || this.state.productData?.public_categ_ids).map((ids) => (
                                                        <Chip
                                                            label={this.state.form_options['public_categ_ids'][ids]}
                                                            onDelete={() => this.removeTax(ids, 'public_categ_ids')}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            {/*
                            </div> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ marginBottom: 10 }}>
                                        <Grid item xs={12} sm={2} md={2}>
                                            <FormControl sx={{ mb: 1, minWidth: 150 }} variant="filled">
                                                {/* <div className="console-form__inputs" > */}
                                                {/* <label htmlFor="inventory_availability">Availability</label> */}
                                                <Typography htmlFor="inventory_availability" variant="headline" style={this.labelStyleSelect}> Availability</Typography>
                                                <Select
                                                    labelId="inventory_availability"
                                                    id="inventory_availability"
                                                    onChange={this.handleChange}
                                                    value={this.state.updatedProductData?.inventory_availability || this.state.productData['inventory_availability']}
                                                    name='inventory_availability'
                                                    sx={{ mb: 1, minWidth: 150 }}
                                                >

                                                    <MenuItem value="never">Sell regardless of invertory</MenuItem>
                                                    <MenuItem value="always">Show invertory on website and prevent sales if not enough stock</MenuItem>
                                                    <MenuItem value="threshold">Show invertory below a thresold and prevent sales if not enough stock</MenuItem>
                                                    <MenuItem value="custom">Show product-specific notifications</MenuItem>



                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {(this.state.updatedProductData?.inventory_availability || this.state.productData['inventory_availability']) == 'custom' &&
                                    <>

                                        <Grid item xs={12} >
                                            {/* <label htmlFor="alternative_product_ids">Alternative Products</label> */}
                                            <Typography htmlFor="alternative_product_ids" variant="headline" style={this.labelStyleSelect}>Custom Message</Typography>
                                            <div>
                                                {/* <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("alternative_product_ids")}>
                                                    Alternative Products
                                                </Button> */}
                                                 <TextField
                                    label="Custom Message"
                                    id="custom_message"
                                    name="custom_message"
                                    type="text"
                                    min={0}
                                    onChange={this.handleChange}
                                    value={(this.state.updatedProductData?.custom_message || this.state.productData['custom_message'])}
                                    size="small"
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                />
                                            </div>
                                            {/* </div> */}
                                        </Grid>
                                    </>
                                    }

{(this.state.updatedProductData?.inventory_availability || this.state.productData['inventory_availability']) == 'threshold' &&
                                    <>

                                        <Grid item xs={12} >
                                            {/* <label htmlFor="alternative_product_ids">Alternative Products</label> */}
                                            <Typography htmlFor="alternative_product_ids" variant="headline" style={this.labelStyleSelect}> Availability Threshold</Typography>
                                            <div>
                                                {/* <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("alternative_product_ids")}>
                                                    Alternative Products
                                                </Button> */}
                                                 <TextField
                                    label="Availability Threshold"
                                    id="available_threshold"
                                    name="available_threshold"
                                    type="number"
                                    min={0}
                                    onChange={this.handleChange}
                                    value={(this.state.updatedProductData?.available_threshold || this.state.productData['available_threshold'])}
                                    size="small"
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                />
                                            </div>
                                            {/* </div> */}
                                        </Grid>
                                    </>
                                    }





                                        {/* </div> */}
                                        {/* <div className="console-form__inputs" > */}
                                        <Grid item xs={12} >
                                            {/* <label htmlFor="alternative_product_ids">Alternative Products</label> */}
                                            <Typography htmlFor="alternative_product_ids" variant="headline" style={this.labelStyleSelect}> Alternative Products</Typography>
                                            <div>
                                                <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("alternative_product_ids")}>
                                                    Alternative Products
                                                </Button>
                                                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '5px' }}>
                                                    {(this.state.updatedProductData?.alternative_product_ids || this.state.productData?.alternative_product_ids).map((ids) => (
                                                        <Chip
                                                            label={this.state.form_options['alternative_product_ids'][ids]}
                                                            onDelete={() => this.removeTax(ids, 'alternative_product_ids')}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </Grid>

                                        {/* <div className="console-form__inputs" > */}
                                        <Grid item xs={12} >
                                            {/* <label htmlFor="accessory_product_ids">Accessory Products</label> */}
                                            <Typography htmlFor="accessory_product_ids" variant="headline" style={this.labelStyleSelect}> Accessory Products</Typography>
                                            <div>
                                                <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("accessory_product_ids")}>
                                                    Accessory products
                                                </Button>
                                                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '5px' }}>
                                                    {(this.state.updatedProductData?.accessory_product_ids || this.state.productData?.accessory_product_ids).map((ids) => (
                                                        <Chip
                                                            label={this.state.form_options['accessory_product_ids'][ids]}
                                                            onDelete={() => this.removeTax(ids, 'accessory_product_ids')}
                                                        />
                                                    ))}
                                                </div>
                                                {/* </div> */}

                                            </div>
                                        </Grid>

                                        {/* <div className="console-form__inputs" > */}
                                        <Grid item xs={12} >
                                            {/* <label htmlFor="website_style_ids">Style</label> */}
                                            <Typography htmlFor="website_style_ids" variant="headline" style={this.labelStyleSelect}> Style</Typography>
                                            <div>
                                                <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.openPopup("website_style_ids")}>
                                                    Style
                                                </Button>
                                                <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '5px' }}>
                                                    {(this.state.updatedProductData?.website_style_ids || this.state.productData?.website_style_ids).map((ids) => (
                                                        <Chip
                                                            label={this.state.form_options['website_style_ids'][ids]}
                                                            onDelete={() => this.removeTax(ids, 'website_style_ids')}
                                                        />
                                                    ))}
                                                </div>
                                            </div>

                                            {/* </div> */}
                                        </Grid>
                                    </Grid>

                                    {/* Ankit Start Bugid-1614 27-09-2022 */}
                                    {this.state.ExtraMedia ?
                                        <>
                                            <div>
                                                <h1>Extra Product Media</h1>
                                            </div>
                                            <div className="console-form__inputs" >
                                                <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={(e) => this.openExtraMedia(e)}>
                                                    + Add a Media
                                                </Button>

                                            </div>

                                            <ExtraMediaPopup data={this.state.AddExtraMedia} handleExtraMediaActions={this.handelExtraMediaChanges} />
                                            <div className='img-cards'>
                                                {this.state.ExtraMediaData.map((option) => (
                                                    <div className='img-card-view' onClick={(e) => { this.openExtraMedia(e, option) }}>
                                                        <Card sx={{ maxWidth: 250 }}>
                                                            <CardActionArea>
                                                                <CardMedia
                                                                    component="img"
                                                                    height="140"
                                                                    image={this.getDisplayMediaImage(option.image)}
                                                                    alt="green iguana"
                                                                />
                                                                <CardContent>
                                                                    {option.name}
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </div>
                                                ))}
                                            </div>
                                        </> : ""
                                    }
                                    {/* Ankit End bugid 1614 27-09-2022 */}

                                </fieldset>
                            </>
                        }
                        {/* Ankit bugid-1564 End 24-08-22 */}
                        {/* Ankit start bugid-1716 9-dec-2022 */}
                        {this.state.selectedTab === 'inventory' &&
                            <>
                                <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>
                                    {(this.state.updatedProductData?.type || this.state.productData['type']) == 'product' &&
                                        <>
                                            <h1>Traceability</h1>
                                            {/* <div className="console-form__inputs" > */}
                                            <Grid container style={{ marginBottom: 10 }}>
                                                <Grid item xs={12} sm={2} md={2}>
                                                    <FormControl sx={{ m: 1, minWidth: 150 }} variant="filled">
                                                        <label htmlFor="tracking">Tracking</label>
                                                        <Select
                                                            labelId="tracking"
                                                            id="tracking"
                                                            onChange={this.handleChange}
                                                            value={this.state.updatedProductData?.tracking || this.state.productData['tracking']}
                                                            name='tracking'>
                                                            <MenuItem value="serial">By Unique Serial Number</MenuItem>
                                                            <MenuItem value="lot">By Lot</MenuItem>
                                                            <MenuItem value="none">No Tracking</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {/* </div> */}
                                            {((this.state.updatedProductData?.tracking || this.state.productData['tracking']) == 'lot' || 
                                            (this.state.updatedProductData?.tracking || this.state.productData['tracking']) == 'serial') &&
                                                <Grid container style={{ marginBottom: 10 }}>
                                                    <Grid item xs={12} >
                                                        <Button size='small' style={{ background: "#02A457", color: "beige" }} onClick={() => this.goToLotSerialTree()}>
                                                            Lot/Serial Numbers
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>

                                    }
                                </fieldset>
                            </>
                        }

                    </form>
                }
                {/* {this.state.productUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Products created successfully
                    </div>
                } */}
                {/* <TaxesPopup 
                    data={this.state.popupData}
                    handlePopupActions={this.handlePopupActions}
                /> */}
                {/* Ankit bugid-1564 24-08-22 */}
                <TaxesPopup
                    data={this.state.popupData}
                    handlePopupActions={this.handlePopupActions}
                    header={this.state.popupName}
                />
                {/* Ankit bugid-1564 24-08-22 */}


                {/* Ankit Start 27 April 2023 */}
                <ProductDataPopup 
                    open={this.state.productFormData['open']} 
                    data={this.state.productFormData}
                    handleProductDataPopupActions={this.handleProductDataPopupActions}
                    type={this.state.productFormData['origin']}
                    selected_value={this.state.productFormData['selected_value']}
                    origin={'product_form_option_data'}
                />
                {/* Ankit End 27 April 2023 */}
                <Loader open={this.state.fetchingData || this.state.updatingProductData} handleClose={() => { }} />
            </div>
        );
    }
}

export default StoreProduct;
