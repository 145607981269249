import React from 'react';
import Progessbar from '../CartPage/Progressbar';
import AddressForm from '../../Components/AddressForm/AddressForm';
import CartSummary from '../../Components/CartSummary/CartSummary';
import '../CheckoutPage/CheckoutPage.css';
import { changeAppTitle } from '../../Shared/utilities';
import { Grid } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import urls from '../../urls';
class AddressPage extends React.Component {

    componentDidMount() {
        changeAppTitle('Shopaas | Addresses');
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let errors = await this.handleValidation();
            if (Object.keys(errors).length > 0) {
                alert('Please fill all fields properly')
                return;
            }
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.putAdress, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        ...this.state.newAddress
                    }
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                if (this.props.history.location.pathname.includes('my/address')) {
                    this.props.goBack();
                }
                else {
                    this.props.history.push(urls.extension + '/shop/checkout');
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            return;
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
            return;
        }
    }

    goBackLastPage = () => {
        if (this.props.history.location.pathname.includes('my/address')) {
            this.props.goBack();
        }
        else {
            let pathname = urls.extension + '/shop/checkout';
            this.props.history.push({ pathname });
        }
    }
    render() {
        return (
            <div className="checkout-page">
                <Grid container xs={12} md={10} justifyContent="center" style={{marginBottom:100}} >
                    <Grid container xs={12} md={12} justifyContent="flex-start">
                        <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                            <div className="progress-bar">
                                <Progessbar stage={2} />
                            </div>
                        </Grid>
                    </Grid>
                    <div className="checkout-details">
                        <Grid container xs={12} md={10} justifyContent="center">
                            <Grid container xs={10} md={6} justifyContent="flex-start">
                                <Grid item xs={8} md={12}>
                                    <div className="checkout-details__left">
                                        <AddressForm addOrEditAddress={this.addOrEditAddress} history={this.props.history} />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container xs={10} md={5} justifyContent="flex-start">
                                <Grid item xs={12} md={12} >
                                    <div className="checkout-details__right">
                                        <CartSummary />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>	
                    {/* <Grid container xs={12} md={12} justifyContent="flex-start" >
                        <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                            <div className="navigation-btns">
                                <button className="btn__back"
                                    onClick={() => this.goBackLastPage()}
                                >
                                    <ArrowBack />  Back
                                </button>
                                <button className="btn__next"
                                    onClick={(e) => this.handleSubmit(e)}
                                >
                                    Next  <ArrowForward />
                                </button>
                            </div>
                        </Grid>
                    </Grid> */}
                </Grid>
            </div>
        );
    }
}

export default AddressPage;
