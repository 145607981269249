import React from 'react'
import './OrderDetails.css';
import { changeAppTitle } from '../../Shared/utilities';
import urls from '../../urls';

class OrderDetails extends React.Component {

    state = {
        order_id:null,
        partner_id:null,
        orderDetails:null,
        pageNumber:1
    }

    componentDidMount(){
        changeAppTitle('Shopaas | Order Details');
        let orderDetails = this.props.match.params.orderDetails;
        orderDetails = orderDetails.split('-'); 
        let order_id = orderDetails.slice(-1);
        let partner_id = orderDetails.slice(-2,-1);
        this.setState({order_id,partner_id});
        this.getOrderDetails(order_id,partner_id);
    }

    getOrderDetails = async (order_id,partner_id) => {
        try {
            const axios = require("axios");
            const response = await axios(urls.orderDetails, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        order_id:order_id,
                        partner_id:partner_id
                        }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                this.setState({orderDetails:res.result.data});
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }



    render() { 
        return(
            <div>

            </div>
        );
    }
}
 
export default OrderDetails;