import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import '../console.css';

export default function AllowedCompaniesPopup(props) {

	const [state,setState]  = useState({selectedItems:null});

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}

	useEffect(() => {
		if(props.data.open === true){
            changeState({'selectedItems':props.company_ids});
		}
	}, [props.data.open])

	const handleChange=(e,item)=>{
        let selectedItems = state.selectedItems;
		if(e.target.checked){
            selectedItems = {...selectedItems,...{[item['value']]:item}};
        }
		else{
            delete selectedItems[item['value']];
        }
        changeState({'selectedItems':selectedItems});
	}

    const isItemSelected=(row=null)=>{
        let selectedItems = state.selectedItems || {};
        let selected = Object.values(selectedItems).includes(row);
        return(selected);
    }

	return (
		<div>
			<Dialog
				open={props.data.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
                            Select Companies
						</div>
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Company</th>
							</thead>
							<tbody>
								{props?.data?.options && props.data.options.map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={isItemSelected(row)}
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{row['name']}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handlePopupActions(state.selectedItems)} autoFocus disabled={!state.selectedItems}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}