import React from 'react';
import { fetchOrderInvoiceData } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';

class Invoices extends React.Component {


    state = {
        invoices:null,orderId:null,
        fetchingData:false,
    }

    componentDidMount(){
        changeAppTitle('Console | Invoices');
        let orderId = this.props.match.params.orderId;
        this.setState({orderId});
        this.fetchOrderInvoiceData(orderId);
    }

    fetchOrderInvoiceData=async(orderId)=>{
        this.setState({fetchingData:true});
        let res  = await fetchOrderInvoiceData(orderId);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({invoices:res.result.data.invoices});
        }
        this.setState({fetchingData:false});
    }

    goToPicking=(invoiceId)=>{
        let newPageRoute = this.props.history.location.pathname;
        newPageRoute = newPageRoute.replace('invoices',('invoice/' + invoiceId));
        this.props.history.push(newPageRoute);
    }

    render() { 
        return (
            <div className="store-orders">
                {this.state.invoices &&
                    <table className="order-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Customer</th>
                                <th>Invoice Date</th>
                                <th>Source Document</th>
                                <th>Sales Person</th>
                                <th>Company</th>
                                <th>Due Date</th>
                                <th>Tax Excluded</th>
                                <th>Total</th>
                                <th>Amount Due</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.invoices.map((row)=>(
                                <tr onClick={()=>this.goToPicking(row['id'])}>
                                    <td>{row['name']}</td>
                                    <td>{row['invoice_partner_display_name']}</td>
                                    <td>{row['invoice_date']}</td>
                                    <td>{row['invoice_origin']}</td>
                                    <td>{row['invoice_user_id']['name']}</td>
                                    <td>{row['company_id']['name']}</td>
                                    <td>{row['invoice_date_due']}</td>
                                    <td>{row['amount_untaxed_signed']}</td>
                                    <td>{row['amount_total_signed']}</td>
                                    <td>{row['amount_residual_signed']}</td>
                                    <td>{row['state']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default Invoices;