import React from 'react';
import '../CheckoutPage/CheckoutPage.css';
import './PaymentConfirmationPage.css'; 
import './PaymentPage.css'; 
import urls from '../../urls';
import { changeAppTitle } from '../../Shared/utilities';

class PaymentConfirmationPage extends React.Component {

    state = {
        paymentConfirmationData:null
    }

    componentDidMount(){
        changeAppTitle('Shopaas | Payment Confirmation');
        this.fetchPaymentConfirmationDetails();
    }

    fetchPaymentConfirmationDetails = async () => {
        try {
            const axios = require("axios");
            const response = await axios(urls.getPaymentConfirmationData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                let paymentConfirmationData = res.result.data;
                this.setState({paymentConfirmationData});
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }

    getAddress=(addr)=>{
        let addrText = '';
        if(addr)
            addrText = ((addr?.street || '') + ', ' + (addr?.street2 || '') + ',' + (addr?.zip || '') + ', ' + 
                        (addr?.city?.name || '') + ',' +
                        (addr?.state?.name || '') + ',' + (addr?.country?.name || ''));
        return addrText;
    }

    render() { 
        return (
            <div className="checkout-page">
                {this.state.paymentConfirmationData && this.state.paymentConfirmationData['sale_order'] &&
                <div className="checkout-details">
                    <div className="checkout-details__left"> 
                        <div className="payment-confirmation">
                            <div className="order-name">Order {this.state.paymentConfirmationData?.sale_order?.name || ''}</div>
                            
                            <div className="payment-info1__heading">Payment Information:</div>
                            <div className="payment-info1">
                                <span>{this.state.paymentConfirmationData?.payment_acquirer?.name || ''}</span>
                                <span style={{fontWeight:"700"}}>
                                    <span>Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>&#8377; {this.state.paymentConfirmationData?.sale_order?.amount_total || 0}</span>
                                </span>                               
                            </div>

                            <div className="payment-info1">
                                <span style={{fontWeight:"700"}}>
                                    <span>Delivery Date: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span> {this.state.paymentConfirmationData?.sale_order?.expected_date || ''}</span>
                                </span>
                            </div>
                            
                            <div className="payment-addresses">
                                <div>
                                    <span>Billing Address:</span> {this.getAddress(this.state.paymentConfirmationData?.billing_address)}
                                </div>
                                <div>
                                    <span>Shipping Address:</span> {this.getAddress(this.state.paymentConfirmationData?.shipping_address)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
 
export default PaymentConfirmationPage;
