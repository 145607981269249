import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import '../console.css';
// import {getToCompanyData} from '../../../Shared/consoleServices';
import {getProductOptions} from '../../../Shared/consoleServices';



export default function ProductDataPopup(props) {

	const [input,GetInput]  = useState([]);
	const [state,setState]  = useState({selectedItem:null});
    const [searchVal, setSearchVal] = useState({})

	const headingLabel = {categ_id:'Category', company_brand_id:'Brand',uom_id:'UOM'} 


	useEffect(()=>{
		if(props.open && props.data){
			setSearchVal({...props.data.form_data})
		}
	},[props.open])
	
	const getMoreData = async () => {
			if(input){	
				let res = await getProductOptions(props.origin,props.id, props.type,input);
            	console.log(res);
				if(res.result.data){
                	const data = {...res.result.data.items};
                    setSearchVal(data);
        	}
		}
	}
	

    const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}


	const handleChange=(e,item)=>{
		if(e.target.checked)
			changeState({selectedItem:item});
		else
			changeState({selectedItem:null});
	}
	






	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
						{headingLabel[props.type]} Search
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder={'Enter ' + headingLabel[props.type]+ ' name'}/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Name</th>
							</thead>
							<tbody>
								{Object.keys(searchVal).map((row,idx)=>(
									<tr>
										<td>
											<input 
												type="checkbox"
												name="selectedItem"
												// checked={state.selectedItem?.id === row}
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{searchVal[row]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=>props.handleProductDataPopupActions(props.type,state.selectedItem,{[state.selectedItem]:searchVal[state.selectedItem]})} autoFocus disabled={!state.selectedItem}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handleProductDataPopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
