import React from 'react';
import './HelpAndSupport.css';
import { Tab } from '@mui/material';
import { TabList,TabContext } from '@material-ui/lab';
import urls from '../../urls';

class HelpAndSupport extends React.Component {

    state = {
        selectedTab:"LEGAL",
        Tabs:['LEGAL','FAQS'],
        fetchingData:false,
        LEGALS:{},
        FAQS:{},
        data:{}
    }

    onTabChange=(e,newValue)=>{
        this.setState({selectedTab:newValue});
        if(newValue==="LEGAL")
            this.setState({data:this.state.LEGALS});
        else
            this.setState({data:this.state.FAQS});
    }

    componentDidMount(){
            this.fetchLegalsAndFAQS(urls.getLEGALS);
    }

    fetchLegalsAndFAQS = async (url) => {
        try {   
            this.setState({fetchingData:true});
            const axios = require("axios");
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(url === urls.getLEGALS){
                    this.setState({LEGALS:res.result.data});
                    this.setState({data:res.result.data});
                    this.fetchLegalsAndFAQS(urls.getFAQS);
                }
                else
                    this.setState({FAQS:res.result.data});
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({fetchingData:false});
        } 
        catch (err) {
            alert(err.message);
            this.setState({fetchingData:false});
        }
    }

    render() { 
        return (
            <div className="help_support">
                <h3 className="help_support__heading">Help & Support</h3>
                <TabContext value={this.state.selectedTab}>
                    <TabList 
                        onChange={this.onTabChange}                                 
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        {this.state.Tabs.map((tab)=>(
                            <Tab 
                                label={tab} 
                                value={tab} 
                            />
                        ))}
                    </TabList>
                </TabContext>
                <div className="details">
                    {Object.keys(this.state.data).map((key)=>(
                        <div>
                            <span>{this.state.data[key]['details']}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
 
export default HelpAndSupport;