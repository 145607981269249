import urls from "../urls";

export async function fetchOrdersData(filters,getOtherInfo){
    try {
        const axios = require("axios");
        const response = await axios(urls.dashboardOrdersData, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {filters,getOtherInfo}
            })
        });
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return {'error':err.message};
    }
}