import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Header from './Components/Header/Header';
import Header from './Components/NavBar/nav';
import Login from './Pages/Login/Login';
import Home from "./Pages/HomePage/Home";
import CompaniesPage from "./Pages/CompaniesPage/CompaniesPage";
import ProductsPage from './Pages/ProductsPage/ProductsPage';
import ProductDetails from "./Pages/ProductDetailsPage/ProductDetails";
import CartPage from "./Pages/CartPage/CartPage";
import CheckoutPage from "./Pages/CheckoutPage/CheckoutPage";
import PaymentPage from "./Pages/PaymentPage/PaymentPage";
import AddressPage from "./Pages/AddressPage/AddressPage";
import PaymentConfirmationPage from './Pages/PaymentPage/PaymentConfirmationPage';
import MyOrders from './Pages/MyOrders/MyOrders';
// Ankit Start 10 March 2023
import MyOrdersDetails from './Pages/MyOrders/MyOrdersDetail';
// Ankit End 10 March 2023
import MyAddress from './Pages/MyAddress/MyAddress';
import HelpAndSupport from './Pages/HelpAndSupport/HelpAndSupport';
import ContactUs from './Pages/ContactUSPage/ContactUS';
import MyCoupons from './Pages/MyCoupons/MyCoupons';
import MyAccount from './Pages/MyAccount/MyAccount';
import OrderDetails from './Pages/OrderDetails/OrderDetails';
import ReturnOrderPage from './Pages/ReturnOrderPage/ReturnOrderPage';
import ProductCompare from './Pages/ProductCompare/ProductCompare';
import CompanyRegistration from './Pages/CompanyRegistration/CompanyRegistration';
import CompanyCompare from './Pages/CompanyCompare/CompanyCompare';
import SignUp from './Pages/SignUp/SignUp';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import ScrollToTopBtn from './Components/Shared/ScrollToTopBtn';
import urls from "./urls";
import { AuthContext } from "./Shared/context";
import './App.css';
// Console pages below
import Console from './Pages/Console/console';
import StoreOrders from './Pages/Console/StoreOrders/StoreOrders';
import StoreOrder from './Pages/Console/StoreOrder/StoreOrder';
import OrderDelivery from './Pages/Console/OrderDelivery/OrderDelivery';
import Pickings from './Pages/Console/OrderDelivery/Pickings';
import Invoices from './Pages/Console/Invoicing/Invoices';
import Invoice from './Pages/Console/Invoicing/Invoice';
import DataTransfer from './Pages/Console/DataTransfer/DataTransfer';
import UpdateProduct from './Pages/Console/UpdateProduct/UpdateProduct';
import StockInventory from './Pages/Console/StockInventory/StockInventory';
import Users from './Pages/Console/Users/Users';
//users tree 
import UsersTree from './Pages/Console/Users/UsersTree';
import StoreProducts from './Pages/Console/StoreProducts/StoreProducts';
import StoreProduct from './Pages/Console/StoreProducts/StoreProduct';

import StoreCategories from './Pages/Console/ProductCategories/StoreCategories';
import StoreCategory from './Pages/Console/ProductCategories/StoreCategory';

import PublicStoreCategories from './Pages/Console/PublicProductCategories/StoreCategories';
import PublicStoreCategory from './Pages/Console/PublicProductCategories/StoreCategory';

import StoreBrands from './Pages/Console/Brand/StoreBrands';
import StoreBrand from './Pages/Console/Brand/StoreBrand';
import StoreUomCategory from './Pages/Console/UomCategory/StoreUomCategory';
import StoreUomCategorys from './Pages/Console/UomCategory/StoreUomCategorys';

import RenewMembership from './Pages/Console/RenewMembership/RenewMembership';
import Pricelists from './Pages/Console/Pricelists/Pricelists';
import Pricelist from './Pages/Console/Pricelists/Pricelist';
//Product Varients
import ProductVarientTree from './Pages/Console/ProductVarients/ProductVarientTree';
import ProductVarient from './Pages/Console/ProductVarients/ProductVarient';
//promotion
import Promotion_tree from './Pages/Console/Promotion_Program/Promotion_Program_tree';
import Promotion from './Pages/Console/Promotion_Program/Promotion_Program';
import Loyalty from './Pages/Console/Loyalty_Program/Loyalty';
import Loyalty_tree from './Pages/Console/Loyalty_Program/Loyalty_tree';
// Dashboard Pages Below
import Dashboard from './Pages/Dashboard/Dashboard';
// Ankit Start bugid-1716 09-12-2022 
import LotSerialsTree  from './Pages/Console/StoreProducts/LotSerialsTree'
import ProductLot  from './Pages/Console/StoreProducts/productLot'
// Ankit End bugid-1716 09-12-2022 
// Ankit Start 10-01-2023
import UpdateQuantity from './Pages/Console/StoreProducts/UpdateQty'
// Ankit End 10-01-2023
// Ankit Start 24-1-2023
import ProductAttributeList from './Pages/Console/StoreProducts/ProductAttributes/ProductAtrributeTree';
import ProductAttributeFormView from './Pages/Console/StoreProducts/ProductAttributes/ProductAttributeForm';

// Ankit End 24-1-2023
import { Translation } from 'react-i18next'
import i18next from 'i18next';


function App(props) {

	const [newAddress,setNewAddress] = React.useState(null);
	const [CartSummary,setCartSummary] = React.useState(null);
	const [signInData,setSignInData] = React.useState(null);
	const [snackBarData,setSnackBarData] = React.useState({open:false,message:'',level:'',autoHideDuration:null});
	const [custom_session_info,set_custom_session_info] = React.useState({products:0,cart_quantity:0,location:''});
	const [companyTypes,setCompanyTypes] = React.useState(null);
	const [UserLang,setUserLang] = React.useState({lang1:'en'});
	// English(US)

	// handleLanguageChange(lang) {
    //     i18next.changeLanguage(lang);
    // }

	return (
		<AuthContext.Provider value={{
			newAddress,setNewAddress,CartSummary,setCartSummary,
			signInData,setSignInData,snackBarData,setSnackBarData,
			custom_session_info,set_custom_session_info,
			companyTypes,setCompanyTypes,
			UserLang,setUserLang,
		}}>
			<BrowserRouter>
				<Header/>
				<Switch>
					{/* Ankit start 12-10-2022 ugid-1635 */}
					<Route path='/' exact component={Home} />
					{/* Ankit End 12-10-2022 bugid-1635 */}
					<Route path={`${urls.extension}`} exact component={Home} />
					{/* <Route path={`${urls.extension}/contactus`} exact component={Login} /> */}
					<Route path={`${urls.extension}/login`} exact component={Login} />
					<Route path={`${urls.extension}/register_company`} exact component={CompanyRegistration} />
					<Route path={`${urls.extension}/signup`} exact component={SignUp} />
					<Route path={`${urls.extension}/reset_password`} exact component={ResetPassword} />
					<Route path={`${urls.extension}/home`} exact component={Home} />
					<Route path={`${urls.extension}/home/:companyType`} exact component={CompaniesPage} />
					<Route path={`${urls.extension}/home/:companyType/:company`} exact component={ProductsPage} />
					<Route path={`${urls.extension}/home/:companyType/:company/:product`} exact component={ProductDetails} />
					<Route path={`${urls.extension}/product_compare`} exact component={ProductCompare} />
					<Route path={`${urls.extension}/company_compare`} exact component={CompanyCompare} />
					<Route path={`${urls.extension}/shop/cart`} exact component={CartPage} />
					<Route path={`${urls.extension}/shop/checkout`} exact component={CheckoutPage} />
					<Route path={`${urls.extension}/shop/address`} exact component={AddressPage} />
					<Route path={`${urls.extension}/shop/payment`} exact component={PaymentPage} />
					<Route path={`${urls.extension}/shop/confirmation`} exact component={PaymentConfirmationPage} />
					<Route path={`${urls.extension}/my/orders`} exact component={MyOrders} />
					{/* Ankit Start 10 March 2023 */}
					<Route path={`${urls.extension}/my/orders/detail/:orderId`} exact component={MyOrdersDetails} />
					{/* Ankit End 10 March 2023 */}
					<Route path={`${urls.extension}/my/address`} exact component={MyAddress} />
					<Route path={`${urls.extension}/help`} exact component={HelpAndSupport} />
					<Route path={`${urls.extension}/contactus`} exact component={ContactUs} />
					<Route path={`${urls.extension}/my/coupons`} exact component={MyCoupons} />
					<Route path={`${urls.extension}/my/account`} exact component={MyAccount} />
					<Route path={`${urls.extension}/my/orders/details/:orderDetails`} exact component={OrderDetails} />
					<Route path={`${urls.extension}/my/orders/return/:orderDetails`} exact component={ReturnOrderPage} />
					{/* Console Routes below */}
					<Route path={`${urls.extension}/console`} exact component={Console} />
					<Route path={`${urls.extension}/console/orders`} exact component={StoreOrders} />
					<Route path={`${urls.extension}/console/orders/:orderId`} exact component={StoreOrder} />
					<Route path={`${urls.extension}/console/orders/:orderId/delivery/:pickingId?`} exact component={OrderDelivery} />
					<Route path={`${urls.extension}/console/orders/:orderId/pickings`} exact component={Pickings} />
					<Route path={`${urls.extension}/console/orders/:orderId/invoice/:pickingId?`} exact component={Invoice} />
					<Route path={`${urls.extension}/console/orders/:orderId/invoices`} exact component={Invoices} />
					<Route path={`${urls.extension}/console/products`} exact component={StoreProducts} />
					<Route path={`${urls.extension}/console/product/:productId`} exact component={StoreProduct} />

					<Route path={`${urls.extension}/console/productcategories`} exact component={StoreCategories} />
					<Route path={`${urls.extension}/console/productcategory/:categoryId`} exact component={StoreCategory} />

					<Route path={`${urls.extension}/console/publicproductcategories`} exact component={PublicStoreCategories} />
					<Route path={`${urls.extension}/console/publicproductcategory/:categoryId`} exact component={PublicStoreCategory} />

					<Route path={`${urls.extension}/console/brand`} exact component={StoreBrands} />
					<Route path={`${urls.extension}/console/brand/:categoryId`} exact component={StoreBrand} />


					<Route path={`${urls.extension}/console/uom_category`} exact component={StoreUomCategorys} />
					<Route path={`${urls.extension}/console/uom_category/:categoryId`} exact component={StoreUomCategory} />

					{/* <Route path={`${urls.extension}/console/users`} exact component={Users} /> */}
					<Route path={`${urls.extension}/console/users/:userId`} exact component={Users} />
					<Route path={`${urls.extension}/console/userstree`} exact component={UsersTree} />
					
					<Route path={`${urls.extension}/console/data_transfer`} exact component={DataTransfer} />
					<Route path={`${urls.extension}/console/product_data_update`} exact component={UpdateProduct} />
					<Route path={`${urls.extension}/console/stock_inventory`} exact component={StockInventory} />
					<Route path={`${urls.extension}/renew_membership`} exact component={RenewMembership} />
					<Route path={`${urls.extension}/console/pricelists`} exact component={Pricelists} />
					<Route path={`${urls.extension}/console/pricelist/:pricelistId`} exact component={Pricelist} />
					<Route path={`${urls.extension}/console/promotion_program/:productId`} exact component={Promotion} />
					<Route path={`${urls.extension}/console/promotion_program_tree`} exact component={Promotion_tree} />
					<Route path={`${urls.extension}/console/productvarienttree`} exact component={ProductVarientTree} />
					{/* Ankit Start 27 Jan 2023 */}
					<Route path={`${urls.extension}/console/productvarienttree/:productId`} exact component={ProductVarientTree} />
					{/* Ankit End 27 Jan 2023 */}
					<Route path={`${urls.extension}/console/productvarient/:productId`} exact component={ProductVarient} />
					<Route path={`${urls.extension}/console/loyalty_program/:loyaltyId`} exact component={Loyalty} />
					<Route path={`${urls.extension}/console/Loyalty_tree`} exact component={Loyalty_tree} />
					{/* Dashboard Routes below */}
					<Route path={`${urls.extension}/dashboard`} exact component={Dashboard} />
					{/* Ankit Start bugid-1716 09-12-2022 */}
					<Route path={`${urls.extension}/product/:productId/lot/serials/tree`} exact component={LotSerialsTree} />
					<Route path={`${urls.extension}/product/lot/:productId/:lotId`} exact component={ProductLot} />
					{/* Ankit End bugid-1716 09-12-2022 */}
					{/* Ankit Start 10-1-2023 */}
					<Route path={`${urls.extension}/Update/Quantity/:productId`} exact component={UpdateQuantity} />
					{/* Ankit End 10-1-2023 */}
					{/* ANkit Start 24 Jan 2023 */}
					<Route path={`${urls.extension}/product/attributes/tree`} exact component={ProductAttributeList} />
					{/* Ankit End 24 Jan 2023 */}
					{/* Ankit Start 24 Jan 2023 */}
					<Route path={`${urls.extension}/product/attributes/From/:attributeId`} exact component={ProductAttributeFormView} />
					{/* Ankit End 24 Jan 2023  */}
				</Switch>
				<ScrollToTopBtn />
			</BrowserRouter>
		</AuthContext.Provider>
	);
}

export default App;
