import React from 'react';
import './CompanyCompare.css';
import { changeAppTitle } from '../../Shared/utilities';
import { Button } from '@mui/material';
import { companyCompare,companyCompareBuyNow } from '../../Shared/services';
import urls from '../../urls';

class CompanyCompare extends React.Component {

    state = {
        data: [],
        attributes: null,
        showPage: false,
        // Ankit Start 19 May 2023
        lines: {},
        companies: {},
        product_price_total: {},
        products: {}
        // Ankit End 19 May 2023
    }

    componentDidMount() {
        changeAppTitle('Shopaas | Company Compare');
        this.getComparisionData();
    }

    getComparisionData = async () => {
        let data = { method: 'GET' };
        let res = await companyCompare(data);
        if (res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if (res.result.statusCode === 201)
            alert(res.result.message);
        else {
            // console.log(res.result);
            // this.setState({companys:res.result.comparecompanys,attributes:res.result.attributes});
            this.setState({ lines: res.result.lines, showPage: true, companies: res.result.companies, product_price_total: res.result.product_price_total, products: res.result.products });
        }
    }

    removeCompany = async (company_id) => {
        let data = { company_id, method: 'POST', addToCompare: false };
        let res = await companyCompare(data);
        if (res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else if (res.result.statusCode === 201)
            alert(res.result.message);
        else {
            console.log({ res });
        }
    }

    buyNow = async(products, company_id)=>{
        let qty = Object.keys(this.state.lines).map(line => this.state.lines[line].qty);
        let data = {company :company_id, products: products, quantity: qty};
        let res = await companyCompareBuyNow(data);
        if (res && (res.result.errorMessage || res.error))
            alert(res.result.errorMessage || res.error);
        else {
            this.props.history.push(urls.extension + '/shop/cart');
        }
    }

    // getAttributeValue=(prod,attr)=>{
    //     let attrs = Object.keys(this.state.attributes[attr]);
    //     let isAttrValue = attrs.find(value => prod.combination_indices.includes(parseInt(value)));
    //     if(isAttrValue)
    //         return(this.state.attributes[attr][isAttrValue]);
    //     return("");
    // }

    render() {
        return (
            <>
                {this.state.showPage &&
                    <div className="company-compare">
                        <div className="company-compare-table">

                            {/* <table className="compare-table" style={{ marginTop: '2%', marginBottom: '2%'}}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '3px' }}>Company Name</th>
                                    <th style={{ padding: '3px' }}>Product Name</th>
                                    <th style={{ padding: '2px' }}>Product Price</th>
                                </tr>
                            </thead>
                            <tbody>
                    
                                {this.state.companies && this.state.companies.map(row => {
                                    return (
                                        <tr>
                                        <td>{row['company_name']}</td>
                                        {row['view_product']?
                                        <td><a href={row['view_product']} target='_blank'>{row['product_name']}</a></td>
                                        : <td>Not Found</td>
                                        }
                                        <td>{row['Price']}</td>

                                        </tr>
                                    )

                                })}
                            </tbody>


                          

                            </table> */}


                            {/* <table className="compare-table" style={{ marginTop: '2%', marginBottom: '2%' }}>
                                {Object.keys(this.state.companies).map(company => {

                                    return (
                                        <tr>
                                            <td style={{textAlign:'center'}}>{this.state.companies[company].company_name}

                                                <table border="2" bordercolor="black" style={{margin :'auto'}}>

                                                    <tr>
                                                        <td>Product Name</td>
                                                        <td>Product Price</td>
                                                    </tr>
                                                   {this.state.companies[company].lines.map(line=>{
                                                        return(
                                                            <tr>
                                                            <td>{line.product_name}</td>
                                                            <td>{line.Price}</td>
                                                        </tr>
                                                        )
                                                   })}
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </table> */}

                            <table className="compare-table" style={{ marginTop: '2%', marginBottom: '2%' }}>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>
                                            Product Name
                                        </th>
                                        <th rowSpan={2}>
                                            Product Qty
                                        </th>
                                        {this.state.lines?.[Object.keys(this.state.lines)[0]]?.['company_name'].map((c_name, ind) => {
                                            return (
                                                <th colSpan={2} style={{ textAlign: 'center' }}>{Object.values(c_name)[0]}</th>
                                            )

                                        })}

                                    </tr>
                                   <tr>
                                   {this.state.lines?.[Object.keys(this.state.lines)[0]]?.['company_name'].map((c_name, ind) => {
                                            return (
                                                <>
                                                <th>Unit Price</th>
                                                <th>Subtotal Price</th>
                                                </>
                                            )

                                        })}
                                   </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(this.state.lines).map((line) => {
                                        return (
                                            <tr>
                                                <td>{this.state.lines[line].name}</td>
                                                <td>{this.state.lines[line].qty}</td>
                                                {this.state.lines[line].product_price.map(price => {
                                                    return (
                                                        <>
                                                            <td>{price.single_product}</td>
                                                            <td>{price.qty_product}</td>
                                                        </>
                                                    )
                                                })

                                                }

                                            </tr>
                                        )
                                    })

                                    }

                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        {this.state.lines?.[Object.keys(this.state.lines)[0]]?.['company_name'].map((c_name, ind) => {
                                            return (
                                                <td colSpan={2} style={{ textAlign: 'center' }}>{this.state.product_price_total[Object.keys(c_name)[0]]}</td>
                                            )

                                        })}
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td></td>

                                        {this.state.lines?.[Object.keys(this.state.lines)[0]]?.['company_name'].map((c_name, ind) => {
                                            return (
                                                // <th colSpan={2} style={{ textAlign: 'center' }}>{this.state.product_price_total[Object.keys(c_name)[0]]}</th>
                                                !this.state.products[Object.keys(c_name)[0]].every(ele => ele === false) ?
                                                <td colSpan={2} style={{textAlign:'center'}}><Button onClick={()=>{this.buyNow(this.state.products[Object.keys(c_name)[0]], c_name)}}>Buy Now</Button></td>
                                                :
                                                <td  colSpan={2}></td>
                                                
                                                )

                                        })}

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default CompanyCompare;
