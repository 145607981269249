import urls from "../urls";

export async function addToCompare(productId=null,method='POST',addToCompare=true){
    try {
        const axios = require("axios");
        let params = {method};
        if(productId)
            params = {...params,...{product_id:productId,addToCompare:addToCompare}};
        const response = await axios(urls.compareProduct, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: params
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        alert(err.message);
    }
  }
  

export async function addProductToCart(product_id,add_qty,product_template_id,set_qty=0){
    try {
        const axios = require("axios");
        const response = await axios(urls.addToCart, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {
                    product_id,add_qty,set_qty,product_template_id
                }
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        alert(err.message);
    }
}

export async function resetCart(productData){
    try {
        let params = {};
        if(productData)
            params = {...productData};
        const axios = require("axios");
        const response = await axios(urls.resetCart, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: params
            })
        }, 7000);
        const res = await response.data;
        if(!res.result.errorMessage && !res.error){
            alert("success");
            return(res);
        }
        else
            alert(res.result.errorMessage || res.error);
    } 
    catch (err) {
        alert(err.message);
    }
}

export async function fetchCart(){
    try {
        const axios = require("axios");
        const response = await axios(urls.getCart, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {}
            })
        }, 7000);
        const res = await response.data;
        return(res);
    } 
    catch (err) {
        return ;
    }
}

export async function validateDelivery(){
    try {
        const axios = require("axios");
        const response = await axios(urls.validateDelivery, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {}
            })
        }, 7000);
        const res = await response.data;
        if(res.error || res.result.errorMessage)
            return(res.error || res.result.errorMessage);
        return(res);
    } 
    catch (err) {
        return(err.message);
    }
}

export async function companyCompare(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.companyCompare, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {...data}
            })
        }, 7000);
        const res = await response.data;
        // if(res.error || res.result.errorMessage)
        //     return(res.error || res.result.errorMessage);
        return(res);
    } 
    catch (err) {
        return({'error':err.message});
    }
}


export async function companyCompareBuyNow(data){
    try {
        const axios = require("axios");
        const response = await axios(urls.company_compare_buy_now, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {data}
            })
        }, 7000);
        const res = await response.data;
        if(res.error || res.result.errorMessage)
            return(res.error || res.result.errorMessage);
        return(res);
    } 
    catch (err) {
        return(err.message);
    }
}

export async function fetchLanguages(){
    try {
        const axios = require("axios");
        const response = await axios(urls.languages, {
            method: 'POST',
            withCredentials:true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                jsonrpc: "2.0",
                params: {}
            })
        }, 7000);
        const res = await response.data;
        if(res.error || res.result.errorMessage)
            return(res.error || res.result.errorMessage);
        return(res);
    } 
    catch (err) {
        return(err.message);
    }
}
