import React, { useState, useEffect } from 'react';
import './ExtraMediaPopup.css';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ReactPlayer from 'react-player';


export default function ExtraMediaPopup(props) {

    const [state, setState] = useState({ selected_media: null, updated_media: null });
    // Ankit Start 12 May 2023
    const [formOption, setFormOption] = useState({});
    // Ankit End 12 May 2023



    useEffect(() => {
        if (props.data.open === true && props.data.selected_media) {
            const selected_media = { ...props.data.selected_media };
            setState({ selected_media });
           
        } else {
            setState({ selected_media: null });
        }

         // Ankit Start 12 May 2023
         const formOption = props.data.form_option;
         setFormOption({...formOption});
         // Ankit End 12 May 2023

    }, [props.data.open])



    const handleChange = (e, property = null, newValue = null) => {
        let key = property;
        let value = newValue;
        if (!newValue) {
            key = e.target.name;
            value = e.target.value;
            e.preventDefault();
        }
        const selected_media = { ...state.selected_media, [key]: value };
        setState({ selected_media });
    }

    const changeImage = async (e) => {
        const newImage = e.target.files[0];
        const base64 = await convertBase64(newImage);
        const split_image = base64.split(',');
        handleChange(null, e.target.name, split_image[1]);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    };

    const getDisplayImage = (imageToUpload) => {
        if (imageToUpload) {
            if (imageToUpload.startsWith("data:image"))
                return imageToUpload;
            else
               return 'data:image/png;base64,' + imageToUpload;
        }
    }


    return (
        <>
            <div>
                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={props.data.open}
                    fullWidth
                >
                    <DialogTitle>
                        Create Extra Product Media
                        <CloseIcon onClick={() => { props.handleExtraMediaActions() }} sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }} />

                    </DialogTitle>

                    <DialogContent dividers>
                        <span className='dialogContent_style'>
                            <div className='left_box'>

                                <div className="img_container">
                                    <input
                                        type="file"
                                        id="product_image"
                                        name="image"
                                        title="Product image"
                                        accept="image/*"
                                        onChange={(e) => changeImage(e)}

                                    />
                                </div>


                                <div className="console-form__inputs">
                                    <label htmlFor="img_name" className='dailoge_input'>Image Name</label>
                                    <input
                                        id="img_name"
                                        name="name"
                                        type="text"
                                        value={state.selected_media?.name}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>

                                <div className="console-form__inputs" >
                                    <label htmlFor="company_id" className='dailoge_input'>Company</label>
                                    <Select
                                        labelId="company_id"
                                        id="company_id"
                                        value={state.selected_media?.company_id}
                                        name='company_id'
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {formOption?.companies &&
                                        formOption?.companies.map((option => <MenuItem value={option.id}>{option.name}</MenuItem>))
                                        
                                        }

                                    </Select>
                                </div>

                                <div className="console-form__inputs">
                                    <label htmlFor="video_url" className='dailoge_input'>Video Url</label>
                                    <input
                                        id="video_url"
                                        name="video_url"
                                        type="text"
                                        value={state.selected_media?.video_url}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                            <div className='right_box'>
                                <div className="uploaded-image">
                                    <img className="img-preview" src={getDisplayImage(state.selected_media?.image)} alt="upload" />
                                </div>
                                {state.selected_media?.video_url? 
                                <div className='uploaded-video'>
                                    {/* <iframe className='video video-view'
                                        title='Youtube player'
                                        // sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                        src={state.selected_media?.video_url}>
                                    </iframe> */}

                                <ReactPlayer
                                width='100%'
                                className='video video-view'
                                url= {state.selected_media?.video_url}
                                controls= {true} 
                                stopOnUnmount = {true}/>
                                            </div>: ""
                                    }
                            </div>
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => {props.handleExtraMediaActions(state.selected_media)}}>
                            Save changes
                        </Button>
                        <Button autoFocus onClick={() => {props.handleExtraMediaActions(state.selected_media,'delete')}}>
                        {/* <Button> */}
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    );
}
