import React from 'react';
import './StoreProducts.css';
import { storeBrands } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import { Button, Grid, Typography ,TextField} from '@mui/material';
import urls from '../../../urls';
import SearchIcon from '@mui/icons-material/Search';
// import IconButton from '@mui/material/IconButton';


class StoreBrands extends React.Component {

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getMoreData();            

        }        
      }


    state = {
        catogaries:null,fetchingData:false,total_catogaries:0,
        productType:null,
        selectedOptions:{type:null},        
        memory:{},
        categoryPage:0,
        SearchInput:[],
        CategoryType:'public_product_category',

    }

    componentDidMount(){
        changeAppTitle('Console | Brand');
        
        this.storeBrands(this.state.categoryPage);        
    }
    handleChangeOptions=async(e,option)=>{
        let value = '';
        if(typeof(e) === 'number')
            value = e;
        else
            value = e.target.value;
        let selectedOptions = this.state.selectedOptions;
        switch(option){
                        
            case 'type':
                selectedOptions = {...selectedOptions,...{type:value}}
                break;
            default:
                alert('Wrong input');
        }
        await this.setState({selectedOptions});
        this.setState({productType:value});
        
        this.storeBrands(this.state.categoryPage,null,value);
    }
    

    storeBrands=async(page,searchbar=null,productType='product', CategoryType=this.state.CategoryType)=>{
        
        this.setState({fetchingData:true});
        let res  = await storeBrands({page,searchbar,productType,CategoryType});
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        let type = searchParams.get('type');
        let selectedOptions = this.state.selectedOptions;
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({catogaries:res.result.data.category,
                            total_catogaries:res.result.data.total_category,
                            categoryPage:page
                        });
        }
        this.setState({fetchingData:false});
    }
    
    goToProduct=(categoryId=false)=>{    
        this.props.history.push(urls.extension + '/console/brand/' + (categoryId || 'New'));
    }

    changeCategoryPage=async(page)=>{
        if(page>=0 && this.state.total_catogaries>=((this.state.categoryPage * 50) + Object.keys(this.state.catogaries).length)){
            let memory = this.state.memory;
            memory[this.state.categoryPage] = this.state.catogaries;
            this.setState({memory});
            if(page in memory){
                let catogaries = memory[page];
                this.setState({catogaries,categoryPage:page});
            }
            else
                this.storeBrands(page,this.state.SearchInput,this.state.productType);
        }
    }
    getMoreData = () =>{
		
		this.storeBrands(this.state.categoryPage,this.state.SearchInput,this.state.productType)
        
	}
    GetInput = (SearchInput=null) =>{
        this.setState({SearchInput})
    }
    // Ankit Start 29-11-2022
    discardPage = ()=>{    
    this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    render() { 
        return (
            <div className="store-orders">
                <Grid container spacing={3}>
                    <Grid item >
                    <Typography variant='h6' sx={{ fontWeight: 'bold'}}>Company Brand</Typography>
                    </Grid>
                    <Grid item >
                                
                                <Button 
                                    style={{background:"#02A457",color:"beige",fontSize: '13px'}} 
                                    onClick={()=>this.goToProduct()}
                                >
                                    Create 
                                </Button>
                            {/* Ankit Start 29-11-2022 */}
                                <Button 
                                    style={{background:"#02A457",color:"beige",fontSize: '13px', margin:'0 10px'}} 
                                    onClick={()=>this.discardPage()}
                                >
                                    Discard 
                                </Button>
                            {/* Ankit End 29-11-2022 */}
                    </Grid>
                    <Grid item >
                    {/* pankaj 09-aug-2022 add  search bar start  */}
                        
                        
                        {/* <input 
                        type='text' 
                        name='searchbar'
                        onChange={(e) => this.GetInput(e.target.value)} 
                        placeholder='enter product detail'  /> */}
                        </Grid>

                        <Grid item >
                        {/* <span  onClick={this.getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span> */}

                        {/* <TextField onChange={(e) => this.GetInput(e.target.value)} 
                        id="outlined-search" label="Enter Product Category Name" 
                        name='searchbar'   
                        onKeyDown={this._handleKeyDown}                     
                        
                        type="search" size="small" InputProps={{
                            endAdornment: (
                                <SearchIcon onClick={this.getMoreData} size="Normal"/>
                            ),}} > */}
                        
                        
                        {/* <SearchIcon onClick={this.getMoreData} size="Normal"/> */}
                        {/* </TextField> */}
                        
                        {/* <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.getMoreData}                                
                            edge="end"
                            ></IconButton> */}
                    
                    {/* search bar endd  */}
                    <Grid item >

                        <TextField onChange={(e) => this.GetInput(e.target.value)} 
                        id="outlined-search" label="Enter Brand Name" 
                        name='searchbar'   
                        onKeyDown={this._handleKeyDown}                     

                        type="search" size="small" InputProps={{
                            endAdornment: (
                                <SearchIcon onClick={this.getMoreData} size="Normal"/>
                            ),}} >


                        </TextField>
                        </Grid>
                        {/* Ankit End 27 April 2023 */}
                    </Grid>
                    
                </Grid>
                <Grid container spacing={3}>
                <div style={{width:"100%"}}>                                                       
                    
                    {this.state.catogaries && Object.keys(this.state.catogaries).length>0 &&
                        <div className="catogaries-pagination" style={{float:"right"}}>
                            <span>
                                <ArrowBack onClick={()=>this.changeCategoryPage(this.state.categoryPage-1)} style={{cursor:'pointer'}}/>
                                <span>{`${(this.state.categoryPage * 50)+1}-${(this.state.categoryPage * 50) + Object.keys(this.state.catogaries).length}/${this.state.total_catogaries}`}</span>
                                <ArrowForward onClick={()=>this.changeCategoryPage(this.state.categoryPage+1)} style={{cursor:'pointer'}}/>
                            </span>
                        </div>
                    }
                </div>
                </Grid>
                
                {this.state.catogaries &&
                    <table className="order-table">
                        <thead>
                            <tr>
                                {/* <th style={{padding:'6px'}}>Internal Reference</th> */}
                                <th style={{padding:'6px'}}>Name</th>
                                {/* <th style={{padding:'6px'}}>Sales Price</th>                                
                                <th style={{padding:'6px'}}>Type</th>
                                */}
                               {/* Ankit Start 11 April 2023 */}
                               <th style={{padding:'6px'}}>Company</th>
                               <th style={{padding:'6px'}}>Company_type</th>
                               {/* Ankit End 11 April 2023 */}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.catogaries).map((categoryId)=>(
                                <tr onClick={()=>this.goToProduct(categoryId)}>
                                    {/* <td>{this.state.catogaries[categoryId]['default_code']}</td> */}
                                    <td>{this.state.catogaries[categoryId]['brand_name']}</td>
                                    {/* Ankit Start 11 April 2023 */}
                                    <td>{this.state.catogaries[categoryId]['company_id_name']}</td>
                                    <td>{this.state.catogaries[categoryId]['company_type_name']}</td>
                                    {/* Ankit End 11 April 2023 */}
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default StoreBrands;
