import React from 'react';
import './ProductAttribute.css';
import { ProductAttributeTree } from '../../../../Shared/consoleServices';
import { changeAppTitle } from '../../../../Shared/utilities';
import Loader from '../../../../Components/Shared/Loader';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import { Button, Grid, Typography ,TextField} from '@mui/material';
import urls from '../../../../urls';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';


class ProductAttributeList extends React.Component{
    state = {
        fetchingData : false,
        lotLines:true,
        attributeData:false,
        page:0,
        total_data:0
    }

    componentDidMount(){
        changeAppTitle('Console | Product Attribute');
        this.fetchAttributeTree();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.fetchAttributeTree();
        }
      }
      

    fetchAttributeTree=async()=>{
        this.setState({fetchingData:true});
        let res  = await ProductAttributeTree({page:this.state.page});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({attributeData: res.result.data,total_data: res.result.total_data})
        }
        this.setState({fetchingData:false});
    }


    
    gotoForm=(attId = false)=>{
        this.props.history.push(urls.extension + '/product/attributes/From/'+(attId || 'New'));
    }

    changeProductsPage = (page)=>{
        if(page >= 0 && (page)*50<this.state.total_data){
            this.setState({page:page})
        }
    }   

    render(){
        return(
            <>
            <div className="store-orders">
                <Grid container spacing={3}>
                    <Grid item >
                    <Typography variant='h6' sx={{ fontWeight: 'bold'}}>Products Attributes</Typography>
                    </Grid>
                    <Grid item >
                                
                                <Button 
                                    style={{background:"#02A457",color:"beige",fontSize: '13px'}} 
                                    onClick={()=>this.gotoForm()}
                                >
                                    Create 
                                </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                <div style={{width:"100%"}}>                                                       
                    
                    {this.state.attributeData.length>0 &&
                        <div className="products-pagination" style={{float:"right"}}>
                            <span>
                                <ArrowBack onClick={()=>this.changeProductsPage(this.state.page-1)} style={{cursor:'pointer'}}/>
                                <span>{`${(this.state.page * 50)+1}-${(this.state.page * 50) + this.state.attributeData.length}/${this.state.total_data}`}</span>
                                <ArrowForward onClick={()=>this.changeProductsPage(this.state.page+1)} style={{cursor:'pointer'}}/>
                            </span>
                        </div>
                    }
                </div>
                </Grid>
             
                
            {this.state.attributeData &&
                    <table className="order-table">
                        <thead>
                            <tr>
                                <th style={{padding:'6px'}}>Name</th>
                                <th style={{padding:'6px'}}>Company</th>
                                <th style={{padding:'6px'}}>Company Type</th>
                                {/* <th style={{padding:'6px'}}>Created on</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {}
                            {this.state.attributeData.map((line)=>(
                                <tr onClick={()=>this.gotoForm(line.id)}>
                                    <td>{line.name}</td>
                                    <td>{line.company_name}</td>
                                    <td>{line.company_type_name}</td>
                                    {/* <td>{line.}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>

            </>
        )
    }

}

export default ProductAttributeList;