import React from 'react';
import '../console.css';
import {fetchVariantData} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button,Grid } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
import VariantPopup from './VariantPopup';
// Ankit Start bug-id 1600
import Chip from '@mui/material/Chip'; 
// Ankit End bug-id 1600


class VariantSelection extends React.Component {
    

    state = {
        viewMode:true, 
        promotionData:null,
        VariantData : {
            attributeList:{},
            valuesList:{},
        },
        VariantList:[],
        attribute_id:null,
        fetchingData:false,
        form_options:{},
        itemsList:{},
        partner_id:null,
        updatedPromotionData:{},
        productData:[],
        attributeItem:[],
        valuesList:[],
        popupData:{open:false},
        errors:{},
        // Ankit bug id - 1600
        selected_popups : [],
        line_counter : 1,
        UpdatedVariantList:[],
        DeletedVariantList:[],
        // Ankit bug id - 1600
        
    }
    componentDidMount(){
        // Ankit Start bug-id 1600
        // this.fetchData('attribute');
        this.fetchData('attribute').then(()=>{
            let tableList = [...this.props.tableLines];
            const VariantList = tableList.reduce((currentVal,nextVal)=>{
                let obj = currentVal.find(item=>item.id===nextVal.id && item.type === nextVal.type);
                if(obj){
                    return currentVal;
                }
                return currentVal.concat([nextVal]);
            },[]);

          this.setState({VariantList})
        });

        // Ankit Start bug-id 1600

    }

    fetchData=async(id)=>{
        this.setState({fetchingData:true});
        let res  = await fetchVariantData(id,this.state.attributeItem);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({form_options:res.result});
            let val = Object.keys(this.state.form_options)
            if(val == 'attribute_id'){
                this.state.VariantData.attributeList = this.state.form_options
            }
            else if(val == 'values') {
                this.state.VariantData.valuesList = this.state.form_options
            }
            console.log('sad')
            
        }
        if(this.state.VariantList===[]){
    }
        this.setState({fetchingData:false});
    }
    // Ankit startt bug-id 1600
    // handlePopupActions=async(selectedItems=null)=>{
    handlePopupActions=async(selectedItems=null,line_no=null)=>{
        let VariantListLine = this.state.VariantList.find(l => l.id === line_no);
        // Ankit End bug-id 1600
        console.log(this.state.VariantList);
        debugger
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if (selectedItems!=null && popupData.type === "attribute"){
        for (let element of this.state.VariantList){			
            if (element["attribute_input"][0]==selectedItems[0]){
                	alert('You have already selected this attribute');
                    return
            }			
		}
        for (let element of this.state.UpdatedVariantList){			
            if (element["attribute_input"][0]==selectedItems[0]){
                	alert('You have already selected this attribute');
                    return
            }			
		}
    }
        
        
        
        if(selectedItems){
            let updatedPromotionData = this.state.updatedPromotionData;
            if(popupData.type === "attribute"){
                let attributeItem = {...this.state.attributeItem,selectedItems}
                this.setState({attributeItem:attributeItem});
                // updatedPromotionData = {...updatedPromotionData,...{attribute_id:selectedItems}}
                // await this.setState({updatedPromotionData});
                // Ankit end bug-id 1600
                VariantListLine.attribute_input = selectedItems; 
                VariantListLine.attribute_value = [];
                // Ankit end bug-id 1600
                this.setState({selectedItems:[]});
            }
            else if(popupData.type === "values"){ 
                let valuesList = {...this.state.valuesList,selectedItems}
                this.setState({valuesList:valuesList});
                // updatedPromotionData = {...updatedPromotionData,...{values:selectedItems}}
                // await this.setState({updatedPromotionData}); 
                // Ankit end bug-id 1600
                VariantListLine.attribute_value = selectedItems;   
                // Ankit end bug-id 1600
                this.setState({selectedItems:[]});
            }
            // Ankit end bug-id 1600
         let UpdateVariantListLine = this.state.UpdatedVariantList.filter(l => l.id == line_no);
            if(UpdateVariantListLine.length > 0){
                UpdateVariantListLine = VariantListLine;
            }else{
                const UpdatedVariantList = [...this.state.UpdatedVariantList,VariantListLine];
                this.setState({UpdatedVariantList})
            }
            // Ankit end bug-id 1600  

        }
    }
    // submitList = () => {
    //     // console.log('hello world')
    //     let variantdict = {
    //         id :'False',
    //         attribute_input : this.state.attributeItem?.selectedItems,
    //         attribute_value : this.state.valuesList?.selectedItems,
    //     };
    //     let exp = [...this.state.VariantList,variantdict]
    //     this.setState({VariantList:exp})
    //     this.setState({attributeItem:[],valuesList:[]})
    //     this.props.getVariantList2(exp)

    // }

    submitList = () => {
        let exp = [...this.state.UpdatedVariantList,...this.state.DeletedVariantList];
        this.props.getVariantList2(exp);
    }


    onClickDeleteRow = (id) => {
        // users.splice(index, 1);
        const updateitem = this.state.VariantList.filter((elem,ind) =>{
            return elem.id !== id;
        });
        
        // Ankti Start bugid 1600
        const deletedRow = this.state.VariantList.filter((elem) =>{
            return elem.id == id;
        });
        // Ankit end bugid 1600

        this.setState({VariantList:updateitem});

        // Ankti Start bugid 1600
        if(deletedRow[0]['type'] === 'update' ){
            deletedRow[0]['type'] = 'remove';
            const DeletedVariantList = [...this.state.DeletedVariantList,...deletedRow];
            this.setState({DeletedVariantList});
        }
        // Ankit end bugid 1600
    }

    // Ankit start bugid-1600
    // openTaxesPopup=(type)=>{
    //     if(type == 'values'){
    //         this.fetchData('values');
    //     }
    //     let popupData = this.state.popupData;
    //     popupData = {open:true,type};
    //     this.setState({popupData});
    // }

    openTaxesPopup=(type,line_no,selected_items)=>{
        if(type == 'values'){
            let VariantListLine = this.state.VariantList.find(l => l.id === line_no);
            if(VariantListLine.attribute_input.length > 0){
            const selectedItems = {selectedItems:VariantListLine.attribute_input || []};
            this.state.attributeItem = selectedItems;
            this.fetchData('values');
            }
        }
        let popupData = this.state.popupData;
        popupData = {open:true,type,line_no,selected_items};
        this.setState({popupData});
    }

    addNewLine=()=>{
        let len = this.state.VariantList[0]?.id || 0
        let new_line = {
        id : len-1,
        attribute_input : [],
        attribute_value : {},
        type:'new'
        }
        
        let exp = [new_line,...this.state.VariantList]
        this.setState({VariantList:exp})
    }   

    // Ankit End bugid=1600


    render() { 
        return (
            <>
            <fieldset disabled={this.props.viewMode} style={{border:"none"}}>

            <div> 
                
                {/* <form onSubmit={this.handleSubmit} >
                <Button variant='secondary' onClick={() => this.openTaxesPopup('attribute')}>
                        Select Atrribute
                </Button>
                <Button variant='secondary' onClick={() => this.openTaxesPopup('values')}>
                        Select Atrribute Value
                </Button>
                <Button variant='secondary' onClick={() => this.submitList()}>
                        Select Item
                </Button>
                </form>  */}

                {/* Ankit Start bugid-1600 */}
                <Button style={{background:"#02A457",color:"beige"}} onClick={() => this.submitList()}>
                        Save Table
                </Button>
                {/* Ankit End Bug-id-1600 */}

                <div style={{marginTop: "30px"}} >
        
           {/* Ankit Start bug-id-1600 */}
           {/* Piyush Kumar 05/12/2022  bugid- 1717 commented and added style in button */}
           {/* <Button  variant='secondary' onClick={() => this.addNewLine()}> */}
           <Button style={{background:"#02A457",color:"beige"}} variant='secondary' onClick={() => this.addNewLine()}>
              Add New Line  
        </Button>
        {/* Piyush KUmar end */}
        {/* Ankit End Bug-id-1600 */}
        <Grid container >
        <Grid item xs={12} sm={12} justifyContent="center" md={6} style={{overflow:'scroll'}}>
        <table className="order-table">
        <tr>
            <th style={{height: "30px",textAlign:'center',fontSize:14}}>Attributes </th>
            <th style={{height: "30px",textAlign:'center',fontSize:14}}>Values</th>
            <th style={{height: "30px",textAlign:'center',fontSize:14}}></th>
        </tr>
        {/* {
            this.state.VariantList.map((elem,ind) => {
                return(
                    <tr key={ind} >
                    <td style={{textAlign:'center'}}>{this.state.VariantData.attributeList.attribute_id[elem.attribute_input]}</td>
                    {/* {renderbody(elem)} */}
                    {/* <td style={{textAlign:'center'}}>{elem.attribute_value}</td>
                    <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteRow(ind)}>&#10006;</td>
                    </tr>
                )
            } )
        }  */}

        {/* Ankit Start Bug id-1600 */}

        {
            this.state.VariantList?.map((elem,ind) => {
                return elem.type !== "remove" ?(
                    
                    <tr key={ind} >
                    <td style={{textAlign:'center'}} onClick={ elem.type === 'new'?() => this.openTaxesPopup('attribute',elem.id,elem.attribute_input) : "" }>
                        {elem.attribute_input.length===0?<span>Enter The Value</span> :this.state.VariantData.attributeList.attribute_id[elem.attribute_input]}
                        </td>
                    <td style={{textAlign:'center'}} onClick={elem.attribute_input.length!==0? () => this.openTaxesPopup('values',elem.id,elem.attribute_value):""}>
                        {Object.values(elem.attribute_value).map(e => { 
                            return <Chip label={e} size="small"  variant="outlined" />
                        } )}</td>

                    <td style={{textAlign:'center'}} onClick={() => this.onClickDeleteRow(elem.id)}>&#10006;</td>
                    </tr>
                ) : ''
            }  )
        }

        {/* Ankit Start Bug id-1600 */}

      
        </table>
            </Grid>
        </Grid>
        </div>
              
                <VariantPopup
                    data={this.state.popupData}
                    attrData = {this.state.VariantData}
                    getListOfItems1 ={this.getListOfItems}
                    handlePopupActions={this.handlePopupActions}

                />
          
            </div>
            </fieldset>
            </>
        );

    }

}
export default VariantSelection;
