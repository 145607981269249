import React from 'react';
import './StockInventory.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { getStockInventory,saveStockInventory } from '../../../Shared/consoleServices';
import { Button } from '@mui/material';
import Chip from '@material-ui/core/Chip';
import Loader from '../../../Components/Shared/Loader';
import { Select,MenuItem } from '@material-ui/core';
import ItemPopup from './ItemPopup';
import '../console.css';
import '../../../Components/Tables/OrderTables.css'

class StockInventory extends React.Component {

    state = {
        viewMode:true,
        inventoryData:null,
        updatedInventoryData:{},
        errors:{},
        form_options:{},
        fetchingData:false,
        updatingInventoryData:false,
        popupData:{open:false},
        selectedItems:{locations:{},products:{}},
        dataUpdated:false
    }

    componentDidMount(){
        changeAppTitle('Console | Stock Inventory');
        this.getStockInventory();
    }

    getStockInventory=async()=>{
        this.setState({fetchingData:true});
        let res  = await getStockInventory();
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({inventoryData:res.result.data,form_options:res.result.form_options});
            if(res.result.data?.location_ids)
                await this.handlePopupActions({"locations":res.result.data.location_ids},'locations')
            if(res.result.data?.product_ids)
                await this.handlePopupActions({"products":res.result.data.product_ids},'products')
            if(res.result.data?.category)
                await this.handlePopupActions({"category":res.result.data.product_ids},'category')
        }
        this.setState({fetchingData:false});
    }

    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedInventoryData = this.state.updatedInventoryData;
        updatedInventoryData = {...updatedInventoryData,...{[key]:value}};
        this.setState({updatedInventoryData});
    }

    handleSubmit=async(e)=>{
        this.setState({fetchingData:true});
        let res = await saveStockInventory(this.state.inventoryData['id'],this.state.updatedInventoryData);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            if(res.result.data){
                this.setState({inventoryData:res.result.data,form_options:res.result.form_options});
                if(res.result.data.location_ids)
                await this.handlePopupActions({"locations":res.result.data.location_ids},'locations')
                if(res.result.data.product_ids)
                    await this.handlePopupActions({"products":res.result.data.product_ids},'products')
                if(res.result.data?.category)
                await this.handlePopupActions({"category":res.result.data.product_ids},'category')
            }
            else
                this.setState({dataUpdated:true});
        }
        this.setState({fetchingData:false,updatedInventoryData:{}});
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }


    getTableData=()=>{
        let data = null;
        let lines = null;
        lines = this.state.inventoryData.move_line_ids_without_package;
        if(Object.keys(lines).length>0){
            data = [];
            Object.keys(lines).forEach(lineId => {
                let newRow = lines[lineId];
                data = [...data,newRow];
            }); 
        }
        return data;
    }

    changeInventoryLineData=(e,lineId,type)=>{
        let lines = {};
        let line = {};
        if(this.state.updatedInventoryData.hasOwnProperty(type))
            lines = this.state.updatedInventoryData[type];
        if(e){
            let key = e.target.name;
            let value = e.target.value;
            if(lines.hasOwnProperty(lineId))
                line = lines[lineId];
            line = {...line,...{[key]:parseFloat(value)}};
        }
        else{
            line = null;
        }
        lines = {...lines,...{[lineId]:line}};
        this.handleChange(null,type,lines);
    }

    showBtn=(btnType)=>{
        let show = false;
        let btnStatus = this.state.inventoryData.btnStatus;
        if(btnType === 'show_start_inventory'){
            show = btnStatus.show_start_inventory;
        }
        else if(btnType === 'show_cancel'){
            show = btnStatus.show_cancel;
        }
        else if(btnType === 'show_validate'){
            show = btnStatus.show_validate;
        }
        return show;
    }

    openPopup=(type)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:true,type}};
        this.setState({popupData});
    }

    deleteSelectedItem=(itemType,id)=>{
        let selectedItems = this.state.selectedItems;
        let items = selectedItems[itemType];
        delete items[parseInt(id)];
        let updatedData = {[itemType]:items}
        selectedItems = {...selectedItems,...updatedData};
        this.setState({selectedItems});
    }
    
    handlePopupActions=(selectedItems=null,type=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if(selectedItems){
            let temp = this.state.selectedItems;
            temp = {...temp,...selectedItems};
            this.setState({'selectedItems':temp});
        }
    }

    performAction=async(action)=>{
        let updatedInventoryData = this.state.updatedInventoryData;
                if(action === 'start_inventory'){
            let selectedItems = this.state.selectedItems;
            let temp = {locations:Object.keys(selectedItems.locations),products:Object.keys(selectedItems.products)}
            updatedInventoryData = {...updatedInventoryData,...{'selectedItems':temp}};
        }
        else if(action === 'cancel')
            updatedInventoryData = {};
        updatedInventoryData = {...updatedInventoryData,...{action}};
        await this.setState({updatedInventoryData});
        let temp = this.state.selectedItems;
            temp = {};
            this.setState({'selectedItems':temp});
        this.handleSubmit();
    }

    getLineData=(field,row)=>{
        let updatedInventoryData = this.state.updatedInventoryData || {}; 
        let line = (updatedInventoryData['line_ids'] && updatedInventoryData['line_ids'][row['id']]) || {};
        let qty = (line && line[field]) || row[field];
        return(qty);
    }


       // Ankit Start 29-11-2022
    discardPage = ()=>{    
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    render() { 
        return (
            <div className="console-form">
                {this.state.inventoryData && !this.state.dataUpdated &&
                    <form onSubmit={this.handleSubmit}>
                        <header className='inventory-header'>
                            <span className="header__left">
                                <h3 className="console-form__heading">{this.state.inventoryData['name']}</h3>
                                {this.showBtn('show_start_inventory') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("start_inventory")}>
                                        Start Inventory
                                    </Button>
                                } 
                                {this.showBtn('show_validate') &&
                                // Ankit Start 20 Jan 2023No need to write full Name)
                                    // <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("validate")}>
                                    //     Validate Inventory
                                    // </Button>
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("validate")}>
                                    Validate
                                </Button>
                                } 
                                {this.showBtn('show_cancel') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("cancel")}>
                                        Cancel
                                    </Button>
                                } 
                                <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.discardPage()}>
                                    Discard
                                </Button>
                            </span>
                            <span className="header__right">
                                <h3>{this.state.inventoryData['state']}</h3>
                            </span>
                        </header>   
                        {this.showBtn('show_start_inventory') &&
                            <fieldset style={{border:"none"}}>
                                <span>
                                    {this.showBtn('show_start_inventory') && 
                                        <div className="console-form__inputs" >
                                            <label htmlFor="prefill_counted_quantity">Counted Quantities</label>
                                            <Select
                                                labelId="prefill_counted_quantity"
                                                id="prefill_counted_quantity"
                                                onChange={this.handleChange}
                                                value={this.state.updatedInventoryData?.prefill_counted_quantity || this.state.inventoryData.prefill_counted_quantity}
                                                name='prefill_counted_quantity'
                                            >
                                                {Object.keys(this.state.form_options['prefill_counted_quantity']).map((option)=>(
                                                    <MenuItem value={option}>
                                                        {this.state.form_options['prefill_counted_quantity'][option]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    }
                                    <div className="console-form__inputs" >
                                        <label htmlFor="accounting_date">Accounting Date</label>
                                        <input
                                            id="accounting_date"
                                            name="accounting_date"
                                            type="date"
                                            onChange={this.handleChange}
                                            value={this.state.updatedInventoryData?.accounting_date}
                                            disabled={false}
                                        />
                                    </div>
                                </span>
                                <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("locations")}>
                                        Select Locations
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                        {this.state.selectedItems?.locations && 
                                        <div> 
                                        {Object.keys(this.state.selectedItems['locations']).map((locationId)=>(
                                            <Chip
                                                label={this.state.selectedItems['locations'][locationId]['name']}
                                                onDelete={()=>this.deleteSelectedItem("locations",locationId)}
                                            />
                                        ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                                {/* pankaj add product category */}
                                <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("category")}>
                                        Select Category
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {this.state.selectedItems?.category && 
                                        <div> 
                                        {Object.keys(this.state.selectedItems['category']).map((productId)=>(
                                            <Chip
                                                label={this.state.selectedItems['category'][productId]['name']}
                                                onDelete={()=>this.deleteSelectedItem("category",productId)}
                                            />
                                        ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                                {/* pankaj end */}
                                <div>
                                    <Button size='small' style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup("products")}>
                                        Select Products
                                    </Button>
                                    <div style={{display:'flex',gap:'5px',flexWrap:'wrap',margin:'5px'}}>
                                    {this.state.selectedItems?.products && 
                                        <div> 
                                        {Object.keys(this.state.selectedItems['products']).map((productId)=>(
                                            <Chip
                                                label={this.state.selectedItems['products'][productId]['name']}
                                                onDelete={()=>this.deleteSelectedItem("products",productId)}
                                            />
                                        ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        }
                        {!this.showBtn('show_start_inventory') &&
                            <fieldset style={{border:"none"}}>
                                {/* Ankit Start 19 Jan 2023 */}
                            
                                <div className='table-div'>
                                {/* <table className="console-table-line"> */} 
                                {/* Ankit End 19 Jan 2023 */}
                                <table className='table-style'>
                                    <thead>
                                        <th style={{padding:'6px'}}>Product</th>
                                        <th style={{padding:'6px'}}>Location</th>
                                        <th style={{padding:'6px'}}>Lot/Serial Number</th>
                                        <th style={{padding:'6px'}}>On hand</th>
                                        <th style={{padding:'6px'}}>Counted</th>
                                        <th style={{padding:'6px'}}>Difference</th>
                                    </thead>
                                    <tbody>
                                        {Object.values(this.state.inventoryData.line_ids).map((row)=>(
                                            <tr>
                                                <td>{row['product_id']['name']}</td>
                                                <td>{row['location_id']['name']}</td>
                                                <td>{row['prod_lot_id']['name']}</td>
                                                <td>{row['theoretical_qty']}</td>
                                                <td>
                                                    <input 
                                                        className='table-input'
                                                        id="product_qty"
                                                        name="product_qty"
                                                        type="number"
                                                        value={this.getLineData('product_qty',row)}
                                                        onChange={(e)=>this.changeInventoryLineData(e,row['id'],'line_ids')}
                                                    />
                                                </td>
                                                <td>
                                                    {parseFloat(this.getLineData('product_qty',row)) - parseFloat(row['theoretical_qty'])}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                             {/* Ankit Start 19 Jan 2022 */}
                                </div>
                                {/* Ankit End 19 Jan 2022 */}
                            </fieldset>
                        }
                    </form>
                }
                {this.state.dataUpdated &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inventory stock updated successfully
                    </div>
                }
                <ItemPopup 
                    origin="stock_inventory"
                    open={this.state.popupData['open']}
                    type={this.state.popupData['type']}
                    handlePopupActions={this.handlePopupActions}
                    id = {this.state.inventoryData?.id}
                />
                <Loader open={this.state.fetchingData || this.state.updatingInventoryData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default StockInventory;
