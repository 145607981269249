import React from 'react';
import { Button,Grid } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
// Ankit Start 07-11-2022 bugid-1602
// import {companyDataTransfer,saveCompanyDataTransfer} from '../../../Shared/consoleServices';
import {companyDataTransfer, saveCompanyDataTransfer, getToCompanyData} from '../../../Shared/consoleServices';
import CompanySearchPopup from './CompanySearchPopup';
// Ankit End 07-11-2022 bugid-1602
import Loader from '../../../Components/Shared/Loader';
import './DataTransfer.css';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import ItemPopup from './ItemPopup';
import Chip from '@material-ui/core/Chip';
import '../console.css';

class DataTransfer extends React.Component {

    state = {
        transferData:null,
        fetchingData:false,
        updatingData:false,
        viewMode:true,
        productPage:0,
        allSelected:false,
        notSelected:[],
        selected:[],
        notPublished:[],
        memory:{},
        popupData:{open:false},
        selectedItem:null,
        dataTransfered:false,
        // Ankit Start 09-11-2022 bugid-1602
        companypopup : {open:false},
        // Ankit End 09-11-2022 bugid-1602
        // Ankit Start 21-nov-2022 
        publishedData : {}
        // Ankit End 21-nov-202

    }

    componentDidMount(){
        this.getCompanyDataTransfer();
    }

    getCompanyDataTransfer=async()=>{
        this.setState({fetchingData:true});
        let res = await companyDataTransfer(this.state.productPage);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({transferData:res.result.data,transfer_form_options:res.result.transfer_form_options});
        }
        this.setState({fetchingData:false});
    }

    handleSubmit=async(e)=>{
        let updatedTransferData = this.state.updatedTransferData;
        if(updatedTransferData && Object.keys(updatedTransferData).length>0){
            this.setState({fetchingData:true});
            if(updatedTransferData?.action==='transfer'){
                let notSelected = this.state.notSelected;
                let selected = this.state.selected;
                let allSelected = this.state.allSelected;
                // Ankit Start 21-11-2022
                // updatedTransferData = {...updatedTransferData,...{allSelected,notSelected,selected}};
                const publishedData = this.state.publishedData;
                updatedTransferData = {...updatedTransferData,...{allSelected,notSelected,selected,publishedData}};
                // Ankit End 21-11-2022
            }
            let res = await saveCompanyDataTransfer(this.state.productPage,this.state.transferData['id'],updatedTransferData);
            if(res.error || res.result.errorMessage){
                alert(res.error || res.result.errorMessage);
            }
            else{
                if(res.result.data)
                    this.setState({transferData:res.result.data,updatedTransferData:{},memory:{}});
                else
                    this.setState({dataTransfered:true});
            }
            this.setState({fetchingData:false});
        }
    }

    // Ankit Start 20 Jan 2022
    handleValidation = ()=>{
        const error_field = {product_category:'Category',product:'Products'}
        const transfer_applied_on = (this.state.updatedTransferData.transfer_applied_on || this.state.transferData['transfer_applied_on']) 
        if (transfer_applied_on == 'product_category' || transfer_applied_on == 'product'){
            if (!this.state.updatedTransferData.selectedItem){
                alert(`Select the ${error_field[transfer_applied_on]} to Start Inventory`);
                return false
            }
        }
        return true;
    }

    // Ankit End 20 Jan 2022

    showBtn=(btnType)=>{
        let show = false;
        if(btnType === 'edit')
            show = this.state.transferData.btnStatus.show_start || true;
        if(btnType === 'show_start')
            show = this.state.transferData.btnStatus.show_start;
        else if(btnType === 'show_transfer')
            show = this.state.transferData.btnStatus.show_transfer;
        else if(btnType === 'show_cancel')
            show = this.state.transferData.btnStatus.show_transfer;
        return show;
    }

    // Ankit Start bugid-1602 07-11-2022
    onChangeFromCompany = async (value) => {
        if (value.from_company_id){
            this.setState({fetchingData:true});
            let res = await getToCompanyData(value);
            if(res.result.to_company_data){
                const to_company_id = {...res.result.to_company_data}
                const transfer_form_options = {...this.state.transfer_form_options,to_company_id};
                this.setState({transfer_form_options});
            }
        }
        this.setState({fetchingData:false});

    } 

    // Ankit End bugid-1602 07-11-2022

    handleChange=async(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        // Ankit Start 09-11-2022 bugid-1602
        if(e.target.value === 'Search_More'){
            this.openPopup('Search_More');
            return
        }
        // Ankit End 09-11-2022 bugid-1602
        if(!newValue){
            e.preventDefault();
            key = e.target.name;
            value = e.target.value;
        }
        let updatedTransferData = this.state.updatedTransferData;
        updatedTransferData = {...updatedTransferData,...{[key]:value}};
        // Ankit start bugid 1602 07-11-2022
        // if(this.state.transferData.is_admin_user){
            this.onChangeFromCompany(updatedTransferData);
        // }
        // Ankit End bugid 1602 07-11-2022
        await this.setState({updatedTransferData});
        if(Object.keys(updatedTransferData).includes('to_company_id')){
            this.handleSubmit();
        }
        else if(Object.keys(updatedTransferData).includes('transfer_applied_on')){
            this.handleSubmit();
            this.setState({selectedItem:null});
        }
    }

    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }

    performAction=async(action)=>{
        let updatedTransferData = this.state.updatedTransferData;
        updatedTransferData = {...updatedTransferData,...{action}};
        let selectedItem = this.state.selectedItem;
        if(selectedItem){
            // selectedItem = selectedItem.id;
            selectedItem = selectedItem;
            updatedTransferData = {...updatedTransferData,...{selectedItem}};
        }
        await this.setState({updatedTransferData});
        // Ankit Start 20 Jan 2022
        if (action == 'start'){
            if(!this.handleValidation()){
                return false
            }
        }
        // Ankit End 20 Jan 2022
        this.handleSubmit();
        await this.setState({selectedItem:null});
    }

    changeProductsPage=async(page)=>{
        if(page>=0 && this.state.transferData.total_line_ids<((this.state.productPage * 50) + Object.keys(this.state.transferData.product_transfer_line_ids).length)){
            let memory = this.state.memory;
            memory[this.state.productPage] = this.state.transferData.product_transfer_line_ids;
            this.setState({memory});
            this.setState({fetchingData:true});                
            let transferData = this.state.transferData;
            if(page in memory){
                let product_transfer_line_ids = {};
                product_transfer_line_ids = memory[page];
                transferData = {...transferData,...{product_transfer_line_ids:product_transfer_line_ids}}
            }
            else{
                let res = await companyDataTransfer(page);
                if(res.error || res.result.errorMessage)
                    alert(res.error || res.result.errorMessage);
                else
                    transferData = {...transferData,...{product_transfer_line_ids:res.result.data.product_transfer_line_ids}}
            }
            this.setState({transferData,fetchingData:false,productPage:page});
        }
    }

    selectAllProducts=(select)=>{
        if(select)
            this.setState({allSelected:select,notSelected:[]});
        else
            this.setState({allSelected:select,selected:[]});
        let transferData = this.state.transferData;
        let product_transfer_line_ids = transferData.product_transfer_line_ids;
        Object.values(product_transfer_line_ids).forEach(line=>{
            line['selected'] = select;
            product_transfer_line_ids = {...product_transfer_line_ids,...{[line['id']]:line}};
        })
        transferData = {...transferData,...{product_transfer_line_ids}};
        this.setState({transferData});
    }

    isLineSelected=(line)=>{
        let selected = false;
        selected = this.state.selected.includes(line['id']) || (!this.state.notSelected.includes(line['id']) && (this.state.allSelected || line['selected']));
        return(selected);
    }

    handleLineChange=(e,line)=>{
        let newValue = e.target.checked;
        let notSelected = this.state.notSelected;
        let selected = this.state.selected;
        line['selected'] = newValue;
        let transferData = this.state.transferData;
        let product_transfer_line_ids = transferData.product_transfer_line_ids;
        product_transfer_line_ids = {...product_transfer_line_ids,...{[line['id']]:line}};
        transferData = {...transferData,...{product_transfer_line_ids}};
        this.setState({transferData});
        if(this.state.allSelected){
            if(!newValue){
                if(!notSelected.includes(line['id'])){
                    notSelected.push(line['id']);
                    selected = selected.filter(i=>i!==line['id']);       
                }
            }
            else
                notSelected = notSelected.filter(i=>i!==line['id']);    
        }
        else{
            if(newValue){
                if(!selected.includes(line['id'])){
                    selected.push(line['id']);
                    notSelected = notSelected.filter(i=>i!==line['id']);    
                }
            }
            else
                selected = selected.filter(i=>i!==line['id']);       
        }
        this.setState({notSelected,selected});
    }

    // Ankit Start 21-nov-2022 (for is_published update)
    publishedUpdate = (e,row)=>{
        let value = false;
        let key = e.target.name;
        if(key === 'is_published')
            value = e.target.checked;
        let publishedData = this.state.publishedData || {};
        let line = publishedData[row['id']] || {};
        line = {...line,...{[key]:value}};
        let id = row['id']; 
        publishedData = {...publishedData,...{[id]:line}};
        this.setState({publishedData});
    }

    isPublished = (row)=>{
        const publishedData = this.state.publishedData || {}; 
        const line = publishedData[row['id']] || {};
        if (Object.values(line)!=0)
            return line['is_published'];
        else{
            return row['is_published'];
        }
        

    }
    // Ankit End 21-nov-2022 

    transferAppliedOn=(option)=>{
        let checked = (this.state.updatedTransferData?.transfer_applied_on || this.state.transferData['transfer_applied_on']) === option;
        return(checked);
    }

    // Ankit Start 07-11-2022 bugid-1609
    // openPopup=()=>{
    //     let popupData = this.state.popupData;
    //     let type = 'category';
    //     if(this.state.transferData.transfer_applied_on === 'product')
    //         type = 'product';
    //     popupData = {...popupData,...{open:true,type}};
    //     this.setState({popupData});
    // }

    openPopup=(popupType = null)=>{
        if(popupType == 'Search_More'){
            let companypopup = {id : this.state.updatedTransferData?.from_company_id};
            companypopup = {...companypopup,...{open:true,type:'company'}};
            this.setState({companypopup});
        }
        else{
        let popupData = this.state.popupData;
        let type = 'category';
        if(this.state.transferData.transfer_applied_on === 'product')
            type = 'product';
        popupData = {...popupData,...{open:true,type}};
        this.setState({popupData});
        }
    }
    // Ankit End 07-11-2022 bugid-1609

    handlePopupActions=(selectedItem=null)=>{
        let popupData = this.state.popupData;
        popupData = {...popupData,...{open:false}};
        this.setState({popupData});
        if(selectedItem){
            this.setState({selectedItem});
        }
    }

    // Ankit Start 09-11-2022 bugid-1602
    handleCompanyPopupActions = (selectedItem=null)=>{
        let companypopup = this.state.companypopup;
        companypopup = {...companypopup,...{open:false}};
        this.setState({companypopup});
        if(selectedItem){
            let updatedTransferData = this.state.updatedTransferData;
            updatedTransferData = {...updatedTransferData,...{to_company_id:selectedItem.id}};
            const new_value = {[selectedItem.id]:selectedItem};
            const to_company_id = {...this.state.transfer_form_options.to_company_id,...new_value};
            const transfer_form_options = {...this.state.transfer_form_options,to_company_id};
            this.setState({transfer_form_options,updatedTransferData});
        }
    }
    // Ankit End 09-11-2022 bugid-1602
    
    // Ankit start 18-11-2022
    startbtn = ()=>{
        const from_company = this.state.updatedTransferData?.from_company_id || this.state.transferData.from_company_id.id
        const from_company_avilable = Object.keys(this.state.transfer_form_options['from_company_id']).indexOf(String(from_company));
        if(from_company_avilable>=0){
            if (this.state.selectedItem === 'all_product'){
                return false;
            }
            else if (((this.state.transferData['transfer_applied_on'] === 'product_category' || 
            this.state.transferData['transfer_applied_on'] === 'product') && this.state.selectedItem !== null)){
            return false;
        }}
        else{
            return true;
        }
        
       
    }
    // Ankit End


     // Ankit Start 29-11-2022
     discardPage = ()=>{
        
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    // Piyush Kumar 5/6/2023 - for delete chip value
    deleteItem = (item) => {
        let remove = this.state.selectedItem;
        // console.log("Item and remove...\n",item,"\n",remove)
        remove = remove.filter(i => i !== item);
        // console.log("Remove after filter ....\n",remove)
        this.setState({selectedItem:remove})
    }
    // Piyush KUmar End

    render() { 
        return (
            <div className="console-form">
                {this.state.transferData && !this.state.dataTransfered &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                {this.showBtn('show_start') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("start")}
                                    // Ankit Start 
                                    disabled={this.startbtn()}
                                    // Ankit End
                                    >
                                        Start
                                    </Button>
                                }
                                {this.showBtn('show_transfer') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("transfer")}>
                                        Transfer
                                    </Button>
                                }
                                {this.showBtn('show_cancel') &&
                                    <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.performAction("cancel")}>
                                        Cancel
                                    </Button>
                                }
                                {/* Ankit Start 29-11-2022 */}
                                 <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.discardPage()}>
                                        Discard
                                    </Button>
                                    {/* Ankit End 29-11-202 */}
                            </span>
                            <span className="header__right"></span>
                        </header>   
                        <fieldset disabled={this.showBtn('show_transfer')} style={{border:"none"}}>
                            <span>
                                {/* <div className="console-form__inputs" >
                                    <label htmlFor="from_company_id">From Company</label>
                                    <input
                                        id="from_company_id1"
                                        name="from_company_id1"
                                        type="text"
                                        // value={this.state.transferData['from_company_id']['name']}
                                        disabled={true}
                                    />
                                </div> */}
                                <div className="console-form__inputs" >
                                    <label htmlFor="to_company_id">From Companies</label>
                                    <Select
                                        labelId="from_company_id"
                                        id="from_company_id"
                                        onChange={this.handleChange}
                                        value={parseInt(this.state.updatedTransferData?.from_company_id || this.state.transferData.from_company_id.id)}
                                        name='from_company_id'
                                    >
                                        {Object.keys(this.state.transfer_form_options['from_company_id']).map((option)=>(
                                            <MenuItem value={parseInt(option)}>
                                                {this.state.transfer_form_options['from_company_id'][option]['name']}
                                               
                                            </MenuItem>
                                             
                                        ))}
                                        {/* Ankit start 07-11-2022 bugid-1602 */}
                                        {/* {Object.keys(this.state.transfer_form_options['from_company_id']).length > 10?
                                             <MenuItem value='Search_More'> Search More  </MenuItem> : ""
                                            } */}
                                        {/* Ankit End 07-11-2022 bugid-1602 */}
                                    </Select>
                                </div>
                                <div className="console-form__inputs" >
                                    <label htmlFor="to_company_id">To Company</label>
                                    <Select
                                        labelId="to_company_id"
                                        id="to_company_id"
                                        onChange={this.handleChange}
                                        value={parseInt(this.state.updatedTransferData?.to_company_id || this.state.transferData.to_company_id.id)}
                                        name='to_company_id'
                                        // Ankit start 09-11-2022 bugid-1609
                                        disabled={Object.keys(this.state.transfer_form_options['to_company_id']).length==0}
                                        // Ankit End 09-11-2022 bugid-1609
                                    >
                                        {Object.keys(this.state.transfer_form_options['to_company_id']).map((option)=>(
                                            <MenuItem value={parseInt(option)}>
                                                {this.state.transfer_form_options['to_company_id'][option]['name']}
                                            </MenuItem>
                                        ))}
                                        {/* Ankit start 07-11-2022 bugid-1602 */}
                                        {Object.keys(this.state.transfer_form_options['to_company_id']).length > 10?
                                             <MenuItem value='Search_More'> Search More  </MenuItem> : <MenuItem> </MenuItem>
                                            }
                                        {/* Ankit End 07-11-2022 bugid-1602 */}
                                    </Select>
                                </div>
                            </span>
                            <span>
                                <div className="console-form__inputs" style={{alignItems:'flex-start'}}>
                                    <label htmlFor="from_company_id">Applied On</label>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        {Object.keys(this.state.transfer_form_options['transfer_applied_on']).map((option)=>(
                                            <span>
                                                <input
                                                    id="transfer_applied_on"
                                                    name="transfer_applied_on"
                                                    type="checkbox"
                                                    onChange={this.handleChange}
                                                    checked={this.transferAppliedOn(option)}
                                                    value={option}
                                                />
                                                <label htmlFor={option}>{this.state.transfer_form_options['transfer_applied_on'][option]}</label>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </span>
                            {this.showBtn('show_start') &&
                                <span>
                                    {this.state.transferData['transfer_applied_on'] === 'product_category' &&
                                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup()}>
                                            Select Category
                                        </Button>
                                    }
                                    {this.state.transferData['transfer_applied_on'] === 'product' &&
                                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.openPopup()}>
                                            Select Product
                                        </Button>
                                    }
                                    {this.state.selectedItem &&
                                    <>
                                    {console.log("Selected item in chip...trasfer..\n\n",this.state.selectedItem)}
                                    {this.state.selectedItem.map((item) =>(
                                        <Chip
                                            // label={this.state.selectedItem.name}
                                            label={item.name}
                                            // onDelete={()=>this.setState({selectedItem:null})}
                                            onDelete={() => this.deleteItem(item)}
                                        />
                                    ))}
                                    </>
                                    }
                                </span>
                            }
                        </fieldset>
                        {this.state.transferData.total_line_ids>0 &&
                            <>
                                <div className="products-pagination">
                                    <Button onClick={()=>this.selectAllProducts(!this.state.allSelected)} style={{background:"#02A457",color:"beige"}}>
                                        {`${this.state.allSelected?"Unselect All":'Select All'}`}
                                    </Button>
                                    <span>
                                        <ArrowBack onClick={()=>this.changeProductsPage(this.state.productPage-1)} style={{cursor:'pointer'}}/>
                                        <span>{`${(this.state.productPage * 50)+1}-${(this.state.productPage * 50) + Object.keys(this.state.transferData.product_transfer_line_ids).length}/${this.state.transferData.total_line_ids}`}</span>
                                        <ArrowForward onClick={()=>this.changeProductsPage(this.state.productPage+1)} style={{cursor:'pointer'}}/>
                                    </span>
                                </div>
                                <fieldset disabled={false} style={{border:"none"}}>
                                    {/* Ankit Start 20 Jan 2023 */}
                                <Grid container >
                                <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
                                    {/* <table className="console-table-line"> */}
                                    <table className="order-table">
                                    {/* Ankit End 20 Jan 2023 */}
                                        <thead>
                                            <th style={{padding:'6px'}}> Select</th>
                                            <th style={{padding:'6px'}}> Product</th>
                                            <th style={{padding:'6px'}}> Product Type</th>
                                            <th style={{padding:'6px'}}> Product Category</th>
                                            <th style={{padding:'6px'}}> Price</th>
                                            <th style={{padding:'6px'}}> Published</th>
                                        </thead>
                                        <tbody>
                                            {Object.values(this.state.transferData.product_transfer_line_ids).map((row)=>(
                                                <tr>
                                                    <td>
                                                        <input 
                                                            name="selected"
                                                            type="checkbox"
                                                            checked={this.isLineSelected(row)}
                                                            onChange={(e)=>this.handleLineChange(e,row)}
                                                        />
                                                    </td>
                                                    <td>{row['product_id']['name']}</td>
                                                    <td>{row['type']}</td>
                                                    <td>{row['categ_id']['name']}</td>
                                                    <td>{row['price']}</td>
                                                    <td>
                                                        <input 
                                                        // Ankit Start 21-nov-2022
                                                            // name="selected"
                                                            name="is_published"
                                                        // Ankit End 21-nov-2022
                                                            type="checkbox"
                                                            // Ankit start 21-nov-2022
                                                            // checked={row['is_published']}
                                                            checked={this.isPublished(row)}
                                                            onChange={(e)=>this.publishedUpdate(e,row)}
                                                            // Ankit End 21-nov-2022
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </Grid>
                                    </Grid>
                                </fieldset>
                            </>
                        }
                    </form>
                }
                {this.state.dataTransfered &&
                    <div className="cart-table-empty">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Products transferred successfully
                    </div>
                }
                <ItemPopup 
                    origin="product-transfer"
                    open={this.state.popupData['open']}
                    type={this.state.popupData['type']}
                    handlePopupActions={this.handlePopupActions}
                    id = {this.state.transferData?.id}
                />

                {/* Ankit Start 07-11-2022 bugid-1669 */}
                <CompanySearchPopup 
                    open={this.state.companypopup['open']} 
                    data={this.state.companypopup} 
                    handleCompanyPopupActions={this.handleCompanyPopupActions}
        
                />
                {/* Ankit Start 07-11-2022 bugid-1669 */}

                <Loader open={this.state.fetchingData || this.state.updatingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default DataTransfer;
