import { useLocation } from 'react-router-dom';
// import React from 'react'
// Ankit Start 26-dec-2022 
import React, { useState } from 'react'
// Ankit End 26-dec-2022
import './nav.css';
import shopaasLogo from '../../images/shopaas.png';
import { useHistory } from "react-router-dom";
import { ShoppingCart } from '@material-ui/icons';
import UserProfile from './UserProfile';
import urls from '../../urls';
import UserLocation from './UserLocation';
import SearchBar from './SearchBar';
import { useAuth } from '../../Shared/context';
import { Badge } from '@mui/material';
// Ankit Start 25-Dec-2022
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import shopaasSamllLogo from '../../images/small_logo.png';

// Ankit End 25-Dec-2022


function Header(props) {


    const location = useLocation();
    const history = useHistory();

    const { custom_session_info, signInData } = useAuth();
    // Ankit Start 26-dec-2022
    const [open, setOpen] = useState(false);
    // Ankit End 26-dec-2022

    function goToHomePage() {
        history.push(urls.extension + '/home');
    }

    function goToContactUsPage() {
        history.push(urls.extension + '/contactus');
    }

    function goToSignInPage() {
        history.push(urls.extension + '/login');
    }

    function goToCartPage() {
        history.push(urls.extension + '/shop/cart');
    }

    // Ankit Start 26-Dec-2022

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        //changes the function state according to the value of open

        setOpen(open);
    };

    const toggleOpenState = (val=false)=>{
        setOpen(val);
    }

// Ankit End 26-dec-2022

if (['/login'].includes(location.pathname))
    return <></>;
else
    return (
        <div className="header">
            <div className="header-left">
                <span className="logo">
                    <img src={shopaasLogo} className='big_logo' alt="Shopaas" onClick={() => goToHomePage()} />
                    <img src={shopaasSamllLogo} alt="Shopaas" className='small_logo' onClick={() => goToHomePage()} />
                </span>
                <UserLocation />
                <span className="search-bar">
                    <SearchBar
                        type="company-type"
                    />
                </span>
            </div>
            <div className="header-right">
                <div className="links">
                    <button onClick={() => goToHomePage()}>Home</button>
                    <button onClick={() => goToContactUsPage()}>Contact Us</button>
                    {parseInt(custom_session_info['cart_quantity']) > 0 &&
                        <button onClick={() => goToCartPage()}>
                            <Badge
                                badgeContent={parseInt(custom_session_info['cart_quantity'])}
                                color="success"
                            >
                                <ShoppingCart />
                            </Badge>
                        </button>
                    }



                    <UserProfile
                        goToSignInPage={goToSignInPage}
                        history={history}
                        signInData={signInData}
                        // Ankit Start 26-dec-2022
                        mobileView={false}
                    // Ankit End 26-dec-2022
                    />

                </div>
            </div>
            {/* Ankit Start 26-dec-2022 */}
            <div className='mobile-header'>
                <div className='links'>
                    {parseInt(custom_session_info['cart_quantity']) > 0 &&
                        <button onClick={() => goToCartPage()}>
                            <Badge
                                badgeContent={parseInt(custom_session_info['cart_quantity'])}
                                color="success"
                            >
                                <ShoppingCart />
                            </Badge>
                        </button>
                    }
                </div>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    sx={{
                        mr: 0,
                        display: {
                            xs: "inline",
                            sm: "none"
                        }
                    }}
                    className='menu-IconBtn'
                >
                    <MenuIcon className='MenuIcon' />
                </IconButton>
                <Drawer
                    //from which side the drawer slides in
                    anchor="right"
                    //if open is true --> drawer is shown
                    open={open}
                    //function that is called when the drawer should close
                    onClose={toggleDrawer(false)}
                    //function that is called when the drawer should open
                    onOpen={toggleDrawer(true)} >
                    {/* The inside of the drawer */}
                    <Box
                        sx={{
                            p: 2,
                            height: 1,
                            backgroundColor: "##d5d3d6"
                        }}
                    >
                        {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                        <IconButton sx={{ mb: 2 }}>
                            <CloseIcon onClick={toggleDrawer(false)} />
                            {signInData &&
                                <span className='UserName'>{signInData['name']}</span>
                            }
                        </IconButton>
                        <Divider sx={{ mb: 2 }} />

                        <ListItemButton onClick={() => { goToHomePage(); setOpen(false) }} >
                            <ListItemText primary="Home" />
                        </ListItemButton>
                        <ListItemButton onClick={() => { goToContactUsPage(); setOpen(false) }} >
                            <ListItemText primary="Contact Us" />
                        </ListItemButton>
                        <UserProfile
                            goToSignInPage={goToSignInPage}
                            history={history}
                            signInData={signInData}
                            mobileView={true}
                            toggleOpenState={toggleOpenState}
                        />
                    </Box>
                </Drawer>
            </div>
            {/* Ankit End 26-dec-2022 */}

        </div>
    );
}

export default Header;
