import { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {fetchPromotionData,fetchSpecificData,savePromtionData} from '../../../Shared/consoleServices';
import '../console.css';

export default function VariantPopup(props) {

	const [state,setState]  = useState({items:[],selectedItems:null});
	const [input,GetInput]  = useState([]);
	const [memory,StoreAll] = useState([]);
     

	const changeState=(newState)=>{
		let tempState = state;
		tempState = {...tempState,...newState};
		setState(tempState);
	}

	useEffect(() => {
		if(props.data.open === true){
            fetchItems();
		}
	}, [props.data.open])

    const fetchItems=async()=>{
		let type = (props.data.type === 'product_id'?'product':'partner');
        let res = await fetchSpecificData(props.data.type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.items});
        }
	}
	const fetchItems1=async(dat)=>{
        let res = await fetchSpecificData(dat,props.data.type);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            changeState({items:res.result.items});
			let up = [...memory,res.result.items]
			StoreAll(up)
			props.getListOfItems1(res.result.items)
        }
	}

	// Ankit Start bug-id 1600


	// const handleChange=(e,item)=>{
    //     let selectedItems = state.selectedItems || [];
	// 	if(e.target.checked){
    //         selectedItems = [...selectedItems,item];
    //     }
	// 	else{
	// 		selectedItems = selectedItems.filter(i=>i!==item)
    //     }
    //     changeState({'selectedItems':selectedItems}); 
	// }

	const handleChange=(e,item)=>{
		let selectedItems = state.selectedItems || [];
		console.log(item);
		console.log();
		if(e.target.type==='checkbox'){
			if(e.target.checked){
				const selected_value = {[item]:props.attrData.valuesList.values[item]}
				console.log(selected_value);
            	selectedItems = {...props.data.selected_items,...selectedItems,...selected_value};
        	}
			else{
				selectedItems = selectedItems.filter(i=>i!==item)
        	}
		}
		else if(e.target.type==='radio'){
			if(e.target.checked){
				selectedItems = [item];
			}
			else{
				selectedItems = selectedItems.filter(i=>i!==item)
        	}
		}
		
		changeState({'selectedItems':selectedItems});
	}


    // const isItemSelected=(item=null)=>{
    //     let selectedItems = state.selectedItems || [];
    //     let selected = selectedItems.includes(item);
    //     return(selected);
    // }

	const isItemSelected=(item=null)=>{
		// if(props.data.type==='values'){
		// let stateselected = state.selectedItems || [];
        // let propsSelected = props.data.selected_items || [];
		// let selectedItems = [...stateselected,...propsSelected];
        // let selected = selectedItems.includes(item);
        // return (selected);
		// }
    }
	// Ankit End bug-id 1600

	const getMoreData = () =>{
		console.log('add itemssss')
		fetchItems1(input);
	}
    const handlePopupActionspop = ()=>{
		// Ankit Start bug-id 1600
		// props.handlePopupActions(state.selectedItems)

		props.handlePopupActions(state.selectedItems, props.data.line_no)
		setState({selectedItems:null})
	}

	return (
		<div>
			
			<Dialog
				open={props.data.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" style={{minHeight:'200px',minWidth:'300px'}}>
						<div>
                            Select {props.data.type}
						</div>
						{/* search bar start  */}
						<div>
							<span>
							<input 
							type='text' 
							name='searchbar'
							onChange={(e) => GetInput(e.target.value)} 
							placeholder='enter detail'/>
							</span>
							<span>
								<span onClick={getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
							</span>
							
						
						</div>
						{/* search bar endd  */}
						<table className="console-table-line" style={{width:"100%",overflow:'auto'}}>
							<thead style={{top:'0'}}>
								<th>Select</th>
								<th>Variant</th>
							</thead>
                                {props.data.type == 'attribute'  ? 
                                <tbody>
								{props?.attrData?.attributeList.attribute_id && Object.keys(props.attrData.attributeList.attribute_id).map((row,idx)=>(
									<tr>
										<td style={{width:"40px"}}>
											<input 
												type="radio"
												name="selectedItem"
												checked={isItemSelected(row)}
												onChange={(e)=>handleChange(e,row)}
											/>
										</td>
										<td>{props.attrData.attributeList.attribute_id[row]}</td>
									</tr>
								))}
                                </tbody>
                            :
                            <tbody>
								{props?.attrData?.valuesList?.values && Object.keys(props.attrData.valuesList.values).map((row,idx)=>(
									<tr>
										<td style={{width:"40px"}}>
											<input 
												type="checkbox"
												name="selectedItem"
												checked={isItemSelected(row)}
												// Ankit Start bug-id 1600
												// onChange={(e)=>handleChange(e,row)}
												onChange={(e)=>handleChange(e,row)}
												// Ankit End bug-id 1600
											/>
										</td>
										<td>{props.attrData.valuesList.values[row]}</td>
									</tr>
								))}
							</tbody>
                            }
						</table>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={()=> handlePopupActionspop()} autoFocus disabled={!state.selectedItems}>
						Select
					</Button>
					<Button variant="outlined" onClick={()=>props.handlePopupActions()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
