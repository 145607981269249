import React from 'react';
import urls from '../../urls';
import './ApplyPromo.css';
import {CouponsPopup} from '../Popup/Popup';
import Loader from '../../Components/Shared/Loader';

class ApplyPromo extends React.Component {

    state = {
        promo:'',
        applyPromo:false,
        coupons:null,
        openCouponsPopup:false,
        fetchingData:false
    }

    changePromo=(e)=>{
        let promo = e.target.value;
        this.setState({promo});
    }

    
    getAvailableCoupons=async(e)=>{
        this.setState({fetchingData:true});
        try {
            const axios = require("axios");
            const response = await axios(urls.getUserCoupons, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        companySpecific:true
                    }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                this.setState({coupons:res.result.data});
                this.setState({openCouponsPopup:true});
            }
            else{
                alert(res.result.errorMessage || res.error);
            }
            this.setState({fetchingData:false});
        } 
        catch (err) {
            this.setState({fetchingData:false});
            alert(err.message);
        }
    }

    applyPromo=async(e)=>{
        this.setState({applyPromo:false,fetchingData:true});
        try {
            const axios = require("axios");
            const response = await axios(urls.applyPromo, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        promo:this.state.promo
                        }
                })
            }, 7000);
            const res = await response.data;
            this.setState({fetchingData:false});
            
            if(!res.result.errorMessage && !res.error){
                this.setState({promo:''});
                this.props.onPromoApplied(res);
                window.location.reload()
            }
            else{
                this.setState({promo:''});
                alert(res.result.errorMessage || res.error);
            }
        } 
        catch (err) {
            this.setState({promo:'',fetchingData:false});
            alert(err.message);
        }
        // window.location.reload()
    }

    handlePopupClose=()=>{
        this.setState({openCouponsPopup:false});
    }

    selectCoupon=(coupon)=>{
        this.setState({promo:coupon['promo_code']});
        this.handlePopupClose();
    }

    render() { 
        return (
            <div>
                {!this.state.applyPromo?
                    <div 
                        className="promocode-link" 
                        onClick={()=>this.setState({applyPromo:true})}
                    >
                        I have a promo code
                    </div>
                    :
                    <>
                        <div className="apply-promo">
                            <input 
                                placeholder="Enter Promo Code..."
                                type="text"
                                value={this.state.promo}
                                onChange={this.changePromo}
                            />
                            <button onClick={this.applyPromo} disabled={!this.state.promo}>Apply</button><br/>
                        </div>
                        <button onClick={()=>this.getAvailableCoupons()} className="available-coupons">Available Coupons</button>
                        <CouponsPopup 
                            handlePopupClose={this.handlePopupClose}
                            coupons={this.state.coupons || {}}
                            open={this.state.openCouponsPopup}
                            selectCoupon={this.selectCoupon}
                        />
                    </>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
            );
    }
}
 
export default ApplyPromo;
