import React from 'react';
import './Loyalty.css';
import '../console.css';
import {saveLoyaltyData,fetchLoyaltyData} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button,Alert } from '@mui/material';
import { Select,MenuItem } from '@material-ui/core';
import { Tab } from '@mui/material';
import { TabList,TabContext } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import Stack from '@mui/material/Stack';
import urls from "../../../urls";





class Loyalty extends React.Component {
    state = {
        viewMode:true,
        updatedLoyaltyData:{},
        imagesToUpload:[],
        errors:{},
        form_options:{},
        fetchingData:false,
        loyaltyUpdated:false,
        selectedTab:"Point Calculation",
        Tabs:['Point Calculation','Redemption Rules'],
        // Ankit Start 13 Jan 2022
        loyaltyId:false,
        // Ankit End 13 Jan 2022
        server_msg: false,
        severity: false,
    }
    componentDidMount(){
        let loyaltyId = this.props.match.params.loyaltyId;
        this.setState({loyaltyId:loyaltyId});
        this.fetchLoyalty(loyaltyId);
    }

    fetchLoyalty=async(loyaltyId)=>{
        this.setState({fetchingData:true});
        // let res  = await fetchLoyaltyData(this.props.match.params.loyaltyId);
        let res  = await fetchLoyaltyData(loyaltyId);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({form_options:res.result.form_options});
        }
        this.setState({fetchingData:false});
    }
    changeViewMode=async()=>{
        let viewMode = this.state.viewMode;
        this.setState({viewMode:!viewMode});
        if(!viewMode){
            this.handleSubmit();
        }
    }
    showBtn=(btnType)=>{
        let show = false;
        if(btnType === "edit"){
            show = true;
        }
        return show;

    };
    handleChange=(e,property=null,newValue=null)=>{
        let key = property;
        let value = newValue;
        if(!newValue){
            key = e.target.name;
            if(e.target.type === 'checkbox')
                value = e.target.checked;
            else{
                value = e.target.value;    
                e.preventDefault();
            }
        }
        // Ankit Start 05-11-2022 Updating Empty string
        // value = value===""?" ":value.trimStart();
        // Ankit End 05-11-2022

        let updatedLoyaltyData = this.state.updatedLoyaltyData;
        updatedLoyaltyData = {...updatedLoyaltyData,...{[key]:value}};
        // Ankit Start 13 jan 2023
        let form_options = this.state.form_options;
        form_options['loyalty_grp'][0] = {...form_options['loyalty_grp'][0],...{[key]:value}};
        // Ankit End 13 Jan 2023
        this.setState({updatedLoyaltyData,form_options: form_options});
    }
    handleSubmit=async(e)=>{
        // Ankit Start 13 Jan 2022
        if(this.handleValidation()){
        // Ankit End 13 Jan 2022
        if(this.state.updatedLoyaltyData && Object.keys(this.state.updatedLoyaltyData).length>0){
            this.setState({fetchingData:true});
            let res = await saveLoyaltyData(this.state.updatedLoyaltyData,this.state.loyaltyId);
            if(res.error || res.errorMessage){
                alert(res.error || res.errorMessage);
            }
            else{
                // this.setState({loyaltyUpdated:true});
                const loyaltyId = this.state.loyaltyId;
                console.log(res)
                if (loyaltyId == 'New'){
                    this.props.history.push(urls.extension + '/console/Loyalty_program/' + (res.loyalty_id || 'New'));
                    this.setState({loyaltyId:res.loyalty_id,server_msg: 'Success', severity: 'success',updatedLoyaltyData:{}});
                    this.fetchLoyalty(res.loyalty_id);
                }else{
                this.fetchLoyalty(loyaltyId);
                this.setState({ server_msg: 'Success', severity: 'success',updatedLoyaltyData:{}});
                }
                
            }
            this.setState({fetchingData:false});
        }
        // Ankit Start 13 Jan 2023
    }
        // Ankit End 13 Jan 2023
    }

    // Ankit Start 13 Jan 2023
    handleValidation=()=>{
        const UI_Fields_name = {
            name: 'loyalty Name',
            minimum_purchase: 'Cost', points: 'Sale Price',
            unit_of_amount:'Points of Every Purchase',
            // Ankit Start 24 Feb 2023
            minimum_redeem_amount:'Minimum Redeem Amount',
            max_redeem_amount:'Maximum Redeem Amount'
            // Ankit End 24 Feb 2023
        };

        let flag = true;
        let errors = {};
        for (let element of Object.keys(UI_Fields_name)) {
                if ((this.state.updatedLoyaltyData[element] != undefined && this.state.updatedLoyaltyData[element] == '') ||
                    (this.state.form_options?.loyalty_grp[0][element] != undefined && this.state.form_options?.loyalty_grp[0][element] == '') ||
                    (this.state.form_options?.loyalty_grp[0][element] == undefined)
                    ) {
                    errors = { ...errors, [element]: `${UI_Fields_name[element]} Cannot be Empty` }
                    flag = false;
                }
            }
        if(this.state.form_options?.loyalty_grp[0]['unit_of_amount'] == undefined||parseInt(this.state.form_options?.loyalty_grp[0]['unit_of_amount'])<=0){
            errors = {...errors, ['unit_of_amount']: `Points of Every Purchase Cannot be less than 0 or cannot be 0` }
            flag = false;
        }
        // Ankit Start 24 Feb 2023
        if(parseInt(this.state.form_options?.loyalty_grp[0]['max_redeem_amount'])<=parseInt(this.state.form_options?.loyalty_grp[0]['minimum_redeem_amount'])){
            errors = {...errors, ['max_redeem_amount']: `Maximum Redeem Amount Should be Greater Than Minimum Redeem Amount`,
            ['minimum_redeem_amount']: `Minimum Redeem Amount Should Be Less Than Maximum Redeem Amount`,}
            flag = false;
        }
        // Ankit End 24 Feb 2023

        if (!flag) {
            this.setState({ errors });
            return false
        } else {
            this.setState({ errors:{} });
            return true;
        }
    }
    // Ankit End 13 Jan 2023
    onTabChange=async(e,newValue)=>{
        this.setState({selectedTab:newValue});
       
    }
    getRadioloyalty =(e) =>{
        this.handleChange(null,'loyalty_policy',e.target.value)
    }
    
    // Ankit Start 29-11-2022
    discardPage = ()=>{    
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    // Ankit Start 05-12-22
    redeemCheck = (value) => {
        console.log(value);
        if (this.state.updatedLoyaltyData?.loyalty_policy == value || this.state.form_options?.loyalty_grp[0]?.loyalty_policy == value){
         return true
        }else{
            return false
        }
    }

    // Ankit End 05-11-2022
    render() { 
        return (
            <>
            {!this.state.loyaltyUpdated &&

            <div className="console-form console-form-loyalty">
            <header>
                <span className='Btn-box'>
                    {this.showBtn('edit') &&
                        <Button style={{background:"#02A457",color:"beige"}} onClick={()=>this.changeViewMode()}>
                            {`${this.state.viewMode?"Edit":"Save"}`}
                        </Button>
                    }
                    {/* Ankit Start 29-11-2022 */}
                    <Button style={{background:"#02A457",color:"beige", margin:'0 10px'}} onClick={()=>this.discardPage()}>
                            Discard
                        </Button>
                    {/* Ankit Start 29-11-2022 */}

                    </span>
                    <span >
                    </span>
                </header>

                  {/* mohit start */}
                  <Stack sx={{ width: '100%' }} spacing={2}>
                            {this.state.server_msg ?
                                <Alert variant="filled" style={{ marginBottom: 10 }} onClose={() => { this.setState({ server_msg: false }) }} severity={this.state.severity}>{this.state.server_msg}</Alert>
                                : ''}
                        </Stack>
                        {/* mohit End */}
          
            <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
            {this.state.form_options.loyalty_grp &&
            <span className="console-form__inputs" style={{width:'auto'}}>
            <label style={{width:'30%'}} for="name">Name:   </label>
            {/* <span style={{paddingBottom:'10px'}}></span> */}
            
                    
                        <input
                        id="name"
                        name="name"
                        // type="number"
                        type='text'
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.name || this.state.form_options?.loyalty_grp[0]?.name}
                        />
                        <span className="input-error">{this.state.errors["name"]}</span>
                    </span>
                    }
            </fieldset>
            
            <TabContext value={this.state.selectedTab}>
            <TabList 
                onChange={this.onTabChange}                                 
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
            >
                {this.state.Tabs.map((tab)=>(
                    <Tab 
                        label={tab} 
                        value={tab} 
                    />
                ))}
            </TabList>
            </TabContext>
            {this.state.selectedTab === 'Point Calculation' && 
                <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                    {this.state.form_options.loyalty_grp &&
                    <div>
                    <span className="console-form__inputs" style={{float:"left"}} >
                    <label for="minimum_purchase" className='loyalty_label'>Minimum Purchase:</label>
                
                        <input
                        id="minimum_purchase"
                        name="minimum_purchase"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.minimum_purchase || this.state.form_options?.loyalty_grp[0]?.minimum_purchase }
                        />
                        <span className="input-error">{this.state.errors["minimum_purchase"]}</span>
                    </span>
                    <span style={{margin:"0 auto",width:'90%',display:'inline-block'}}>
                        <p><b>Note</b></p>
                        <p>NOTES
                            Minimum Purchase - is sale Order amount criteria which the customer have to satisfied to gain the loyalty points.
                            Benefits Awarded is Order Stage at which the gain loyalty points willtransferred to customer accounts.
                        </p>
                    </span  >
                    <span style={{width:"100%"}}></span>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div>
                    <span className="console-form__inputs" style={{float:"left"}} >
                    <label for="points" className='loyalty_label'>Customer will awarded:</label>
                
                        <input
                        id="points"
                        name="points"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.points  || this.state.form_options?.loyalty_grp[0]?.points }
                        />
                        <span className="input-error">{this.state.errors["points"]}</span>
                    </span>
                    </div>
                    <div style={{width:"100%"}}></div>
                    <div>
                    <span className="console-form__inputs" style={{float:"left"}} >
                    <label for="unit_of_amount" className='loyalty_label'>Points For a Purchase of every:</label>
                
                        <input
                        id="unit_of_amount"
                        name="unit_of_amount"
                        type="number"
                        // Ankit Start 14 Jan 2023
                        min = {1}
                        // ANkit End 14 Jan 2023
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.unit_of_amount || this.state.form_options?.loyalty_grp[0]?.unit_of_amount }
                        />
                        <span className="input-error">{this.state.errors["unit_of_amount"]}</span>
                    </span>
                    </div>
                    </div>
                    }
                </fieldset>
            }
            {this.state.selectedTab === 'Redemption Rules' && 
                <fieldset disabled={this.state.viewMode} style={{border:"none"}}>
                    {this.state.form_options.loyalty_grp &&
                <>
                <div style={{float:"left"}}>
                <span className="console-form__inputs"  >
                <label for="max_redeem_amount" >Maximum Redeem Amount Per Sale Order:</label>
                        <input
                        id="max_redeem_amount"
                        name="max_redeem_amount"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.max_redeem_amount  || this.state.form_options?.loyalty_grp[0]?.max_redeem_amount}
                        />
                        
                        <span className="input-error">{this.state.errors["max_redeem_amount"]}</span>
                    </span>
                    
                    <span className="console-form__inputs"  >
                    <label for="minimum_redeem_amount">Minimum Redeem Amount:
                    </label>
                
                        <input
                        id="minimum_redeem_amount"
                        name="minimum_redeem_amount"
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.minimum_redeem_amount  || this.state.form_options?.loyalty_grp[0]?.minimum_redeem_amount}
                        />
                        <span className="input-error">{this.state.errors["minimum_redeem_amount"]}</span>
                    </span>
                    <span className="console-form__inputs"  >
                    <label for="company_id">Company: </label>
                        <input
                        id="company_id"
                        name="company_id"
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.updatedLoyaltyData?.company_id || this.state.form_options?.loyalty_grp[0]?.company}
                        />
                        <span className="input-error">{this.state.errors["company_id"]}</span>
                    </span>
                    {/* <span className="console-form__inputs" > */}
                    <span className="" >
                    <h3>Loyalty Policy:</h3>
                   <span>
                   <label for="one_time">One Time Redeem
                   <input type="radio" id="one_time" 
                    onChange={this.getRadioloyalty}  
                    // onClick={this.getRadioloyalty}
                    name="loyalty" value="one_time"
                    // checked={(() => {
                    //     if(this.state.form_options?.loyalty_grp[0]?.loyalty_policy == 'one_time'){
                    //         return "checked";
                    //     }
                    // })()}

                    // checked={(() => {
                    //         if( this.state.updatedLoyaltyData?.loyalty_policy == 'partial' || this.state.form_options?.loyalty_grp[0]?.loyalty_policy == 'one_time'){
                    //             return true ;
                    //         }
                    //     })()}
                    checked={this.redeemCheck('one_time')}
                    />
                    </label>
                    <br></br>
                    <label for="partial">Partial Redeem 
                    <input type="radio" 
                    id="partial" 
                    onChange={this.getRadioloyalty}  
                    name="loyalty" 
                    value="partial"
                    // checked={(() => {
                    //     if(this.state.form_options?.loyalty_grp[0]?.loyalty_policy == 'partial'){
                    //         return "checked";
                    //     }
                    // })()}

                    // checked={(() => {
                    //     if( this.state.updatedLoyaltyData?.loyalty_policy == 'partial' || this.state.form_options?.loyalty_grp[0]?.loyalty_policy == 'partial'){
                    //         return true;
                    //     }
                    // })()}
                    checked={this.redeemCheck('partial')}
                    
                    />
                    </label>
                    
                   </span>
                   
                   </span>
                </div>
                <div style={{margin:"0 auto",width:'90%',display:'inline-block'}}>
                <span >
                        <p><b>Note</b></p>
                        <p>NOTES
                            Maximum Redeem amount per sale order - is the Maximum amount of money that a user can Redeeam at once.
                            Redeem Policy - is defined the how redeem points deduction.
                            1. One Time Redeem
                            The unit will have to lost all it's points for a single redemption
                            2.Patial Redeem
                            The user will lost only the appropriated number of points foa a redemption.its Awarded is Order Stage at which the gain loyalty points willtransferred to customer accounts.
                        </p>
                    </span >
                </div>

                </>
                    }
                </fieldset>
            }
            
            {/* Ankit Start 15-11-2022 bugid-1579 adding Loder */}
            <Loader open={this.state.fetchingData || this.state.updatingData} handleClose={()=>{}}/>
            {/* Ankit End 15-11-2022 bugid-1579 adding Loder */}
            </div>
    }
            {/* Ankit Start 05-11-2022 */}
            {/* {this.state.loyaltyUpdated &&
                <div className="cart-table-empty">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Loyalty created successfully
                </div>
        } */}
        {/*  Ankit End 05-11-2022 */}
            </>
         
        )
    }
        
}
export default Loyalty;
