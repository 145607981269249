import React from 'react';
import './Users.css';
import '../console.css';
import { changeAppTitle } from '../../../Shared/utilities';
import { fetchUserData, saveUserData } from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button, Alert, Typography, Snackbar } from '@mui/material';
import { Select, MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import AllowedCompaniesPopup from './AllowedCompaniesPopup';
import urls from "../../../urls";
import { Routes, Route, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { CardActionArea, Input, TextField, Grid, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
class Users extends React.Component {


    state = {
        categoryId: null,
        viewMode: true,
        userData: null,
        updatedUserData: null,
        fetchingData: false,
        form_options: null,
        popupData: { open: false },
        userDataUpdated: false,
        server_msg: false,
        severity: false,
        fields: {},
        errors: {}
    }


    componentDidMount() {
        changeAppTitle('Console | User');
        let userId = this.props.match.params.userId;
        this.setState({ userId });
        this.fetchUserData(userId);

    }

    fetchUserData = async (id) => {
        this.setState({ fetchingData: true });
        let res = await fetchUserData(id);
        if (res.error || res.result.errorMessage) {
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({ userData: res.result.userData, form_options: res.result.form_options });
        }
        this.setState({ fetchingData: false });
    }
    // Added by mohit dec 27 for validtion of name, passsword and email
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        const array = ["name", "login"];
        var users = [
            { name: "name" },
            { name: "login" },
        ];
        for (let x of array) {

            if (document.getElementsByName(x)[0].value == "") {
                formIsValid = false;
                errors[x] = x + " can-not be empty";
            }
        }

        if (document.getElementsByName("password")[0].value == "" && this.props.match.params.userId == 'New') {
            formIsValid = false;
            errors["password"] = "Password can-not be empty";
        }

        if (typeof fields["login"] !== ("undefined" || "")) {
            let lastAtPos = fields["login"].lastIndexOf('@');
            let lastDotPos = fields["login"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["login"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["login"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["login"] = "Email is not valid";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    // Mohit Start 29-11-2022
    discardPage = () => {
        this.props.history.goBack();
    }
    // Mohit End 29-11-2022
    handleSubmit = async (e) => {
        if (this.state.updatedUserData && Object.keys(this.state.updatedUserData).length > 0) {
            this.setState({ fetchingData: true });
            let tempData = { ...this.state.userData };
            let modules = tempData.modules;
            delete tempData['modules'];

            Object.values(modules).forEach((temp) => {
                Object.keys(temp).forEach((value) => {
                    tempData[value] = temp[value]['value'];
                })
            })
            if (this.handleValidation()) {
                alert("Form submitted");
                // debugger
                // let updatedUserData = {...tempData,...this.state.updatedUserData};
                let updatedUserData = this.state.updatedUserData;
                // await this.setState({...this.state.updatedUserData,...{['company_id']:document.getElementsByName('company_id')[0].value}});
                updatedUserData = { ...updatedUserData, ...{ ['company_id']: document.getElementsByName('company_id')[0].value } };
                await this.setState({ updatedUserData });
                let res = await saveUserData(this.state.userData['id'], 'save_user', this.state.updatedUserData, document.getElementsByName('company_id')[0].value);
                if (res.error || res.result.errorMessage) {
                    this.setState({ server_msg: 'Failed !! ' + res.result.errorMessage, severity: 'error' });
                    alert(res.error || res.result.errorMessage);
                }
                else {
                    if (res.result.statusCode === "201") {
                        // this.setState({userDataUpdated:true});                                                
                        let userId = res.result.userData.id;
                        // this.props.match.params.userId = userId
                        // this.props.history.replace({userId:userId});
                        // // this.setState({categoryUpdated:true});
                        this.props.history.replace(urls.extension + '/console/users/' + (userId));
                        this.setState({ userDataUpdated: false });
                        this.setState({ userData: res.result.userData, form_options: res.result.form_options, updatedUserData: {} });
                        this.setState({ server_msg: 'Success Created User', severity: 'success' });
                    }
                    else {

                        this.setState({ userData: res.result.userData, form_options: res.result.form_options, updatedUserData: {} });
                        let userId = this.props.match.params.userId;

                        this.setState({ userDataUpdated: false });
                        this.fetchUserData(userId);

                        // this.setState({categoryUpdated:true});
                        this.setState({ server_msg: 'Success', severity: 'success' });

                        // this.props.history.push(urls.extension + '/console/UsersTree/');
                        // this.props.navigation.navigate('LoginPage')
                        // navigate(urls.extension + '/console/users/' + (userId || 'New'));
                    }
                }
            } else {
                // alert("Please fill fields email, name etc")
                

            }
            this.setState({ fetchingData: false });
        }
    }

    changeViewMode = async () => {
        let viewMode = this.state.viewMode;
        this.setState({ viewMode: !viewMode });
        if (!viewMode) {
            this.handleSubmit();
        }
    }


    showBtn = (btnType) => {
        let show = false;
        if (btnType === "edit") {
            show = true;
        }
        return (show);
    }

    handleChange = async (e, add_fields = null, property = null, newValue = null) => {

        let key = property;
        let value = newValue;
        if (!newValue) {
            ////
            if (add_fields != null) {
                let fields = this.state.fields;
                fields[add_fields] = e.target.value;
                this.setState({ fields });
            }
            ///
            key = e.target.name;
            value = e.target.value;
            if (e.target.type === "checkbox")
                value = e.target.checked;
            else
                value = e.target.value; //added mohit
            e.preventDefault();
        }
        let updatedUserData = this.state.updatedUserData;
        if (key === 'company_id') {
            let tempUpdatedUserData = { 'company_id': value, 'updatingDefaultCompany': true };
            this.setState({ fetchingData: true });
            let res = await saveUserData(this.state.userData['id'], 'save_user', tempUpdatedUserData);
            if (res.error || res.result.errorMessage) {
                alert(res.error || res.result.errorMessage);
            }
            else {
                this.setState({ form_options: res.result.form_options });
                updatedUserData = { ...updatedUserData, ...{ [key]: value }, ...{ company_ids: null } };
                await this.setState({ updatedUserData });
            }
            this.setState({ fetchingData: false });
        }
        // Ankit Start 3 March 2023
        else if(key === "sel_groups_36_40"){
            updatedUserData = { ...updatedUserData, ...{ [key]: value,sel_groups_20_21_22:'22'} };
            await this.setState({ updatedUserData });
        }
        // Ankit End 3 March 2023
        else {

            updatedUserData = { ...updatedUserData, ...{ [key]: value } };
            await this.setState({ updatedUserData });

        }
        // userData = {...userData,...{[key]:value}};
        //mohit added userData update to clear the fields in edit mode ... start dec 27
        let userData = this.state.userData;
        userData = { ...userData, ...{ [key]: value } };
        this.setState({ userData });
        //mohit added userData update to clear the fields... end dec 27
    }

    getCurrentValue = (module, option) => {
        let value = '';

        if (module) {
            value = this.state.userData.modules[module][option]['value'];
            if (this.state.updatedUserData && this.state.updatedUserData.hasOwnProperty(option))
                value = this.state.updatedUserData[option];
        }
        else {

            if (option === 'company_id') {
                value = this.state.userData['company_id'];
                if (this.state.updatedUserData && this.state.updatedUserData.hasOwnProperty('company_id'))
                    value = this.state.updatedUserData['company_id'];
            }
        }
        return (value);
    }

    openAllowedCompanies = () => {
        let popupData = this.state.popupData;
        let company_ids = this.state.updatedUserData?.company_ids || {};
        let options = this.state.form_options['company_ids'];
        popupData = { ...popupData, ...{ open: true, company_ids, options } };
        this.setState({ popupData });
        console.log({ popupData });
    }

    handlePopupActions = async (selectedItems = null) => {
        let popupData = this.state.popupData;
        popupData = { ...popupData, ...{ open: false } };
        this.setState({ popupData });
        if (selectedItems) {
            let updatedUserData = this.state.updatedUserData;
            updatedUserData = { ...updatedUserData, ...{ company_ids: selectedItems } }
            await this.setState({ updatedUserData });
        }
    }

    removeFromAllowedCompanies = async (compId) => {
        let updatedUserData = this.state.updatedUserData;
        let company_ids = updatedUserData.company_ids;
        delete company_ids[compId];
        updatedUserData = { ...updatedUserData, ...{ company_ids } }
        await this.setState({ updatedUserData });
    }

    render() {
        return (
            <div className="console-form">

                {!this.state.userDataUpdated && this.state.userData &&
                    <form onSubmit={this.handleSubmit}>
                        <header>
                            <span className="header__left">
                                <Typography mr={2} variant="h5" component="h5">
                                    Users
                                </Typography>
                                {this.showBtn('edit') &&
                                    <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.changeViewMode()}>
                                        {`${this.state.viewMode ? "Edit" : "Save"}`}
                                    </Button>

                                }
                                <Button style={{ background: "#02A457", color: "beige" }} onClick={() => this.discardPage()} >Discard</Button>
                            </span>
                            <span className="header__right">

                            </span>
                        </header>


                        {/* {this.state.userDataUpdated ? <Alert severity='success'>{this.state.server_msg.msg}</Alert> : ''}
                        {this.state.server_msg ? <Alert variant="filled" onClose={this.handleClose} severity='success'>Success</Alert> : ''}
                         */}

                        {/* using below is snackbar  */}
                        {/* <Stack sx={{ width: '100%' }} spacing={2}>
                        <Snackbar open={this.state.server_msg} autoHideDuration={10000} onClose={() => {this.setState({server_msg:false})}}>
                            {this.state.server_msg ?  
                            <Alert sx={{ width: '100%' }} variant="filled" onClose={() => {this.setState({server_msg:false})}} severity={this.state.severity}>{this.state.server_msg}</Alert>                        
                            : ''}
                        </Snackbar>
                        </Stack> */}

                        <Stack sx={{ width: '100%' }} spacing={2}>
                            {this.state.server_msg ?
                                <Alert variant="filled" style={{ marginBottom: 10 }} onClose={() => { this.setState({ server_msg: false }) }} severity={this.state.severity}>{this.state.server_msg}</Alert>
                                : ''}
                        </Stack>



                        <fieldset disabled={this.state.viewMode} style={{ border: "none" }}>

                            <Grid container style={{ marginBottom: 10 }}  >
                                <Grid container md={3} style={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={13} mr={1} >

                                        <TextField
                                            label={<Typography variant="headline" style={this.labelStyle}> User Name </Typography>}
                                            id="name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => this.handleChange(e, "name")}
                                            required
                                            value={this.state.updatedUserData?.name || this.state.userData['name']}
                                            size="small"
                                            

                                        />
                                    </Grid>
                                    {this.state.errors ? 
                                    <Grid item xs={12} md={13} mr={1}>
                                        <span htmlFor="name" className="error">{this.state.errors["name"]}</span>
                                    </Grid>
                                    : ''}
                                </Grid>

                                <Grid container md={3} style={{ marginBottom: 3 }} >
                                    <Grid item xs={12} md={13} mr={1}>
                                        <TextField
                                            label={<Typography variant="headline" style={this.labelStyle}> E-mail/User Id </Typography>}
                                            id="login"
                                            name="login"
                                            type="text"
                                            onChange={(e) => this.handleChange(e, "login")}
                                            value={this.state.updatedUserData?.login || this.state.userData['login']}
                                            required
                                            size="small"
                                            
                                        />
                                    </Grid>
                                    {this.state.errors ? 
                                    <Grid item xs={12} md={13} mr={1}>
                                        <span htmlFor="name" className="error">{this.state.errors["login"]}</span>
                                    </Grid>
                                    : ''}
                                </Grid>
                                <Grid container md={3} style={{ marginBottom: 3 }}>
                                    <Grid item xs={12} md={13} mr={1}>
                                        <TextField
                                            id="password"
                                            label={<Typography variant="headline" style={this.labelStyle}> Password </Typography>}
                                            name="password"
                                            type="text"
                                            onChange={(e) => this.handleChange(e, "password")}
                                            value={this.state.updatedUserData?.password || this.state.userData?.password || ''}
                                            size="small"
                                            
                                        />
                                    </Grid>
                                    {this.state.errors ? 
                                    <Grid item xs={12} md={13} mr={1}>
                                        <span className="error">{this.state.errors["password"]}</span>
                                    </Grid>
                                    : ''}
                                </Grid>
                            </Grid>

                            <span>
                                <div className="console-form__inputs" >
                                    <label htmlFor="company_id">Default Company</label>
                                    <Select
                                        labelId="company_id"
                                        id="company_id"
                                        onChange={this.handleChange}
                                        value={this.getCurrentValue(null, "company_id")}
                                        name="company_id"
                                    >
                                        {this.state.form_options['company_id'].map((sel) => (
                                            <MenuItem value={sel['value']}>
                                                {sel['name']}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }} >
                                    <label htmlFor="company_ids">Allowed Companies</label>
                                    <Button
                                        style={{ background: "#02A457", color: "beige", maxHeight: '30px', fontSize: '13px' }}
                                        onClick={() => this.openAllowedCompanies()}
                                    >
                                        Select
                                    </Button>
                                    {this.state.updatedUserData?.company_ids &&
                                        Object.keys(this.state.updatedUserData.company_ids).map((compId) => (
                                            <Chip
                                                label={this.state.updatedUserData.company_ids[compId]['name']}
                                                onDelete={() => this.removeFromAllowedCompanies(compId)}
                                            />
                                        ))
                                    }
                                </div>
                            </span>
                            {Object.keys(this.state.userData.modules).map((module) => (
                                <div>
                                    <h4>{module} :</h4>
                                    <span className="user-form__inputs">
                                        {Object.keys(this.state.userData.modules[module]).map((option) => (
                                            <span className="user-form__inputs checkbox">
                                                <label htmlFor={option}>{this.state.userData.modules[module][option]['name']}</label>
                                                {option.includes("sel_group") ?
                                                    <Select
                                                        labelId={option}
                                                        id={option}
                                                        onChange={this.handleChange}
                                                        value={this.getCurrentValue(module, option)}
                                                        name={option}
                                                    >
                                                        {this.state.form_options[option].map((sel) => (
                                                            <MenuItem value={sel['value']}>
                                                                {sel['name']}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    :
                                                    <input
                                                        key={option}
                                                        id={option}
                                                        name={option}
                                                        type="checkbox"
                                                        onChange={(e) => this.handleChange(e)}
                                                        checked={this.getCurrentValue(module, option)}
                                                    />
                                                }
                                            </span>
                                        ))}
                                    </span>
                                </div>
                            ))}
                        </fieldset>
                    </form>
                }
                {/* {this.state.userDataUpdated &&
                <div className="cart-table-empty">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User created successfully
                </div>
            } */}
                <AllowedCompaniesPopup
                    data={this.state.popupData}
                    handlePopupActions={this.handlePopupActions}
                />
                <Loader open={this.state.fetchingData} handleClose={() => { }} />
            </div>
        );
    }
}

export default Users;

