import React from 'react';
import './SearchBar.css';
import { Select,MenuItem } from '@material-ui/core';
import urls from '../../urls';
import { Search } from '@material-ui/icons';
import { withRouter } from "react-router";
import { AuthContext } from '../../Shared/context';

class SearchBar extends React.Component {

    static contextType = AuthContext;

    state={
        searchInput:'',
        companyTypes:[],
        selectedCompanyType:-1,
        products:[],
        product_search_input:'',
        show_view_more_btn:true
    }

    componentDidMount(){
        if(this.props.type==="company-type")
            this.fetchCompanyTypes();
    }

    fetchCompanyTypes = async () => {
        try {
            this.setState({companyTypes:[]});
            const axios = require("axios");
            const response = await axios(urls.getHomepageData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({})
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                this.setState({companyTypes:res.result.companyTypes});
                this.context.setCompanyTypes(res.result.companyTypes);
            }
        } 
        catch (err) {
        }
    }

    changeProductSearchInput=async(e)=>{
        clearInterval(this.state.productSearchIntervalId);
        let product_search_input = this.state.product_search_input;
        let value = e.target.value;
        if(value.length>2){
            let productSearchIntervalId = setInterval(() => {
                this.fetchProducts(value);
            }, 1000);
            this.setState({productSearchIntervalId});
        }
        else{
            this.setState({products:[]});
        }
        product_search_input = value;
        this.setState({product_search_input});
    }

    componentWillUnmount(){
        clearInterval(this.state.productSearchIntervalId);
    }

    fetchProducts = async (product_search_input,offset=0) => {
        try {
            clearInterval(this.state.productSearchIntervalId);
            const axios = require("axios");
            const response = await axios(urls.searchProduct, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            company_type_id:this.props.companyTypeId,
                            company_id:this.props.companyId,
                            offset:offset,
                            limit:false,
                            search_input:product_search_input
                        }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(offset>0)
                    this.setState({products:[...this.state.products,...res.result.products],show_view_more_btn:res.result.show_view_more_btn});
                else
                    this.setState({products:res.result.products,show_view_more_btn:res.result.show_view_more_btn});
            }
        } 
        catch (err) {
        }
    }

    clickHandler(product){
        // In case of any issue in routing to product details page, user clickhandler method from productcard 
        let newPageRoute = product['name'].replace('/',',') + '-' + product['id'];
        this.props.history.push(this.props.history.location.pathname + '/' +newPageRoute);
    }


    changeSearchInput=async(e)=>{
        let value = e.target.value;
        await this.setState({searchInput:value});
    }

    goToSearchPage=(e)=>{
        if((e==='Enter' || e.key === 'Enter') && this.state.searchInput){
            let selectedCompanyType = this.state.selectedCompanyType;
            let companyType = this.state.companyTypes.find(comp=>comp['id'] === selectedCompanyType) || {'id':'','name':'Shopaas Id'}
            let newPageRoute = companyType['name'].replace('/',',') + '-' + companyType['id'] + '?search=' + this.state.searchInput;
            this.props.history.push(urls.extension + '/home/'+newPageRoute);  
            // window.location.reload();
        }
    }

    handleCompanyTypeChange=(e)=>{
        this.setState({selectedCompanyType:e.target.value});
        this.setState({companies:[],searchInput:''});
    }

    render() { 
        return (
          <div className="search-bar-section">
            {this.props.type === "company-type" && (
              <>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Company Type"
                  onChange={this.handleCompanyTypeChange}
                  defaultValue={this.state.selectedCompanyType}
                  className="company-types-dropdown"
                  style={{ color: "beige" }}
                >
                  <MenuItem value={-1}>Shopaas Id</MenuItem>
                  {this.state.companyTypes.map((comp_type, idx1) => (
                    // pankaj call remove fav company id in search bar 08-04-2022
                    // <span style={{ color: "black" }}>
                    //  {/* // {comp_type["id"] != 1 ? ( */}
                        <MenuItem value={comp_type["id"]}>
                          {comp_type["name"]}
                        </MenuItem>
                      // {/* //) : null} */}
                    // </span>
                  ))}
                </Select>
                <input
                  className="company-search"
                  type="text"
                  placeholder="Search Company..."
                  onChange={this.changeSearchInput}
                  value={this.state.searchInput}
                  onKeyDown={(e) => this.goToSearchPage(e)}
                />
                <Search
                  onClick={() => this.goToSearchPage("Enter")}
                  className="search-icon"
                />
              </>
            )}
            {this.props.type === "product-search" && (
              <div style={{ position: "relative" }}>
                <input
                  className="product-search"
                  type="text"
                  // Ankit Start 30-11-2022
                  placeholder="Search Product..."
                  // Ankit End 30-11-2022
                  onChange={(e) => this.changeProductSearchInput(e)}
                  value={this.state.product_search_input}
                />
                {this.state.products.length > 0 && (
                  <div className="products-search-list">
                    {this.state.products.map((prod) => (
                      <div
                        className="product"
                        onClick={() => this.clickHandler(prod)}
                      >
                        <div>
                          <img
                            src={urls.domain + prod.image}
                            alt="no preview available"
                          />
                        </div>
                        <div className="name">{prod.name}</div>
                        <div className="price">
                          <span
                            className={`originalprice ${
                              prod.discountedPrice !== false ? "strike" : ""
                            }`}
                          >
                            &#8377; {prod.originalPrice}
                          </span>
                          {prod.discountedPrice !== false && (
                            <span className="discountedprice">
                              &#8377; {prod.discountedPrice}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                    {this.state.products.length > 0 &&
                      this.state.show_view_more_btn && (
                        <div
                          className="view-more-products"
                          onClick={() =>
                            this.fetchProducts(
                              this.state.product_search_input,
                              this.state.products.length
                            )
                          }
                        >
                          View More...
                        </div>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
    }
}
 
export default withRouter(SearchBar);
