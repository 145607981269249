import React from 'react';
import './Dashboard.css';
import { changeAppTitle } from '../../Shared/utilities';
import Loader from '../../Components/Shared/Loader';
import {fetchOrdersData} from '../../Shared/dashboardServices';
import { Select,MenuItem } from '@material-ui/core';
import { Button } from '@mui/material';
import { LineGraph } from '../../Components/Graphs/Graphs';
import { Badge } from '@mui/material';
import urls from '../../urls';

class Dashboard extends React.Component {

    state = {
        ordersData:null,
        otherInfo:null,
        getOtherInfo:true,
        filters:{
            time:{year:'Year',month:'Month',week:'Week',day:'Day',quarter:'Quarter'},
            sale_order_type:{order:'Sale Orders',return:'Return Orders'}
        },
        appliedFilters:{time:'month',sale_order_type:'order'},
        status:null
    }

    componentDidMount(){
        changeAppTitle('Dashboard');
        this.fetchOrdersData();
    }

    fetchOrdersData=async()=>{
        this.setState({fetchingData:true});
        let res  = await fetchOrdersData(this.state.appliedFilters,this.state.getOtherInfo);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({ordersData:res.result.ordersData});
            if(res.result.otherInfo)
                this.setState({otherInfo:res.result.otherInfo,getOtherInfo:false});
        }
        this.setState({fetchingData:false});
    }

    handleFilterChange=(e,filterType)=>{
        let appliedFilters = this.state.appliedFilters;
        let value = e.target.value;
        appliedFilters = {...appliedFilters,...{[filterType]:value}};
        this.setState({appliedFilters});
    }

    applyFilter=async(e)=>{
        this.fetchOrdersData();
    }

    getLineGraphData=()=>{
        let labels = this.state.ordersData.map(i=>i['labels']);
        let temp = {
          labels,
          datasets: [
            {
              label: 'Number of Orders',
              data:this.state.ordersData.map(i=>i['count']),
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Order Value',
              data:this.state.ordersData.map(i=>i['amount_total']),
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        };
        return(temp);
    }

    goToOrders=(type)=>{
        this.props.history.push(urls.extension + '/console/orders?type='+ type);
    }

    goRenewMebership=async()=>{
        this.props.history.push(urls.extension + '/renew_membership');
    }

    render() { 
        return (
            <div className="dashboard">
                <>
                    {this.state.otherInfo &&
                        <div className="otherInfo"> 
                            <table>
                                <tr>
                                    <th>Company Validity</th>
                                    <td>{this.state.otherInfo['validity_date']}</td>
                                </tr>
                                <tr>
                                    <th>Number Of Products</th> 
                                    <td>{this.state.otherInfo['products_count']}</td> 
                                </tr>
                            </table>
                            {/* {this.state.otherInfo?.show_renew_membership_btn && */}
                                <Button variant="contained" onClick={()=>this.goRenewMebership()}>
                                    Renew Membership
                                </Button>
                            {/* } */}
                            <Badge 
                                badgeContent={this.state.otherInfo?.ordersToConfirm} 
                                color="info"
                                showZero
                            >
                                <Button variant="contained" disabled={!this.state.otherInfo?.ordersToConfirm}
                                    onClick={()=>this.goToOrders("toConfirm")}
                                >
                                    Orders to be confirmed
                                </Button>
                            </Badge>
                            <Badge 
                                badgeContent={this.state.otherInfo?.ordersToDeliver} 
                                color="info"
                                showZero
                            >
                                <Button variant="contained" disabled={!this.state.otherInfo?.ordersToDeliver}
                                    onClick={()=>this.goToOrders("toDeliver")}
                                >
                                    Orders to be delivered
                                </Button>
                            </Badge>
                           
                        </div>
                    }
                    <div className="dashboard__linegraph">
                        <div className="dashboard__filters">
                            {Object.keys(this.state.filters).map((filter)=>(
                                <Select
                                    labelId={filter}
                                    id={filter}
                                    onChange={(e)=>this.handleFilterChange(e,filter)}
                                    value = {this.state.appliedFilters[filter]}
                                    name={filter}
                                >
                                    {Object.keys(this.state.filters[filter]).map((option)=>(
                                        <MenuItem value={option}>
                                            {this.state.filters[filter][option]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ))}
                            <Button onClick={()=>this.applyFilter()}>
                                Apply
                            </Button>
                        </div>
                        {this.state.ordersData && 
                            <LineGraph 
                                data={this.getLineGraphData()} 
                                title="Orders"
                            />
                        }
                    </div>
                </>
                <Loader open={this.state.fetchingData || this.state.updatingOrderData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default Dashboard;