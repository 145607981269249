import React from 'react';
import './StoreOrders.css';
import { fetchStoreOrders, saveOrderData } from '../../../Shared/consoleServices';
import { AuthContext } from '../../../Shared/context';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import OrderTables from '../../../Components/Tables/OrderTables';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import urls from '../../../urls';
import Order from './OrderCard';
import Kanban from './Kanban';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { Button } from '@mui/material';
import { Grid, Typography, TextField } from '@mui/material';
const Header = (props) => {
    return (
        <div className="console__header">
            <Grid container justifyContent={'center'} spacing={2}>
                <div className="dropdown">


                    <Grid container mt={2} ml={1} spacing={1} >
                        {Object.keys(props.typeOptions).map((type) => (
                            <Grid item >
                            <Button
                                variant={`${(props.selectedOptions['type'] === props.typeOptions[type] ? 'contained' : 'outlined')}`}
                                size="small"
                                onClick={(e) => props.handleChangeOptions(e, 'type')}
                                value={props.typeOptions[type]}
                            >
                                {type}
                            </Button>
                            </Grid>

                        ))}

                    </Grid>


                </div>

                {/* <div className="store-orders"> */}


                <Grid item >
                    {/* <div className="dropdown"> */}
                    <InputLabel id="demo-simple-select-outlined-label">Filter By</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={props.selectedOptions['filterBy']}
                        onChange={(e) => props.handleChangeOptions(e, 'filterBy')}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {Object.keys(props.filterByOptions).map((option) => (
                            <MenuItem value={option}>{props.filterByOptions[option]['name']}</MenuItem>
                        ))
                        }
                    </Select>
                    {/* </div> */}
                </Grid>

                <Grid item >
                    {/* <div className="dropdown"> */}
                        <InputLabel id="demo-simple-select-outlined-label">Sort By</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props.selectedOptions['sortBy']}
                            onChange={(e) => props.handleChangeOptions(e, 'sortBy')}
                        >
                            <MenuItem value={null}>None</MenuItem>
                            {Object.keys(props.sortByOptions).map((option) => (
                                <MenuItem value={option}>{props.sortByOptions[option]['name']}</MenuItem>
                            ))
                            }
                        </Select>
                    {/* </div> */}
                </Grid>
                <Grid item >
                    {/* <div className="dropdown"> */}
                        <InputLabel id="demo-simple-select-outlined-label">Group By</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props.selectedOptions['groupBy']}
                            onChange={(e) => props.handleChangeOptions(e, 'groupBy')}
                        >
                            <MenuItem value={null}>None</MenuItem>
                            {Object.keys(props.groupByOptions).map((option) => (
                                <MenuItem value={option}>{props.groupByOptions[option]['name']}</MenuItem>
                            ))
                            }
                        </Select>
                    {/* </div> */}
                </Grid>
                <Grid item >
                    {/* <div className="dropdown"> */}
                        <InputLabel id="demo-simple-select-outlined-label">View</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={props.selectedOptions['viewType']}
                            onChange={(e) => props.handleChangeOptions(e, 'viewType')}
                        >
                            {props.viewType['options'].map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                            ))
                            }
                        </Select>
                    {/* </div> */}
                </Grid>

                {/* </div> */}
                <Grid item mt={1}>
                    {!props.selectedOptions['groupBy'] && props.orderList && props.orderList.length > 0 &&
                        <div className="products-pagination" style={{ float: "right" }}>
                            <span>
                                <ArrowBack onClick={() => props.changepage(props.currentPage - 1)} style={{ cursor: 'pointer' }} />
                                <span>{`${(props.currentPage * 50) + 1}-${(props.currentPage * 50) + Object.keys(props.orderList).length}/${props.totalRecords}`}</span>
                                <ArrowForward onClick={() => props.changepage(props.currentPage + 1)} style={{ cursor: 'pointer' }} />
                            </span>
                        </div>
                    }
                </Grid>
            </Grid>

        </div>

    )
}


class StoreOrders extends React.Component {

    static contextType = AuthContext;

    state = {
        orders: null, orderList: null,
        selectedOptions: { filterBy: null, sortBy: null, groupBy: null, viewType: 'Tree', type: null },
        filterByOptions: {
            1: { name: 'Last 1 day', value: 'last_1_days' },
            2: { name: 'Last 3 days', value: 'last_3_days' },
            3: { name: 'Last 7 days', value: 'last_7_days' },
            4: { name: 'Last 30 days', value: 'last_30_days' }
        },
        sortByOptions: {
            1: { name: 'Order Date', value: 'date_order' },
            2: { name: 'Delivery Date', value: 'commitment_date' },
            3: { name: 'Expected Date', value: 'expected_date' },
            4: { name: 'Order Value', value: 'amount_total' },
            5: { name: 'Status', value: 'state' }
        },
        groupByOptions: { 1: { name: 'Status', value: 'state' } },
        typeOptions: { 'All': null, 'Orders to be confirmed': 'toConfirm', 'Orders to be delivered': 'toDeliver' },
        viewType: { options: ['Kanban', 'Tree'] },
        fetchingData: false,
        updatingOrderData: false,
        currentPage: 0,
        totalRecords: 0
    }

    componentDidMount() {
        changeAppTitle('Console | Orders');
        this.fetchStoreOrders(this.state.currentPage);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.fetchStoreOrders(this.state.currentPage);
        }
    }

    handleChangeOptions = async (e, option) => {
        let value = '';
        if (typeof (e) === 'number')
            value = e;
        else
            value = e.target.value;
        let selectedOptions = this.state.selectedOptions;
        switch (option) {
            case 'filterBy':
                selectedOptions = { ...selectedOptions, ...{ filterBy: value } }
                break;
            case 'sortBy':
                selectedOptions = { ...selectedOptions, ...{ sortBy: value } }
                break;
            case 'groupBy':
                selectedOptions = { ...selectedOptions, ...{ groupBy: value } }
                break;
            case 'viewType':
                selectedOptions = { ...selectedOptions, ...{ viewType: value } }
                break;
            case 'type':
                selectedOptions = { ...selectedOptions, ...{ type: value } }
                break;
            default:
                alert('Wrong input');
        }
        await this.setState({ selectedOptions });
        if (option !== 'viewType') {
            this.changeUrlParams(option, value);
        }
    }

    changeUrlParams = (key, value) => {
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        searchParams.delete(key)
        if (key && value)
            searchParams.append(key, value);
        searchParams = searchParams.toString();
        let newPageRoute = `?${searchParams}`;
        this.props.history.push(this.props.history.location.pathname + newPageRoute);
    }

    fetchStoreOrders = async (currentPage = 1) => {
        this.setState({ fetchingData: true });
        let searchParams = this.props.location.search;
        searchParams = new URLSearchParams(searchParams);
        let filters = {}
        let filterBy = searchParams.get('filterBy');
        let sortBy = searchParams.get('sortBy');
        let groupBy = searchParams.get('groupBy');
        let type = searchParams.get('type');
        let selectedOptions = this.state.selectedOptions;
        selectedOptions = { ...selectedOptions, ...{ filterBy, sortBy, groupBy, type } };
        this.setState({ selectedOptions });
        if (filterBy) {
            filters = { ...filters, ...{ filterBy: this.state.filterByOptions[filterBy]['value'] } }
        }
        if (sortBy) {
            filters = { ...filters, ...{ sortBy: this.state.sortByOptions[sortBy]['value'] } }
        }
        if (groupBy) {
            filters = { ...filters, ...{ groupBy: this.state.groupByOptions[groupBy]['value'] } }
        }
        let res = await fetchStoreOrders({ ...filters, ...{ type, 'page': currentPage } });
        if (res.error || res.result.errorMessage) {
            alert(res.error || res.result.errorMessage);
        }
        else {
            this.setState({ currentPage });
            if (res.result.data.orders)
                this.setState({ orders: res.result.data.orders, orderList: res.result.data.orderList, totalRecords: res.result.data.totalRecords });
            else
                this.setState({ data: res.result.data.grouped_data });
            this.setState({ states: res.result.data.states });
        }
        this.setState({ fetchingData: false });
    }

    getTableData = (datatype) => {
        let data = null;
        if (datatype === 'column') {
            let firstOrder = this.state.orders[this.state.orderList[0]];
            data = Object.keys(firstOrder);
        }
        else if (datatype === 'rows') {
            data = [];
            this.state.orderList.forEach(orderId => {
                let newRow = this.state.orders[orderId];
                data = [...data, newRow];
            });
        }
        return data;
    }

    goToOrder = (orderId) => {
        this.props.history.push(urls.extension + '/console/orders/' + orderId);
    }

    moveOrder = async (source, destination) => {
        let temp = { ...this.state.data };
        let sourceData = temp[source['droppableId']];
        let destData = temp[destination['droppableId']];
        let removedOrderId = sourceData['orderList'].splice(source['index'], 1)[0];
        removedOrderId = String(removedOrderId);
        this.setState({ fetchingData: true });
        let updatedOrderData = {};
        Object.keys(this.state.states).forEach(state => {
            if (this.state.states[state] === destination['droppableId']) {
                updatedOrderData = { 'state': state };
            }
        });
        console.log({ updatedOrderData });
        let res = await saveOrderData(removedOrderId, updatedOrderData);
        if (res.error || res.result.errorMessage) {
            sourceData['orderList'].splice(source['index'], 0, removedOrderId);
            alert(res.error || res.result.errorMessage);
        }
        else {
            let orderData = sourceData['orders'][removedOrderId];
            orderData = { ...orderData, ...{ state: destination['droppableId'] } };
            destData['orders'] = { ...destData['orders'], ...{ [removedOrderId]: orderData } };
            destData['orderList'].splice(destination['index'], 0, removedOrderId);
        }
        this.setState({ fetchingData: false });
    }

    changepage = async (page) => {
        if (page >= 0 && this.state.totalRecords > ((this.state.currentPage * 50) + this.state.orderList.length)) {
            this.fetchStoreOrders(page);
        }
    }

    render() {
        return (
            <div className="store-orders">
                <Header
                    filterByOptions={this.state.filterByOptions}
                    sortByOptions={this.state.sortByOptions}
                    groupByOptions={this.state.groupByOptions}
                    viewType={this.state.viewType}
                    typeOptions={this.state.typeOptions}
                    selectedOptions={this.state.selectedOptions}
                    handleChangeOptions={this.handleChangeOptions}
                    changepage={this.changepage}
                    totalRecords={this.state.totalRecords}
                    currentPage={this.state.currentPage}
                    orderList={this.state.orderList}
                />
                {!this.state.selectedOptions['groupBy'] &&
                    <>
                        {this.state.selectedOptions['viewType'] === 'Kanban' && this.state.orderList &&
                            this.state.orderList.map((orderId) => (

                                <Order
                                    order={this.state.orders[orderId]}
                                    goToOrder={this.goToOrder}
                                />
                            ))}
                        {this.state.selectedOptions['viewType'] === 'Tree' && this.state.orderList &&
                            this.state.orderList.length > 0 &&
                            <>
                                <OrderTables
                                    columns={this.getTableData('column')}
                                    rows={this.getTableData('rows')}
                                    goToOrder={this.goToOrder}
                                    handleChangeOptions={this.handleChangeOptions}
                                />
                            </>
                        }
                    </>
                }
                {this.state.selectedOptions['groupBy'] && this.state.data &&
                    <Kanban
                        data={this.state.data}
                        goToOrder={this.goToOrder}
                        moveOrder={this.moveOrder}
                    />
                }
                <Loader open={this.state.fetchingData || this.state.updatingOrderData} handleClose={() => { }} />
            </div>
        );
    }
}

export default StoreOrders;
