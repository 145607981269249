import React, { useEffect,useState } from 'react'
import { useParams } from "react-router";
import urls from '../../urls';
import './CompaniesPage.css';
import { useLocation } from 'react-router-dom';
import { Star } from '@material-ui/icons';
import { changeAppTitle } from '../../Shared/utilities';
import { Favorite,FavoriteBorder } from '@material-ui/icons';
import { Button } from '@mui/material';
import { ArrowLeft } from '@material-ui/icons';
import { Select,InputLabel,MenuItem } from '@material-ui/core';
import { CompanySliders } from '../../Components/Sliders/Sliders';
import { AdvertisementPopup } from '../../Components/Popup/Popup';
import { CompareArrows } from '@material-ui/icons';
import {companyCompare} from '../../Shared/services';
import Loader from '../../Components/Shared/Loader';
import Pagination from '../../Components/Pagination/Pagination';
import { useAuth } from '../../Shared/context';

function CompaniesPage(props) {

    const {companyType} =   useParams();
    const [companyTypeObj,setCompanyTypeObj] = useState(null);
    const [Companies, setCompanies] = useState(null);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [companyList,setCompanyList] = useState(null);
    const [sliders,setSliders] = useState(null);
    const [advertisment,setAdvertisment] = useState({open:false,data:null});
    const [filterBy,setFilterBy] = useState(null);
    const [filterByOptions,setFilterByOptions] = useState({1:{name:'Popularity',value:'popularity_count_update DESC'},
                                                            2:{name:'Rating',value:'rating_count_update DESC'},
                                                            3:{name:'Newest First',value:'id DESC'}});

    const [fetchingData,setFetchingData] = useState(false);
    const [state,setState] = useState({totalPages:0,currentPage:1});

    const location = useLocation();
    const {custom_session_info} = useAuth();

    useEffect(() => {
        changeAppTitle('Shopaas | Companies');
        let tempVar = companyType.split('-');
        let companyTypeId = null;
        if(tempVar.length > 1)
            companyTypeId = parseInt(tempVar.slice(-1));
        let searchParams = location.search;
        searchParams = new URLSearchParams(searchParams);
        let search_location_input = searchParams.get('search') || '';
        let page = searchParams.get('page') || 1;
        let tempFilterBy = searchParams.get('filter_by') || null;
        setFilterBy(tempFilterBy);
        if(!companyTypeId && !search_location_input)
            props.history.push(urls.extension + '/home');
        else{
            tempVar.splice(-1);
            tempVar = tempVar.join(' ');
            setCompanyTypeObj({'id':companyTypeId,'name':tempVar});
            fetchCompanies(companyTypeId,search_location_input,page);
            if(!search_location_input)
                fetchCompanyTypes(companyTypeId);
            else{
                setCompanyTypeObj(null);
                setCompanyTypes(null);
            }
        }
    }, [props.location, custom_session_info.location])


    function onCompanyClick(company){
        let companyTypeId = company['company_type'][0];
        let companyTypeName = company['company_type'][1];
        let newPageRoute = companyTypeName + '-' + companyTypeId + '/';
        newPageRoute += company['name'].replace('/',',') + '-' + companyTypeId + '-' +  company['id'] + '?page=1';
        props.history.push(urls.extension + '/home/' +newPageRoute);
    }

    async function fetchSliders(companyList,companyTypeId){
        try {
            const axios = require("axios");
            const response = await axios(urls.getSliders, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {companyList,companyTypeId}
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                setSliders(res.result.data.sliders);
                if(res.result.data.advertisements){
                    let temp = advertisment;
                    let open = res.result.data.advertisements.length>0;
                    temp = {...advertisment,...{open,data:res.result.data.advertisements}};
                    setAdvertisment(temp);
                }
            }
        } 
        catch (err) {
        }
    
    }

    function arraysEqual(a, b) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
      }

    function changePage(currentPage){
        if(state.currentPage != currentPage){
            let totalPages = state.totalPages;
            setState({totalPages,currentPage});
            changeUrlParams("page",currentPage);
        }
    }

    function changeUrlParams(key,value){
        let searchParams = props.location.search;
        searchParams = new URLSearchParams(searchParams);
        searchParams.delete(key)
        if(key && value)
            searchParams.append(key,value);
        searchParams = searchParams.toString();
        let newPageRoute = `?${searchParams}`;
        props.history.push(props.history.location.pathname +newPageRoute);
    }

    async function fetchCompanies(companyTypeId,search_location_input=null,page=1){
        try {
            setCompanies(null);
            setFetchingData(true);
            const axios = require("axios");
            let url = urls.getCompanies;
            let params = {
                'companyTypeId':companyTypeId,
                'filterBy':filterBy?filterByOptions[filterBy]['value']:false,
                'page':page
            };
            if(search_location_input){
                url = urls.searchCompany;
                params ={...params,...{search_location_input}};
            }
            const response = await axios(url, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                if(parseInt(res.result.statusCode) === 200){
                    setCompanies(res.result.companies);
                    setState({totalPages:res.result.totalPages,currentPage:page});
                    if(companyList !==res.result.companyList){
                        let temp1 = (companyList || []).sort();
                        setCompanyList(res.result.companyList);
                        let temp2 = (res.result.companyList || []).sort();
                        if(!arraysEqual(temp1,temp2))
                            fetchSliders(res.result.companyList || [],companyTypeId);
                    }
                }
                else if(parseInt(res.result.statusCode) === 201){
                    alert(res.result.message);
                    props.history.push(urls.extension + '/home');
                }
            }
            else
                alert(res.result.errorMessage || res.error);
            setFetchingData(false);
        } 
        catch (err) {
            setFetchingData(false);
            alert(err.message);
        }
    }

    async function editFavourites(company){
        try {
            const axios = require("axios");
            let params = {'companyId':company['id'],
                            'companyTypeId':company['company_type'][0],
                            'addToFavourites':!company['isFavourite']
                        }
            const response = await axios(urls.editFavourites, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: params
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                let companiesList = Companies.map((comp)=>{
                    if(comp['id'] === company['id']){
                        comp['isFavourite'] = res.result.isFavourite;
                    }
                    return(comp);
                })
                setCompanies(companiesList);
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }

    async function companyComparision(company_id){
        let data = {company_id,method:'POST',addToCompare:true}
        let res  = await companyCompare(data);
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            props.history.push(urls.extension + '/company_compare');
        }
    }


    const CompanyCard=(company,idx)=>{
        return(
            <div className="company__card">
                <div className="company-logo">
                    <img 
                        src={urls.domain + company.logo} 
                        alt="" 
                        onClick={()=>onCompanyClick(company)}
                        loading="lazy"    
                    />
                    {company.deliveryOptions.includes('Home Delivery') && 
                        <span className="delivery-options">Home Delivery</span>
                    }
                </div>
                <div className="company-details1">
                    <span>
                        <span className="name" onClick={()=>onCompanyClick(company)}>{company.name}</span><br/>
                        <span>{company.city}</span>
                    </span>
                    <span>
                        {company['isFavourite'] === false &&
                            <FavoriteBorder className="favorite-icon" onClick={()=>editFavourites(company)}/>
                        }
                        {company['isFavourite'] === true &&
                            <Favorite className="favorite-icon" style={{color:"#f63030"}} onClick={()=>editFavourites(company)}/>
                        }
                    </span>
                </div>
                <div className="company-details2">
                    <span className="rating">
                        <Star style={{color:"beige",width:"15px",height:"15px"}}/> &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>{company.rating}</span>
                    </span>
                    <span>
                        <CompareArrows onClick={()=>companyComparision(company.id)} style={{cursor:'pointer'}}/>
                    </span>
                    <span>{company.distance}</span>
                </div>
                <div className="company-details3">
                    {company.tagline && <div>{company.tagline}</div>}
                    {company.companyMsg && <div>{company.companyMsg}</div>}                           
                </div>
            </div>
        )
    }

    async function fetchCompanyTypes (companyTypeId) {
        try {
            setCompanyTypes([]);
            const axios = require("axios");
            const response = await axios(urls.getHomepageData, {
                method: 'POST',
                withCredentials:true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                            companyTypeId
                        }
                })
            }, 7000);
            const res = await response.data;
            if(!res.result.errorMessage && !res.error){
                setCompanyTypes(res.result.companyTypes);
            }
            else
                alert(res.result.errorMessage || res.error);
        } 
        catch (err) {
            alert(err.message);
        }
    }

    function companyTypeClickHandler(companyType){
        let newPageRoute = companyType['name'].replace('/',',') + '-' + companyType['id'];
        props.history.push(urls.extension + '/home/'+ newPageRoute);
        setCompanyTypeObj({'id':companyType['id'],'name':companyType['name']});
        fetchCompanies(companyType['id']);
        fetchCompanyTypes(companyType['id']);
    }

    function applyFilter(e){
        let temp = e.target.value;
        setFilterBy(temp);
        changeUrlParams('filter_by',temp);
    }

    function changeUrlParams(key,value){
        let searchParams = props.location.search;
        searchParams = new URLSearchParams(searchParams);
        searchParams.delete(key)
        if(key && value)
            searchParams.append(key,value);
        searchParams = searchParams.toString();
        let newPageRoute = `?${searchParams}`;
        props.history.push(props.history.location.pathname +newPageRoute);
    }

    function closeAdvertisementPopup(){
        let temp = advertisment;
        temp = {...advertisment,...{open:false}};
        setAdvertisment(temp);
    }

    return ( 
        <>
            {sliders && Object.keys(sliders).map((sliderId)=>(
                <div>
                    <CompanySliders 
                        items={sliders[sliderId]['sliders']} 
                        isSingle = {sliders[sliderId]['isSingle']}
                        autoPlay={false} 
                        className="top"
                    />
                    <br/>
                </div>
            ))}
            <div className="companies-page">
                <div className="company-type1">
                    {companyTypeObj &&
                        <span style={{fontSize:"16px",fontWeight:"bold",fontStyle:"italic"}}>
                            {companyTypeObj['name']}
                        </span>
                    }
                    <span>
                        <div className="filter-by">
                            <InputLabel id="demo-simple-select-outlined-label">Filter By</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={filterBy}
                                onChange={(e)=>applyFilter(e)}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                {Object.keys(filterByOptions).map((option)=>(
                                    <MenuItem value={option}>{filterByOptions[option]['name']}</MenuItem>
                                ))
                                }
                            </Select>
                        </div>
                    </span>
                </div>
                {companyTypes && companyTypes.length>0 &&
                    <div className="company-types">
                        {companyTypes.map((companyType,idx)=>(
                            <div 
                                className="company-type"
                                onClick={()=>companyTypeClickHandler(companyType)}
                            >
                                <img src={urls.domain + companyType.image} alt={companyType.name} />
                                <span>{companyType.name}</span>
                            </div>
                        ))
                        }
                    </div>
                }
                {parseInt(state.totalPages) > 0 && 
                    <Pagination 
                        totalPages = {state.totalPages}
                        changePage = {changePage}
                        currentPage = {parseInt(state.currentPage)}
                        fetchingData = {fetchingData}
                    />
                }
                <div className="companies">
                    {Companies && Companies.map((company,idx)=>{
                            return(
                                CompanyCard(company,idx)
                            )
                    })}
                    {Companies && Companies.length===0 &&
                        <div className="company-not-available">
                            <div>No company found</div>
                            <Button variant="primary" style={{background:"#007A77",color:"beige"}} 
                                onClick={()=>props.history.goBack()}
                            >
                                <ArrowLeft />
                                Back
                            </Button>
                        </div>
                    }
                </div>
                <AdvertisementPopup 
                    open={advertisment['open']}
                    data = {advertisment['data']}
                    handlePopupClose = {closeAdvertisementPopup}
                />
                <Loader open={fetchingData} handleClose={()=>{}}/>
            </div>
        </>
     );
}

export default CompaniesPage;


