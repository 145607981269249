import React from 'react';
import '../console.css';
import {fetchUsersTreeData} from '../../../Shared/consoleServices';
import Loader from '../../../Components/Shared/Loader';
import { Button,Typography,Grid } from '@mui/material';
// import { Select,MenuItem } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
import urls from "../../../urls";


class UsersTree extends React.Component {
    

    state = {
        viewMode:true, 
        fetchingData:false,
        form_options:{},
    }
    componentDidMount(){
        this.UsersTreeData();
    }

    UsersTreeData=async()=>{
        this.setState({fetchingData:true});
        let res  = await fetchUsersTreeData();
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            this.setState({form_options:res.result.form_options});
            this.setState({fetchingData:false});
        }
        this.setState({fetchingData:false});
    }
    goToUsers=(userId=false)=>{
        // this.props.history.push(urls.extension + '/console/Users/' + (productId || 'New'));
        this.props.history.push(urls.extension + '/console/users/' + (userId || 'New'));
    }
    render() { 
        return (
            <>
            {/* create function  start here */}
            <div style={{width:"100%"}} className="console-form">
            <header>
                    <span className="header__left">
                        <Typography mr={2} variant="h5" component="h5">
                            Users 
                        </Typography> 
                          <Button 
                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.goToUsers()}
                    >
                        Create 
                    </Button>
                    </span>
                            <span className="header__right">
                      
                            </span>
                    </header>  
                </div>
            {/* create function end here */}
            {/* tree view start here  */}
            <div>
            {this.state.form_options.users_data && 
            <Grid container >
            <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
            <table className="order-table" >
                        <thead>
                            <tr>
                                <th style={{textAlign:'center',padding:'6px'}}>Name</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Login</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Language</th>
                                <th style={{textAlign:'center',padding:'6px'}}>Latest Authentication</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                        {
                        this.state.form_options.users_data.map((elem,ind) => {
                            return(
                                <tr key={ind} onClick={()=>this.goToUsers(elem.id)} >
                                <td style={{textAlign:'center'}}>{elem.name}</td>
                                <td style={{textAlign:'center'}}>{elem.login}</td>
                                <td style={{textAlign:'center'}}>{elem.lang}</td>
                                <td style={{textAlign:'center'}}>{elem.login_date}</td>
                                
                                </tr>
                                )
                            } )
                        }
                        </tbody>
                    </table>
                    </Grid>
                    </Grid>
            }
            <Loader open={this.state.fetchingData } handleClose={()=>{}}/>
            </div>
            {/* tree view end here */}
            
            </>
        );

    }

}
export default UsersTree;
