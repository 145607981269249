import React from 'react';
import '../CheckoutPage/CheckoutPage.css';
import './PaymentPage.css';
import Progessbar from '../CartPage/Progressbar';
import CartSummary from '../../Components/CartSummary/CartSummary';
import { Edit, ArrowBack, ArrowForward } from '@material-ui/icons';
import urls from '../../urls';
import { changeAppTitle } from '../../Shared/utilities';
import { AuthContext } from '../../Shared/context';
import Loader from '../../Components/Shared/Loader';
import { savePaymentData } from '../../Shared/consoleServices';
import { Grid } from '@material-ui/core';

class PaymentPage extends React.Component {

    static contextType = AuthContext;

    state = {
        paymentDetails: null,
        paymentData: {},
        fetchingData: false,
        // Piyush Kumar 19/6/2023
        payButtonName: 'Pay Now'
    }

    componentDidMount() {
        changeAppTitle('Shopaas | Payment');
        this.fetchPaymentDetails();
    }

    fetchPaymentDetails = async () => {
        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.getPaymentDetails, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {}
                })
            }, 7000);
            const res = await response.data;
            if (!res.result.errorMessage && !res.error) {
                let paymentDetails = res.result.data;
                this.setState({ paymentDetails });
            }
            else
                alert(res.result.errorMessage || res.error);
            this.setState({ fetchingData: false });
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
        }
    }

    goToPaymentGateway = async (e) => {

        // Ankit Start 23-09-2022 temp-bugid-2109 (payment Gateway)
        let form = document.getElementById('payuform');

        if (form) {
            this.setState({ fetchingData: true });
            form.submit();
        }
        // Ankit End 23-09-2022 temp-bugid-2109


        // Ankit Start 23-09-2022 temp-bugid-2109 (commenting bold Payumoney payment Gateway)
        // if(this.state.paymentData){

        //     window.bolt.launch(this.state.paymentData, {
        //         responseHandler: async function (response) {
        //             if(response?.response?.txnStatus == 'SUCCESS'){
        //                 let res = await savePaymentData(response);
        //                 if(!res?.result?.errorMessage && !res.error){
        //                     // this.props.history.push(urls.extension + '/shop/confirmation');
        //                     alert(res.result.message)  
        //                 }
        //                 else
        //                     alert(res.result.errorMessage || res.error);
        //             }
        //             else if(response?.response?.txnStatus == 'CANCEL'){

        //             }
        //             else if(response?.response?.txnStatus == 'FAIL'){

        //             }
        //         },
        //         catchException: function (response) {
        //             alert(response);

        //         }
        //     });

        // };
        // Ankit End 23-09-2022 temp-bugid-2109 (commenting bold Payumoney payment Gateway)


    }


    razorpaySuccess = async(razorpay_payment_id)=>{
        await this.setState({ fetchingData: true });
        try {
            const axios = require("axios");
            const response = await axios(urls.razorpay_api, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        payment_id: razorpay_payment_id,
                    }
                })
            }, 7000);
            const res = await response.data;
            console.log(res)
            if(res.result.data.success){
                let custom_session_info = this.context.custom_session_info;
                    custom_session_info = { ...custom_session_info, ...{ cart_quantity: 0 } };
                    this.context.set_custom_session_info(custom_session_info);
                    this.props.history.push(urls.extension + '/shop/confirmation')
            }else{
            //    await alert('Payment Not Completed! Please Contact to Shopaas')
            }
            // window.location.href = res.result.host_url+res.result.api_url;
        }
        catch (error){
            alert(error);
        }
        await this.setState({ fetchingData: false });
    }

    // Ankit Start 28 April 2023
    loadScript = (src) => {
        return new Promise((resovle) => {
          const script = document.createElement("script");
          script.src = src;
    
          script.onload = () => {
            resovle(true);
          };
    
          script.onerror = () => {
            resovle(false);
          };
    
          document.body.appendChild(script);
        });
      };
    
    displayRazorpay = async (amount) => {
        const paymentData = this.state.paymentData;
        const razorpaySuccess = this.razorpaySuccess;

        const res = await this.loadScript(
          paymentData.razorpay_url
        );
    
        if (!res) {
          alert("You are offline... Failed to load Razorpay SDK");
          return;
        }
        

        const options = {
            "key": paymentData.key,
            "amount": paymentData.amount,
            "name": paymentData.name,
            "description": "Thanks for purchasing",
            "handler": function (response) {
                console.log(response);
                if(response.razorpay_payment_id){
                    razorpaySuccess(response.razorpay_payment_id)
                }
            } ,
            "modal": {
                "ondismiss": function() { window.location.reload(); },
                'backdropclose': function() { window.location.reload(); }
            },
            'prefill': {
                'name': paymentData.name,
                'contact': paymentData.contact,
                'email': paymentData.email
            },
            'notes': {
                'order_id': paymentData.order_id,
            },
        }


    
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      };

    
    // Ankit Start 28 April 2023


    payNow = async (e) => {
        if (e)
            e.preventDefault();
        try {
            this.setState({ fetchingData: true });
            const axios = require("axios");
            const response = await axios(urls.payNow, {
                method: 'POST',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    jsonrpc: "2.0",
                    params: {
                        acquirer_id: this.state.paymentDetails['payment_mode']
                    }
                })
            }, 7000);
            const res = await response.data;
            this.setState({ fetchingData: false });
            if (!res.result.errorMessage && !res.error) {
                if (res.result.statusCode === 201) {
                    // Ankit Start 28 April 2023 
                    if (res.result.payment == 'razorpay'){
                    console.log(res.result.paymentData);
                    await this.setState({ paymentData: res.result.paymentData });
                    this.displayRazorpay();
                    }else{
                    // Ankit End 28 April 2023
                    await this.setState({ paymentData: res.result.paymentData });
                    this.goToPaymentGateway();
                    }
                }
                else {
                    let custom_session_info = this.context.custom_session_info;
                    custom_session_info = { ...custom_session_info, ...{ cart_quantity: 0 } };
                    this.context.set_custom_session_info(custom_session_info);
                    this.props.history.push(urls.extension + '/shop/confirmation')
                }
            }
            else
                alert(res.result.errorMessage || res.error);
        }
        catch (err) {
            this.setState({ fetchingData: false });
            alert(err.message);
        }
    }

    getAddress = (addr) => {
        let addrText = '';
        if (addr)
            addrText = (addr['street'] + ', ' + addr['street2'] + addr['zip'] + ', ' + addr['city']['name'] +
                addr['state']['name'] + ', ' + addr['country']['name']);
        return addrText;
    }

    changeDeliveryOption = (e) => {
        let paymentDetails = this.state.paymentDetails;
        let delivery_type = e.target.value;
        paymentDetails = { ...paymentDetails, ...{ delivery_type } };
        this.setState({ paymentDetails });
    }

    changePaymentMode = (e) => {
        let paymentDetails = this.state.paymentDetails;
        let payment_mode = parseInt(e.target.value);
        paymentDetails = { ...paymentDetails, ...{ payment_mode } };
        this.setState({ paymentDetails });
        // piyush Kumar 19/6/0223 
        this.showPayNow(e.target.name)
        // Piyush KUmar End
    }
    // Piyush Kumar 19/6/2023 
    showPayNow = (payName) => {
        if (payName === "Cash On Delivery") {
            this.setState({ payButtonName: 'PLACE ORDER' });
        }
        else {
            this.setState({ payButtonName: 'Pay Now' });
        }
    }

    // Piyush Kumar End

    render() {
        return (
            <div className="checkout-page">
                <Grid container xs={12} md={10} justifyContent="center">

                    <Grid container xs={12} md={12} justifyContent="flex-start">
                        <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                            <div className="progress-bar">
                                <Progessbar stage={3} />
                            </div>
                        </Grid>
                    </Grid>
                    {this.state.paymentDetails &&
                        <div className="checkout-details">
                            <Grid container xs={12} md={10} justifyContent="center">
                                <Grid container xs={10} md={6} justifyContent="flex-end">
                                    <Grid item xs={12} md={12}>
                                        <div className="checkout-details__left">
                                            <div className="payment-info">
                                                <div className="payment-addresses">
                                                    <div>
                                                        <span>Billing Address:</span> {this.getAddress(this.state.paymentDetails['billing_address'])}
                                                    </div>
                                                    <div>
                                                        <span>Shipping Address:</span> {this.getAddress(this.state.paymentDetails['shipping_address'])}
                                                    </div>
                                                    <Edit className="edit-address"
                                                        onClick={() => this.props.history.push(urls.extension + '/shop/checkout')}
                                                    />
                                                </div>
                                                {/* <div className="heading">Shipping Methods</div> */}
                                                {/* <div className="payment-delivery-option">
                                {this.state.paymentDetails['delivery_options'].map((option)=>(
                                    <div className="option">
                                        <input type="radio" name={option} checked={this.state.paymentDetails['delivery_type']===option} 
                                            onChange={this.changeDeliveryOption} value={option}/>
                                        <span>{option}</span>
                                    </div>
                                ))
                                }
                            </div> */}
                                                <div className="heading">Pay With</div>
                                                <div className="payment-methods">
                                                    {this.state.paymentDetails['payment_acquirers'].map((option) => (
                                                        <div className="option">
                                                            <input type="radio" name={option['name']} checked={this.state.paymentDetails['payment_mode'] === option['id']}
                                                                onChange={(e) => this.changePaymentMode(e)} value={option['id']} />
                                                            <span>{option['name']}</span>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                                <div className="payment-methods-online">
                                                    {this.state.paymentDetails['online_payments'].map((option) => (
                                                        <div >
                                                            
                                                            <span style={{ alignItems: 'flex-end', marginRight: 2, alignContent:'center',paddingRight:10,fontSize:18}}>{option['name']}:</span>  <span style={{ alignItems: 'flex-end', marginLeft: 2, alignContent:'end' }}>{option['number']}</span> 
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container xs={10} md={5} justifyContent="flex-start">
                                    <Grid item xs={12} md={12} >
                                        <div className="checkout-details__right">
                                            <CartSummary />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </div>


                    }
                    <Grid container xs={12} md={12} justifyContent="flex-start">
                        <Grid item xs={12} md={10} paddingLeft={1} display="inline-table">
                            <div className="navigation-btns">
                                <button className="btn__back"
                                    onClick={() => this.props.history.push(urls.extension + '/shop/cart')}
                                >
                                    <ArrowBack />  Return to Cart
                                </button>
                                <form action={this.state.paymentData?.url} id="payuform" name="payuform" method='POST' >
                                    {Object.keys(this.state.paymentData).map((name) => (
                                        <input type="hidden" name={name} value={this.state.paymentData[name]} />
                                    ))}
                                </form>
                                <button className="btn__next"
                                    onClick={(e) => this.payNow(e)}
                                >
                                    {this.state.payButtonName}  <ArrowForward />
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Loader open={this.state.fetchingData} handleClose={() => { }} />
            </div>
        );
    }
}

export default PaymentPage;
