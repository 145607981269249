import React from 'react';
import urls from '../../urls';
import './console.css';
import { Select,MenuItem } from '@material-ui/core';
import {allowedCompanies} from '../../Shared/consoleServices';
import Loader from '../../Components/Shared/Loader';

class Console extends React.Component {

    state = {
        modules:[
                {'name':'Orders','route':'/console/orders'},
                {'name':'Users','route':'/console/UsersTree'},
                {'name':'Products','route':'/console/products'},
                {'name':'Product Variants','route':'/console/productvarienttree'},

                {'name':'Product Categories','route':'/console/productcategories'},
                {'name':'Ecommerce Categories','route':'/console/publicproductcategories'},
               
                
                {'name':'Pricelists','route':'/console/pricelists'},
                {'name':'Products Transfer','route':'/console/data_transfer'},
                {'name':'Product Data Update','route':'/console/product_data_update'},
                {'name':'Stock Inventory','route':'/console/stock_inventory'},
                {'name':'Dashboard','route':'/dashboard'},
                {'name':'Promotion','route':'/console/promotion_program_tree'},
                {'name':'Loyalty','route':'/console/Loyalty_tree'},
                {'name':'Brand ','route':'/console/brand'},
                // Ankit Start 5 April 2023
                {'name':'Uom','route':'/console/uom_category'}
                // Ankit End 5 April 2023
            
              
        ],
        companies:null,
        // Ankit Start 5 April 2023
        // is_admin_company: false,
        // Ankit End 5 April 2023
        // Ankit Start 12 May 2023
        service_product: false,
        // Ankit End 12 May 2023
    }

    componentDidMount(){
        this.allowedCompanies();
    }

  

    addInConsole=()=>{
        let modules = this.state.modules;
        modules = modules.filter(l=>l.name !== 'Stock Inventory');
        this.setState({modules});
    }

    // Ankit End 5 April 2023

    allowedCompanies=async(companyId = null)=>{
        this.setState({fetchingData:true});
        let res  = await allowedCompanies(companyId);
        if(!(res.error || res.result.errorMessage)){
            // Ankit Start 5 April 2023
            // this.setState({companies:res.result.data});
            this.setState({companies:res.result.data,service_product:res.result.service_product});
            if(res.result.service_product === true){
                this.addInConsole();
            }
            // Ankit End 5 April 2023
        }
        this.setState({fetchingData:false});
    }

    goToModule=(module)=>{
        this.props.history.push(urls.extension + module['route']);
    }


    handleCompanyChange=(e)=>{
        this.allowedCompanies(e.target.value);
    }

    render() { 
        return (
            <div className="console">
                {this.state.companies &&
                    <div className='select_company'>
                        <Select
                            labelId="select_company"
                            id="select_company"
                            onChange={this.handleCompanyChange}
                            value={parseInt(this.state.companies['selected_company'] || 0)}
                            name='company'
                        >
                            {Object.keys(this.state.companies['allowed_companies']).map((option)=>(
                                <MenuItem value={parseInt(option)}>
                                    {this.state.companies['allowed_companies'][option]['name']}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                }
                <div className='modules'>
                    {this.state.modules.map((module)=>(
                        <div 
                            className="console__module"
                            onClick={()=>this.goToModule(module)}
                        >
                          <div style={{textAlign: 'center'}}>  {module['name']}</div>
                        </div>
                    ))}
                </div>
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
            );
    }
}
 
export default Console;
