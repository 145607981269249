import React from 'react';
import { Pagination as Pages } from '@material-ui/lab';
import './Pagination.css';

function Pagination(props) {

    function handleChangePage(event,newPage){
        // props.fetchProducts(newPage);
        props.changePage(newPage);
    }

    return ( 
        <div className="pagination">
            <Pages 
                    count = {props.totalPages}
                    page = {props.currentPage}
                    variant = "outlined"
                    color = "primary"
                    onChange = {handleChangePage}
                    disabled = {props.fetchingData}
                    size = "small"
                    shape = "rounded"
                />
        </div>
     );
}

export default Pagination;
 