import React from 'react';
import '../../Pages/CartPage/CartPage.css';
import urls from '../../urls';
import { Delete } from '@material-ui/icons';
import { Grid } from '@mui/material';
class SaleOrder extends React.Component {
    render() { 
        return (
            <div className={`cart-table ${this.props.updatingCart?'disabled':''}`}>
                <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12} >
                <table md={12} width={700}>
                    <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                    {Object.keys(this.props.cart['sale_order_lines']).map((lineId,idx)=>(
                        <tr className="order-line">
                            <td className="product">
                                <div sx={{widht:100}}>
                                    <img width={70}
                                        src={urls.domain + this.props.cart['sale_order_lines'][lineId]['product_image']} 
                                        alt="no preview available"
                                    />
                                </div>
                                {/* Piyush Kumar 1/6/2023 */}
                                {/* <span className="name" width={10}>
                                    {this.props.cart['sale_order_lines'][lineId]['name']}
                                </span> */}
                                <div>
                                    <span className="name" width={10}>
                                        {this.props.cart['sale_order_lines'][lineId]['name']}
                                    </span>
                                    {this.props.cart['sale_order_lines'][lineId]['sale_desc'] &&
                                    <>
                                    <br/>
                                    <span style={{marginLeft:10}}>Desc:</span>
                                    <span className="name" width={10}>
                                        {this.props.cart['sale_order_lines'][lineId]['sale_desc']}
                                    </span>
                                    </>
                                    }
                                </div>
                                {/* Piyush Kumar End */}
                            </td>
                            {this.props.cart['sale_order_lines'][lineId]['editable'] ?
                                <td className="qty">
                                    <button 
                                        className="qty-btns"
                                        value={this.props.cart['sale_order_lines'][lineId]['product_uom_qty']-1} 
                                        onClick={(e)=>this.props.handleQuantityChange(e.target.value,parseInt(lineId),1000)}
                                        disabled={this.props.cart['sale_order_lines'][lineId]['product_uom_qty']<=1}
                                    >
                                        -
                                    </button>
                                    <span className="qty-input">
                                            <input 
                                                type="number"
                                                id="order-line-quantity"
                                                name="quantity"
                                                min="1"
                                                value={this.props.cart['sale_order_lines'][lineId]['product_uom_qty']}
                                                onChange={(e)=>this.props.handleQuantityChange(e.target.value,parseInt(lineId),1000)}
                                            />
                                        </span>
                                        <button 
                                            className="qty-btns"
                                            value={this.props.cart['sale_order_lines'][lineId]['product_uom_qty']+1} 
                                            onClick={(e)=>this.props.handleQuantityChange(e.target.value,parseInt(lineId),1000)}
                                        >
                                            +
                                        </button>
                                </td>
                                :
                                <td className="qty">{this.props.cart['sale_order_lines'][lineId]['product_uom_qty']}</td>
                            }
                            <td>&#8377; {this.props.cart['sale_order_lines'][lineId]['product_price']}</td>
                            <td>&#8377; {this.props.cart['sale_order_lines'][lineId]['price_subtotal']}</td>
                            <td>
                                {(this.props.cart['sale_order_lines'][lineId]['name'].toLowerCase().startsWith("home delivery") || this.props.cart['sale_order_lines'][lineId]['name'].toLowerCase().startsWith("pickup")) ? 
                                    <></>
                                    :
                                    <Delete 
                                        style={{cursor:'pointer'}}
                                        onClick={(e)=>this.props.handleQuantityChange(-1,parseInt(lineId))}
                                    />
                                }
                            </td>
                        </tr>
                    ))}
                </table>
                </Grid>
                </Grid>
            </div>
        );
    }
}
 
export default SaleOrder;
