import React from 'react';
import './StoreProducts.css';
import { LotSerialTreeData } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import { Button, Grid, Typography ,TextField} from '@mui/material';
import urls from '../../../urls';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';


class LotSerialTree extends React.Component{
    state = {
        fetchingData : false,
        productId:null,
        lotLines:true,
        lotData:false
    }

    componentDidMount(){
        changeAppTitle('Console | Lot Serial Numbers');
        let productId = this.props.match.params.productId;
        this.setState({productId});
        this.fetchLotTree(productId);
        
    }

    fetchLotTree=async(id)=>{
        this.setState({fetchingData:true});
        let res  = await LotSerialTreeData({id});
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else {
            // this.setState({ productData: res.result.data.product, form_options: res.result.data.form_options });
            this.setState({lotData: res.result.data})
        }
        this.setState({fetchingData:false});
    }


    
    goTolot=(lotId = false)=>{
        this.props.history.push(urls.extension + '/product/lot/' +(this.state.productId)+'/'+(lotId || 'New'));
    }

    render(){
        return(
            <>
            <div className="store-orders">
                <Grid container spacing={3}>
                    <Grid item >
                    <Typography variant='h6' sx={{ fontWeight: 'bold'}}>Products</Typography>
                    </Grid>
                    <Grid item >
                                
                                <Button 
                                    style={{background:"#02A457",color:"beige",fontSize: '13px'}} 
                                    onClick={()=>this.goTolot()}
                                >
                                    Create 
                                </Button>
                    </Grid>
                </Grid>
             
                
            {this.state.lotData &&
                    <table className="order-table">
                        <thead>
                            <tr>
                                <th style={{padding:'6px'}}>Lot/Serial Number</th>
                                <th style={{padding:'6px'}}>Internal Reference</th>
                                <th style={{padding:'6px'}}>Product</th>
                                <th style={{padding:'6px'}}>Created on</th>
                            </tr>
                        </thead>
                        <tbody>
                            {}
                            {this.state.lotData.map((lot)=>(
                                <tr onClick={()=>this.goTolot(lot.id)}>
                                    <td>{lot.name}</td>
                                    <td>{lot['ref']}</td>
                                    <td>{lot['product_name']}</td>
                                    <td>{lot['create_date']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>

            </>
        )
    }

}

export default LotSerialTree;