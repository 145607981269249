import React from 'react';
// import './StoreProducts.css';
import { storeProductVariantTree } from '../../../Shared/consoleServices';
import { changeAppTitle } from '../../../Shared/utilities';
import Loader from '../../../Components/Shared/Loader';
import { ArrowBack,ArrowForward } from '@material-ui/icons';
import urls from '../../../urls';
import { Button, Grid, Typography ,TextField} from '@mui/material';
// Ankit Start 22 March 2023
import SearchIcon from '@mui/icons-material/Search';
// Ankit End 22 March 2023



// Ankit Start 22 March 2023
const Header=(props)=>{
    return(
        <div className='console__header'>
             <div className='dropdown'>
             <Grid container spacing={1}>
                {Object.keys(props.typeOptions).map((type)=>(
                        <Grid item >
                        <Button 
                            variant={`${(props.selectedOptions['type'] === props.typeOptions[type]?'contained':'outlined')}`} 
                            size="small"
                            onClick={(e)=>props.handleChangeOptions(e,'type')}
                            value = {props.typeOptions[type]}
                            
                        >
                            {type}
                        </Button>
                        </Grid>
                    
                ))}
                </Grid>
            </div>
        </div>
    )
    
}
// Ankit End 22 March 2023


class StoreProductVarientTree extends React.Component {


    state = {
        products:null,fetchingData:false,total_products:0,
        memory:{},productPage:0,SearchInput:[],
        // Ankit Start 27 Jan 2023
        productId:false,
        // Ankit End 27 Jan 2023
        // Ankit start 22 March 2023
        productType:null,
        selectedOptions:{type:null},
        typeOptions:{'All':'all','Storable Products':'product','Consumable Type':'consu','Service Type':'service'},
        user_select_option: false,
        // Ankit End 22 March 2023
    }

    componentDidMount(){
        changeAppTitle('Console | Products');
        // Ankit Start 27 jan 2023
        const productId = this.props.match.params.productId || false;
        if(productId){
            this.setState({productId:productId})
        this.storeProducts(this.state.productPage,null,null,productId);
        }else{
        this.storeProducts(this.state.productPage);
        // Ankit Start 22 March 2023
        this.setState({selectedOptions:{type:'product'},productType:'product'});
        // Ankit End 22 March 2023
        }
        // Ankit End 27 jan 2023

    }


    // Ankit Start 20 March 2023
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getMoreData();            

        }        
      }
    // Ankit End 20 March 2023
    // Ankit Start 27-1-2023
    // storeProducts=async(page,searchbar=null)=>{
    // Ankit Start 22 March 2023
    // storeProducts=async(page,searchbar=null,productId=false)=>{
    storeProducts=async(page,searchbar=null,productType='product',productId=false,)=>{
    // Ankit End 22 March 2023 
    // Ankit Start 27-1-2023
        this.setState({fetchingData:true});
        // Ankit Start 27 Jan 2023
        // let res  = await storeProductVariantTree({page,searchbar});
        // Ankit Start 22 Dec 2023
        // let res  = await storeProductVariantTree({page,searchbar,productId});
        let user_select = this.state.user_select_option
        let res  = await storeProductVariantTree({page,searchbar,productId,productType,user_select});
        let selectedOptions = this.state.selectedOptions;
        // Ankit End 22 Dec 2023
        // Ankit End 27 Jan 2023
        if(res.error || res.result.errorMessage){
            alert(res.error || res.result.errorMessage);
        }
        else{
            // Ankit Start 22 March 2023
            if(res.result.data.is_service_company == true && this.state.user_select_option == false){
                selectedOptions = {selectedOptions:{type:'service'},productType:'service'}
            }
            // Ankit End 22 March 202
            this.setState({products:res.result.data.products,
                            total_products:res.result.data.total_products,
                            productPage:page,
                            // Ankit Start 22 March 2023
                            ...selectedOptions
                            // Ankit End 22 March 2023
                        });
        }
        this.setState({fetchingData:false});
    }


    // Ankit Start 22 March 2023
    handleChangeOptions=async(e,option)=>{
        let value = '';
        if(typeof(e) === 'number')
            value = e;
        else
            value = e.target.value;
        let selectedOptions = this.state.selectedOptions;
        switch(option){ 
            case 'type':
                selectedOptions = {...selectedOptions,...{type:value}}
                break;
            default:
                alert('Wrong input');
        }
        await this.setState({selectedOptions});
        this.setState({productType:value,user_select_option:true});
        this.storeProducts(this.state.productPage,null,value);
    }
    // Ankit End 22 March 2023

    goToProduct=(productId=false)=>{
        this.props.history.push(urls.extension + '/console/productvarient/' + (productId || 'New'));
    }

    changeProductsPage=async(page)=>{
        if(page>=0 && this.state.total_products>=((this.state.productPage * 50) + Object.keys(this.state.products).length)){
            let memory = this.state.memory;
            memory[this.state.productPage] = this.state.products;
            this.setState({memory});
            if(page in memory){
                let products = memory[page];
                this.setState({products,productPage:page});
            }
            else
                this.storeProducts(page);
        }
    }
    getDisplayImage(imageToUpload){
        if(typeof(imageToUpload) !== typeof('string'))
            imageToUpload = URL.createObjectURL(imageToUpload);
        else
            imageToUpload = urls.domain + imageToUpload;
        return imageToUpload;
    }
    getMoreData = () =>{
		console.log('add itemssss')
        // Ankit Start 22 March 2023
		// this.storeProducts(this.state.productPage,this.state.SearchInput)
        const productType=this.state.productType;
		this.storeProducts(this.state.productPage,this.state.SearchInput,productType)
        // Ankit Start 22 March 2023
	}
    GetInput = (SearchInput=null) =>{
        this.setState({SearchInput})
    }

    // Ankit Start 29-11-2022
    discardPage = ()=>{    
        this.props.history.goBack();
    }
    // Ankit End 29-11-2022

    render() { 
        return (
            <div className="store-orders">
                <div style={{width:"100%"}}>
                    {/* Ankit Start 27 jan 2023 */}
                    {!this.state.productId &&
                    // Ankitr Start 22 March 2023
                    <Grid container spacing={3}>
                    <Grid item>
                    {/* // Ankitr Start 22 March 2023 */}
                        {/* Ankit Start 5 April commented because no need to create Varinat form here */}
                    {/* <Button 
                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px'}} 
                        onClick={()=>this.goToProduct()}
                    >
                        Create 
                    </Button> */}
                        {/* Ankit End 5 April commented because no need to create Varinat form here */}

                    {/* Ankit End 27 jan 2023 */}
                    {/* Ankit Start 29-11-2022 */}
                    <Button 
                        style={{background:"#02A457",color:"beige",maxHeight: '30px',fontSize: '13px', margin:'0 10px'}} 
                        onClick={()=>this.discardPage()}
                    >
                        Discard 
                    </Button>
                        </Grid>

                             {/* Ankit Start 22 March 2023  */}
                        <Grid item >

                        <TextField onChange={(e) => this.GetInput(e.target.value)} 
                        id="outlined-search" label="Enter Product Name" 
                        name='searchbar'   
                        onKeyDown={this._handleKeyDown}                    
                        
                        type="search" size="small" InputProps={{
                            endAdornment: (
                                <SearchIcon onClick={this.getMoreData} size="Normal"/>
                            ),}} >                        
                        </TextField>
                               
                    </Grid>
                    <Grid item >
                     <Header 
                    typeOptions = {this.state.typeOptions}
                    selectedOptions = {this.state.selectedOptions}
                    handleChangeOptions = {this.handleChangeOptions}
                    />
                    <br></br>
                    </Grid>
                    </Grid>
                    }
                    {/* Ankit End 22 March 2023 */}
                    {/* Ankit End 29-11-2022 */}
                    {this.state.products && Object.keys(this.state.products).length>0 &&
                        <div className="products-pagination" style={{float:"right"}}>
                            <span>
                                <ArrowBack onClick={()=>this.changeProductsPage(this.state.productPage-1)} style={{cursor:'pointer'}}/>
                                <span>{`${(this.state.productPage * 50)+1}-${(this.state.productPage * 50) + Object.keys(this.state.products).length}/${this.state.total_products}`}</span>
                                <ArrowForward onClick={()=>this.changeProductsPage(this.state.productPage+1)} style={{cursor:'pointer'}}/>
                            </span>
                        </div>
                    }
                </div>
                {/* Ankit Start 27-1-2023 */}
                {!this.state.productId &&
                    <div>
                        {/* <span>
                        <input 
                        type='text' 
                        name='searchbar'
                        onChange={(e) => this.GetInput(e.target.value)} 
                        placeholder='enter product detail'/>
                        </span>
                        <span>
                            <span  onClick={this.getMoreData} style={{backgroundColor:'green',padding:'8px'}}>+</span>
                        </span> */}
                        
               
                    </div>
                    }
                {/* Ankit End 27-1-2023 */}
						
                {this.state.products &&
                    <Grid container >
                    <Grid item xs={12} sm={12} md={12} style={{overflow:'scroll'}}>
                    <table className="order-table">
                        <thead>
                            <tr>
                                
                                <th style={{padding:'6px'}}>Internal Reference</th>
                                <th style={{padding:'6px'}}>image</th>
                                <th style={{padding:'6px'}}>Name</th>
                                <th style={{padding:'6px'}}>Variants Price</th>
                                <th style={{padding:'6px'}}>Sales Price</th>
                                <th style={{padding:'6px'}}>Cost</th>
                                <th style={{padding:'6px'}}>Type</th>
                                <th style={{padding:'6px'}}>Qty(On hand)</th>
                                <th style={{padding:'6px'}}>Qty(Forecasted)</th>
                                <th style={{padding:'6px'}}>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.products).map((productId)=>(
                                <tr onClick={()=>this.goToProduct(productId)}>
                                <td>{this.state.products[productId]['default_code']}</td>
                                <td>
                                    
                                        <img style={{width:40}} src={this.getDisplayImage( this.state.products[productId]['image'])}  alt="upload" />
                                
                                </td>
                                    
                                    <td>{this.state.products[productId]['name']}</td>
                                    <td>{this.state.products[productId]['variant_price']}</td>
                                    <td>&#8377; {this.state.products[productId]['list_price']}</td>
                                    <td>&#8377; {this.state.products[productId]['standard_price']}</td>
                                    <td>{this.state.products[productId]['type']}</td>
                                    <td>{this.state.products[productId]['qty_available']}</td>
                                    <td>{this.state.products[productId]['virtual_available']}</td>
                                    <td>
                                        <input
                                            name="active-product"
                                            type="checkbox"
                                            disabled={true}
                                            checked={this.state.products[productId]['active']}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </Grid>
                    </Grid>
                }
                <Loader open={this.state.fetchingData} handleClose={()=>{}}/>
            </div>
        );
    }
}
 
export default StoreProductVarientTree;
